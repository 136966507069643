import { call, put, select, takeLatest, all } from 'redux-saga/effects';
import request from '../../utils/request';
import moment from 'moment/min/moment-with-locales';
import { LOAD_HOMEDATA } from './constants';
import { setHomeEvents, setHomeNews, setHomeProfiles } from './actions';

import { API_ROOT, LE_ROOT } from '../../api-config';
/**
 * Github repos request/response handler
 */
export function* getEvenemangHome() {
  // Select username from store
  const starttimeevent = '';
  let endtimeevent = '';
  const referencetime = moment();
  // starttimeevent =  moment(referencetime.utc()).isoWeekday(1).startOf('day').toISOString();
  endtimeevent = moment(referencetime.utc())
    .isoWeekday(7)
    .endOf('day')
    .toISOString();
  const requestURL = `${LE_ROOT}event/?data_source=kulturforum,osterbotten,luckan,place2go,place2go_hango,place2go_inga,place2go_raseborg,strax&include=location,keywords&page_size=6&super_event=null&sort=end_time&start=today&end=${endtimeevent}`;


  const requestURLNews = `${API_ROOT}news/?limit=7`;
  const requestURLProfile = `${API_ROOT}posters/?profile=true&service=kulturforum.fi&isfritid=false&limit=7`;

  const [evenemanghome, newshome, profilehome] = yield all([
    call(request, requestURL),
    call(request, requestURLNews),
    call(request, requestURLProfile),
  ]);

  yield put(setHomeEvents(evenemanghome.data));
  yield put(setHomeNews(newshome.results));
  yield put(setHomeProfiles(profilehome.results));
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* homeWatcher() {
  yield takeLatest(LOAD_HOMEDATA, getEvenemangHome);
}
