import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import Kontakta from '../../images/kontakta.png';
import A from '../../components/A';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


const styles = theme => ({

});

function Dashboard(props) {
  const { classes, locale } = props;
  let { tabvalue }  = props;
  if(!tabvalue) {
    tabvalue = 0;
  }

  return (
    <Tabs value={tabvalue} TabIndicatorProps={{style: {backgroundColor:'#3949AB'}}} centered >
      <Tab  component={Link} to="/uppgifter"   label="EGNA UPPGIFTER"  />
      <Tab  component={Link} to="/groups" label="ORGANISATIONER"/>
    </Tabs>
  );
}


export default withStyles(styles)(Dashboard);
