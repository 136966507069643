/**
 * Direct selector to the AddEvenemang state domain
 */
import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the AddEvenemang state domain
 */

const selectSingleProfileEdit = state =>
  state.get('profilesingleedit', initialState);

const makeSingleProfileEdit = () =>
  createSelector(
    selectSingleProfileEdit,
    nyhetState => nyhetState.toJS(),
  );

const makeSelectProfile = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('profile'),
  );
const getFullProfile = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('fullprofile'),
  );
const getFirstName = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('firstname'),
  );
const getOwnEvents = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('events'),
  );
const getError = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('error'),
  );
const getProfiles = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('profiles'),
  );
const getLoading = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('loading'),
  );
const getLastName = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('lastname'),
  );
const getDescription = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('description'),
  );
const getGroupName = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('groupname'),
  );
  const getUrls = () =>
    createSelector(
      selectSingleProfileEdit,
      profileState => profileState.get('urls'),
    );
  const getContact = () =>
    createSelector(
      selectSingleProfileEdit,
      profileState => profileState.get('contact'),
    );
  const getPhone = () =>
    createSelector(
      selectSingleProfileEdit,
      profileState => profileState.get('phone'),
    );
  const getTitle = () =>
    createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('title'),
  );
const makeSelectValue = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('value'),
  );
const makeSelectPosterCategories = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('postercategories'),
  );
const getSelectedLocations = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('selectedlocations'),
  );
const getSelectedGroups = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('selectedgroups'),
  );
const getSelectedProfiles = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('selectedprofiles'),
  );
const makeSelectPictures = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('pictures'),
  );
const makeSelectPicturesProfile = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('profilepictures'),
  );
const getFyrk = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('fyrk'),
  );
const getFreq = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('freq'),
  );

const getHeaderImage = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('previousheaderimage'),
  );

const getProfileImage = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('previousprofileimage'),
  );

const getEditorState = () =>
  createSelector(
    selectSingleProfileEdit,
    profileState => profileState.get('editorstate'),
  );
export {
  getHeaderImage,
  getProfileImage,
  selectSingleProfileEdit,
  getEditorState,
  getError,
  getLoading,
  getFyrk,
  getDescription,
  getLastName,
  getFirstName,
  makeSelectProfile,
  makeSelectValue,
  makeSelectPosterCategories,
  getSelectedLocations,
  getSelectedGroups,
  getSelectedProfiles,
  getOwnEvents,
  makeSelectPictures,
  makeSelectPicturesProfile,
  getTitle,
  getGroupName,
  getUrls,
  getContact,
  getPhone,
  getProfiles,
  getFullProfile,
};
