/*
 *
 * ResetPage constants
 *
 */

export const DEFAULT_ACTION = 'app/ResetPage/DEFAULT_ACTION';
export const LOGIN_CHANGE = 'app/ResetPage/LOGIN_CHANGE';
export const PASSWORD_CHANGE = 'app/ResetPage/PASSWORD_CHANGE';
export const PASSWORDCONFIRM_CHANGE = 'app/ResetPage/PASSWORDCONFIRM_CHANGE';
export const SUBMITREGISTER = 'app/ResetPage/SUBMITREGISTER';
export const AUTH_FAILURE = 'app/ResetPage/AUTH_FAILURE';
export const REGISTER_SUCCESS = 'app/ResetPage/REGISTER_SUCCESS';
export const SET_LOADING = 'app/ResetPage/SET_LOADING';
export const SET_ERROR = 'app/ResetPage/SET_ERROR';
