import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import List from '../../components/List';
import ListItem from '../../components/ListItem';
import LoadingIndicator from '../../components/LoadingIndicator';
import KulturListItem from '../../containers/KulturListItem';
import KulturListItemCard from '../../containers/KulturListItemCard';

import Masonry from 'react-masonry-component';

const masonryOptions = {
  transitionDuration: 0,
};

const imagesLoadedOptions = { background: '.my-bg-image-el' };

const styles = theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'left',
  },
  control: {
    padding: theme.spacing(2),
  },
  griditem: {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },

    [theme.breakpoints.up('md')]: {
      width: '33%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '25%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '20%',
    },
    [theme.breakpoints.up('xxl')]: {
      width: '16.5%',
    },
  },
  griditemhome: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },

    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '33%',
    },
  },
});
function KulturList(props) {
  const {
    loading,
    error,
    kultur,
    masonryview,
    listview,
    user,
    userid,
    favoriter,
  } = props;
  const { classes } = props;
  let homepage = false;
  let listclass = classes.griditem;

  if (props.home) {
    homepage = true;
    listclass = classes.griditemhome;
  }
  let limit = 0;
  let limitset = false;
  if (props.limit) {
    limit = props.limit;
    limitset = true;
  }
  /* if (loading) {
    return <List component={LoadingIndicator} />;
  } */

  if (error !== false) {
    const ErrorComponent = () => (
      <ListItem item="Something went wrong, please try again!" />
    );
    return <List component={ErrorComponent} />;
  }
  if (kultur !== false && masonryview) {
    return (
      <Masonry
        className="my-gallery-class" // default ''
        options={masonryOptions} // default {}
        columns={4} // default 2
        disableImagesLoaded={false} // default false
        updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
        imagesLoadedOptions={imagesLoadedOptions} // default {}
      >
        {kultur.map((card, i) => {
          if (limitset && i >= limit) {
          } else {
            return (
              <Grid key={`${card.uid}grid`} className={listclass} item xs={12}>
                <KulturListItemCard
                  user={user}
                  userid={userid}
                  item={card}
                  key={`${card.uid}carditem`}
                  favoriter={favoriter}
                />
              </Grid>
            );
          }
        })}
      </Masonry>
    );
  }
  if (kultur !== false && listview) {
    return (
      
      <Grid
        container
        justifyContent="flex-start"
        className={classes.root}
        spacing={6}
      >
        {kultur.map((card, i) => (
          <KulturListItem
            user={user}
            userid={userid}
            item={card}
            key={`${card.uid}listitem`}
            favoriter={favoriter}
          />
        ))}
      </Grid>
    );
  }
  return null;
}

KulturList.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  kultur: PropTypes.any,
};

export default withStyles(styles)(KulturList);
