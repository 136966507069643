/*
 * Home Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */
import {
  EVENTS_LOADED,
  LOAD_EVENTS,
  CHANGE_EDITORSTATE,
  CHANGE_FYRK,
  CHANGE_DESCRIPTION,
  CHANGE_TITLE,
  CHANGE_GROUPNAME,
  CHANGE_FULLPROFILE,
  CHANGE_FIRSTNAME,
  CHANGE_LASTNAME,
  CLEAR_IMAGE,
  CLEAR_PROFILE,
  IMAGE_DROP,
  CHANGE_CATEGORY,
  CHANGE_POSTERCATEGORY,
  CHANGE_LOCATION,
  CHANGE_GROUP,
  CHANGE_URLS,
  CHANGE_CONTACT,
  CHANGE_PHONE,
  CHANGE_PROFILES,
  SUBMIT_EDITPROFILEFORM,
  IMAGE_DROP_PROFILE,
  ADD_KULTUR,
  REMOVE_KULTUR,
  CHANGE_FREQ,
  LOAD_PROFILE,
  CLEAR_FORM,
  PROFILE_LOADED,
  SET_ERROR,
  SET_LOADING,
  PROFILES_LOADED,
} from './constants';
export function profilesLoaded(value) {
  return {
    type: PROFILES_LOADED,
    value,
  };
}
export function setError(value) {
  return {
    type: SET_ERROR,
    value,
  };
}
export function setLoading(value) {
  return {
    type: SET_LOADING,
    value,
  };
}
export function clearForm() {
  return {
    type: CLEAR_FORM,
  };
}
export function changeFyrk() {
  return {
    type: CHANGE_FYRK,
  };
}

export function saveEvents(events) {
  return {
    type: EVENTS_LOADED,
    events,
  };
}
export function loadEvents() {
  return {
    type: LOAD_EVENTS,
  };
}
export function changeEditorState(value) {
  return {
    type: CHANGE_EDITORSTATE,
    value,
  };
}
export function profileLoaded(profile) {
  return {
    type: PROFILE_LOADED,
    profile,
  };
}
export function clearImage() {
  return {
    type: CLEAR_IMAGE,
  };
}
export function clearProfile() {
  return {
    type: CLEAR_PROFILE,
  };
}
export function handleChange(value) {
  return {
    type: CHANGE_CATEGORY,
    value,
  };
}
export function handleChangePosterCategory(value) {
  return {
    type: CHANGE_POSTERCATEGORY,
    value,
  };
}

export function changeDescription(value) {
  return {
    type: CHANGE_DESCRIPTION,
    value,
  };
}
export function changeTitle(value) {
  return {
    type: CHANGE_TITLE,
    value,
  };
}
export function changeFullProfile() {
  return {
    type: CHANGE_FULLPROFILE,
  };
}
export function changeGroupName(value) {
  return {
    type: CHANGE_GROUPNAME,
    value,
  };
}
export function changeUrls(value) {
  return {
    type: CHANGE_URLS,
    value,
  };
}
export function changeContact(value) {
  return {
    type: CHANGE_CONTACT,
    value,
  };
}
export function changePhone(value) {
  return {
    type: CHANGE_PHONE,
    value,
  };
}
export function changeFirstName(value) {
  return {
    type: CHANGE_FIRSTNAME,
    value,
  };
}
export function changeLastName(value) {
  return {
    type: CHANGE_LASTNAME,
    value,
  };
}
export function imageDrop(image) {
  return {
    type: IMAGE_DROP,
    value: image,
  };
}
export function imageDropProfile(image) {
  return {
    type: IMAGE_DROP_PROFILE,
    value: image,
  };
}
export function submitEditProfileForm() {
  return {
    type: SUBMIT_EDITPROFILEFORM,
  };
}
export function changeLocation(value) {
  return {
    type: CHANGE_LOCATION,
    value,
  };
}
export function changeGroup(value) {
  return {
    type: CHANGE_GROUP,
    value,
  };
}
export function changeProfiles(value) {
  return {
    type: CHANGE_PROFILES,
    value,
  };
}
export function loadProfileSingle(id) {
  return {
    type: LOAD_PROFILE,
    id,
  };
}
