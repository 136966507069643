/**
 * Homepage selectors
 */

import { createSelector } from 'reselect';

const selectEvenemang = state => state.get('evenemanger');

const makeSelectUsername = () =>
  createSelector(selectEvenemang, EvenemangState =>
    EvenemangState.get('username'),
  );

const makeSelectListView = () =>
  createSelector(selectEvenemang, EvenemangState =>
    EvenemangState.get('listview'),
  );
const makeSelectMasonryView = () =>
  createSelector(selectEvenemang, EvenemangState =>
    EvenemangState.get('masonryview'),
  );

const getPage = () =>
  createSelector(selectEvenemang, EvenemangState => EvenemangState.get('page'));
const getPageSize = () =>
  createSelector(selectEvenemang, EvenemangState =>
    EvenemangState.get('pagesize'),
  );
const getMore = () =>
  createSelector(selectEvenemang, EvenemangState => EvenemangState.get('more'));

export {
  selectEvenemang,
  getPage,
  getPageSize,
  makeSelectUsername,
  makeSelectMasonryView,
  makeSelectListView,
  getMore,
};
