/*
 * Home Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
  LOAD_PROFILE,
  SET_MENUOPEN,
  SET_ANCHOREL,
  LOAD_EVENTS,
  LOAD_FYRK,
  LOAD_GROUP_FYRK,
  PROFILE_LOADED,
  SIMILAR_LOADED,
  SOMMAR_LOADED,
  EVENTS_LOADED,
  SET_EXPANDED,
  SET_TAB,
  CLOSE_EVENT,
  SET_EVENT,
  FYRK_LOADED,
  OPEN_DIALOG,CLOSE_DIALOG,SET_CURRENT_USER
} from './constants';


export function setAnchorEl(value) {
  return {
    type: SET_ANCHOREL,
    value,
  };
}
export function toggleMenu(value) {
  return {
    type: SET_MENUOPEN,
    value
  };
}
export function setEvent(value) {
  return {
    type: SET_EVENT,
    value
  };
}
export function profileLoaded(profile) {
  return {
    type: PROFILE_LOADED,
    profile,
  };
}
export function eventsLoaded(event) {
  return {
    type: EVENTS_LOADED,
    event,
  };
}
export function fyrkLoaded(fyrk) {
  return {
    type: FYRK_LOADED,
    fyrk,
  };
}
export function sommarEventsLoaded(event) {
  return {
    type: SOMMAR_LOADED,
    event,
  };
}
export function loadEvents(event) {
  return {
    type: LOAD_EVENTS,
    event,
  };
}
export function loadFyrk(fyrk) {
  return {
    type: LOAD_FYRK,
    fyrk,
  };
}
export function loadGroupFyrk(event) {
  return {
    type: LOAD_GROUP_FYRK,
    event,
  };
}
export function saveSimilar(similar) {
  return {
    type: SIMILAR_LOADED,
    similar,
  };
}
export function setExpanded(value) {
  return {
    type: SET_EXPANDED,
    value,
  };
}
export function setTab(value) {
  return {
    type: SET_TAB,
    value,
  };
}
export function loaduserProfiles(id) {
  return {
    type: LOAD_PROFILE,
    id,
  };
}
export function openDialog() {
  return {
    type: OPEN_DIALOG,
  };
}
export function closeDialog() {
  return {
    type: CLOSE_DIALOG,
  };
}
export function closeEvent() {
  return {
    type: CLOSE_EVENT,
  };
}
export function setCurrentUser(user, usergroup) {
  return {
    type: SET_CURRENT_USER,
    user, usergroup
  };
}

