/*
 * HomeReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */
import { fromJS } from 'immutable';

import {
  CHANGE_USERNAME,
  CHANGE_PAGE,
  CHANGE_PAGESIZE,
  TO_LIST,
  TO_MASONRY,
  CHANGE_MORE,
} from './constants';

// The initial state of the App
const initialState = fromJS({
  username: '',
  masonryview: true,
  listview: false,
  pagesize: 30,
  page: 1,
  more: false,
});

function evenemangReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PAGE:
      return state.set('page', action.value);
    case CHANGE_PAGESIZE:
      return state.set('pagesize', action.value);
    case CHANGE_MORE:
      return state.set('more', action.value);
    case CHANGE_USERNAME:
      return state.set('username', action.name.replace(/@/gi, ''));
    case TO_LIST:
      return state.set('listview', true).set('masonryview', false);
    case TO_MASONRY:
      return state.set('masonryview', true).set('listview', false);
    default:
      return state;
  }
}

export default evenemangReducer;
