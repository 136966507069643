
import {
  LOAD_EVENEMANG,
  EVENEMANG_LOADED,
  SUBEVENEMANG_LOADED,
  DELETE_EVENEMANG,
  CLOSE_DIALOG,
  SET_PROFILE,
  OPEN_DIALOG,
  SET_TABVALUE,
  SET_TABVALUE_TITLE
} from './constants';

export function evenemangLoaded(evenemang) {

  return {
    type: EVENEMANG_LOADED,
    evenemang,
  };
}
export function subevenemangLoaded(subevenemang) {

  return {
    type: SUBEVENEMANG_LOADED,
    subevenemang,
  };
}
export function setTabValue(value) {

  return {
    type: SET_TABVALUE,
    value,
  };
}
export function setTabValueTitle(value) {

  return {
    type: SET_TABVALUE_TITLE,
    value,
  };
}
export function openDialog() {

  return {
    type: OPEN_DIALOG,
  };
}
export function closeDialog() {

  return {
    type: CLOSE_DIALOG,
  };
}
export function setProfile(profile) {
  return {
    type: SET_PROFILE,
    profile,
  };
}
export function loadEvenemangSingle(id) {
  return {
    type: LOAD_EVENEMANG,
    id,
  };
}


