/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */
import _ from 'lodash';

export const LOAD_PROFILE = 'boilerplate/ProfileSingleEdit/LOAD_PROFILE';
export const PROFILE_LOADED = 'boilerplate/ProfileSingleEdit/PROFILE_LOADED';
export const CHANGE_CATEGORY = 'app/ProfileSingleEdit/CHANGE_CATEGORY';
export const CHANGE_POSTERCATEGORY =
  'app/ProfileSingleEdit/CHANGE_POSTERCATEGORY';
export const CHANGE_LOCATION = 'app/ProfileSingleEdit/CHANGE_LOCATION';
export const CHANGE_GROUP = 'app/ProfileSingleEdit/CHANGE_GROUP';
export const SUBMIT_EDITPROFILEFORM =
  'app/ProfileSingleEdit/SUBMIT_EDITPROFILEFORM';
export const IMAGE_DROP = 'app/ProfileSingleEdit/IMAGE_DROP';
export const IMAGE_DROP_PROFILE = 'app/ProfileSingleEdit/IMAGE_DROP_PROFILE';
export const CHANGE_DESCRIPTION = 'app/ProfileSingleEdit/CHANGE_DESCRIPTION';
export const CHANGE_TITLE = 'app/ProfileSingleEdit/CHANGE_TITLE';
export const CHANGE_FYRK = 'app/ProfileSingleEdit/CHANGE_FYRK';
export const EVENTS_LOADED = 'boilerplate/ProfileSingleEdit/EVENTS_LOADED';
export const LOAD_EVENTS = 'boilerplate/ProfileSingleEdit/LOAD_EVENTS';
export const CLEAR_IMAGE = 'boilerplate/ProfileSingleEdit/CLEAR_IMAGE';
export const CLEAR_PROFILE = 'boilerplate/ProfileSingleEdit/CLEAR_PROFILE';
export const CLEAR_FORM = 'boilerplate/ProfileSingleEdit/CLEAR_FORM';

export const CHANGE_LASTNAME = 'app/ProfileSingleEdit/CHANGE_LASTNAME';
export const CHANGE_FIRSTNAME = 'app/ProfileSingleEdit/CHANGE_FIRSTNAME';
export const CHANGE_GROUPNAME = 'app/ProfileSingleEdit/CHANGE_GROUPNAME';
export const CHANGE_URLS = 'app/ProfileSingleEdit/CHANGE_URL';
export const CHANGE_CONTACT = 'app/ProfileSingleEdit/CHANGE_CONTACT';
export const CHANGE_PHONE = 'app/ProfileSingleEdit/CHANGE_PHONE';

export const SET_ERROR = 'app/ProfileSingleEdit/SET_ERROR';
export const SET_LOADING = 'app/ProfileSingleEdit/SET_LOADING';

export const CHANGE_EDITORSTATE = 'app/ProfileSingleEdit/CHANGE_EDITORSTATE';
export const ADD_KULTUR = 'app/ProfileSingleEdit/ADD_KULTUR';
export const REMOVE_KULTUR = 'app/ProfileSingleEdit/REMOVE_KULTUR';
export const CHANGE_FREQ = 'app/ProfileSingleEdit/CHANGE_FREQ';
export const PROFILES_LOADED = 'app/ProfileSingleEdit/PROFILES_LOADED';
export const CHANGE_PROFILES = 'app/ProfileSingleEdit/CHANGE_PROFILES';
export const CHANGE_FULLPROFILE = 'app/ProfileSingleEdit/CHANGE_FULLPROFILE';
