import { call, put, select, takeLatest } from 'redux-saga/effects';
import request from '../../utils/request';
import { push } from 'connected-react-router';
import moment from 'moment/min/moment-with-locales';
import _ from 'lodash';

import { LOAD_EVENEMANG, DELETE_EVENEMANG } from './constants';
import { evenemangLoaded, setProfile, subevenemangLoaded } from './actions';
import { API_ROOT, LE_ROOT } from '../../api-config';
import 'moment/locale/sv';
/**
 * Github repos request/response handler
 */
const fetchJSON = (url, options = {}) =>
  new Promise((resolve, reject) =>
    fetch(url, options)
      .then(response =>
        response.status !== 201 &&
        response.status !== 200 &&
        response.status !== 400
          ? reject(response)
          : response,
      )
      .then(response => {
        if (response.status == 400) {
          response.json().then(error => reject(error));
        } else {
          return response.json();
        }
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => reject(error)),
  );

export function* loadEvenemang(value) {
  const nyhetId = value.id;
  const requestURL = `${LE_ROOT}event/${nyhetId}?include=location,keywords&super_event=null&sort=end_time`;

  try {
    const evenemang = yield call(request, requestURL);

    yield put(evenemangLoaded(evenemang));
    let newArray = [];
    if (evenemang.sub_events && evenemang.sub_events.length > 0) {
      try {
        newArray = yield evenemang.sub_events.map(function(item) {
          let url = item['@id'];
          url = url.replace(/http:/g, 'https:');
          return call(fetchJSON, url);
        });
        newArray = _.sortBy(newArray, [
          function(o) {
            return o.start_time;
          },
        ]);
        newArray = _.filter(newArray, function(o) {
          return moment(o.start_time) > moment();
        });
        yield put(subevenemangLoaded(newArray));
      } catch (err) {}
    }

    if (
      evenemang.custom_data &&
      evenemang.custom_data.hasOwnProperty('usergroup_id')
    ) {
      const requestURLprofile = `${API_ROOT}usergroups/${
        evenemang.custom_data.usergroup_id
      }/`;

      try {
        const profile = yield call(request, requestURLprofile);
        yield put(setProfile(profile));
      } catch (err) {}
    }
  } catch (err) {
    yield put(push('/evenemang'));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* githubData() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(LOAD_EVENEMANG, loadEvenemang);
}
