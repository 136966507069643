/**
 * RepoListItem
 *
 * Lists the name and the issue count of a repository
 */
import React from "react";

import PropTypes from "prop-types";

import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import FyrkImage from "../../components/FyrkImage";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@material-ui/core/IconButton";
import _ from "lodash";
import moment from "moment/min/moment-with-locales";

import "moment/locale/sv";
// import saga from './saga'
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import PopFavorite from "../../components/PopFavorite";
import Avatar from "@material-ui/core/Avatar";

import {

  fyrkLoaded,
} from "./actions";


import { addCategory,loadFyrk,  
  likeEvent,
  dislikeEvent,
  deleteFyrk
} from '../App/actions';

import { API_ROOT } from "../../api-config";
const styles = (theme) => ({
  iconcolor: {
    marginRight: "0.5em",
    color: "#666",
  },
  media: {
    height: 0,
    paddingTop: "56.5%", // 16:9
  },
  title: {
    textAlign: "left",
    paddingLeft: "1em",
    width: "100%",
  },
  flexNone: {
    flex: "1",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "7em",
    },
    [theme.breakpoints.up("sm")]: {
        paddingLeft: "1em",
    },
  },
  flexNoneTitle: {
    textAlign: "left",
    width: "100%",
    flex: "1",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "7em",
    },
    [theme.breakpoints.up("sm")]: {
        paddingLeft: "1em",
    }
  },
  categorylink: {
    cursor: "pointer",
    color: "#000000",
  },
  rowitem: {
    [theme.breakpoints.down("xs")]: {
      display: `block`,
    },

    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
    minHeight: "138px",
    backgroundColor: "#E2F3E7",
    marginBottom: "6px",
  },
  avataritem: {
    [theme.breakpoints.down("xs")]: {
      float: `left`,
      margin: "0 16px",
    },
    [theme.breakpoints.up("xs")]: {
      marginRight: "16px !important",
      boxShadow: "1px 3px 4px rgba(0, 0, 0, 0.2)",
      width: "73px",
      height: "73px",
      marginTop: "2.5em"
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
    },
    [theme.breakpoints.up("lg")]: {},
  },
  "@media print": {
    avataritem: {
      display: "none",
    },
    menulink: {
      display: "none",
    },
    editbutton: {
      display: "none",
    },
    deletebutton: {
      display: "none",
    },
  },
  likebutton: {
    color: "#44AB66",
  },
  likedbutton: {
    color: "#44AB66",
  },
});

export class FyrkListItem extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  UNSAFE_componentWillMount() {
    this.tempimage = FyrkImage();
  }

  handleItemClick(id) {
    this.props.history.push(`/fyrkpost/${id}`);
  }

  handleItemClickEdit(evt, id) {
    evt.stopPropagation();
    this.props.history.push(`/fyrkpost/${id}/edit`);
  }

  render() {
    const { item } = this.props;
    const {
      title,
      procurator,
      description,
      fyrkimages,
      categories,
      groups,
      next_period,
      next_period_status,
      prev_period,
      periodtype,
    } = this.props.item;

    const { userid, user, favoriter } = this.props;
    const { classes } = this.props;
    let fyrkimage = this.tempimage;
    let favoriteids = _.map(favoriter, 'urls');
    
    let favorite = false;

    let usergroupid = localStorage.getItem("usergroupid");

    let itempath = API_ROOT+"fyrk/"+item.id+"/"
    if(_.indexOf(favoriteids, itempath) != -1) {
      favorite = true;
    }
    if (fyrkimages.length > 0) {
      fyrkimage = API_ROOT.replace(/\/$/, "") + fyrkimages[0].thumbnail;
      fyrkimage = fyrkimage.replace(/WxH/g, "74x74");
    }
    var datestring = "";
    var periodstatus = "";
    var periodstring = "";
    if (next_period && next_period_status == 1) {
      datestring = "Ansökningstid " + moment(next_period).format("D.M.YYYY");
      periodstatus = " (slutgiltig)";
    }
    if (next_period && next_period_status == 0) {
      datestring = "Ansökningstid " + moment(next_period).format("MMM YYYY");
      periodstatus = " (preliminär)";
    }

    if (next_period == null && prev_period != null) {
      datestring = moment(prev_period).format("MMM YYYY");
      // periodstatus = " (föregående)";
    }
    if (periodtype == "CONTINUOUS") {
      periodstring = (
        <>
          <b>Fortlöpande<br/> ansökningstid</b>
        </>
      );
    } else {
      periodstring = <><b>
        {datestring}{periodstatus}
        </b></>;
    }
    const categorieslist = categories.map((list, index) => {
      if (categories.length > 0 && index < 3) {
        return (
          <a
            key={list.id}
            className={classes.categorylink}
            onClick={(evt) => {
              this.props.addCategory(evt, list.id, list.name);
            }}
          >
            #{list.name}{" "}
          </a>
        );
      }
    });
    let editbutton = "";
    if (user) {
    if (item.user == userid || user.is_staff || usergroupid == item.usergroup) {
      editbutton = (
        <Button
          onClick={(evt) => {
            this.handleItemClickEdit(evt, item.id);
          }}
          variant="text"
          size="small"
          className={classes.editbutton}
        >
          <EditIcon className={classes.leftIcon} />
        </Button>
      );
    }
  }
    let deletebutton = "";
    if (user) {
    if (item.user == userid || user.is_staff || usergroupid == item.usergroup) {
      deletebutton = (
        <Button
          onClick={(evt) => {
            this.props.deleteFyrk(evt, item.id);
          }}
          variant="text"
          size="small"
          className={classes.deletebutton}
        >
          <DeleteIcon className={classes.leftIcon} />
        </Button>
      );
    }
  }

  let likebutton = "";
      if(favorite) {
        likebutton = (
          <IconButton
          onClick={evt => {
            this.props.dislikeFyrk(evt, item.id);
          }}
            className={classes.likebutton}
          >
            <Favorite />
          </IconButton>
        );
      } else {
        likebutton = (
          <IconButton
          onClick={evt => {
            this.props.likeFyrk(evt, item.id);
          }}
            className={classes.likedbutton}
          >
            <PopFavorite />
          </IconButton>
        );
      }
    
    return (
      <ListItem
        className={classes.rowitem}
        key={item.id}
        button
        onClick={() => {
          this.handleItemClick(item.id);
        }}
      >
        <Avatar
          className={classes.avataritem}
          alt="Fyrk Image"
          src={fyrkimage}
        />

        <ListItemText
          className={classes.flexNoneTitle}
          primary={<b>{procurator}</b>}
        />
        <ListItemText className={classes.flexNone} primary={title} />
        <ListItemText
          className={classes.flexNoneTitle}
          primary={periodstring}
        />
        <ListItemText className={classes.flexNone} primary={categorieslist} />
        <ListItem className={classes.flexNone} primary={groups} >
        {likebutton}
        {deletebutton}
        {editbutton}
        </ListItem>
      </ListItem>
    );
  }
}
export function mapDispatchToProps(dispatch) {
  return {
    deleteFyrk: (evt, id) => {
      dispatch(deleteFyrk(id));
      evt.stopPropagation();
    },
    likeFyrk: (evt, id) => {
      dispatch(likeEvent(id, 'FYRK'));
      evt.stopPropagation();
    },
    dislikeFyrk: (evt, id) => {
      dispatch(dislikeEvent(id, 'FYRK'));
      evt.stopPropagation();
    },
    addCategory: (evt, id, value) => {
      evt.stopPropagation();
      dispatch(addCategory(id, value));
      dispatch(loadFyrk());
    },
  };
}
const withConnect = connect(null, mapDispatchToProps);

FyrkListItem.propTypes = {
  item: PropTypes.object,
  classes: PropTypes.object,
};
export default compose(
  withConnect,
  withRouter,
  withStyles(styles)
)(FyrkListItem);
