/**
 *
 * AddEvenemang
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import MenuItem from '@material-ui/core/MenuItem';
import Tabs from '@material-ui/core/Tabs';
import DeleteIcon from '@mui/icons-material/Delete';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import HistoryIcon from '@mui/icons-material/History';

import _ from 'lodash';

import {
  getTotalCategories,
  getBuyLink,
  getError,
  getEditIndex,
  getSearchfieldValue,
  getLinks,
  getDates,
  getProfiles,
  getKultur,
  getPrice,
  getPrices,
  getPriceDescription,
  isFree,
  makeSelectTitleFi,
  makeSelectTitleSv,
  makeSelectTitleEn,
  makeSelectMetasSelected,
  makeSelectLocationValue,
  makeSelectLink,
  makeSelectShortDescriptionFi,
  makeSelectShortDescriptionSv,
  makeSelectShortDescriptionEn,
  makeSelectPictures,
  makeSelectValue,
  selectStartTimeDate,
  selectEndTimeDate,
  selectPlace,
  selectAddress,
  selectPostal,
  selectCity,
  makeSelectGroupsSelected,
  getSelectedMainCategories,
  getSuggestions,
  getTabValue,
  getTabValueTitle,
  getTabValueShort,
  getEditorStateFi,
  getEditorStateSv,
  getEditorStateEn,
  makeSelectDescriptionFi,
  makeSelectDescriptionSv,
  makeSelectDescriptionEn,
  getLanguage,
  getCustomOpen,
  getRSVP,
  getOrg,
  getRegion,
  getSending,
  selectNotisCategories
} from './selectors';
import { hideLeftDrawer,setUSerGroup } from './../App/actions';
import {
  changeBuyLink,
  changeEditorStateSv,
  changeEditorStateFi,
  changeEditorStateEn,
  setEditIndex,
  clearSuggestions,
  setSuggestions,
  handleSearchFieldValue,
  changeProfileName,
  changeProfile,
  addProfile,
  removeProfile,

} from './actions';
import {
  makeSelectCategoriesEvenemang,
  makeSelectLocationsEvenemangFiltered,
  getHelp,
  makeSelectCurrentUser,
  getUserGroupId,
} from "./../App/selectors";
import {
  loadEventSingle,
  changeLinkName,
  changeLink,
  addLink,
  removeLink,
  addPrice,
  removePrice,
  addDate,
  removeDate,
  changePriceDescription,
  changePrice,
  toggleFree,
  imageDrop,
  clearImage,
  restoreImage,
  titleOnChangeEn,
  titleOnChangeSv,
  titleOnChangeFi,
  linkOnChange,
  descriptionOnChangeSv,
  descriptionOnChangeFi,
  descriptionOnChangeEn,
  shortdescriptionOnChangeFi,
  shortdescriptionOnChangeSv,
  shortdescriptionOnChangeEn,
  submitAddEvenemangForm,
  handleChange,
  changeLanguage,
  handleChangeLocation,
  changeStartDate,
  changeEndDate,
  changeAddress,
  changeCity,
  changePlace,
  changeRSVP,
  changePostal,
  addGroup,
  removeGroup,
  addCategoryEvenemang,
  removeCategoryEvenemang,
  changeTabValue,
  changeTabValueTitle,
  changeTabValueShort,
  setCustomOpen,
  changeOrg,
  clearForm
} from './actions';
import { addMeta, removeMeta } from './actions';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import draftToHtml from 'draftjs-to-html';

import reducer from './reducer';
import saga from './saga';
import * as Autosuggest from 'react-autosuggest';
import InputLabel from '@material-ui/core/InputLabel';
import H2 from '../../components/H2';
import H3 from '../../components/H3';
import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/styles/withStyles';
/* eslint-disable react/prefer-stateless-function */
import Button from '@material-ui/core/Button';
import { withCookies } from 'react-cookie';
import ImageUploader from 'react-images-upload';
import Select from 'react-select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import rullstolsv from '../../images/rullstolsv.png';
import synskadade from '../../images/synskadade.png';
import horslinga from '../../images/horslinga.png';
import CardMedia from '@material-ui/core/CardMedia';
import RandomImage from '../../components/RandomImage';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw } from 'draft-js';
import 'moment/locale/sv';
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import sv from 'date-fns/locale/sv';
import {
  makeSelectGroupsEvenemang,getProfile, getRegions
} from './../App/selectors';
import { ThreeDRotationSharp } from '@mui/icons-material';
registerLocale('sv', sv);

const styles = theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'left',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  disableAbsolute : {
    position: "static"
  },
  centered: {
    textAlign: "center"
  },
  errorStyle: {
    color: '#f44336',
    textAlign: 'center',
  },
  helpStyle: {
    color: '#9691BC',
    textAlign: 'left',
    marginTop: '-20px',
    clear: 'both',
  },
  helpStyleCenter: {
    color: '#9691BC',
    textAlign: 'center',
    margin: '0 auto',
  },
  textarea: {
    border: 'none',
    background: '#fff',
  },
  errorBlock: {
    backgroundColor: '#ffa4a4',
    color: '#fff',
    marginTop: 0,
    padding: '0.4em',
  },
  alignbottom: {
    alignSelf: 'flex-end',
    marginBottom: '9px',
  },
  textFieldError: {
    border: '1px solid #ffa4a4 !important',
  },
  wysiwyg: {
    border: '1px solid #ccc',
    marginTop: '1em',
    background: '#fff',
  },
  increasemargin: {
    marginTop: '7px',
  },
  wlabel: {
    fontSize: '17px',
    marginTop: '2em',
  },
  editor: {
    minHeight: '8em',
    padding: '0.4em',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  fullwidth: {
    width: '100%',
    marginBottom: 0,
  },
  removeMargin: {
    marginBottom : 0
  },
  textField: {
    width: '100%',
    border: '1px solid #d6d1f0',
    background: '#fff',
    '&:before': {
      border: 'none',
    },
    paddingLeft: '5px',
  },
  textFieldShortRoot: {
    width: '100%',
  },
  textFieldShort: {
    width: '100%',
    border: '1px solid #d6d1f0',
    background: 'beige',
    '&:before': {
      border: 'none',
    },
    paddingLeft: '5px',
  },
  textFieldShortTime: {
    width: '100%',
    border: '1px solid #d6d1f0',
    background: '#fff',
    '&:before': {
      border: 'none',
    },
    paddingLeft: '5px',
  },
  textFieldRoot: {
    '&:before': {
      border: 'none',
    },
  },
  clearButton: {
    float: 'right',
    marginTop: '2em',
  },
  button: {
    width: '100%',
    textTransform: 'none',
  },
  iconbutton: {
    fontSize: '18px',
  },
  floatleft: {
    float: 'left',
  },
  floatright: {
    float: 'right',
  },
  tabContainer: {
    flexDirection: 'row-reverse',
  },
  paper: {
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3,
    )}px`,
  },
  datepicker: {
    width: '100%',
  },
  imagepaper: {
    marginTop: theme.spacing(1),
    minHeight: '200px',
    backgroundColor: 'rgba(242,122,104,0.11)',
    border: '1px solid #F27A68',
  },
  centerLabel: {
    textAlign: 'center',
    paddingTop: '5em',
  },
  helpertext: {
    color: '#999',
    textAlign: 'right',
  },
  icon: {
    width: '16px',
    height: '16px',
    backgroundColor: '#fff',
    margin: '4px',
    outline: '2px auto #333',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    'input:hover ~ &': {
      backgroundColor: '#fff',
      outline: '2px auto #ccc',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: '#fff',
    },
  },
  form: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  formLabel: {
    fontSize: '0.75rem',
    lineHeight: '1',
  },
  submit: {
    float: 'right',
    marginTop: theme.spacing(3),
  },
  suggestionsContainerOpen: {
    zIndex: 1,
    width: 'auto',
  },
  suggestion: {
    display: 'block',
    width: 'auto',
  },
  cityselect: {
    paddingTop: '0.5em',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    width: '100%',
  },
  picker: {
    width: '100%',
  },
  labelContainer: {
    minWidth: '50px',
  },
  tabRoot: {
    minWidth : '50px',
    textTransform: 'uppercase',
    fontWeight: '900',
    fontSize: '16px', // Some CSS
    fontFamily: ['Barlow'].join(','),
    marginRight: theme.spacing(1),
    color: '#333',
    '&:hover': {
      color: '#000',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#000',
      fontWeight: '900',
    },
    '&:focus': {
      color: '#000',
    },
  },
  tabSelected: {},
  listItem2col: {
    width: '49%',
    display: 'inline-flex',
    float: 'left'
  },
  listItem4col: {
    width: '24%',
    display: 'inline-flex'
  }
});

function renderInput(inputProps) {
  const { classes, ref, ...other } = inputProps;

  return (
    <TextField
      className={classes.textField}
      InputProps={{
        inputRef: ref,
        ...other,
      }}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion, matches);
  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 300 }}>
              {`${part.text.firstname} ${part.text.lastname} ${
                part.text.groupname
              }`}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 500 }}>
              {`${part.text.firstname} ${part.text.lastname} ${
                part.text.groupname
              }`}
            </strong>
          ),
        )}
      </div>
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;
  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion;
}

export class AddEvenemang extends React.Component {
  
  UNSAFE_componentWillMount() {
    this.props.hideSearch();
    this.tempimage = RandomImage();


    const singleid = this.props.match.params.id;
    // const groupid = this.props.match.params.usergroup;
    const groupid = this.props.usergroup;
  /*  const type = this.props.match.params.type;
    if(type) {
      this.type = type;
    } else {
      this.type = "hobby";

    }*/
    this.props.onFormLoad(singleid, groupid);
    
    this.shouldload = true;
    this.name = React.createRef();
    this.description = React.createRef();
    this.locationsection = React.createRef();
    this.scrolled = false;
    this.kategorier = React.createRef();
    this.imageupload = React.createRef();

  }

  getSuggestionValue(suggestion) {
    return suggestion;
  }
  openScroll(refe) {
    this.scrolled = false;
  }

  getSuggestions(value, list) {
    if(value != undefined) {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      let count = 0;
      const result =
        inputLength === 0
          ? []
          : list.filter(suggestion => {
              const keep =
                count < 5 &&
                suggestion.textsearch.includes(inputValue);

              if (keep) {
                count += 1;
              }

              return keep;
      });
      return result;
    }
    return [];
  }


  render() {
    const {
      selectedgroups,
      totalcategories,
      buylink,
      editindex,
      kultur,
      searchfieldvalue,
      profiles,
      links,
      dates,
      price,
      pricedescription,
      tabvalue,
      sending,
      tabvaluetitle,
      tabvalueshort,
      isfree,
      rsvp,
      titlefi,
      titlesv,
      titleen,
      prices,
      descriptionfi,
      descriptionsv,
      descriptionen,
      custom_open,
      user,
      region,
      shortdescriptionfi,
      shortdescriptionen,
      shortdescriptionsv,
      link,
      classes,
      cookies,
      maincategoriesevenemang,
      selectedmaincategories,
      notiscategoriesevenemang,
      pictures,
      groups,
      selectedmetas,
      place,
      postal,
      regions,
      city,
      address,
      helptexts,
      language,
      profile,
      org
    } = this.props;
    const { error,starttimedate,endtimedate,editorstatefi,editorstatesv,editorstateen,locations} = this.props;
    const{error_external_links,error_lokalen,error_address,error_region, error_keywords,error_start_time,error_end_time,error_headerimage,error_short_description,error_description,error_name} = error;
    const errorProps = {
      error: true,
    };
    let allowdescription = false;
    allowdescription = !(editorstatesv.getCurrentContent().hasText());

    let allowdescriptionshort = false;

    allowdescriptionshort = !(shortdescriptionsv != "");
    let allowtitle = false;

    let orgplaceholder = '';


    if(profile.groupname !== '' && !user.is_staff) {
      orgplaceholder = profile.groupname;
    }

    allowtitle = !(titlesv !== "");
    if(!this.scrolled && error && error.error_name && error_name != "") {
      if(this.name.current) {
      this.name.current.scrollIntoView({ behavior: 'smooth' });
      this.scrolled = true;
      }
    }
    if(error_description) {
      error_description_sv = error_description;
    }
    if(!this.scrolled && error && error.error_description && error_description_sv != "") {
      if(this.description.current) {
      this.description.current.scrollIntoView({ behavior: 'smooth' });
      this.scrolled = true;
      }
    }
    if(!this.scrolled && error && error.error_lokalen && error_lokalen != "") {
      if(this.locationsection.current) {
        this.locationsection.current.scrollIntoView({ behavior: 'smooth' });
        this.scrolled = true;
      }
    }
    if(!this.scrolled && error && error.error_address && error_address != "") {
      if(this.locationsection.current) {
      this.locationsection.current.scrollIntoView({ behavior: 'smooth' });
      this.scrolled = true;
      }
    }
    if(!this.scrolled && error && error.error_region && error_region != "") {
      if(this.locationsection.current) {
      this.locationsection.current.scrollIntoView({ behavior: 'smooth' });
      this.scrolled = true;
      }
    }
    if(!this.scrolled && error && error.error_keywords && error_keywords != "") {
      if(this.kategorier.current) {
      this.kategorier.current.scrollIntoView({ behavior: 'smooth' });
      this.scrolled = true;
      }
    }
    if(!this.scrolled && error && error.error_headerimage && error_headerimage != "") {
      if(this.imageupload.current) {
      this.imageupload.current.scrollIntoView({ behavior: 'smooth' });
      this.scrolled = true;
      }
    }
    let event_info = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_info' });
      if (temptext) {
        event_info = temptext.description;
      }
    }

    let event_basinfo = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_basinfo' });
      if (temptext) {
        event_basinfo = temptext.description;
      }
    }

    let event_date = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_date' });
      if (temptext) {
        event_date = temptext.description;
      }
    }

    let event_image = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_image' });
      if (temptext) {
        event_image = temptext.description;
      }
    }

    let event_links = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_links' });
      if (temptext) {
        event_links = temptext.description;
      }
    }

    let event_partner = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_partner' });
      if (temptext) {
        event_partner = temptext.description;
      }
    }

    let event_group = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_group' });
      if (temptext) {
        event_group = temptext.description;
      }
    }

    let event_cat = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_cat' });
      if (temptext) {
        event_cat = temptext.description;
      }
    }

    let event_free = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_free' });
      if (temptext) {
        event_free = temptext.description;
      }
    }

    let event_price = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_price' });
      if (temptext) {
        event_price = temptext.description;
      }
    }

    let event_access = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_access' });
      if (temptext) {
        event_access = temptext.description;
      }
    }

    let event_post = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_post' });
      if (temptext) {
        event_post = temptext.description;
      }
    }

    let event_region = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_region' });
      if (temptext) {
        event_region = temptext.description;
      }
    }

    let event_addres = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_addres' });
      if (temptext) {
        event_addres = temptext.description;
      }
    }

    let event_add = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_add' });
      if (temptext) {
        event_add = temptext.description;
      }
    }


    let event_desc = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_desc' });
      if (temptext) {
        event_desc = temptext.description;
      }
    }

    let event_short = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_short' });
      if (temptext) {
        event_short = temptext.description;
      }
    }

    let event_title = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_title' });
      if (temptext) {
        event_title = temptext.description;
      }
    }

    let event_lokalen = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_lokalen' });
      if (temptext) {
        event_lokalen = temptext.description;
      }
    }

    let event_exception = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'event_exception' });
      if (temptext) {
        event_exception = temptext.description;
      }
    }

    let suggestions = [];
    let error_short_description_sv = null;
    let error_description_sv = null;
    if(error_short_description) {
      error_short_description_sv = error_short_description.sv;
    }
    if(searchfieldvalue && kultur.length > 0) {
      suggestions = this.getSuggestions(searchfieldvalue,kultur);
    }

    const pStyle = {
      background: 'transparent',
      boxShadow : 'none',
    };
    let eventimage = this.tempimage;

    if (pictures && pictures.length > 0) {
      eventimage = pictures;
    }
    let value = this.props.value;
    if (!value) {
      value = [];
    }
    function TabContainer(props) {
      return (
        <div>
          {props.children}
       </div>
      );
    }

    let categories = this.props.categories;
    if(!categories) {
      categories = [];
    }
    let locationvalue = this.props.locationvalue;

    const metas = [
      { id: 'fysisk', name: 'RULLSTOLSVÄNLIGT', imgtag: 'horslinga' },
      {
        id: 'syn',
        name: 'SERVICE TILL HJÄLP FÖR SYNSKADADE',
        imgtag: 'horslinga',
      },
      { id: 'horsel', name: 'SERVICE TILL HJÄLP FÖR HÖRSLINGA', imgtag: 'horslinga' },
    ];

    const metalist = metas.map(list => {
      if (metas) {
        let Tag = (<img src={rullstolsv} style={{'width':'45px'}}/>);
        if(list.id == "fysisk") {
          Tag = (<img src={rullstolsv} style={{'width':'45px'}} />);
        } else if(list.id == "syn") {
          Tag = (<img src={synskadade} style={{'width':'45px'}}/>);
        } else {
          Tag = (<img src={horslinga} style={{'width':'45px'}}/>);
        }

        if (!selectedmetas.includes(list.id)) {
          return (
            <ListItem
              className={classes.listItem3col}
              key={list.id}
              onClick={() => {
                this.props.addMeta(list.id);
              }}
            >
              <Checkbox className={classes.checkBox} checked={false} />
              {Tag}
              <ListItemText primary={list.name} />
            </ListItem>
          );
        }
        return (
          <ListItem
            className={classes.listItem3col}
            key={list.id}
            onClick={() => {
              this.props.removeMeta(list.id);
            }}
          >
            <Checkbox className={classes.checkBox} checked />
            {Tag}
            <ListItemText primary={list.name} />
          </ListItem>
        );
      }
    });

    let groupslist = "";
    if(groups) {
      groupslist = groups.map(list => {
        if (!selectedgroups.includes(list.id)) {
          return (
            <ListItem
              className={classes.listItem4col}
              key={list.id}
              onClick={() => {
                this.props.addGroup(list.id);
              }}
            >
              <Checkbox className={classes.checkBox} checked={false} />
              <ListItemText primary={list.name} />
            </ListItem>
          );
        }
        return (
          <ListItem className={classes.listItem4col}
            key={list.id}
            onClick={() => {
              this.props.removeGroup(list.id);
            }}
          >

            <Checkbox className={classes.checkBox} checked />
            <ListItemText primary={list.name} />
          </ListItem>
        );
      });
    }

    let profilelist = "";
    if(profiles.toJS().length > 0){
      profilelist = profiles.map((list,index) => {
        let item = list.toJS();
        return (
          <Grid key={item.id+"grid1profile"} container spacing={2}>
            <Grid key={item.id+"grid2profile"} item xs={5}>
              <TextField
                key={`role_${item.id}`}
                data-key={item.id}
                label={`${index+1}.`+` Roll`}
                placeholder="Roll"
                fullWidth
              InputProps={{className : classes.textField,disableUnderline : true}}
                onChange={(evt) => {
                  this.props.changeProfileName(index,evt);
                }}
                margin="normal"
                value={item.name}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid key={item.id+"grid3profile"}  className={classes.increasemargin} item xs={6}>
              <FormControl key={item.id+"grid1control"}  className={classes.increasemargin}>
                <InputLabel key={item.id+"grid1inputlabel"}  style={{marginBottom:'4px'}} className={classes.disableAbsolute}  shrink>Namn</InputLabel>
              </FormControl>
              <Autosuggest
                key={`link_${item.id}`}
                data-key={item.id}
                id={`autosuggest_${item.id}`}
                label = 'Namn'
                theme={{
                  container: classes.container,
                  suggestionsContainerOpen: classes.suggestionsContainerOpen,
                  suggestionsList: classes.suggestionsList,
                  suggestion: classes.suggestion,
                }}
                InputProps={{className : classes.textField,disableUnderline : true}}
                InputLabelProps={{
                  shrink: true,
                }}
                renderInputComponent={renderInput}
                suggestions={suggestions}
                onSuggestionsFetchRequested={
                  this.props.handleSuggestionsFetchRequested
                }
                onSuggestionsClearRequested={
                  this.props.handleSuggestionsClearRequested
                }
                onSuggestionSelected={this.props.handleSuggestionSelected}
                renderSuggestionsContainer={renderSuggestionsContainer}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={{
                  inputProps: { "data-key" : index},
                  disableUnderline : true,
                  classes,
                  placeholder: 'Namn',
                  value: item.link,
                  onChange: this.props.handleChangeAutoComplete,
                  label : "Namn",
                }}
              />
            </Grid>
            <Grid key={item.id+"grid4"}  className={classes.alignbottom} item xs={1}>
          <Button key={item.id+"grid4button"}
              onClick={evt => {
                this.props.removeProfile(item.id);
              }}
              variant="text"
              size="small"
            >
              <DeleteIcon/>
            </Button>
          </Grid>
          </Grid>
        );
      });
    }

    const linklist = links.map((list,index) => {
      let item = list.toJS();

      return (
        <Grid key={item.id+"grid1link"} container spacing={2}>
          {' '}
          <Grid  key={item.id+"grid2linke"} item xs={5}>
            <TextField
              key={item.id+"namn"}
              data-key={item.id}
            label={`${index+1}.`+` Länknamn`}
              placeholder="Länk namnet"
            fullWidth
            onChange={(evt) => {
              this.props.changeLinkName(index,evt);
              }}
            margin="normal"
              InputProps={{className : classes.textField,disableUnderline : true}}
              InputLabelProps={{
                shrink: true,
            }}
            value={item.name}
            />
        </Grid>
        <Grid key={item.id+"grid3link"} item xs={6}>
          <TextField
              key={item.id+"address"}
            data-key={item.id}
            label={`${index+1}.`+` länk address`}
            placeholder="https://"
            fullWidth
              InputProps={{className : classes.textField,disableUnderline : true}}
            onChange={(evt) => {
              this.props.changeLink(index,evt);
              }}
              value={item.value}
              margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            />
          </Grid>
                  <Grid key={item.id+"grid4link"} className={classes.alignbottom} item xs={1}>
          <Button key={item.id+"gridlinkbutton"}
              onClick={evt => {
                this.props.removeLink(item.id);
              }}
              variant="text"
              size="small"
            >
              <DeleteIcon/>
            </Button>
          </Grid>

        </Grid>

      );
    });
    const datelist = dates.map((list,index) => {
      let item = list.toJS();

      return (
        <Grid  key={item.id+"grid1"} container spacing={2}>
                <Grid item xs={4}>
                  <p>Starttid *</p>
                  <div className="picker">
                  <DatePicker
                    showTimeSelect
                    locale="sv"
                    timeCaption="Tid"
                    dateFormat='dd.MM.yyyy HH:mm'
                    selected={item.start}
                    onChange={(evt) => {
                      this.props.changeStartDate(index,evt);
                    }}
                    timeIntervals={15}
                    minDate={new Date()}
                    customInput={ <TextField InputProps={{className : classes.textField,disableUnderline : true}}/>}
                  />
                  </div>
                  {error && error.error_start_time && error_start_time != "" && <p className={classes.errorBlock}>{error_start_time}</p> }
                </Grid>

                <Grid key={item.id+"grid2"} item xs={4}>
                  <p>Sluttid</p>
                  <div className="picker">
                  <DatePicker
                    showTimeSelect
                    locale="sv"
                    timeCaption="Tid"
                    dateFormat='dd.MM.yyyy HH:mm'
                    selected={item.end}
                    timeIntervals={15}
                    onChange={(evt) => {
                      this.props.changeEndDate(index,evt);
                    }}
                    minDate={new Date()}
                    customInput={ <TextField InputProps={{className : classes.textField,disableUnderline : true}}/>}
                  />

                  </div>
                  {error && error.error_end_time && error_end_time != "" && <p className={classes.errorBlock}>{error_end_time}</p> }
                </Grid>
              <Grid item className={classes.alignbottom} xs={1}>
          <Button
              onClick={evt => {
                this.props.removeDate(item.id);
              }}
              variant="text"
              size="small"
            >
              <DeleteIcon/>
            </Button>
          </Grid>
              </Grid>
      );
    });
    let locationinput =  null;
    locationinput =  (
      <Grid>
        <Grid item xs={12}>
          <TextField
            label="Platsnamn"
            placeholder={event_lokalen}
            fullWidth
            margin="normal"

            onChange={this.props.changePlace}
            value={place}
            className={classes.removeMargin}
            error={error && error.error_lokalen && error_lokalen != "" }
            InputProps={{classes: {
              error: classes.textFieldError
            },className : classes.textField,disableUnderline : true}}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {error && error.error_lokalen && error_lokalen != "" && <p className={classes.errorBlock}>{error_lokalen}</p> }

          <TextField
            label="Adress"
            placeholder={event_addres}
            fullWidth
            margin="normal"
            error={error && error.error_address && error_address != ""}
            className={classes.removeMargin}
            onChange={this.props.changeAddress}
            value={address}
            InputProps={{classes: {
              error: classes.textFieldError
            },className : classes.textField,disableUnderline : true}}
            InputLabelProps={{
              shrink: true,
            }}
          />

            {error && error.error_address && error_address != "" && <p className={classes.errorBlock}>{error_address}</p> }


        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} style={{ marginTop: '10px', padding:'4px'}}>
            <InputLabel style={{ lineHeight: '1.7',marginBottom:'5px' }}className={classes.formLabel}>Region</InputLabel>

            <Select
              placeholder={event_region}
              className={classes.cityselect}
              onChange={this.props.handleChangeLocationValue}
              name="form-field-name"
              getOptionLabel={({name}) => name}
              getOptionValue={({id}) => id}
              error={error && error.error_region && error_region != ""}

              margin="normal"
              InputProps={{classes: {
                error: classes.textFieldError
              },className : classes.textField,disableUnderline : true}}
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                width: '100%',
                maxWidth: '100%',
                marginTop: '10px'
              }}
              value={locationvalue}
              options={locations}
            />
             {error && error.error_region && error_region != "" && <p className={classes.errorBlock}>{error_region}</p> }

          </Grid>

          <Grid item xs={6}>

            <TextField
              label="Postkod"
              placeholder={event_post}
              onChange={this.props.changePostal}
              InputProps={{classes: {
                error: classes.textFieldError
              },className : classes.textField,disableUnderline : true}}
              value={postal}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}

            />
          </Grid>
        </Grid>
      </Grid>
    );
    let rsvpfield = null;

    if(isfree)  {
      rsvpfield =         <TextField
      label="Anmälan till:"
      placeholder="https://"
      onChange={this.props.changeRSVP}
      InputProps={{classes: {
        error: classes.textFieldError
      },className : classes.textField,disableUnderline : true}}
      value={rsvp}
      fullWidth
      className={classes.removeMargin}
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
    />
    }
    let orgfield =  <TextField
                      onChange={this.props.changeOrg}
                      InputProps={{classes: {
                        error: classes.textFieldError
                      },className : classes.textField,disableUnderline : true}}
                      value={org}
                      placeholder={orgplaceholder}
                      fullWidth
                      className={classes.removeMargin}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      />
    let pricelist = null;
    if(!isfree) {
     pricelist = prices.map((list,index) => {
      let item = list.toJS();

      return (
        <Grid key={item.id+"grid3"} container spacing={2}>
          {' '}
          <Grid key={item.id+"grid4"} item xs={3}>
            <TextField
              key={item.id+"pris"}
              data-key={item.id}
            label={`${index+1}.`+` Pris`}
              placeholder="Pris €"
            fullWidth
            onChange={(evt) => {
              this.props.changePrice(index,evt);
              }}
              InputProps={{className : classes.textField,disableUnderline : true}}
            margin="normal"
              InputLabelProps={{
                shrink: true,
            }}
            value={item.price}
            />
        </Grid>
        <Grid key={item.id+"grid5"} item xs={4}>
          <TextField
              key={item.id}
            data-key={item.id+"desc"}
            label={`${index+1}.`+` Prisbeskrivning`}
            placeholder="Prisbeskrivning"
            fullWidth
            onChange={(evt) => {
              this.props.changePriceDescription(index,evt);
              }}
              InputProps={{className : classes.textField,disableUnderline : true}}
              value={item.pricedescription}
              margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            />
          </Grid>
          <Grid key={item.id+"grid6"} item xs={4}>
          <TextField
              key={item.id+"kop"}
            data-key={item.id}
            label={`${index+1}.`+` Köp biljetter`}
            placeholder="http://"
            fullWidth
              InputProps={{className : classes.textField,disableUnderline : true}}
            onChange={(evt) => {
              this.props.changeBuyLink(index,evt);
              }}
              value={item.buylink}
              margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            />
          </Grid>
            <Grid className={classes.alignbottom} item xs={1}>
          <Button
              onClick={evt => {
                this.props.removePrice(item.id);
              }}
              variant="text"
              size="small"
            >
              <DeleteIcon/>
            </Button>
          </Grid>
        </Grid>

      );
    });
  }
let maincategorylistevents = "";
if (notiscategoriesevenemang) {
   maincategorylistevents = notiscategoriesevenemang.map(list => {

      let check1 = _.find(selectedmaincategories, { 'id': list.id});
      let check2 = _.find(value, { 'id': list.id});

      if (!check1 && !check2) {
        return (
          <ListItem
            button
            className={classes.listItem2col}
            key={list.id}
            onClick={() => {
              this.props.addCategoryMainEvent(list.id, list.name);
            }}
          >
           <Checkbox className={classes.checkBox} checked={false} />
            <ListItemText primary={list.name} />
          </ListItem>
        );
      }
      return (
        <ListItem
          button
          key={list.id}
          className={classes.listItem2col}
          onClick={() => {
            this.props.removeCategoryMainEvent(list.id);
          }}
        >
        <Checkbox className={classes.checkBox} checked />
          <ListItemText primary={list.name} />
        </ListItem>
      );

  });
}
  let categoryrequired = true;
  if(selectedmaincategories.size > 0 ) {
    categoryrequired = false;
  }
  if(value.length > 0 ) {
    categoryrequired = false;
  }
    let priceinput =  null;
    if(!isfree) {
      priceinput =  (
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel control={<Checkbox checked={isfree} color="primary" />} label="Gratis" onChange={this.props.toggleFree} />
          </Grid>
        </Grid>
      );
    } else {
      priceinput =  (
        <Grid>
          <Grid item xs={12}>
            <FormControlLabel
            control={
              <Checkbox checked={isfree}  color="primary" />
            } label="Gratis" onChange={this.props.toggleFree} />
            {rsvpfield}
          </Grid>
        </Grid>
      );
    }

    return (
      <div>
        <CssBaseline />
        <form className={classes.form} onSubmit={this.props.onSubmitForm}>
        <Grid container spacing={2}>
          <Grid item xs={false} md={4}/>
          <Grid item xs={false} md={8} />
           </Grid>
           <Grid container spacing={2}>
            <Grid item  xs={false} md={3}>
            </Grid>

            <Grid item xs={12} md={6}>
          {error && error.error_headerimage && error_headerimage != "" && <p className={classes.errorBlock}>{error_headerimage}</p> }

          </Grid>
          <Grid item  xs={false} md={3}>

          </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item  xs={false} md={3} />
            <Grid item xs={12} md={6}>
            <p className={classes.helpStyleCenter}>{event_basinfo}</p>

              <H2 >
              Basinformation
              </H2>
              <p className={classes.floatleft}>
              <label className={classes.wlabel}>Rubrik *</label>
              </p>
            <Tabs classes={{ root: classes.floatright, flexContainer: classes.tabContainer }} value={tabvaluetitle} onChange={this.props.changeTabTitle}>
              <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} disabled={allowtitle} label="EN" />
              <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} disabled={allowtitle} label="FI" />
              <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="SV" />
          </Tabs>
            {tabvaluetitle === 0 && <div>
              <TextField
                id="title"
                className={classes.fullwidth}
                onChange={this.props.titleOnChangeEn}
                margin="normal"
                value={titleen}
                InputProps={{className : classes.textField,disableUnderline : true}}
               InputLabelProps={{
                shrink: true,
            }}
              />
              </div>}
              {tabvaluetitle === 1 && <div>
              <TextField
                id="title"
                className={classes.fullwidth}
                onChange={this.props.titleOnChangeFi}
                margin="normal"
                value={titlefi}
                InputProps={{className : classes.textField,disableUnderline : true}}
            InputLabelProps={{
              shrink: true,
            }}
              />
              </div>}
              {tabvaluetitle === 2 && <div>
              <div ref={this.name}> </div>
              <TextField
                id="title"
                className={classes.fullwidth}
                onChange={this.props.titleOnChangeSv}
                margin="normal"
                value={titlesv}
                error={error && error.error_name && error_name != ""}
                InputProps={{
                  classes: {
                    error: classes.textFieldError
                  },
                  className : classes.textField,
                  disableUnderline : true
                }}
                InputLabelProps={{
                  shrink: true,

                }}
              />
              <p className={classes.helpStyle} style={{marginTop:'0px'}}>{event_info}</p>
              </div>}
              {error && error.error_name && error_name != "" && <p className={classes.errorBlock}>{error_name}</p> }

              <p className={classes.floatleft}>
                <label className={classes.wlabel}>Beskrivning *</label>
              </p>
              <Tabs classes={{ root: classes.floatright, flexContainer: classes.tabContainer, }} value={tabvalue} onChange={this.props.changeTab}>
                <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} disabled={allowdescription} label="EN" />
                <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} disabled={allowdescription} label="FI" />
                <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="SV" />
              </Tabs>
              <p className={classes.helpStyle}></p><div ref={this.description}> </div>

        {tabvalue === 0 && <TabContainer>
        <Editor
                editorState={editorstateen}
                toolbarClassName="toolbarClassName"
                wrapperClassName={classes.wysiwyg}
                editorClassName={classes.editor}
                onEditorStateChange={this.props.onEditorStateChangeEn}
                stripPastedStyles={true}
                toolbar={{
                  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link'],
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              />
      </TabContainer>}
        {tabvalue === 1 && <TabContainer>  <Editor
                editorState={editorstatefi}
                toolbarClassName="toolbarClassName"
                wrapperClassName={classes.wysiwyg}
                editorClassName={classes.editor}
                onEditorStateChange={this.props.onEditorStateChangeFi}
                stripPastedStyles={true}
                toolbar={{
                  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link'],
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              /></TabContainer>}
        {tabvalue === 2 && <TabContainer>


          <Editor
                editorState={editorstatesv}
                toolbarClassName="toolbarClassName"
                wrapperClassName={classes.wysiwyg}
                editorClassName={classes.editor}
                onEditorStateChange={this.props.onEditorStateChangeSv}
                stripPastedStyles={true}
                toolbar={{
                  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link'],
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              /></TabContainer>}

              {error && error.error_description && error_description_sv != "" && <p className={classes.errorBlock}>{error_description}</p> }

              <p className={classes.helpStyle} style={{marginTop:'0px'}}>{event_desc}</p>

              <p className={classes.floatleft}>
              <label className={classes.wlabel}>Tidningsnotis</label>
              </p>
              <Tabs classes={{ root: classes.floatright, flexContainer: classes.tabContainer }} value={tabvalueshort} onChange={this.props.changeTabShort}>
                <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} disabled={allowdescriptionshort} label="EN" />
                <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} disabled={allowdescriptionshort} label="FI" />
                <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="SV" />
              </Tabs>
              {tabvalueshort === 0 && <div>


              <TextField
                id="multiline-flexible-13"
                //helperText={error_short_description_en}
                onChange={this.props.shortdescriptionOnChangeEn}
                multiline
                helperText={`${shortdescriptionen.length}/160`}
                className={classes.textFieldShortRoot}

                minRows="3"
                margin="normal"
                value={shortdescriptionen}
                FormHelperTextProps={{className : classes.helpertext}}
                InputProps={{
                  classes: {
                    error: classes.textFieldError
                  },
                  className : classes.textFieldShort,
                  disableUnderline : true
                }}
                inputProps={{
                  maxLength: 160
                }}
              />
              </div>}

              {tabvalueshort === 1 && <div>

            <TextField
                id="multiline-flexible-12"
                //helperText={error_short_description_fi}
                onChange={this.props.shortdescriptionOnChangeFi}
                multiline
                minRows="3"
                 helperText={`${shortdescriptionfi.length}/160`}
                margin="normal"
                className={classes.textFieldShortRoot}
                value={shortdescriptionfi}
                FormHelperTextProps={{className : classes.helpertext}}
                InputProps={{disableUnderline : true, className : classes.textFieldShort }}
                inputProps={{
                  maxLength: 160
                }}
                //required={true}
              />
              </div>}

              {tabvalueshort === 2 && <div>
                <TextField
                id="multiline-flexible-14"
               // helperText={error_short_description_sv}
                onChange={this.props.shortdescriptionOnChangeSv}
                                className={classes.textFieldShortRoot}

                multiline
                minRows="3"
                helperText={`${shortdescriptionsv.length}/160`}
                margin="normal"
                value={shortdescriptionsv}
                FormHelperTextProps={{className : classes.helpertext}}
                InputProps={{disableUnderline : true, className : classes.textFieldShort }}

                inputProps={{
                  maxLength: 160
                }}
              />
              </div>}
              <p className={classes.helpStyle} style={{marginTop:'0px'}}>{event_short}</p>
              <H2>Evenemangstider</H2>
              <p className={classes.helpStyle}>{event_date}</p>
              {datelist}
                  <Grid item className={classes.centered}>
                 <IconButton className={classes.iconbutton} color="primary" onClick={this.props.addDate}  aria-label="add link" size="medium">
                  Lägg till &nbsp;&nbsp;<AddCircleIcon  fontSize="large" />
                </IconButton>
                <H3>Detaljer och undantag</H3>
                <p className={classes.helpStyle} style={{marginTop:'0px'}}>{event_exception}</p>
                <TextField
                    id="multiline-flexible-13"
                    onChange={this.props.setCustomOpen}
                    multiline
                    className={classes.textFieldShortTime}
                    minRows="3"
                    margin="normal"
                    value={custom_open}
                    FormHelperTextProps={{className : classes.helpertext}}
                    InputProps={{
                      classes: {
                        error: classes.textFieldError
                      },
                      className : classes.textFieldShortRoot,
                      disableUnderline : true
                    }}
                  />
                  </Grid>
              <div ref={this.kategorier}></div>
              <H2>Kategorier *</H2>
              <p className={classes.helpStyle}>{event_cat}</p>
              <Grid item xs={12}>
              {maincategorylistevents}
              </Grid>
              <p className={classes.helpStyle}></p>
              <Select
                onChange={this.props.handleChange}
                name="form-field-name"
                getOptionLabel={({name}) => name}
                getOptionValue={({id}) => id}
                style={{
                  width: '100%',
                  maxWidth: '100%',
                }}
                placeholder="Välj"
                isMulti={true}
                value={value}
                options={categories}
              />
              {error && error.error_keywords && error_keywords != "" && <p className={classes.errorBlock}>{error_keywords}</p> }

              <div ref={this.locationsection}></div>
              <Grid item xs={12}>
              <H2> Lokalen *</H2>
              <p className={classes.helpStyle}>{event_addres}</p>
              {locationinput}
              <Grid/>
                <Grid item xs={12}>
                <p className={classes.helpStyle} style={{marginTop:'20px'}}>{event_access}</p>
                  {metalist}
                </Grid>

              <Grid item xs={12}>
                <H2>Pris</H2>
                <p className={classes.helpStyle}>{event_price}</p>
                {priceinput}
                {pricelist}
                <Grid className={classes.centered}>
                 <IconButton className={classes.iconbutton} color="primary" onClick={this.props.addPrice}  aria-label="add link" size="medium">
                  Lägg till pris&nbsp;&nbsp;<AddCircleIcon  fontSize="large" />
                  </IconButton>
              </Grid>
              <H2>Arrangör</H2>
              {orgfield}

              <FormControl component="fieldset">
              <H2>Evenemang språk</H2>
                <RadioGroup
                  aria-label="position"
                  name="position"
                  value={language}
                  onChange={this.props.changeLanguage}
                  row
                >
                  <FormControlLabel
                    value="sv"
                    control={<Radio color="primary" />}
                    label="Svenska"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="fi"
                    control={<Radio color="primary" />}
                    label="Finska"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="en"
                    control={<Radio color="primary" />}
                    label="Engelska"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="utan"
                    control={<Radio color="primary" />}
                    label="Annat"
                    labelPlacement="top"
                  />
                </RadioGroup>
                </FormControl>

                <Grid item xs={false} />
              </Grid>
              <Grid item xs={12}>
              <H2>Målgrupper</H2>
              <p className={classes.helpStyle}>{event_group}</p>
              {groupslist}
              </Grid>
              <Grid item xs={12}>
              <H2>Medverkande / arbetsgrupp</H2>
              <p className={classes.helpStyle}>{event_partner}</p>
              {profilelist}
                <Grid className={classes.centered}>
                 <IconButton className={classes.iconbutton} color="primary" onClick={this.props.addProfile}  aria-label="add link" size="medium">
          Lägg till profil&nbsp;&nbsp;<AddCircleIcon  fontSize="large" />
        </IconButton>
              </Grid>

                <Grid item xs={12}>
              <H2>Länkar</H2>
              <p className={classes.helpStyle}>{event_links}</p>
              {linklist}

                        <Grid className={classes.centered}>
                 <IconButton className={classes.iconbutton} color="primary" onClick={this.props.addLink}  aria-label="add link" size="medium">
          Lägg till länk&nbsp;&nbsp;<AddCircleIcon  fontSize="large" />
        </IconButton>
        {error && error.error_external_links && error_external_links != "" && <p className={classes.errorBlock}>{error_external_links}</p> }

          </Grid>
              </Grid>
                </Grid>
          <Grid container>      
          <Grid container>      
          <h2>Evenemang bild</h2>
          </Grid>
          <Grid item xs={12} md={6} >            
          <CardMedia
            className={classes.media}
            image={eventimage}
            title="Evenemang bild"
          />
          <div ref={this.imageupload} className={classes.helpStyleCenter}>
          {eventimage != this.tempimage && (
            <>
            <Button
            onClick={evt => {
              this.props.clearImage();
            }}
            variant="text"
            size="small"
            className={classes.deletebutton}
          >
            <DeleteIcon className={classes.leftIcon} />
          </Button>
          
          <Button
            onClick={evt => {
              this.props.restoreImage();
            }}
            variant="text"
            size="small"
            className={classes.deletebutton}
          >
            <HistoryIcon className={classes.leftIcon} />
          </Button>
          </>
            )} </div>

          </Grid>
          <Grid item xs={false} md={1} />
          <Grid item xs={12} md={4} >
          <div className={classes.helpStyleCenter}>{event_image}</div>
          <ImageUploader
            singleImage
            withPreview={false}
            withIcon={false}
            label=""
            buttonText="Ladda upp bild"
            onChange={this.props.imageDrop}
            imgExtension={['.jpg','.jpeg', '.png', '.gif']}
            maxFileSize={5242880}
            fileContainerStyle={pStyle}
          />

           </Grid>
           </Grid>
           
              <Button
                type="submit"
                onClick={this.openScroll.bind(this)}
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={sending}
              > Spara</Button>
            </Grid>
            <Grid item xs={false} md={3} />
            </Grid>
            </Grid>

        </form>

      </div>
    );
  }
}


AddEvenemang.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  description: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  titlefi: makeSelectTitleFi(),
  titlesv: makeSelectTitleSv(),
  titleen: makeSelectTitleEn(),
  link: makeSelectLink(),
  rsvp: getRSVP(),
  org: getOrg(),
  user: makeSelectCurrentUser(),
  usergroup: getUserGroupId(),
  starttimedate: selectStartTimeDate(),
  endtimedate: selectEndTimeDate(),
  descriptionsv: makeSelectDescriptionSv(),
  descriptionfi: makeSelectDescriptionFi(),
  descriptionen: makeSelectDescriptionEn(),
  shortdescriptionfi: makeSelectShortDescriptionFi(),
  shortdescriptionsv: makeSelectShortDescriptionSv(),
  shortdescriptionen: makeSelectShortDescriptionEn(),
  pictures: makeSelectPictures(),
  categories: makeSelectCategoriesEvenemang(),
  locations: makeSelectLocationsEvenemangFiltered(),
  selectedmaincategories: getSelectedMainCategories(),
  value: makeSelectValue(),
  locationvalue: makeSelectLocationValue(),
  groups: makeSelectGroupsEvenemang(),
  selectedgroups: makeSelectGroupsSelected(),
  selectedmetas: makeSelectMetasSelected(),
  links: getLinks(),
  dates: getDates(),
  language: getLanguage(),
  profiles: getProfiles(),
  profile: getProfile(),
  place: selectPlace(),
  address: selectAddress(),
  postal: selectPostal(),
  city: selectCity(),
  isfree: isFree(),
  sending: getSending(),
  price: getPrice(),
  prices: getPrices(),
  region: getRegion(),
  suggestions: getSuggestions(),
  kultur: getKultur(),
  searchfieldvalue: getSearchfieldValue(),
  pricedescription: getPriceDescription(),
  editindex: getEditIndex(),
  error: getError(),
  regions: getRegions(),
  editorstatefi: getEditorStateFi(),
  editorstateen: getEditorStateEn(),
  editorstatesv: getEditorStateSv(),
  custom_open: getCustomOpen(),
  buylink: getBuyLink(),
  totalcategories: getTotalCategories(),
  helptexts: getHelp(),
  tabvalue: getTabValue(),
  tabvaluetitle: getTabValueTitle(),
  tabvalueshort: getTabValueShort(),
  notiscategoriesevenemang: selectNotisCategories(),
});


function mapDispatchToProps(dispatch) {
  return {
    titleOnChangeFi: evt => {
      dispatch(titleOnChangeFi(evt.target.value));
    },
    titleOnChangeEn: evt => {
      dispatch(titleOnChangeEn(evt.target.value));
    },
    titleOnChangeSv: evt => {
      dispatch(titleOnChangeSv(evt.target.value));
    },
    addGroup: value => {
      dispatch(addGroup(value));
    },
    removeGroup: value => {
      dispatch(removeGroup(value));
    },
    addCategoryMainEvent: (id, name) => {
      dispatch(addCategoryEvenemang(id, name));
    },
    removeCategoryMainEvent: value => {
      dispatch(removeCategoryEvenemang(value));
    },
    addProfile: evt => {
      const id = new Date().getTime();
      dispatch(addProfile({ id, name: '', link: '' }));
    },
    changeProfile: (index, evt) => {
      dispatch(changeProfile(index, evt.target.value));
    },
    changeProfileName: (index, evt) => {
      dispatch(changeProfileName(index, evt.target.value));
    },
    removeProfile: value => {
      dispatch(removeProfile(value));
    },
    changeLink: (index, evt) => {
      dispatch(changeLink(index, evt.target.value));
    },
    changeLinkName: (index, evt) => {
      dispatch(changeLinkName(index, evt.target.value));
    },
    removePrice: value => {
      dispatch(removePrice(value));
    },
    addPrice: evt => {
      const id = new Date().getTime();
      dispatch(
        addPrice({ id: id, price: '', pricedescription: '', buylink: '' }),
      );
    },
    removeLink: value => {
      dispatch(removeLink(value));
    },
    addLink: evt => {
      const id = new Date().getTime();
      dispatch(addLink({ id, name: '', value: '' }));
    },
    removeDate: value => {
      dispatch(removeDate(value));
    },
    addDate: evt => {
      const id = new Date().getTime();
      dispatch(addDate({ id, start: null, end: null }));
    },
    addMeta: value => {
      dispatch(addMeta(value));
    },
    removeMeta: value => {
      dispatch(removeMeta(value));
    },
    hideSearch: evt => {
      dispatch(hideLeftDrawer());
    },
    toggleFree: evt => {
      dispatch(toggleFree());
    },
    linkOnChange: evt => {
      dispatch(linkOnChange(evt.target.value));
    },

    changeCity: value => {
      dispatch(changeCity(value.value));
    },
    setCustomOpen: evt => {
      dispatch(setCustomOpen(evt.target.value));
    },
    changePlace: evt => {
      dispatch(changePlace(evt.target.value));
    },
    changeRSVP: evt => {
      dispatch(changeRSVP(evt.target.value));
    },
    changeOrg: evt => {
      dispatch(changeOrg(evt.target.value));
    },
    changePostal: evt => {
      dispatch(changePostal(evt.target.value));
    },
    changeAddress: evt => {
      dispatch(changeAddress(evt.target.value));
    },
    changePrice: (index, evt) => {
      dispatch(changePrice(index, evt.target.value));
    },
    changeBuyLink: (index, evt) => {
      dispatch(changeBuyLink(index, evt.target.value));
    },
    changePriceDescription: (index, evt) => {
      dispatch(changePriceDescription(index, evt.target.value));
    },

    changeStartDate: (index, value) => {
      dispatch(changeStartDate(index, value));
    },
    changeEndDate: (index, value) => {
      dispatch(changeEndDate(index, value));
    },
    changeLanguage: evt => {
      dispatch(changeLanguage(evt.target.value));
    },
    changeTab: (event,value) => {
      dispatch(changeTabValue(value));
    },
    changeTabTitle: (event, value) => {
      dispatch(changeTabValueTitle(value));
    },
    changeTabShort: (event, value) => {
      dispatch(changeTabValueShort(value));
    },

    descriptionOnChangeFi: evt => {
      dispatch(descriptionOnChangeFi(evt.target.value));
    },
    descriptionOnChangeEn: evt => {
      dispatch(descriptionOnChangeEn(evt.target.value));
    },
    descriptionOnChangeSv: evt => {
      dispatch(descriptionOnChangeSv(evt.target.value));
    },
    shortdescriptionOnChangeFi: evt => {
      dispatch(shortdescriptionOnChangeFi(evt.target.value));
    },
    shortdescriptionOnChangeSv: evt => {
      dispatch(shortdescriptionOnChangeSv(evt.target.value));
    },
    shortdescriptionOnChangeEn: evt => {
      dispatch(shortdescriptionOnChangeEn(evt.target.value));
    },
    onSubmitForm: evt => {
      evt.preventDefault();
      evt.stopPropagation();
      dispatch(submitAddEvenemangForm());
    },
    onFormLoad: (singleid, groupid) => {
      if (singleid) {
        dispatch(loadEventSingle(singleid));
      } else {
        //dispatch(setUSerGroup(groupid));
        dispatch(clearForm());
      }
    },
    clearForm: evt => {
      dispatch(descriptionOnChangeFi(''));
      dispatch(descriptionOnChangeSv(''));
      dispatch(descriptionOnChangeEn(''));

      dispatch(linkOnChange(''));
      //dispatch(titleOnChange(''));
    },
    handleChange: value => {
      dispatch(handleChange(value));
    },
    handleChangeLocationValue: value => {
      dispatch(handleChangeLocation(value));
    },
    imageDrop: (event, picture) => {
      dispatch(imageDrop(_.last(picture)));
    },
    clearImage: () => {
      dispatch(clearImage());
    },
    restoreImage: () => {
      dispatch(restoreImage());
    },
    handleSuggestionsFetchRequested: value => {
      dispatch(setSuggestions(value));
    },
    handleSuggestionsClearRequested: () => {
      dispatch(clearSuggestions(''));
    },
    handleChangeAutoComplete: (event, value) => {
      if (event.target.getAttribute('data-key')) {
        dispatch(setEditIndex(event.target.getAttribute('data-key')));
        dispatch(handleSearchFieldValue(event.target.value));
        dispatch(
          changeProfile(
            event.target.getAttribute('data-key'),
            event.target.value,
            '',
          ),
        );
      } else {
      }
    },
    onEditorStateChangeSv: editorstate => {
      dispatch(changeEditorStateSv(editorstate));
      if (editorstate) {
        dispatch(
          descriptionOnChangeSv(
            draftToHtml(convertToRaw(editorstate.getCurrentContent())),
          ),
        );
      }
    },
    onEditorStateChangeFi: editorstate => {
      dispatch(changeEditorStateFi(editorstate));
      if (editorstate) {
        dispatch(
          descriptionOnChangeFi(
            draftToHtml(convertToRaw(editorstate.getCurrentContent())),
          ),
        );
      }
    },
    onEditorStateChangeEn: editorstate => {
      dispatch(changeEditorStateEn(editorstate));
      if (editorstate) {
        dispatch(
          descriptionOnChangeEn(
            draftToHtml(convertToRaw(editorstate.getCurrentContent())),
          ),
        );
      }
    },
    handleSuggestionSelected: (event, newValue) => {
      dispatch(
        changeProfile(
          '',
          `${newValue.suggestionValue.firstname
            } ${
            newValue.suggestionValue.lastname } ${ newValue.suggestionValue.groupname}`,
          newValue.suggestionValue.id,
        ),
      );
      dispatch(
        handleSearchFieldValue(`${newValue.firstname  } ${  newValue.lastname}`),
      );
    },
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'AddEvenemang', reducer });
const withSaga = injectSaga({ key: 'AddEvenemang', saga });

export default compose(
  withReducer,
  withSaga,
  withCookies,
  withConnect,
  withStyles(styles),
)(AddEvenemang);
