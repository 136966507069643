/**
 * Gets the repositories of the user from Github
 */

import { takeLatest, takeEvery } from 'redux-saga/effects';
import { take, call, put, select, fork, cancel } from 'redux-saga/effects';
import { makeSelectUserId,makeSelectCurrentUser, getUserGroupId } from '../../containers/App/selectors';
import { push, browserHistory } from 'connected-react-router';
import moment from 'moment';

import request from '../../utils/request';
import { API_ROOT } from '../../api-config';
import { setError, setLoading, clearForm, fyrkLoaded } from './actions';

import { SUBMIT_ADDFYRKFORM, LOAD_FYRKITEM } from './constants';
import {
  makeSelectDescription,
  makeSelectTitle,
  makeSelectLink,
  makeSelectLinkDescription,
  makeSelectPictures,
  makeSelectValue,
  getSelectedGroups,
  getSelectedLocations,
  getProcurator,
  getContactName,
  getContactEmail,
  getContactPhone,
  getContactAddress,
  getNextPeriod,
  getPeriodType,
  getFyrkItem,
  getUpdaterEmail,
  getPeriodStatus

} from './selectors';

const fetchJSON = (url, options = {}) =>
  new Promise((resolve, reject) =>
    fetch(url, options)
      .then(response =>
        response.status !== 201 &&
        response.status !== 204 &&
        response.status !== 200 &&
        response.status !== 400
          ? reject(response)
          : response,
      )
      .then(response => {
        if (response.status === 400) {
          response.json().then(error => reject(error));
        } else if (response.status === 204) {
          return resolve({});
        } else {
          return resolve(response.json());
        }
        return '';
      })
      .catch(error => reject(error)),
  );
  const validateForm= (payload) => {
    let errorresult = false;
    let error = {};
   if(payload.title == "") {
      errorresult = true;
      error.title = 'Rubrik saknas';
    } else {
      error.title = '';
    }
   if(payload.description == "") {
      errorresult = true;
      error.description = 'Beskrivning saknas';
    } else {
      error.description = '';
    }
    if(payload.procurator == "") {
      errorresult = true;
      error.procurator = 'Bidragskälla saknas';
    } else {
      error.procurator = '';
    }
    if(payload.contact_email == "") {
      errorresult = true;
      error.contactemail = 'Kontakt email saknas';
    } else {
      error.contactemail = '';
    }
    if(payload.contact_name == "") {
      errorresult = true;
      error.contactname = 'Kontakt namn saknas';
    } else {
      error.contactname = '';
    }
    if(payload.next_period == null && payload.periodtype != "CONTINUOUS") {
      errorresult = true;
      error.nextperiod = 'Ansökningstid saknas';
    } else {
      error.nextperiod = '';
    }
    // error.urls = "";
    // if(!payload.hasOwnProperty('urls') ) {
    //   error.urls = 'Länk saknas';
    //   errorresult = true;
    // }
   /* if(payload.urls && payload.urls.length > 0 ) {
      _.forEach(payload.urls, function(value, key) {
        if (
          !(value.link.includes('https://') || value.link.includes('http://'))
        ) {
          error.urls = 'http: required';
          errorresult = true;
        }
      });
    }*/

    if(errorresult) {
      throw error;
    }
  };
export function* loadFyrkItemRequest(action) {
  yield put(clearForm());
  const requestURL = `${API_ROOT}fyrk/${action.value}`;

  try {
    const fyrk = yield call(request, requestURL);
    yield put(fyrkLoaded(fyrk));

    yield put(setLoading(false));
  } catch (err) {
    // yield put(nyheterLoadingError(err));
  }
}

function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.toString().split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function* addFyrkRequest() {


  const title = yield select(makeSelectTitle());
  const description = yield select(makeSelectDescription());
  const link = yield select(makeSelectLink());
  const linkdescription = yield select(makeSelectLinkDescription());

  const images = yield select(makeSelectPictures());
  const userid = yield select(makeSelectUserId());
  const usergroupid = yield select(getUserGroupId());

  const values = yield select(makeSelectValue());
  const locations = yield select(getSelectedLocations());
  const groups = yield select(getSelectedGroups());

  const procurator = yield select(getProcurator());
  const contactname = yield select(getContactName());
  const contactemail = yield select(getContactEmail());
  const contactphone = yield select(getContactPhone());
  const contactaddress = yield select(getContactAddress());

  const nextperiod = yield select(getNextPeriod());
  const periodtype = yield select(getPeriodType());
  const fyrkitem = yield select(getFyrkItem());
  let updateremail = yield select(getUpdaterEmail());
  const currentuser = yield select(makeSelectCurrentUser());
  let currentusermemail =  currentuser.email;
  const next_period_status = yield select(getPeriodStatus());
  if(updateremail === false || updateremail == "") {
    updateremail = currentusermemail;
  }
  let tempimage = false;
  let image = false;

  if (images.length > 0) {
    tempimage = images[0];
    image = tempimage;
  }

  let payload = {
    user: userid,
    usergroup: usergroupid,
    title,
    description,
    periodtype,
    procurator ,
    mailingaddress : contactaddress,
    contact_name : contactname,
    contact_email : contactemail,
    contact_telephone : contactphone,
    next_period_status: next_period_status,
    updater_email : updateremail
  };

  if(image == false) {
    payload.fyrkimages = [];
  }

  if (nextperiod != null && periodtype != 'CONTINUOUS') {
    payload.next_period = nextperiod;
  }

  if (link != '') {
    payload.urls = [
      {
        url: link,
        text: linkdescription,
      },
    ];
  }
  const fyrkid = fyrkitem.id || 0;

  if (values.length > 0) {
    const catObjData = values.map((data, idx) => ({ id: data.id }));
    payload.categories = catObjData;
  }
  if (locations.length > 0) {
    const locationObjData = locations.map((data, idx) => ({ id: data.id }));
    payload.regions = locationObjData;
  }
  if (groups.length > 0) {
    const groupObjData = groups.map((data, idx) => ({ id: data.id }));
    payload.groups = groupObjData;
  }
  let path = `${API_ROOT}fyrk/${fyrkitem.id}/`;
  let method = 'PUT';
  if (fyrkid == 0) {
    path = `${API_ROOT}fyrk/`;
    method = 'POST';
  }

  const token = localStorage.getItem('token');

  try {
    validateForm(payload);

    const res = yield call(fetchJSON, path, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(payload),
    });

    if (image && !image.includes('http')) {
      const file = dataURLtoFile(image, 'testFile.png');
      const imagepayload = { role: 'fyrk', file };
      const formData = new FormData();

      for (const name in imagepayload) {
        formData.append(name, imagepayload[name]);
      }

      try {
        const resimage = yield call(
          fetchJSON,
          `${API_ROOT}fyrk/${res.id}/upload_image/`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              Authorization: `Token ${token}`,
            },
            body: formData,
          },
        );
      } catch (error) {
        error.fyrkimage = error.detail[0];
        throw error;
      }
    }
    yield put(clearForm());
    yield put(push(`/fyrkpost/${res.id}`));
  } catch (error) {
    const errorarray = [];
    Object.keys(error).map((key, index) => {
      errorarray[`error_${key}`] = error[key]
    });
    yield put(setError(errorarray));
  }
  yield put(setLoading(false));

}

export default function* loginWatcher() {
  yield takeLatest(LOAD_FYRKITEM, loadFyrkItemRequest);
  yield takeLatest(SUBMIT_ADDFYRKFORM, addFyrkRequest);
}
