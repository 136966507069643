/*
 *
 * AddFyrk reducer
 *
 */

import { fromJS } from "immutable";
import { DEFAULT_ACTION } from "./constants";
import { TITLE_CHANGE, PROCURATOR_CHANGE } from "./constants";
import { DESCRIPTION_CHANGE } from "./constants";
import { LINK_CHANGE, LINKDESCRIPTION_CHANGE } from "./constants";
import { IMAGE_DROP, FYRK_LOADED } from "./constants";
import {
  CHANGE_FYRK,
  CHANGE_CATEGORY,
  CHANGE_LOCATION,
  CHANGE_GROUP,
  SET_ERROR,
  SET_LOADING,
  CLEAR_FORM,
  CONTACTNAME_CHANGE,
  CONTACTEMAIL_CHANGE,
  CONTACTPHONE_CHANGE,
  CONTACTADDRESS_CHANGE,
  NEXTPERIOD_CHANGE,
  PERIODSTATUS_CHANGE,
  PERIODTYPE_CHANGE,
  CHANGE_UPDATEREMAIL,
  CLEAR_IMAGE,
  RESTORE_IMAGE
} from "./constants";

import parseISO from "date-fns/parseISO";

import { API_ROOT } from "../../api-config";

export const initialState = fromJS({
  title: "",
  description: "",
  linkdescription: "",
  contactname: "",
  contactphone: "",
  contactaddress: "",
  contactemail: "",
  nextperiod: null,
  next_period_status: 0,
  periodtype: "",
  fyrkitem: "",
  link: "",
  pictures: [],
  originalimage : [],
  selectedlocations: false,
  selectedgroups: false,
  value: false,
  loading: true,
  error: [],
  fyrk: false,
  procurator: "",
  updateremail: false,
});

function AddFyrkReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case NEXTPERIOD_CHANGE:
      return state.set("nextperiod", action.value);
    case PERIODSTATUS_CHANGE:
      return state.set("next_period_status", action.value);
    case PERIODTYPE_CHANGE:
      return state.set("periodtype", action.value);
    case TITLE_CHANGE:
      return state.set("title", action.value);
    case CONTACTNAME_CHANGE:
      return state.set("contactname", action.value);
    case CONTACTPHONE_CHANGE:
      return state.set("contactphone", action.value);
    case CONTACTADDRESS_CHANGE:
      return state.set("contactaddress", action.value);
    case CONTACTEMAIL_CHANGE:
      return state.set("contactemail", action.value);
    case PROCURATOR_CHANGE:
      return state.set("procurator", action.value);
    case FYRK_LOADED:
      const {
        description,
        title,
        contact_name,
        contact_telephone,
        contact_email,
        next_period,
        mailingaddress,
        urls,
        categories,
        groups,
        regions,
        fyrkimages,
        procurator,
        periodtype,
        next_period_status,
      } = action.fyrk;
      let link = "";
      let linkdescription = "";

      if (urls.length > 0) {
        link = urls[0].url;
        linkdescription = urls[0].text;
      }

      let fyrkimage = "";
      if (fyrkimages && fyrkimages.length > 0) {
        fyrkimage = API_ROOT.replace(/\/$/, "") + fyrkimages[0].thumbnail;
        fyrkimage = fyrkimage.replace(/WxH/g, "1000x1000");
      }
      let nextperiodvalue = null;
      if (next_period) {
        nextperiodvalue = parseISO(next_period);
      }

      return state
        .set("title", title)
        .set("contactname", contact_name)
        .set("updateremail", false)
        .set("contactphone", contact_telephone)
        .set("contactemail", contact_email)
        .set("nextperiod", nextperiodvalue)
        .set("next_period_status", next_period_status+"")
        .set("contactaddress", mailingaddress)
        .set("periodtype", periodtype)
        .set("procurator", procurator)
        .set("fyrkitem", action.fyrk)
        .set("link", link)
        .set("linkdescription", linkdescription)
        .set("description", description)
        .set("value", categories)
        .set("selectedgroups", groups)
        .set("selectedlocations", regions)
        .set("pictures", [fyrkimage])
        .set("originalimage", [fyrkimage]);
    case CHANGE_FYRK:
      if (state.get("fyrk")) {
        return state.set("fyrk", false);
      }
      return state.set("fyrk", true);
    case DESCRIPTION_CHANGE:
      return state.set("description", action.value);
    case CHANGE_CATEGORY:
      return state.set("value", action.value);
    case CHANGE_UPDATEREMAIL:
      return state.set("updateremail", action.value);
    case CHANGE_LOCATION:
      return state.set("selectedlocations", action.value);
    case CHANGE_GROUP:
      return state.set("selectedgroups", action.value);
    case LINK_CHANGE:
      return state.set("link", action.value);
    case LINKDESCRIPTION_CHANGE:
      return state.set("linkdescription", action.value);
    case IMAGE_DROP:
      return state.set("pictures", [action.value]);
    case CLEAR_IMAGE:
        return state.set("pictures", false);
    case RESTORE_IMAGE:
        return state.set("pictures", state.get("originalimage"));
    case SET_ERROR:
      return state.set("error", action.value);
    case SET_LOADING:
      return state.set("loading", action.value);
    case CLEAR_FORM:
      return initialState;
    default:
      return state;
  }
}

export default AddFyrkReducer;
