/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';

import withStyles from '@material-ui/styles/withStyles';

import List from '../../components/List';
import LoadingIndicator from '../../components/LoadingIndicator';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import Logo from '../../images/logo.svg';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import { useMatomo } from "@datapunt/matomo-tracker-react";

import {
  makeSelectUserId,
  makeSelectCurrentUser,
  makeSelectKultur,
  makeSelectLoading,
  makeSelectError,
  makeSelectCategoriesMainSelectedNames,
  getFavoriter,
  makeSelectGroups,
  getLocationName,
  getHelp,
} from '../../containers/App/selectors';

import KulturList from '../../components/KulturList';
import BreadCrumb from '../../components/BreadCrumb';

import ViewList from '@mui/icons-material/ViewList';
import ViewModule from '@mui/icons-material/ViewModule';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import Section from './Section';
import { loadKultur, removeCategory, handleChangePlats } from '../App/actions';
import reducer from './reducer';
import saga from './saga';
import CenteredSection from './CenteredSection';
import {
  makeSelectListView,
  makeSelectMasonryView,
  getPage,
  getMore,
} from './selectors';
import { toListView, toMasonryView, changePage } from './actions';
import { getSelectedLocations } from '../ProfileSingleEdit/selectors';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import ReactToPrint from 'react-to-print';
import Box from '@material-ui/core/Box';
const styles = theme => ({
  alignright: {
    float: 'right',
    marginRight: '1em',
    textAlign: 'right',

  },
  alignleft: {
    marginLeftt: '1em',
    textAlign: 'left',
    float: 'left',

  },
  nomarginSection: {
    margin: "0 !important",
    paddingBottom: '2em',
  },
  nomarginSection: {
    paddingBottom: '2em',
  }
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#9691BC',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    },
  arrow:{
    color: theme.palette.common.white,

  },
}))(Tooltip);

export class Kultur extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  /**
   * when initial state username is not null, submit the form to load repos
   */
  componentDidMount() {
    this.props.onSubmitForm();
  }


  render() {
    const {
      loading,
      error,
      kultur,
      breadcrumb,
      listview,
      masonryview,
      classes,
      userid,
      user,
      favoriter,
      more,
      page,
      helptexts,
    } = this.props;

    let kulturarbetare_info = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'kulturarbetare_info' });
      if (temptext) {
        kulturarbetare_info = temptext.description;
      }
    }

    const kulturListProps = {
      loading,
      error,
      kultur,
      userid,
      listview,
      masonryview,
      user,
      favoriter,
    };
    let loader = '';
    if (loading) {
      loader = <List component={LoadingIndicator} />;
    }

    const nextpage = page + 1;
    let morebutton = '';
    if (more) {
      morebutton = (
        <Button
          className={classes.morebutton}
          onClick={evt => {
            this.props.onMore(nextpage);
          }}
        >
          {' '}
          MER{' '}
        </Button>
      );
    }

    return (
      <article>
        <Helmet>
          <title>Kultur</title>
          <meta name="Profiler" content="" />
        </Helmet>
        <Section className={classes.nomarginSection}>
          <div className={classes.alignleft}>
            <BreadCrumb {...this.props} 
              removeCategory={(id) => {
                this.props.removeCategory(id);
              }} 
              removeAllCategories={() => {
                this.props.removeCategoryAll();
              }}/>
          </div>
          <div className={classes.alignleft}>
          <LightTooltip title={kulturarbetare_info} arrow placement="right">
            <IconButton >
            <Chip avatar={<Avatar src={Logo} />} label="Lista kulturarbetare"/>
            </IconButton>
            </LightTooltip>
            </div>

          <div className={classes.alignright}>
            <IconButton
              onClick={() => {
                this.props.onChangeViewMasonry();
              }}
            >
              {' '}
              <ViewModule />{' '}
            </IconButton>
            <IconButton
              onClick={() => {
                this.props.onChangeViewList();
              }}
            >
              {' '}
              <ViewList />{' '}
            </IconButton>
            {listview &&
          <Box display="inline-flex" displayPrint="none">
          <IconButton>
              <ReactToPrint
                pageStyle="margin-top:1em"
                trigger={() => {
                  return <div><LocalPrintshopIcon /></div>;
                }}
                content={() => this.componentRef
                }
              />
              </IconButton>
            </Box>
            }
          </div>
        </Section>
        <div>

          <Section ref={el => (this.componentRef = el)} className={classes.marginSection}>

            <KulturList {...kulturListProps} />
          </Section>
          <CenteredSection>
            {' '}
            {morebutton}
            {loader}
          </CenteredSection>
        </div>
      </article>
    );
  }
}

Kultur.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  kultur: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  onSubmitForm: PropTypes.func,
  username: PropTypes.string,
  onChangeUsername: PropTypes.func,
};

export function mapDispatchToProps(dispatch) {
  return {
    onSubmitForm: evt => {
      if (evt !== undefined && evt.preventDefault) evt.preventDefault();
      dispatch(loadKultur());
    },
    onChangeViewList: evt => dispatch(toListView()),
    onChangeViewMasonry: evt => dispatch(toMasonryView()),
    onMore: page => {
      dispatch(changePage(page));
      dispatch(loadKultur(true));
    },
    removeCategoryAll: () => {
      dispatch(removeCategory('all'));
      dispatch(loadKultur(true));
    },
    removeCategory: id => {
      dispatch(removeCategory(id));
      dispatch(loadKultur(true));
    },
    removeLocation: id => {
      dispatch(handleChangePlats());
      dispatch(loadKultur(true));
    },
  };
}

const mapStateToProps = createStructuredSelector({
  kultur: makeSelectKultur(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  breadcrumb: makeSelectCategoriesMainSelectedNames(),
  userid: makeSelectUserId(),
  user: makeSelectCurrentUser(),
  listview: makeSelectListView(),
  masonryview: makeSelectMasonryView(),
  favoriter: getFavoriter(),
  more: getMore(),
  page: getPage(),
  helptexts: getHelp(),
  groups: makeSelectGroups(),
  locationname: getLocationName(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'kultur', reducer });
const withSaga = injectSaga({ key: 'kultur', saga });

const KulturWithMatomo = (props) => {
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView({});
  }, []);

  return <Kultur {...props} />;
};

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withStyles(styles),
)(KulturWithMatomo);
