import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the AddEvenemang state domain
 */

const selectAddEvenemangDomain = state =>
  state.get('AddEvenemang', initialState);


const makeSelectAddEvenemang = () =>
  createSelector(selectAddEvenemangDomain, nyhetState => nyhetState.toJS());

const selectStartTimeDate = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('starttimedate'),
  );
  const selectNotisCategories = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('notiscategoriesevenemang').toJS(),
  );

const selectStartTimeHour = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('starttimehour'),
  );

const selectEndTimeDate = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('endtimedate'),
  );

const selectPlace = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('place'),
  );
const selectAddress = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('address'),
  );
const selectCity = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('city'),
  );

const selectPostal = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('postal'),
  );
const getLinks = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('links'),
  );
  const getTabValue = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('tabvalue'),
  );
  const getTabValueTitle = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('tabvaluetitle'),
  );
  const getTabValueShort = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('tabvalueshort'),
  );
  const getDates = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('dates'),
  );
const getKultur = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('kultur'),
  );
  const getCustomOpen= () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('custom_open'),
  );
  const getLanguage = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('language'),
  );
  const getProfiles = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('profiles'),
  );
const getEditIndex = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('editindex'),
  );
const getSearchfieldValue = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('searchfieldvalue'),
  );

const getPrice = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('price'),
  );
  const getPrices = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('prices'),
  );
const getError = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('error'),
  );
const getPriceDescription = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('pricedescription'),
  );

const selectEndTimeHour = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('endtimehour'),
  );

const makeSelectTitleFi = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('titlefi'),
  );
  const makeSelectTitleSv = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('titlesv'),
  );
  const makeSelectTitleEn = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('titleen'),
  );
const makeSelectDescriptionSv = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('descriptionsv'),
  );


  const makeSelectDescriptionFi = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('descriptionfi'),
  );

  const makeSelectDescriptionEn = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('descriptionen'),
  );



const makeSelectShortDescriptionEn = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('shortdescriptionen'),
  );
  const makeSelectShortDescriptionFi = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('shortdescriptionfi'),
  );
  const makeSelectShortDescriptionSv = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('shortdescriptionsv'),
  );



const makeSelectGroupsSelected = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('selectedgroups'),
  );
const makeSelectMetasSelected = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('selectedmetas'),
  );
  
  const getSelectedMainCategories = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('selectedmaincategories').toJS(),
  );


const makeSelectValue = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('value'),
  );

  const getSuggestions = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('suggestions'),
  );
const makeSelectLocationValue = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('locationvalue'),
  );
const isFree = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('isfree'),
  );
const makeSelectLink = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('link'),
  );
  const getRSVP = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('rsvp'),
  );
  const getRegion = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('region'),
  );
  const getSending = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('sending'),
  );
  const getOrg= () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('org'),
  );
const makeSelectPictures = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('pictures'),
  );

const getEditorStateSv = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('editorstatesv'),
  );
  const getEditorStateEn = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('editorstateen'),
  );
  const getEditorStateFi = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('editorstatefi'),
  );
const getBuyLink = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('buylink'),
  );
const getEvent = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('eventitem'),
  );
const getTotalCategories = () =>
  createSelector(selectAddEvenemangDomain, nyhetState =>
    nyhetState.get('totalcategories'),
  );
export {
  getTotalCategories,
  getEvent,
  getBuyLink,
  getEditorStateSv,
  getEditorStateEn,
  getEditorStateFi,
  getError,
  getEditIndex,
  getSearchfieldValue,
  getProfiles,
  getLinks,
  makeSelectMetasSelected,
  getPrice,
  getPrices,
  getPriceDescription,
  makeSelectGroupsSelected,
  isFree,
  selectPostal,
  getDates,
  selectCity,
  selectAddress,
  selectPlace,
  makeSelectTitleFi,
  makeSelectTitleSv,
  makeSelectTitleEn,
  makeSelectDescriptionSv,
  makeSelectDescriptionFi,
  makeSelectDescriptionEn,
  makeSelectLink,
  makeSelectPictures,
  makeSelectValue,
  selectStartTimeDate,
  selectStartTimeHour,
  getLanguage,
  getRSVP,
  getOrg,
  getTabValue,
  getTabValueShort,
  getTabValueTitle,
  makeSelectLocationValue,
  getCustomOpen,
  selectEndTimeDate,
  selectEndTimeHour,
  getRegion,
  makeSelectShortDescriptionSv,
  makeSelectShortDescriptionFi,
  makeSelectShortDescriptionEn,
  getSelectedMainCategories,
  getSuggestions,
  getKultur,
  getSending,
  selectNotisCategories,
};
