/**
 * RepoListItem
 *
 * Lists the name and the issue count of a repository
 */
import React from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import RandomImage from '../../components/RandomImage';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import _ from 'lodash';

// import saga from './saga'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import Avatar from '@material-ui/core/Avatar';
import {
  likeEvent,
  dislikeEvent,
  addCategory,
  loadNyheter,
  addGroup,
  deleteNyhet,
} from '../App/actions';

import { API_ROOT } from '../../api-config';
import PopFavorite from '../../components/PopFavorite';
const styles = theme => ({
  iconcolor: {
    marginRight: '0.5em',
    color: '#666',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  title: {
    textAlign: 'left',
    paddingLeft: '1em',
  },
  flexNone: {
    flex: '1',
  },
  likebutton: {
    color: '#F27A68',
  },
  categorylink: {
    cursor: 'pointer',
    color: '#f57d6c',
  },
  rowitem: {
    [theme.breakpoints.down('xs')]: {
      display: `block`,
    },

    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {},
  },
  avataritem: {
    [theme.breakpoints.down('xs')]: {
      float: `left`,
      margin: '0 16px',
    },
    [theme.breakpoints.up('xs')]: {
      marginRight: '16px !important',
    },
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {},
  },
  '@media print': {
    avataritem: {
      display: 'none'
    },
    menulink: {
      display: 'none'
    },
    editbutton: {
      display: 'none'
    },
    deletebutton: {
      display: 'none'
    },
  },
});

export class NyheterListItem extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  UNSAFE_componentWillMount() {
    this.tempimage = RandomImage();
  }

  handleItemClick(id) {
    this.props.history.push(`/nyhet/${id}`);
  }

  handleItemClickEdit(evt, id) {
    evt.stopPropagation();
    this.props.history.push(`/nyhet/${id}/edit`);
  }

  render() {
    const { item } = this.props;
    const {
      title,
      description,
      newsimages,
      categories,
      groups,
    } = this.props.item;
    const { userid, user, favoriter } = this.props;
    const { classes } = this.props;
    let newsimage = this.tempimage;
    let favoriteids = _.map(favoriter, 'urls');
    let favorite = false;



    let itempath = API_ROOT+"news/"+item.id+"/"
    if(_.indexOf(favoriteids, itempath) != -1) {
      favorite = true;
    }
    if (newsimages.length > 0) {
      newsimage = API_ROOT.replace(/\/$/, '') + newsimages[0].thumbnail;
      newsimage = newsimage.replace(/WxH/g, '440x440');
    }

    const categorieslist = categories.map((list, index) => {
      if (categories.length > 0 && index < 5) {
        return (
          <a
            key={list.id}
            className={classes.categorylink}
            onClick={evt => {
              this.props.addCategory(evt, list.id, list.name);
            }}
          >
            #{list.name}{' '}
          </a>
        );
      }
    });
    let editbutton = '';

    if (item.user == userid || user.is_staff) {
      editbutton = (
        <Button
          onClick={evt => {
            this.handleItemClickEdit(evt, item.id);
          }}
          variant="text"
          size="small"
          className={classes.editbutton}
        >
          <EditIcon className={classes.leftIcon} />
        </Button>
      );
    }
    let deletebutton = '';
    if (item.user == userid || user.is_staff) {
      deletebutton = (
        <Button
          onClick={evt => {
            this.props.deleteNyhet(evt, item.id);
          }}
          variant="text"
          size="small"
          className={classes.deletebutton}
        >
          <DeleteIcon className={classes.leftIcon} />
        </Button>
      );
    }

    const groupslist = groups.map(list => {
      if (groups.length > 0) {
        return (
          <a
            key={list.id}
            id={list.id}
            className={classes.categorylink}
            onClick={evt => {
              this.props.addGroup(evt, list.id, list.name);
            }}
          >
            #{list.name}{' '}
          </a>
        );
      }
    });
    let likebutton = '';
      if (favorite) {
        likebutton = (
          <IconButton
            onClick={evt => {
              this.props.dislikeNyhet(evt, item.id);
            }}
            className={classes.likebutton}
          >
            <Favorite />
          </IconButton>
        );
      } else {
        likebutton = (
          <IconButton
            onClick={evt => {
              this.props.likeNyhet(evt, item.id);
            }}
            className={classes.likebutton}
          >
            <PopFavorite />
          </IconButton>
        );
      }
    
    return (
      <ListItem
        className={classes.rowitem}
        key={item.id}
        button
        divider
        onClick={() => {
          this.handleItemClick(item.id);
        }}
      >
        <Avatar
          className={classes.avataritem}
          alt="Event Image"
          src={newsimage}
        />
        <ListItemText className={classes.flexNone} primary={title} />
        <ListItemText className={classes.flexNone} primary={categorieslist} />
        <ListItemText className={classes.flexNone} primary={groupslist} />
        {likebutton}
        {deletebutton}
        {editbutton}
      </ListItem>
    );
  }
}
export function mapDispatchToProps(dispatch) {
  return {
    deleteNyhet: (evt, id) => {
      dispatch(deleteNyhet(id));
      evt.stopPropagation();
    },
    likeNyhet: (evt, id) => {
      dispatch(likeEvent(id, 'NEWS'));
      evt.stopPropagation();
    },
    dislikeNyhet: (evt, id) => {
      dispatch(dislikeEvent(id, 'NEWS'));
      evt.stopPropagation();
    },
    addCategory: (evt, id, value) => {
      evt.stopPropagation();
      dispatch(addCategory(id, value));
      dispatch(loadNyheter());
    },
    addGroup: (evt, id, value) => {
      evt.stopPropagation();
      dispatch(addGroup(id));
      dispatch(loadNyheter());
    },
  };
}
const withConnect = connect(
  null,
  mapDispatchToProps,
);

NyheterListItem.propTypes = {
  item: PropTypes.object,
  classes: PropTypes.object,
};
export default compose(
  withConnect,
  withRouter,
  withStyles(styles),
)(NyheterListItem);
