/**
 *
 * GroupPage
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import injectSaga from "../../utils/injectSaga";
import injectReducer from "../../utils/injectReducer";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { push } from "connected-react-router";

import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/styles/withStyles";
import saga from "./saga";
import reducer from "./reducer";
import { hideLeftDrawer } from "../../containers/App/actions";
import Select from "react-select";
import { getGroups, getOpenDialog } from "../App/selectors";

import {
  submitLoginForm,
  changeOrganisation,
  changeNewOrganisation,
  setOpen,
} from "./actions";
import {
  getRegisterSuccess,
  makeSelectMessage,
  makeSelectLoading,
  getError,
  getGroup,
  getOrganisation,
  getNewOrganisation,
  getOpen,
} from "./selectors";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    background: "#F5F5F5",
    marginBottom: theme.spacing(2),
  },
  dialogcontent: {
    padding: theme.spacing(10),
    textAlign: "center",
  },
  form: {
    width: "100%",
  },
  selectitem: {
    zIndex: "100",
  },
  spinner: {
    //marginLeft: '20px',
    //width: '20px',
    //height: '20px',
  },
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  griditem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: "center",
  },
});
const SpinnerAdornment = withStyles(styles)((props) => (
  <CircularProgress
    className={props.classes.spinner}
    size={20}
    style={{ color: "#fff" }}
  />
));

const AdornedButton = (props) => {
  const { children, loading, ...rest } = props;
  return (
    <Button {...rest}>
      {children}
      {loading && <SpinnerAdornment {...rest} />}
    </Button>
  );
};

export class GroupPage extends React.PureComponent {
  componentDidMount() {
    this.props.hideSearch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      registersuccess,
      classes,
      error,
      newgroup,
      organisation,
      usergroups,
      open,
    } = this.props;

    let sendmessage = "";

    if (registersuccess) {
      sendmessage = "Verifierande e-post skickad.";
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="sm">
          <Grid container direction="row">
            <Typography variant="h4">
              <p>Välj organisation</p>
            </Typography>
          </Grid>
          <Paper className={classes.paper}>
            <Grid container direction="row">
              <Grid item xs={8} className={classes.griditem}>
                <Select
                  className={classes.selectitem}
                  onChange={this.props.changeOrganisation}
                  name="form-field-name"
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  placeholder="Välj"
                  isDisabled={registersuccess}
                  value={organisation}
                  options={usergroups}
                />
              </Grid>
              <Grid item xs={4} className={classes.griditem}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={registersuccess || !organisation}
                  onClick={() => {
                    this.props.onSubmit();
                  }}
                >
                  ANSÖK
                </Button>
              </Grid>
              <Grid item xs={2} className={classes.griditem}>
                <Typography variant="h6">eller</Typography>
              </Grid>
              <Grid item xs={6} className={classes.griditem}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={registersuccess}
                  onClick={() => {
                    this.props.setGroup(newgroup);
                  }}
                >
                  SKAPA EN NY ORGANISATION
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Container>

        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText className={classes.dialogcontent}>
              Du får en bekräftelse till din e-post, när din begäran om
              användarrättighet har godkänts.
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.props.closeDialog} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

GroupPage.propTypes = {
  login: PropTypes.string,
  password: PropTypes.string,
  message: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  error: getError(),
  message: makeSelectMessage(),
  loading: makeSelectLoading(),
  registersuccess: getRegisterSuccess(),
  usergroups: getGroups(),
  newgroup: getGroup(),
  organisation: getOrganisation(),
  neworganisation: getNewOrganisation(),
  open: getOpen(),
});

function mapDispatchToProps(dispatch) {
  return {
    closeDialog: () => {
      dispatch(setOpen(false));
      dispatch(push("/groups"));
    },
    openDialog: () => {
      dispatch(setOpen(true));
    },
    hideSearch: (evt) => {
      dispatch(hideLeftDrawer());
    },
    setGroup: () => {
      dispatch(push("/profile/new"));
      // dispatch(setGroup(!value));
    },
    changeOrganisation: (value) => {
      dispatch(changeOrganisation(value));
    },
    changeNewOrganisation: (evt) => {
      dispatch(changeNewOrganisation(evt.target.value));
    },
    onSubmit: () => {
      dispatch(submitLoginForm());
    },
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: "GroupPage", reducer });
const withSaga = injectSaga({ key: "GroupPage", saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withStyles(styles)
)(GroupPage);
