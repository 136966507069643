/*
 *
 * AddNyhet reducer
 *
 */

import { fromJS } from 'immutable';
import {
  DEFAULT_ACTION,
  CHANGE_FYRKDATE,
  CHANGE_EDITORSTATE,
} from './constants';
import { TITLE_CHANGE } from './constants';
import { DESCRIPTION_CHANGE } from './constants';
import { LINK_CHANGE } from './constants';
import { IMAGE_DROP, NYHET_LOADED } from './constants';
import {
  CHANGE_FYRK,
  CHANGE_CATEGORY,
  CHANGE_LOCATION,
  CHANGE_GROUP,
  SET_ERROR,
  SET_LOADING,
  CLEAR_FORM,
  CLEAR_IMAGE,
  RESTORE_IMAGE,
} from './constants';
import moment from 'moment/min/moment-with-locales';
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
} from 'draft-js';

import { API_ROOT } from '../../api-config';

export const initialState = fromJS({
  title: '',
  description: '',
  newsitem: '',
  link: '',
  pictures: [],
  selectedlocations: false,
  selectedgroups: false,
  value: false,
  loading: true,
  error: [],
  fyrk: false,
  editorstate: EditorState.createEmpty(),
  fyrkdate: moment().format(),
});

function AddNyhetReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case TITLE_CHANGE:
      return state.set('title', action.value);
    case NYHET_LOADED:
      const {
        description,
        title,
        urls,
        categories,
        groups,
        regions,
        newsimages,
      } = action.nyhet;
      let link = '';
      if (urls.length > 0) {
        link = urls[0].url;
      }

      let newsimage = '';
      if (newsimages && newsimages.length > 0) {
        newsimage = API_ROOT.replace(/\/$/, '') + newsimages[0].thumbnail;
        newsimage = newsimage.replace(/WxH/g, '1000x1000');
      }

      const blocksFromHTML = convertFromHTML(description);
      const neweditorstate = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      return state
        .set('title', title)
        .set('newsitem', action.nyhet)
        .set('link', link)
        .set('description', description)
        .set('value', categories)
        .set('selectedgroups', groups)
        .set('selectedlocations', regions)
        .set('editorstate', EditorState.createWithContent(neweditorstate))
        .set('pictures', [newsimage]);
    case CHANGE_FYRK:
      if (state.get('fyrk')) {
        return state.set('fyrk', false);
      }
      return state.set('fyrk', true);

    case CHANGE_EDITORSTATE:
      return state.set('editorstate', action.value);

    case DESCRIPTION_CHANGE:
      return state.set('description', action.value);
    case CHANGE_CATEGORY:
      return state.set('value', action.value);
    case CHANGE_LOCATION:
      return state.set('selectedlocations', action.value);
    case CHANGE_GROUP:
      return state.set('selectedgroups', action.value);
    case LINK_CHANGE:
      return state.set('link', action.value);
    case CHANGE_FYRKDATE:
      return state.set('fyrkdate', action.value);
    case IMAGE_DROP:
      return state.set('pictures', [action.value]);
    case CLEAR_IMAGE:
        return state.set("pictures", false);
    case RESTORE_IMAGE:
        return state.set("pictures", state.get("originalimage"));
    case SET_ERROR:
      return state.set('error', action.value);
    case SET_LOADING:
        return state.set('loading', action.value);
    case CLEAR_FORM:
      return initialState;
    default:
      return state;
  }
}

export default AddNyhetReducer;
