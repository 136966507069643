import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { LOAD_FAVORITES } from "./constants";
import { favoritesLoaded, setLoading } from "./actions";

import { getFavoriter } from "../../containers/App/selectors";
import { loadLikes } from "../../containers/App/actions";
import _ from "lodash";
import { API_ROOT, LE_ROOT } from "../../api-config";
import request from "../../utils/request";

import { loadFavoriter } from "../../containers/App/actions";
/**
 * Github repos request/response handler
 */
const token = localStorage.getItem("token");

const fetchJSON = (url, options = {}) =>
  new Promise((resolve, reject) =>
    fetch(url, options)
      .then((response) =>
        response.status !== 201 &&
        response.status !== 200 &&
        response.status !== 410 &&
        response.status !== 404
          ? reject(response)
          : response
      )
      .then((response) => {
        if (response.status == 410 || response.status == 404) {
          resolve(null);
        } else {
          return response.json();
        }
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error))
  );

export function* loadFavorites() {
  yield put(setLoading(true));
  let favoriterobjects = [];
  const token = localStorage.getItem("token");
  const userid = localStorage.getItem("userid");
  const usergroupid = localStorage.getItem("usergroupid");
  let favresults = [];

  if(token) {
    const requestFavoriter = `${API_ROOT}favorite/?user=${userid}`;
    const favoriterarr = yield call(request, requestFavoriter);
    yield put(loadFavoriter(favoriterarr.results));
     favresults = favoriterarr.results;
  } else {
    let cachefavorites = localStorage.getItem('cachefavorites');
    let cacheditems = JSON.parse(cachefavorites);
    yield put(favoritesLoaded(cacheditems));
    yield put(setLoading(false));
     favresults = cacheditems;

  }

  try {
    let newArray = [];

    if (favresults) {
      newArray = yield all(
        favresults.map(function (item) {
          let url = item.urls;
          if ((item.LINKTYPE = "EVENT")) {
            url = url + "?include=keywords,location";
          }
          return call(fetchJSON, url);
        })
      );
    }
    newArray.map((item) => {
      if (item != null) {
        if (item.hasOwnProperty("periodtype")) {
          item.type = "FYRK";
          favoriterobjects.push(item);
        } else if (item.hasOwnProperty("newsimages")) {
          item.type = "NEWS";
          favoriterobjects.push(item);
        } else if (item.hasOwnProperty("data_source")) {
          item.type = "EVENT";
          favoriterobjects.push(item);
        } else if (item.hasOwnProperty("posterimages")) {
          item.type = "PROFILE";
          favoriterobjects.push(item);
        }
      }
    });
    yield put(favoritesLoaded(favoriterobjects));
    yield put(setLoading(false));
  } catch (err) {
    // yield put(nyheterLoadingError(err));
  }
  
  
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* githubData() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(LOAD_FAVORITES, loadFavorites);
}
