/*
 *
 * AddFyrk actions
 *
 */

import { DEFAULT_ACTION } from './constants';
import { TITLE_CHANGE } from './constants';
import { DESCRIPTION_CHANGE } from './constants';
import { LINK_CHANGE,LINKDESCRIPTION_CHANGE } from './constants';
import { SUBMIT_ADDFYRKFORM } from './constants';
import { IMAGE_DROP, LOAD_FYRKITEM, FYRK_LOADED } from './constants';
import {
  CHANGE_EDITORSTATE,
  PROCURATOR_CHANGE,
  CHANGE_FYRK,
  CHANGE_CATEGORY,
  CHANGE_LOCATION,
  CHANGE_GROUP,
  SET_ERROR,
  SET_LOADING,
  RESTORE_IMAGE,
  CLEAR_FORM,
  CONTACTNAME_CHANGE,
  CONTACTPHONE_CHANGE,
  CONTACTEMAIL_CHANGE,
  CONTACTADDRESS_CHANGE,
  NEXTPERIOD_CHANGE,
  PERIODSTATUS_CHANGE,
  PERIODTYPE_CHANGE,
  CHANGE_UPDATEREMAIL,
  CLEAR_IMAGE
} from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}
export function titleOnChange(title) {
  return {
    type: TITLE_CHANGE,
    value: title,
  };
}
export function changeProcurator(value) {
  return {
    type: PROCURATOR_CHANGE,
    value: value,
  };
}
export function changeUpdaterEmail(value) {
  return {
    type: CHANGE_UPDATEREMAIL,
    value: value,
  };
}
export function changeContactEmail(value) {
  return {
    type: CONTACTEMAIL_CHANGE,
    value: value,
  };
}
export function changeNextPeriod(value) {
  return {
    type: NEXTPERIOD_CHANGE,
    value: value,
  };
}
export function changePeriodStatus(value) {
  return {
    type: PERIODSTATUS_CHANGE,
    value: value,
  };
}
export function changePeriodType(value) {
  return {
    type: PERIODTYPE_CHANGE,
    value: value,
  };
}
export function changeContactAddress(value) {
  return {
    type: CONTACTADDRESS_CHANGE,
    value: value,
  };
}
export function changeContactPhone(value) {
  return {
    type: CONTACTPHONE_CHANGE,
    value: value,
  };
}
export function changeContactName(value) {
  return {
    type: CONTACTNAME_CHANGE,
    value: value,
  };
}
export function clearForm() {
  return {
    type: CLEAR_FORM,
  };
}
export function fyrkLoaded(fyrk) {

  return {
    type: FYRK_LOADED,
    fyrk,
  };
}

export function setError(value) {
  return {
    type: SET_ERROR,
    value,
  };
}
export function setLoading(value) {
  return {
    type: SET_LOADING,
    value,
  };
}
export function handleChange(value) {
  return {
    type: CHANGE_CATEGORY,
    value,
  };
}
export function linkOnChange(link) {
  return {
    type: LINK_CHANGE,
    value: link,
  };
}
export function linkDescriptionOnChange(value) {
  return {
    type: LINKDESCRIPTION_CHANGE,
    value: value,
  };
}
export function imageDrop(image) {
  return {
    type: IMAGE_DROP,
    value: image,
  };
}
export function loadFyrkItem(id) {
  return {
    type: LOAD_FYRKITEM,
    value: id,
  };
}
export function submitAddFyrkForm() {
  return {
    type: SUBMIT_ADDFYRKFORM,
  };
}

export function changeLocation(value) {
  return {
    type: CHANGE_LOCATION,
    value,
  };
}
export function clearImage() {
  return {
    type: CLEAR_IMAGE,
  };
}
export function restoreImage() {
  return {
    type: RESTORE_IMAGE,
  };
}
export function changeGroup(value) {
  return {
    type: CHANGE_GROUP,
    value,
  };
}
export function changeFyrk() {
  return {
    type: CHANGE_FYRK,
  };
}

export function changeEditorState(value) {
  return {
    type: CHANGE_EDITORSTATE,
    value,
  };
}

export function descriptionOnChange(description) {
  return {
    type: DESCRIPTION_CHANGE,
    value: description,
  };
}
