/*
 * HomeReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */
import { fromJS } from 'immutable';

import {
  SET_EVENTS,
  SET_NEWS,
  SET_PROFILES,
  TO_LIST_EVENTS,
  TO_MASONRY_EVENTS,
  TO_LIST_NEWS,
  TO_MASONRY_NEWS,
  TO_LIST_PROFILES,
  TO_MASONRY_PROFILES
} from './constants';

// The initial state of the App
const initialState = fromJS({
  username: '',
  homeevents : [],
  homenews : [],
  homeprofiles : [],
  masonryviewevents: true,
  listviewevents: false,
  masonryviewnews: true,
  listviewnews: false,
  masonryviewprofiles: true,
  listviewprofiles: false,
});

function evenemangReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EVENTS:
      return state.set('homeevents', action.value);
    case SET_NEWS:
      return state.set('homenews', action.value);
    case SET_PROFILES:
      return state.set('homeprofiles', action.value);
    case TO_LIST_EVENTS:
      return state.set('listviewevents', true).set('masonryviewevents', false);
    case TO_MASONRY_EVENTS:
      return state.set('masonryviewevents', true).set('listviewevents', false);
    case TO_LIST_NEWS:
      return state.set('listviewnews', true).set('masonryviewnews', false);
    case TO_MASONRY_NEWS:
      return state.set('masonryviewnews', true).set('listviewnews', false);
    case TO_LIST_PROFILES:
      return state.set('listviewprofiles', true).set('masonryviewprofiles', false);
    case TO_MASONRY_PROFILES:
      return state.set('masonryviewprofiles', true).set('listviewprofiles', false);
    default:
      return state;
  }
}

export default evenemangReducer;
