/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';


import H1 from '../../components/H1';
import messages from './messages';

export default function NotFound() {
  return (
    <article>
    <Grid>
      <H1>
      </H1>
      </Grid>
    </article>
  );
}
