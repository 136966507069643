/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const LOAD_PROFILE = 'boilerplate/ProfileSingle/LOAD_PROFILE';
export const PROFILE_LOADED = 'boilerplate/ProfileSingle/PROFILE_LOADED';

export const EVENTS_LOADED = 'boilerplate/ProfileSingle/EVENTS_LOADED';
export const EVENTS_LOADED_PAST = 'boilerplate/ProfileSingle/EVENTS_LOADED_PAST';

export const NEWS_LOADED = 'boilerplate/ProfileSingle/NEWS_LOADED';
export const FYRK_LOADED = 'boilerplate/ProfileSingle/FYRK_LOADED';

export const SIMILAR_LOADED = 'boilerplate/ProfileSingle/SIMILAR_LOADED';

export const LOAD_EVENTS = 'boilerplate/ProfileSingle/LOAD_EVENTS';
