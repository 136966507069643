/*
 *
 * ResetPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  PASSWORD_CHANGE,
  PASSWORDCONFIRM_CHANGE,
  AUTH_FAILURE,
  REGISTER_SUCCESS,
  SET_LOADING,
  SET_ERROR,
  DEFAULT_ACTION,
  LOGIN_CHANGE,
} from './constants';

export const initialState = fromJS({
  login: '',
  password: '',
  passwordconfirm: '',
  message: '',
  loading: false,
  token: '',
  error: false,
  registersuccess: false,
});

function ResetPageReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case LOGIN_CHANGE:
      return state.set('login', action.value);
    case PASSWORD_CHANGE:
      return state.set('password', action.value);
    case PASSWORDCONFIRM_CHANGE:
      return state.set('passwordconfirm', action.value);
    case AUTH_FAILURE:
      return state.set('message', action.value);
    case REGISTER_SUCCESS:
      return state.set('loading', false).set('registersuccess', true);
    case SET_LOADING:
      return state.set('loading', action.value);
    case SET_ERROR:
      return state.set('error', action.value);
    default:
      return state;
  }
}

export default ResetPageReducer;
