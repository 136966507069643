/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */


export const CHANGE_USERNAME = 'boilerplate/Kultur/CHANGE_USERNAME';
export const TO_LIST = 'boilerplate/Kultur/TO_LIST';
export const TO_MASONRY = 'boilerplate/Kultur/TO_MASONRY';
export const CHANGE_PAGE = 'boilerplate/Kultur/CHANGE_PAGE';
export const CHANGE_MORE = 'boilerplate/Kultur/CHANGE_MORE';
export const CHANGE_PAGESIZE = 'boilerplate/Kultur/CHANGE_PAGESIZE';

