/**
 * Homepage selectors
 */

import { createSelector } from 'reselect';

const selectKultur = state => state.get('kultur');
const makeSelectListView = () =>
  createSelector(
    selectKultur,
    EvenemangState => EvenemangState.get('listview'),
  );
const makeSelectMasonryView = () =>
  createSelector(
    selectKultur,
    EvenemangState => EvenemangState.get('masonryview'),
  );

const getPage = () =>
  createSelector(
    selectKultur,
    EvenemangState => EvenemangState.get('page'),
  );
const getPageSize = () =>
  createSelector(
    selectKultur,
    EvenemangState => EvenemangState.get('pagesize'),
  );

const getMore = () =>
  createSelector(
    selectKultur,
    EvenemangState => EvenemangState.get('more'),
  );

export {
  selectKultur,
  getPage,
  getPageSize,
  makeSelectMasonryView,
  makeSelectListView,
  getMore,
};
