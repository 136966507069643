import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the UppgifterPage state domain
 */

const selectUppgifterPageDomain = state =>
  state.get('UppgifterPage', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by UppgifterPage
 */

const makeSelectUppgifterPage = () =>
  createSelector(selectUppgifterPageDomain, uppgifterState => uppgifterState.toJS());

const makeSelectLogin = () =>
  createSelector(selectUppgifterPageDomain, uppgifterState =>
    uppgifterState.get('login'),
  );
const getError = () =>
  createSelector(selectUppgifterPageDomain, uppgifterState =>
    uppgifterState.get('error'),
  );

  const getFirstName= () =>
  createSelector(selectUppgifterPageDomain, uppgifterState =>
    uppgifterState.get('firstname'),
  );
  const getLastName =() =>
  createSelector(selectUppgifterPageDomain, uppgifterState =>
    uppgifterState.get('lastname'),
  );
  const getOpen=() =>
  createSelector(selectUppgifterPageDomain, uppgifterState =>
    uppgifterState.get('open'),
  );
  const getOpenDialog=() =>
  createSelector(selectUppgifterPageDomain, uppgifterState =>
    uppgifterState.get('opendialog'),
  );
const getRegisterSuccess = () =>
  createSelector(selectUppgifterPageDomain, uppgifterState =>
    uppgifterState.get('registersuccess'),
  );
const makeSelectMessage = () =>
  createSelector(selectUppgifterPageDomain, uppgifterState =>
    uppgifterState.get('message'),
  );
const makeSelectLoading = () =>
  createSelector(selectUppgifterPageDomain, uppgifterState =>
    uppgifterState.get('loading'),
  );
const getPassword = () =>
  createSelector(selectUppgifterPageDomain, uppgifterState =>
    uppgifterState.get('password'),
  );
  const makeSelectCurrentPass = () =>
  createSelector(selectUppgifterPageDomain, uppgifterState =>
    uppgifterState.get('currentpass'),
  );
const makeSelectPasswordConfirm = () =>
  createSelector(selectUppgifterPageDomain, uppgifterState =>
    uppgifterState.get('passwordconfirm'),
  );
const getfyrkrequestvalue = () =>
  createSelector(selectUppgifterPageDomain, (uppgifterState) =>
  uppgifterState.get("fyrklover")
  );

export {
  getRegisterSuccess,
  getError,
  makeSelectCurrentPass,
  makeSelectUppgifterPage,
  makeSelectLogin,
  getPassword,
  makeSelectMessage,
  getOpenDialog,
  makeSelectLoading,
  makeSelectPasswordConfirm,
  getFirstName,
  getLastName,
  getOpen,
  getfyrkrequestvalue
};
