/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */
import { push } from "connected-react-router";
import { withRouter } from "react-router-dom";

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import FyrkImage from "../../components/FyrkImage";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@mui/icons-material/Delete";

import { API_ROOT } from "../../api-config";
import Favorite from "@mui/icons-material/Favorite";
import PopFavorite from "../../components/PopFavorite";
import { Link } from "react-router-dom";

import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import moment from "moment/min/moment-with-locales";
import "moment/locale/sv";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const styles = (theme) => ({
  alignright: {
    float: "right",
    marginRight: "1em",
    textAlign: "right",
  },
  root: {
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      flexFlow: "column",
    },
    [theme.breakpoints.up("sm")]: {
      display: "block",
      flexFlow: "nowrap",
    },

    [theme.breakpoints.up("md")]: {
      display: "flex",
      paddingBottom: "5em",
    },
    backgroundColor: "transparent",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },

  content: {
    // flex: '1 0 auto',
    order: 3,
    "@media print": {
      order: 1,
    } 
  },
  parts: {
    order: 2,
  "@media print": {
    order: 4,
    },
  },
  card: {
    marginTop: "1em",
  },
  actions: {
    alignItems: "flex-start",
    flexDirection: "column",
    order: 3,
    "@media print": {
      width: "20em",
      order: 4,
    } 

  },
  cardcontent: {
    marginLeft: "3em",
    marginRight: "3em",
  },
  description: {
    whiteSpace: "pre-wrap",
    marginTop: "2em",
    marginBottom: "1em",
  },
  categorylink: {
    cursor: "pointer",
    color: "#000000",
  },

  collabutton: {
    color: "#fff",
    fontWeight: "bold",
  },
  media: {
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      margin: "0 0em auto 0",
      padding: "1em",
      order: 1,
    },
    [theme.breakpoints.up("md")]: {
      width: "16em",
      height: "14em",
      margin: "1em 4em 2em 0",
      padding: "0",
      order: 1,
    },
    "@media print": {
      display: "none",
    } 

  },

  iconcolor: {
    marginRight: "0.5em",
    color: "#666",
  },
  title: {
    textAlign: "left",
    paddingLeft: "1em",
  },
  bigAvatar: {
    width: 150,
    height: 150,
    marginTop: 40,
  },
  avatar: {
    width: "10em",
    height: "10em",
    margin: "0em 6em 1em 0",
    "@media print": {
      display: "none",
    } 
  },
  row: {
    display: "flex",
    justifyContent: "center",
  },
  likebutton: {
    color: "#44AB66",
    backgroundColor: "##E2F3E7",
    "@media print": {
      display: "none",
    } 

  },
  backarrow: {
    color: "#000000",
    "@media print": {
      display: "none",
    } 

  },
});

export class FyrkerSingle extends React.PureComponent {
  componentDidMount() {
    this.tempimage = FyrkImage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  handleBack() {
    this.props.history.push("/");
  }

  handleItemClickEdit(evt, id) {
    evt.stopPropagation();
    this.props.history.push(`/fyrkpost/${id}/edit`);
  }

  render() {
    const { item, classes, fyrk, userid, user, favoriter } = this.props;

    const favoriteids = _.map(favoriter, "urls");
    const itempath = `${API_ROOT}fyrk/${fyrk.id}/`;
    let favorite = false;
    if (_.indexOf(favoriteids, itempath) != -1) {
      favorite = true;
    }

    const {
      title,
      categories,
      fyrkimages,
      groups,
      updated,
      procurator,
      next_period,
      prev_period,
      next_period_status,
      periodtype,
      contact_name,
      contact_email,
      contact_telephone,
      mailingaddress,
      urls,
    } = fyrk;

    let fyrkimage = this.tempimage;
    if (fyrkimages && fyrkimages.length > 0) {
      fyrkimage = API_ROOT.replace(/\/$/, "") + fyrkimages[0].thumbnail;
      fyrkimage = fyrkimage.replace(/WxH/g, "600x6000");
    }
    let description = "";
    if (fyrk.description) {
      description = fyrk.description;
    }

    let datestring = "";
    let periodstatus = "";
    let periodstring = "";

    if (next_period && next_period_status == 1) {
      datestring = "Ansökningstid " + moment(next_period).format("D.M.YYYY");
      periodstatus = " (slutgiltig)";
    }
    if (next_period && next_period_status == 0) {
      datestring = "Ansökningstid " + moment(next_period).format("MMM YYYY");
      periodstatus = " (preliminär)";
    }
    if (next_period == null && prev_period != null) {
      datestring = moment(prev_period).format(" MMM YYYY");
      // periodstatus = " (föregående)";
    }

    if (periodtype == "CONTINUOUS") {
      periodstring = (
        <>
          <b>Fortlöpande ansökningstid</b>
        </>
      );
    } else {
      periodstring = datestring + periodstatus;
    }

    let usergroupid = localStorage.getItem("usergroupid");

    let editbutton = "";
    if (user) {
    if (fyrk.user == userid || user.is_staff || usergroupid == fyrk.usergroup) {
      editbutton = (
        <Button
          onClick={(evt) => {
            this.handleItemClickEdit(evt, fyrk.id);
          }}
          variant="text"
          size="small"
          className={classes.editbutton}
        >
          <EditIcon className={classes.leftIcon} />
          REDIGERA
        </Button>
      );
    }
  }
    let deletebutton = "";
    if (user) {
    if (fyrk.user == userid ||user.is_staff || usergroupid == fyrk.usergroup) {
      deletebutton = (
        <Button
          onClick={(evt) => {
            this.props.deleteFyrk(evt, fyrk.id);
          }}
          variant="text"
          size="small"
          className={classes.deletebutton}
        >
          <DeleteIcon className={classes.leftIcon} />
          TA BORT
        </Button>
      );
    }
  }

    let likebutton = "";
    if (favorite) {
      likebutton = (
        <IconButton
          onClick={(evt) => {
            this.props.dislikeFyrk(evt, fyrk.id);
          }}
          className={classes.likebutton}
        >
          <Favorite />
        </IconButton>
      );
    } else {
      likebutton = (
        <IconButton
          onClick={(evt) => {
            this.props.likeFyrk(evt, fyrk.id);
          }}
          className={classes.likebutton}
        >
          {" "}
          <Typography variant="h5">Spara</Typography>
          <PopFavorite />
        </IconButton>
      );
    }

    let categorieslist = "";
    if (categories) {
      categorieslist = categories.map((list) => {
        if (categories.length > 0) {
          return (
            <a
              key={list.id}
              className={classes.categorylink}
              onClick={(evt) => {
                this.props.addCategory(evt, list.id, list.name);
              }}
            >
              #{list.name}{" "}
            </a>
          );
        }
      });
    }
    let groupslist = "";
    if (groups) {
      groupslist = groups.map((list) => {
        if (groups.length > 0) {
          return (
            <a
              id={list.id}
              key={list.id}
              className={classes.categorylink}
              onClick={(evt) => {
                this.props.addGroup(evt, list.id, list.name);
              }}
            >
              #{list.name}{" "}
            </a>
          );
        }
      });
    }
    let www = "";
    if (urls) {
      www = urls.map((list, index) => {
        if (urls.length > 0) {
          let link = "Länk";
          if (list.text != "string" && list.text != "") {
            link = list.text;
          } else {
            if (list.url.length > 30) {
              link = list.url.substring(0, 30) + "...";
            }
          }
          return (
            <a
              key={index}
              className={classes.categorylink}
              id={list.id}
              href={list.url}
              target="_blank"
            >
              {link}
            </a>
          );
        }
      });
    }

    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={false} md={1} />
          <Grid item xs={6} md={6}>
            <IconButton
              component={Link}
              to="/fyrk"
              className={classes.backarrow}
              style={{ textTransform: "none" }}
            >
              <ArrowBackIcon />
              <Typography variant="body"> tillbaka</Typography>
            </IconButton>
          </Grid>

          <Grid item xs={6} md={4} className={classes.alignright}>
            {likebutton}
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={false} md={1} />
          <Grid item xs={12} md={9}>
            <Card className={classes.root} elevation={0}>
              <div className={classes.parts}>
                <CardMedia className={classes.media}>
                  <Avatar
                    aria-label="Logo"
                    variant="square"
                    className={classes.avatar}
                    src={fyrkimage}
                  />
                </CardMedia>
                <CardActions className={classes.actions} disableSpacing={true} >
                  <Typography component="h5" variant="h5">
                    Kontaktinformation
                  </Typography>
                  <Typography variant="subtitle1" >
                    <b>
                      Kontaktperson: {contact_name}
                      <br />
                      E-post: {contact_email}
                      <br />
                      Telefon: {contact_telephone}
                      <br />
                      Adress: {mailingaddress}
                      <br />
                      Webbsidan: {www} <br />
                      {categorieslist} <br />
                    </b>
                    <br />
                  </Typography>
                </CardActions>
              </div>
              <CardContent className={classes.content}>
                <Typography component="h5" variant="h5">
                  {title}
                </Typography>
                <Typography variant="h3">
                  <b>{procurator}</b>
                </Typography>
                <div className={classes.description}>{description}</div>
                <div style={{ marginBottom: "1em" }}>
                  Kontrollera ALLTID ansökningstiderna med bidragskällan.
                </div>
                {periodtype != "CONTINUOUS" && (
                  <Typography
                    sx={{ fontWeight: "bolder" }}
                    component="h5"
                    variant="h5"
                  >
                    <AccessTimeIcon
                      style={{ marginRight: "0.3em", marginBottom: "0.2em" }}
                      title="tid"
                    />
                    <b>{periodstring} </b>
                  </Typography>
                )}
                {periodtype == "CONTINUOUS" && (
                  <Typography
                    sx={{ fontWeight: "bolder" }}
                    component="h5"
                    variant="h5"
                  >
                    <b>Fortlöpande ansökningstid</b>
                  </Typography>
                )}
                Uppdaterad: {moment(updated).format("D.M.YYYY")}{" "}
                <div>
                  {deletebutton}
                  {editbutton}
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

FyrkerSingle.propTypes = {
  item: PropTypes.object,
};






export default compose(
  withRouter,
  withStyles(styles)
)(FyrkerSingle);
