import Img1 from '../../images/placeholders/1.jpg';
import Img2 from '../../images/placeholders/2.jpg';
import Img3 from '../../images/placeholders/3.jpg';
import Img4 from '../../images/placeholders/4.jpg';
import Img5 from '../../images/placeholders/5.jpg';
import Img6 from '../../images/placeholders/6.jpg';
import Img7 from '../../images/placeholders/7.jpg';
import Img8 from '../../images/placeholders/8.jpg';
import Img9 from '../../images/placeholders/9.jpg';
import Img10 from '../../images/placeholders/10.jpg';
import Img11 from '../../images/placeholders/11.jpg';
import Img12 from '../../images/placeholders/12.jpg';
import Img13 from '../../images/placeholders/13.jpg';
import Img14 from '../../images/placeholders/14.jpg';
import Img15 from '../../images/placeholders/15.jpg';
import Img16 from '../../images/placeholders/16.jpg';
import Img17 from '../../images/placeholders/17.jpg';
import Img18 from '../../images/placeholders/18.jpg';
import Img19 from '../../images/placeholders/19.jpg';
import Img20 from '../../images/placeholders/20.jpg';
import Img21 from '../../images/placeholders/21.jpg';
import Img22 from '../../images/placeholders/22.jpg';
import Img23 from '../../images/placeholders/23.jpg';
import Img24 from '../../images/placeholders/24.jpg';
import Img25 from '../../images/placeholders/25.jpg';
import Img26 from '../../images/placeholders/26.jpg';
import Img27 from '../../images/placeholders/27.jpg';
import Img28 from '../../images/placeholders/28.jpg';
import Img29 from '../../images/placeholders/29.jpg';
import Img30 from '../../images/placeholders/30.jpg';
import Img31 from '../../images/placeholders/31.jpg';
import Img32 from '../../images/placeholders/32.jpg';
import Img33 from '../../images/placeholders/33.jpg';
import Img34 from '../../images/placeholders/34.jpg';
import Img35 from '../../images/placeholders/35.jpg';
import Img36 from '../../images/placeholders/36.jpg';
import Img37 from '../../images/placeholders/37.jpg';
import Img38 from '../../images/placeholders/38.jpg';
import Img39 from '../../images/placeholders/39.jpg';
import Img40 from '../../images/placeholders/40.jpg';
import Img41 from '../../images/placeholders/41.jpg';
import Img42 from '../../images/placeholders/42.jpg';
import Img43 from '../../images/placeholders/43.jpg';
import Img44 from '../../images/placeholders/44.jpg';

import React from 'react';
import PropTypes from 'prop-types';
function randomize(myArray) {
  return myArray[Math.floor(Math.random() * myArray.length)];
}
function RandomImage() {
  const arr = [
    Img1,
    Img2,
    Img3,
    Img4,
    Img5,
    Img6,
    Img7,
    Img8,
    Img9,
    Img10,
    Img11,
    Img12,
    Img13,
    Img14,
    Img15,
    Img16,
    Img17,
    Img18,
    Img19,
    Img20,
    Img21,
    Img22,
    Img23,
    Img24,
    Img25,
    Img26,
    Img27,
    Img28,
    Img29,
    Img30,
    Img31,
    Img32,
    Img33,
    Img34,
    Img35,
    Img36,
    Img37,
    Img38,
    Img39,
    Img40,
    Img41,
    Img42,
    Img43,
    Img44,
  ];
  return randomize(arr);
}

export default RandomImage;
