import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the AddNyhet state domain
 */

const selectAddNyhetDomain = state => state.get('AddNyhet', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by AddNyhet
 */

const makeSelectAddNyhet = () =>
  createSelector(selectAddNyhetDomain, nyhetState => nyhetState.toJS());

const getNewsItem = () =>
  createSelector(selectAddNyhetDomain, nyhetState =>
    nyhetState.get('newsitem'),
  );

const makeSelectTitle = () =>
  createSelector(selectAddNyhetDomain, nyhetState => nyhetState.get('title'));
const makeSelectDescription = () =>
  createSelector(selectAddNyhetDomain, nyhetState =>
    nyhetState.get('description'),
  );
const makeSelectValue = () =>
  createSelector(selectAddNyhetDomain, nyhetState => nyhetState.get('value'));
const makeSelectLink = () =>
  createSelector(selectAddNyhetDomain, nyhetState => nyhetState.get('link'));
const makeSelectPictures = () =>
  createSelector(selectAddNyhetDomain, nyhetState =>
    nyhetState.get('pictures'),
  );
const getSelectedLocations = () =>
  createSelector(selectAddNyhetDomain, nyhetState =>
    nyhetState.get('selectedlocations'),
  );
const getError = () =>
  createSelector(selectAddNyhetDomain, nyhetState => nyhetState.get('error'));
const getSelectedGroups = () =>
  createSelector(selectAddNyhetDomain, nyhetState =>
    nyhetState.get('selectedgroups'),
  );
const getFyrk = () =>
  createSelector(selectAddNyhetDomain, nyhetState => nyhetState.get('fyrk'));

  const getLoading = () =>
  createSelector(selectAddNyhetDomain, nyhetState => nyhetState.get('loading'));

const getFyrkDate = () =>
  createSelector(selectAddNyhetDomain, nyhetState =>
    nyhetState.get('fyrkdate'),
  );
const getEditorState = () =>
  createSelector(selectAddNyhetDomain, nyhetState =>
    nyhetState.get('editorstate'),
  );
export {
  getNewsItem,
  getEditorState,
  getFyrkDate,
  getFyrk,
  makeSelectTitle,
  makeSelectDescription,
  makeSelectLink,
  getLoading,
  makeSelectPictures,
  makeSelectValue,
  getSelectedLocations,
  getSelectedGroups,
  getError,
};
