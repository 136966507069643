/**
 * Homepage selectors
 */

import { createSelector } from "reselect";

const selectSingleProfile = (state) => state.get("userProfiles");

const makeSelectProfile = () =>
  createSelector(selectSingleProfile, (profileState) =>
    profileState.get("profile")
  );
const getEvents = () =>
  createSelector(selectSingleProfile, (profileState) =>
    profileState.get("events")
  );
const getFyrk = () =>
  createSelector(selectSingleProfile, (profileState) =>
    profileState.get("fyrk")
  );
const getSommarEvents = () =>
  createSelector(selectSingleProfile, (profileState) =>
    profileState.get("sommarevents")
  );
const getExpanded = () =>
  createSelector(selectSingleProfile, (profileState) =>
    profileState.get("expanded")
  );
const getTab = () =>
  createSelector(selectSingleProfile, (profileState) =>
    profileState.get("tab")
  );
const getOwnEventsPast = () =>
  createSelector(selectSingleProfile, (profileState) =>
    profileState.get("eventspast")
  );
const getOwnNews = () =>
  createSelector(selectSingleProfile, (profileState) =>
    profileState.get("news")
  );
const getOpenDialog = () =>
  createSelector(selectSingleProfile, (profileState) =>
    profileState.get("opendialog")
  );
const getCurrentUser = () =>
  createSelector(selectSingleProfile, (profileState) =>
    profileState.get("currentuser")
  );
const getCurrentUserGroup = () =>
  createSelector(selectSingleProfile, (profileState) =>
    profileState.get("currentusergroup")
  );
const getSimilar = () =>
  createSelector(selectSingleProfile, (profileState) =>
    profileState.get("similar")
  );

const getAnchorEl = () =>
  createSelector(selectSingleProfile, (evenemangState) =>
    evenemangState.get("anchorel")
  );
const getMenuOpen = () =>
  createSelector(selectSingleProfile, (evenemangState) =>
    evenemangState.get("menuopen")
  );
const getCurrentEvent = () =>
  createSelector(selectSingleProfile, (evenemangState) =>
    evenemangState.get("selectedevent")
  );

const getEvent = () =>
  createSelector(selectSingleProfile, (evenemangState) =>
    evenemangState.get("eventitem")
  );
const getOpenEvent = () =>
  createSelector(selectSingleProfile, (evenemangState) =>
    evenemangState.get("openevent")
  );

export {
  getFyrk,
  getSommarEvents,
  getOpenEvent,
  getEvent,
  getAnchorEl,
  getMenuOpen,
  getCurrentEvent,
  getTab,
  getCurrentUser,
  getCurrentUserGroup,
  getOpenDialog,
  getOwnNews,
  getEvents,
  selectSingleProfile,
  makeSelectProfile,
  getOwnEventsPast,
  getSimilar,
  getExpanded,
};
