import Fyrk from '../../images/Fyrk_webb.png';


import React from 'react';
import PropTypes from 'prop-types';

function FyrkImage() {
  return Fyrk;
}

export default FyrkImage;
