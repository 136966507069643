/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */
import { push } from 'connected-react-router';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from '../../utils/injectReducer';
import _ from 'lodash';
import LoadingIndicator from '../../components/LoadingIndicator';
import List from '../../components/List';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import classNames from 'classnames';
// import RandomImage from 'components/RandomImage';
import RandomImage from '../../components/RandomImage';
import Avatar from '@material-ui/core/Avatar';
import injectSaga from '../../utils/injectSaga';
import H2 from '../../components/H2';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';

import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/sv';
import { makeSelectLocale } from '../LanguageProvider/selectors';
import messages from '../EvenemangSingleModal/messages';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import PrintIcon from '@mui/icons-material/Print';
import EmailIcon from '@mui/icons-material/Email';
import ClearIcon from '@mui/icons-material/Clear';

import { closeDialog,openDialog,setTabValue,setTabValueTitle} from './actions';
import { getOpen,getTabValue,getTabValueTitle } from './selectors';
import PlaceIcon from '@mui/icons-material/Place';
import LinkIcon from '@mui/icons-material/Link';

import reducer from './reducer';
import saga from './saga';
import { makeSelectCurrentUser, makeSelectUserId } from '../App/selectors';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@material-ui/core/Typography';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Place from '../../images/place.svg';
import Time from '../../images/time.svg';
import Gmap from '../../images/google.svg';
import Redigera from '../../images/redigera.svg';
import {closeEvent} from '../../containers/UserProfiles/actions'
//import EvenemangSingleCard from  'containers/EvenemangSingleCard';

import { API_ROOT } from '../../api-config';
import Section from './Section';

const styles = theme => ({
  card: {
    marginTop: '1em',
  }
});

export class EvenemangSingleModal extends React.PureComponent {



  render() {

    const {     onClose,    openevent,      locale, classes, evenemang,user, userid ,profile} = this.props;
    if (evenemang) {
      return (
        <Dialog
        open={openevent}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={this.props.closeEvent}
        maxWidth="md"
      >
      <Grid sx={1} align="right" style={{padding: "8px 0 0 22px"}}>
      <Button onClick={this.props.closeEvent}
      variant="text"
      disableElevation
      color="primary"
      >
         <ClearIcon />
        </Button>
        </Grid>


      <Grid sx={1} align="right" style={{padding: "0 22px 8px 0"}}>
      <Button onClick={this.props.closeEvent}
      variant="text"
      disableElevation
      color="primary"
      > 
      <span style={{fontWeight: '600', fontSize:'14px'}}>STÄNG</span>
        </Button>
        </Grid>
      </Dialog>

      );
    }
    return '';
  }
}
EvenemangSingleModal.propTypes = {
  evenemang: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.object,
  ]),
};
const mapStateToProps = createStructuredSelector({

  openvariable : getOpen(),
  tabvalue : getTabValue(),
  tabvaluetitle : getTabValueTitle(),
  locale: makeSelectLocale(),

});

export function mapDispatchToProps(dispatch) {
  return {

    deleteEvent: (evt, id) => {
     // dispatch(deleteEvent(id));
      dispatch(closeDialog());
    },
    openDialog: () => {
      dispatch(openDialog());
    },
    setTabValue: (event,value)=> {
      dispatch(setTabValue(value));
    },
    setTabValueTitle: (event,value)=> {
      dispatch(setTabValueTitle(value));
    },
    closeDialog: () => {
      dispatch(closeDialog());
    },
    closeEvent: () => {
      dispatch(closeEvent());
    },
    addCategory: (evt,id, name) => {
      //dispatch(addCategoryEvenemang(id, name));
      //dispatch(loadEvenemang());
      dispatch(push(`/hobby`));
      evt.stopPropagation();
    },
  };
}
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);
const withReducer = injectReducer({ key: 'evenemangsinglemodal', reducer });
const withSaga = injectSaga({ key: 'evenemangsinglemodal', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withRouter,
  withStyles(styles),
)(EvenemangSingleModal);
