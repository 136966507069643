/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const LOAD_EVENEMANG = 'boilerplate/Evenemangsingle/LOAD_EVENEMANG ';
export const EVENEMANG_LOADED = 'boilerplate/Evenemangsingle/EVENEMANG_LOADED';
export const SUBEVENEMANG_LOADED = 'boilerplate/Evenemangsingle/SUBEVENEMANG_LOADED';

export const DELETE_EVENEMANG = 'boilerplate/Evenemangsingle/DELETE_EVENEMANG';
export const SET_PROFILE = 'boilerplate/Evenemangsingle/SET_PROFILE';
export const OPEN_DIALOG = 'boilerplate/Evenemangsingle/OPEN_DIALOG';

export const CLOSE_DIALOG = 'boilerplate/Evenemangsingle/CLOSE_DIALOG';
export const SET_TABVALUE = 'boilerplate/Evenemangsingle/SET_TABVALUE';
export const SET_TABVALUE_TITLE = 'boilerplate/Evenemangsingle/SET_TABVALUE_TITLE';

