import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import List from '../../components/List';
import ListItem from '../../components/ListItem';
import LoadingIndicator from '../../components/LoadingIndicator';
import NyheterListItemCard from '../../containers/NyheterListItemCard';
import KulturListItemCard from '../../containers/KulturListItemCard';
import EvenemangListItemCard from '../../containers/EvenemangListItemCard';
import FyrkListItemCard from '../../containers/FyrkListItemCard';
import NyheterListItem from '../../containers/NyheterListItem';
import KulturListItem from '../../containers/KulturListItem';
import EvenemangListItem from '../../containers/EvenemangListItem';
import FyrkFavItem from '../../containers/FyrkFavItem';
import Masonry from 'react-masonry-component';

const masonryOptions = {
  transitionDuration: 0,
};

const imagesLoadedOptions = { background: '.my-bg-image-el' };

const styles = theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'left',
  },
  control: {
    padding: theme.spacing(2),
  },
  griditem: {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
      height: '564px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },

    [theme.breakpoints.up('md')]: {
      width: '33%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '25%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '20%',
    },
    [theme.breakpoints.up('xxl')]: {
      width: '16.5%',
    },
  
  
  },
});
function FavoriteList(props) {
  const { favoriter, favorites, user, userid, loader, error, filter,
    masonryview,
    listview } = props;
  const { classes } = props;
  if (loader) {
    return <List component={LoadingIndicator} />;
  }
  if (error !== false) {
    const ErrorComponent = () => (
      <ListItem item="Something went wrong, please try again!" />
    );
    return <List component={ErrorComponent} />;
  }

  if (favorites !== false && masonryview) {
    return (
      <Masonry
        className="my-gallery-class" // default ''
        defaultHeight={550} // default 100px
        options={masonryOptions} // default {}
        columns={4} // default 2
        disableImagesLoaded={false} // default false
        updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
        imagesLoadedOptions={imagesLoadedOptions} // default {}
      >


        {favorites.map((card, i) => {
          if (card.type == "EVENT")
            return <Grid className={classes.griditem} key={i} item xs={12}>
              <EvenemangListItemCard
                user={user}
                userid={userid}
                item={card}
                key={i}
                favoriter={favoriter}
              />
            </Grid>
          if (card.type == "NEWS")
            return <Grid className={classes.griditem} key={i} item xs={12}>
              <NyheterListItemCard
                user={user}
                userid={userid}
                item={card}
                key={i}
                favoriter={favoriter}
              />
            </Grid>
          if (card.type == "PROFILE")
            return <Grid className={classes.griditem} key={i} item xs={12}>
              <KulturListItemCard
                user={user}
                userid={userid}
                item={card}
                key={i}
                favoriter={favoriter}
              />
            </Grid>
        
        if (card.type == "FYRK")
          return <Grid className={classes.griditem} key={i} item xs={12}>
        <FyrkListItemCard
          user={user}
          userid={userid}
          item={card}
          key={i}
          favoriter={favoriter}
      />
       </Grid>

        }
        )}
      </Masonry>
    );
  }
  
  if (favorites !== false && listview) { 
   return( <Grid
    container
    justifyContent="flex-start"
    className={classes.root}
    spacing={6}
  >
    {favorites.map((card, i) => {
      if (card.type == "EVENT"){
        return <EvenemangListItem favoriter={favoriter} user={user} userid={userid} item={card} key={i} />
      }
     if (card.type == "FYRK"){
        return <FyrkFavItem favoriter={favoriter} user={user} userid={userid} item={card} key={i} />
      }
        if (card.type == "PROFILE") {
        return <KulturListItem favoriter={favoriter} user={user} userid={userid} item={card} key={`${card.uid}listitem`}  />
      }
      if (card.type == "NEWS"){ 
        
        return <NyheterListItem favoriter={favoriter} user={user} userid={userid} item={card} key={i} />
      }
    }
    )}
     </Grid>
   );
  }

  return null;

}
FavoriteList.propTypes = {
  loading: PropTypes.bool,
  Nyheter: PropTypes.any,
};

export default withStyles(styles)(FavoriteList);
