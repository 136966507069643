/**
 * Gets the repositories of the user from Github
 */
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  LOAD_CATEGORIES,
  LOAD_FAVORITER,
  LOAD_LIKES,
  DISLIKE_EVENT,
  LOGOUT,
  DELETE_NYHET,
  DELETE_PROFILE,
  DELETE_PROFILEGROUP,
  REMOVE_USERGROUP,
  REMOVE_USER,
  DELETE_EVENT,
  LIKE_EVENT,
  DELETE_FYRK
} from '../../containers/App/constants';
import {
  loaduserProfiles,
  loadEvents,
  loadSommarEvents
} from '../../containers/UserProfiles/actions';
import _ from 'lodash';

import { push } from 'connected-react-router';

import {
  categoriesLoaded,
  regionsLoaded,
  loadCategoriesMain,
  loadCategoriesMainEvenemang,
  loadCategoriesEvenemang,
  loadLocationsEvenemang,
  loadGroupsEvenemang,
  loadGroups,
  loadGrups,
  loadKultur,
  loadPlats,
  loadFavoriter,
  tagsLoaded,
  loadEvenemang,
  setUser,
  setProfile,
  hasProfile,
  loadLikes,
  setHelp,
  loadNyheter,
  loadFyrk,
} from '../../containers/App/actions';
import { loadFavorites } from '../../containers/FavoritePage/actions'
import request from '../../utils/request';
import { makeSelectValue, getFavoriter,getUserGroupId,makeSelectCurrentUser } from '../../containers/App/selectors';
import { API_ROOT, LE_ROOT } from '../../api-config';

const fetchJSON = (url, options = {}) =>
  new Promise((resolve, reject) =>
    fetch(url, options)
      .then(response =>
        response.status !== 201 &&
        response.status !== 200 &&
        response.status !== 204 &&
        response.status !== 400
          ? reject(response)
          : response,
      )
      .then(response => {
        if (response.status === 400) {
          response.json().then(error => reject(error));
        } else if (response.status === 204) {
          return resolve({});
        } else {
          return resolve(response.json());
        }
        return '';
      })
      .catch(error => reject(error)),
  );

export function* logOut() {
  try {
    const logout = `${API_ROOT}rest-auth/logout/`;

    const res = yield call(fetchJSON, logout, {
      credentials: 'include',
      method: 'POST',
    });
    localStorage.removeItem('token');
    localStorage.removeItem('userid');
    localStorage.removeItem('usergroupid');
    localStorage.removeItem('cachefavorites');

    yield put(push(`/`));
  } catch (error) {}
}
export function* getCategories(value) {
  var { value } = value;
  const categories = yield select(makeSelectValue());
  // Select username from store
  const requestCategoriesLevel2 = `${API_ROOT}categories/?level=2`;
  const requestCategoriesLevel1 = `${API_ROOT}categories/?level=1`;
  const requestCategoriesLevel0 = `${API_ROOT}categories/?level=0`;

  const requestRegions = `${API_ROOT}regions/?limit=200`;

  const requestGrup = `${API_ROOT}groups/`;
  const requestHelp = `https://api.luckan.fi/help/`;

  const requestPlats = `${API_ROOT}regions/?limit=200`;
  // const requestKultur = `${API_ROOT}posters/?limit=20`;

  const requestEvenemangCategories = `${LE_ROOT}keyword_set/?include=keywords`;
  const requestEvenemangLocations = `${LE_ROOT}place/?page_size=300&data_source=kulturforum&show_all_places=1`;

  // user login
  const token = localStorage.getItem('token');
  const userid = localStorage.getItem('userid');
  const usergroupid = localStorage.getItem('usergroupid');

  if (token) {
    const requestUser = `${API_ROOT}users/${userid}` + `/`;
    try {
      const res = yield call(fetchJSON, requestUser, {
        method: 'GET',
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      res.id = userid;
      res.token = token;
      let fyrk = false;
      if(res.hasOwnProperty('ext')) {
        if(res.ext && res.ext.hasOwnProperty('fyrk')) {
          fyrk = res.ext.fyrk;
        } else {
          res.ext = {};
          res.ext.fyrk = fyrk; 
        }
      } else {
        res.ext = {};
        res.ext.fyrk = fyrk; 
      }
  
  
      yield put(setUser(res));




 //fetch profile by usergroup
      let loadedprofile = false;
      if (usergroupid != 0) {

        const requestURL = `${API_ROOT}posters/?usergroup=${usergroupid}&isfritid=false`;
        try {

          const usergrouprofile = yield call(request, requestURL);
          if (usergrouprofile.count == 0) {
            yield put(hasProfile(false));
          } else {
            yield put(hasProfile(true));
            yield put(setProfile(usergrouprofile.results[0]));
            loadedprofile = true;
          }
        } catch (err) {}
      }

      if (!loadedprofile) {
        const requestURL = `${API_ROOT}posters/?isfritid=false&user=${userid}`;
        try {
          const profile = yield call(request, requestURL);
          if (profile.count == 0) {
            yield put(hasProfile(false));
          } else if (profile.results[0].profile) {
            yield put(hasProfile(true));
            yield put(setProfile(profile.results[0]));
          } else {
            yield put(hasProfile(false));
            yield put(setProfile(profile.results[0]));
          }
        } catch (err) {}
      }
    } catch (error) {

      // remove user
      localStorage.removeItem('token');
      localStorage.removeItem('userid');
      localStorage.removeItem('usergroupid');
      localStorage.removeItem('cachefavorites');


    }
  }

  try {
    // Call our request helper (see '../../utils/request')
    const categoriesLevel1 = yield call(request, requestCategoriesLevel1);
    const categoriesLevel2 = yield call(request, requestCategoriesLevel2);
    const categoriesLevel0 = yield call(request, requestCategoriesLevel0);


    // const regions = yield call(request, requestRegions);
   const requestGroups = `${API_ROOT}usergroups/?limit=9999`;
   const usergroups = yield call(request, requestGroups);
   yield put(loadGroups(usergroups.results));

    const evenemangCategories = yield call(request, requestEvenemangCategories);
    // const evenemangLocations = yield call(request, requestEvenemangLocations);
    const help = yield call(request, requestHelp);
    yield put(setHelp(help.results));

    const grups = yield call(request, requestGrup);
    const plats = yield call(request, requestPlats);
    const regions = plats;
    // const kultur = yield call(request, requestKultur);

    if (token) {
      const requestFavoriter = `${API_ROOT}favorite/?user=${userid}`;
      const favoriter = yield call(request, requestFavoriter);
      yield put(loadFavoriter(favoriter.results));
    } else {

      let cachefavorites = localStorage.getItem('cachefavorites');
      let cacheditems = JSON.parse(cachefavorites);
      yield put(loadFavoriter(cacheditems));

    }
    const eventcategories = evenemangCategories.data;
    const eventgroupdata = evenemangCategories.data;
    const eventlocationdata = evenemangCategories.data;

    const evenemangsection = _.find(eventcategories, {
      id: 'kulturforum:api.postercategory',
    });
    const groupssection = _.find(eventgroupdata, {
      id: 'kulturforum:api.postergroup',
    });
    const locationsection = _.find(eventlocationdata, {
      id: 'kulturforum:region',
    });

    const eventkeywords = evenemangsection.keywords;
    const eventgroups = groupssection.keywords;
    const eventlocations = locationsection.keywords;

    const evenemang = eventkeywords.map(item => {
      {
        item.name = item.name.fi;
      }
    });
    const evenemangroups = eventgroups.map(item => {
      {
        item.name = item.name.fi;
      }
    });
    const searchregions = [
      { id: 'Regioner', name: 'Regioner', type: 'region', disabled: true },
      { id: 'Huvudstadsregion', name: 'Huvudstadsregionen', region: 'Huvudstadsregionen', type: 'region' },
      { id: 'Västra Nyland,Huvudstadsregionen,Östra Nyland', name: 'Hela Nyland', region: 'Västra Nyland,Huvudstadsregionen,Östra Nyland', type: 'region' },
      { id: 'Västra Nyland', name: 'Västra Nyland', region: 'Västra Nyland', type: 'region' },
      { id: 'Östra Nyland', name: 'Östra Nyland', region: 'Östra Nyland', type: 'region' },
      { id: 'Österbotten', name: 'Österbotten', region: 'Österbotten', type: 'region' },
      // {"id" : 'Mellersta Österbotten', "name" : "Mellersta Österbotten", "type" : "region"},
      // {"id" : 'Norra Österbotten', "name" : "Norra Österbotten", "type" : "region"},
      // {"id" : 'Södra Österbotten', "name" : "Södra Österbotten", "type" : "region"},
      { id: 'Åboland', name: 'Åboland', region: 'Åboland', type: 'region' },
      { id: 'Åland', name: 'Åland', region: 'Åland', type: 'region' },
      { id: 'Plats', name: 'Plats', type: 'region', disabled: true },
    ];
    const locations = eventlocations.map(item => {
      {
        item.name = item.name.fi;
        item.type = 'location';
      }
    });
    yield put(loadCategoriesMain(categoriesLevel1.results));
    yield put(regionsLoaded(regions.results));

    yield put(categoriesLoaded(categoriesLevel2.results));
    yield put(tagsLoaded(categoriesLevel0.results));
    yield put(loadGrups(grups.results));
    const sortedplats = _.orderBy(plats.results, ['name'], ['asc']);

    yield put(loadPlats(sortedplats));

    const sortedkeywords = _.orderBy(eventkeywords, ['n_events'], ['desc']);

    const eventkeywordsmain = _.slice(sortedkeywords, 0, 6);
    const eventkeywordsother = _.slice(
      sortedkeywords,
      6,
      sortedkeywords.length,
    );
    yield put(loadCategoriesEvenemang(eventkeywordsother));
    yield put(loadCategoriesMainEvenemang(eventkeywordsmain));

    yield put(loadGroupsEvenemang(eventgroups));
    // const uniqueLocations = eventlocations.filter((val,id,array) => array.indexOf(val) == name);
    let sortedlocations = _.orderBy(eventlocations, ['name'], ['asc']);
    sortedlocations = [...searchregions, ...sortedlocations];

    var uniqueLocations = _.uniqBy(sortedlocations,'name');

    yield put(loadLocationsEvenemang(uniqueLocations));
  } catch (err) {
    // yield put(nyheterLoadingError(err));
  }
}
export function* removeNyhetRequest(value) {
  const token = localStorage.getItem('token');

  const nyhetId = value.id;
  const requestURL = `${API_ROOT}news/${nyhetId}`;
  try {
    yield call(fetchJSON, `${API_ROOT}news/${nyhetId}/`, {
      credentials: 'omit',
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    });

    yield put(loadNyheter());
    yield put(push('/nyheter'));
  } catch (error) {
    throw error;
  }
}
export function* removeFyrkRequest(value) {
  const token = localStorage.getItem('token');

  const fyrkId = value.id;
  try {
    yield call(fetchJSON, `${API_ROOT}fyrk/${fyrkId}/`, {
      credentials: 'omit',
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    });

    yield put(loadFyrk());
    yield put(push('/fyrk'));
  } catch (error) {
    throw error;
  }
}
export function* getLikes(value) {
  const userid = localStorage.getItem('userid');

  const requestFavoriter = `${API_ROOT}favorite/?user=${userid}`;
if(userid) {
  try {
    const favoriter = yield call(request, requestFavoriter);
    yield put(loadFavoriter(favoriter.results));
  } catch (error) {}

} else {
  let cachefavorites = localStorage.getItem('cachefavorites');
  let cacheditems = JSON.parse(cachefavorites);
  yield put(loadFavoriter(cacheditems));
}


}




export function* removeUserRequest(value) {
  const token = localStorage.getItem('token');
  const userid = localStorage.getItem('userid');

  try {
    yield call(fetchJSON, `${API_ROOT}users/${userid}/`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    });
    localStorage.removeItem('token');
    localStorage.removeItem('userid');
    localStorage.removeItem('usergroupid');

    yield put(push(`/`));
  } catch (error) {
    throw error;
  }
}


export function* removeProfileRequest(value) {
  const token = localStorage.getItem('token');

  const profileId = value.id;
  try {
    yield call(fetchJSON, `${API_ROOT}posters/${profileId}`, {
      credentials: 'omit',
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    });

    yield put(loadKultur());
    yield put(push('/profiler'));
  } catch (error) {
    throw error;
  }
}
export function* removeEventRequest(value) {
  try {
    yield call(fetchJSON, `${LE_ROOT}event/${value.id}/`, {
      credentials: 'omit',
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: '60429c29-8acb-4585-91db-3dff0ee1b159',
      },
    });
    yield put(loadEvenemang());
    yield put(push('/evenemang'));
  } catch (error) {}
}
export function* removeProfileGroupRequest(value) {
  const token = localStorage.getItem('token');
  const userGroupId = value.usergroupid;

  try {
    yield call(fetchJSON, `${API_ROOT}usergroup/delete/${userGroupId}/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    });

    yield put(loaduserProfiles(0));
    yield put(push('/groups'));
  } catch (error) {
    throw error;
  }
}
export function* removeUserGroupRequest(value) {
  const token = localStorage.getItem('token');
  const userid = value.userid;
  const groupid = value.value;


  try {
    yield call(fetchJSON, `${API_ROOT}usergroup/remove/${groupid}/${userid}/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    });
  } catch (error) {
    throw error;
  }

  yield put(loaduserProfiles(0));
}
export function* likeEventRequest(value) {
  const userid = localStorage.getItem('userid');
  const itemid = value.id;
  const user = yield select(makeSelectCurrentUser());
  
  let url_root = '';

  if (value.itemtype == 'EVENT') {
    url_root = `${LE_ROOT}event/`;
  } else if (value.itemtype == 'NEWS') {
    url_root = `${API_ROOT}news/`;
  } else if (value.itemtype == 'FYRK') {
    url_root = `${API_ROOT}fyrk/`;
  } else {
    url_root = `${API_ROOT}posters/`;
  }
  const payload = {
    user: `${API_ROOT}users/${userid}/`,
    urls: `${url_root + itemid}/`,
    linktype: value.itemtype,
  };
  const token = localStorage.getItem('token');
  if(token) {
    try {
      yield call(fetchJSON, `${API_ROOT}favorite/`, {
        credentials: 'omit',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(payload),
      });
      yield put(loadLikes());
    } catch (error) {
      throw error;
    }
  } else {

    let cachefavorites = localStorage.getItem('cachefavorites');
    let payloadstring = {
      urls: `${url_root + itemid}/`,
      linktype: value.itemtype,
    };

    if(!cachefavorites) {
      localStorage.setItem('cachefavorites', '[]' );
      cachefavorites = '[]';
    }  

      let cacheditems = JSON.parse(cachefavorites);
      cacheditems.push(payloadstring);
      localStorage.setItem('cachefavorites', JSON.stringify(cacheditems)      )
      yield put(loadLikes());
      yield put(loadFavorites());

      
  } 
  
}
export function* dislikeEventRequest(value) {
  const favoriter = yield select(getFavoriter());
  const itemid2 = value.id;
  let url_root = '';
  let url_root2 = '';
  let result = null;
  if (value.itemtype == 'EVENT') {
    url_root = `${LE_ROOT}event/?data_source=kulturforum,osterbotten,luckan,place2go,place2go_hango,place2go_inga,place2go_raseborg,strax`;
    url_root2 = 'https://luckan.linkedevents.fi/v1/' + 'event/';
  } else if (value.itemtype == 'NEWS') {
    url_root = `${API_ROOT}news/`;
  } else if (value.itemtype == 'FYRK') {
    url_root = `${API_ROOT}fyrk/`;
  }else {
    url_root = `${API_ROOT}posters/`;
  }

  result = _.find(favoriter, { urls: `${url_root + itemid2}/` });
  if (value.itemtype == 'EVENT') {
    result = _.find(favoriter, { urls: `${url_root2 + itemid2}/` });
  }
  const itemid = result.id;
  const token = localStorage.getItem('token');

  if(token) {
  try {
    yield call(fetchJSON, `${API_ROOT}favorite/${itemid}/`, {
      method: 'DELETE',
      headers: {
        Authorization: `Token ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    yield put(loadLikes());
    yield put(loadFavorites());

  } catch (error) {}
  }else {
    let cachefavorites = localStorage.getItem('cachefavorites');
    let cacheditems = JSON.parse(cachefavorites);
    localStorage.setItem('cachefavorites',    JSON.stringify(_.reject(cacheditems,result))   )
    yield put(loadLikes());
    yield put(loadFavorites()); 
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export default function* githubData() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(LOAD_LIKES, getLikes);
  yield takeLatest(LOAD_CATEGORIES, getCategories);
  yield takeLatest(LOGOUT, logOut);
  yield takeLatest(DELETE_NYHET, removeNyhetRequest);
  yield takeLatest(DELETE_FYRK, removeFyrkRequest);

  yield takeLatest(DELETE_PROFILE, removeProfileRequest);
  yield takeLatest(DELETE_EVENT, removeEventRequest);
  yield takeLatest(LIKE_EVENT, likeEventRequest);
  yield takeLatest(DISLIKE_EVENT, dislikeEventRequest);
  yield takeLatest(REMOVE_USERGROUP, removeUserGroupRequest);
  yield takeLatest(REMOVE_USER, removeUserRequest);
  yield takeLatest(DELETE_PROFILEGROUP, removeProfileGroupRequest);
}
