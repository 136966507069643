import { call, put, select, takeLatest } from 'redux-saga/effects';
import request from '../../utils/request';
import { makeSelectUserId,getUserGroupId } from '../../containers/App/selectors';
import { push } from 'connected-react-router';
import { LOAD_PROFILE, LOAD_EVENTS ,LOAD_FYRK} from './constants';
import {
  profileLoaded,
  eventsLoaded,
  fyrkLoaded
} from './actions';
import { API_ROOT,LE_ROOT } from '../../api-config';

/**
 * Github repos request/response handler
 */
export function* loadProfile(value) {
  yield put(profileLoaded(''));
  const profileId = value.id;
  let profileuser = 0;
  let userid = yield select(makeSelectUserId());
  if (!userid) {
    userid = localStorage.getItem('userid');
  }

  let requestURL = `${API_ROOT}usergroup/?user=`+userid;


  try {
  const profile = yield call(request, requestURL);
  profileuser = profile.results;
  yield put(profileLoaded(profileuser));




  } catch (err) {
    console.log(err);
    // yield put(nyheterLoadingError(err));
  }
}
export function* loadGroupEvents(value) {
  const groupId = value.event;
  const userId = yield select(makeSelectUserId());
  const requestEvents = `${LE_ROOT}event/?data_source=kulturforum&page_size=999&include=location,keywords&limit&custom_data=usergroup_id:${groupId}&custom_data:origin_uid=${userId}`;

    try {
      const events = yield call(request, requestEvents);
      yield put(eventsLoaded(events.data));
    } catch (err) {
      console.log(err);
  }

}
export function* loadGroupFyrk(value) {
  const groupId = value.event;
  const requestEvents = `${LE_ROOT}event/?data_source=kulturforum&page_size=999&include=location,keywords&limit&custom_data=usergroup_id:${groupId}`;
  let requestURL = `${API_ROOT}fyrk?usergroup=${groupId}`;
    try {
      const fyrk = yield call(request, requestURL);
      yield put(fyrkLoaded(fyrk.results));
    } catch (err) {
      console.log(err);
  }

}
/* export function* loadGroupEventsSommar(value) {
  const groupId = value.event;
  const requestEvents = `${LE_ROOT}event/?data_source=fritid&page_size=999&include=location,keywords&keyword=${sommarKeyword}&limit&custom_data=usergroup_id:${groupId}`;

    try {
      const events = yield call(request, requestEvents);
      yield put(sommarEventsLoaded(events.data));
    } catch (err) {
      console.log(err);
  }

}*/

/**
 * Root saga manages watcher lifecycle
 */
export default function* githubData() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(LOAD_PROFILE, loadProfile);
  yield takeLatest(LOAD_EVENTS, loadGroupEvents);
  yield takeLatest(LOAD_EVENTS, loadGroupFyrk);

 // yield takeLatest(LOAD_SOMMAR, loadGroupEventsSommar);


}
