/**
 *
 * ResetPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import saga from './saga';
import reducer from './reducer';
import { hideLeftDrawer } from '../App/actions';
import { loginOnChange, submitLoginForm } from './actions';
import {
  getRegisterSuccess,
  makeSelectLogin,
  makeSelectMessage,
  makeSelectLoading,
  getError,
} from './selectors';
/* eslint-disable react/prefer-stateless-function */

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3,
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
  },
  spinner: {
    marginLeft: '20px',
    width: '20px',
    height: '20px',
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});
const SpinnerAdornment = withStyles(styles)(props => (
  <CircularProgress
    className={props.classes.spinner}
    size={20}
    style={{ color: '#fff' }}
  />
));

const AdornedButton = props => {
  const { children, loading, ...rest } = props;
  return (
    <Button {...rest}>
      {children}
      {loading && <SpinnerAdornment {...rest} />}
    </Button>
  );
};

export class ResetPage extends React.PureComponent {
  componentDidMount() {
    this.props.hideSearch();
  }


  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      loginvisible,
      registersuccess,
      classes,
      loginvalue,
      passwordvalue,
      passwordconfirmvalue,
      message,
      loading,
      error,
    } = this.props;
    const {
      username,
      email,
      password1,
      passwordconfirm,
      non_field_errors,
    } = error;

    const errorProps = {
      error: true,
    };
    const emailerror = email || '';
    const usererror = username || '';

    let sendmessage = '';

    if (registersuccess) {
      sendmessage = 'Verification e-mail sent.';
    }
    return (
      <div>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography variant="headline">Din Epost</Typography>

            <form className={classes.form} onSubmit={this.props.onSubmit}>
              <TextField
                id="username"
                label="Epost"
                helperText={emailerror + usererror}
                margin="normal"
                FormHelperTextProps={errorProps}
                onChange={this.props.loginOnChange}
                value={loginvalue}
                fullWidth
                required
                autoFocus
                disabled={registersuccess}
              />

              {non_field_errors}
              {sendmessage}
              <AdornedButton
                type="submit"
                fullWidth
                loading={loading}
                variant="raised"
                color="primary"
                className={classes.submit}
                disabled={registersuccess}
              >
                Skicka
              </AdornedButton>

              {message}
            </form>
          </Paper>
        </main>
      </div>
    );
  }
}

ResetPage.propTypes = {
  login: PropTypes.string,
  message: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  loginvalue: makeSelectLogin(),
  error: getError(),
  message: makeSelectMessage(),
  loading: makeSelectLoading(),
  registersuccess: getRegisterSuccess(),
});

function mapDispatchToProps(dispatch) {
  return {
    loginOnChange: evt => {
      dispatch(loginOnChange(evt.target.value));
    },
    hideSearch: evt => {
      dispatch(hideLeftDrawer());
    },
    onSubmit: evt => {
      evt.preventDefault();
      evt.stopPropagation();
      dispatch(submitLoginForm());
    },
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'ResetPage', reducer });
const withSaga = injectSaga({ key: 'ResetPage', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withStyles(styles),
)(ResetPage);
