/**
 * Gets the repositories of the user from Github
 */
import { fromJS } from 'immutable';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { LOAD_NYHETER } from '../../containers/App/constants';
import { nyheterLoaded, nyheterLoadingError } from '../../containers/App/actions';
import { handleChange } from '../../containers/App/actions';
import { API_ROOT } from '../../api-config';
import { getPage, getPageSize, getResultSize } from './selectors';
import {changeMore,changePage}  from './actions';
import moment from 'moment/min/moment-with-locales';

import {
  makeSelectStartTime,
  makeSelectEndTime,
  getReferencetime,
  getTimeIndex,
  makeSelectSearch,
  makeSelectPlatsSelected,
  makeSelectGroupsSelected,
  makeSelectCategoriesMainSelected,
  makeSelectNyheter,
  makeSelectValue,
} from '../../containers/App/selectors';

import request from '../../utils/request';

/**
 * Github repos request/response handler
 */
export function* getNyheter(action) {
  if(action.value) {

  } else {

    yield put(changePage(1));
  }
  // Select username from store
  const queryparams = [];

  let categories = yield select(makeSelectValue());
  if (!categories) {
    categories = [];
  }
  let starttime = "";
  let endtime = "";
  const currentpage = yield select(getPage());
  const pagesize = yield select(getPageSize());
  const nextpage = currentpage + 1;
  const filterindex = yield select(getTimeIndex());

  const maincategories = (yield select(
    makeSelectCategoriesMainSelected(),
  )).toJS();
  const searchparam = yield select(makeSelectSearch());
  const selectedgroups = (yield select(makeSelectGroupsSelected())).toJS();
  const currentnews= yield select(makeSelectNyheter());
  const totalcategories = [...categories, ...maincategories];
  let referencetime = yield select(getReferencetime());
  if(referencetime == '') {
    referencetime = moment();
  }



  if(filterindex) {
    if(filterindex == 1) {
      starttime = referencetime.utc().startOf('day').toISOString();
      endtime=    referencetime.utc().endOf('day').toISOString();
    } else if(filterindex == 2) {



      starttime =  moment(referencetime.utc()).isoWeekday(1).startOf('day').toISOString();
      endtime=  moment(referencetime.utc()).isoWeekday(7).endOf('day').toISOString();
    }else if (filterindex == 3)  {
      starttime = moment(referencetime.utc()).startOf('month').toISOString();
      endtime=  moment(referencetime.utc()).endOf('month').toISOString();

    } else {
      starttime ="";
      endtime ="";
    }
  } else {
    starttime ="";
    endtime ="";
  }



  let selectedplats = yield select(makeSelectPlatsSelected());
  if (!selectedplats) {
    selectedplats = '';
  }

  if (totalcategories.length > 0) {
    queryparams.categories = totalcategories.join();
  }
  if (selectedgroups.length > 0) {
    queryparams.groups = selectedgroups.join();
  }
  if (selectedplats.length != '') {
    queryparams.regions = selectedplats.id;
  }
  if (searchparam && searchparam != '') {
    queryparams.textsearch__icontains = searchparam;
  }
  if (starttime != '' && endtime != '') {
    queryparams.added__gt = starttime;
    queryparams.added__lt = endtime;
  }
    queryparams.offset = (currentpage-1)*pagesize;

  const queryString = Object.keys(queryparams)
    .map(key => `${key}=${queryparams[key]}`)
    .join('&');

  // const categories = yield select(makeSelectValue());
  let requestURL = `${API_ROOT}news/?limit=20&ordering=-updated&`;
  requestURL += queryString;

  try {
    const nyheter = yield call(request, requestURL);
    if (currentpage > 1) {
      if(nyheter.results.length > 0) {
        const totalnews = [...currentnews, ...nyheter.results];
        yield put(nyheterLoaded(totalnews));
      }
    } else {
      yield put(nyheterLoaded(nyheter.results));
      if(nyheter.next) {
        yield put(changeMore(true));
      } else {
        yield put(changeMore(false));
      }
    }

  } catch (err) {
    yield put(nyheterLoadingError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* githubData() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(LOAD_NYHETER, getNyheter);
}
