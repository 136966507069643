/*
 *
 * RegisterPage actions
 *
 */

import {
  PASSWORD_CHANGE,
  PASSWORDCONFIRM_CHANGE,
  SUBMITREGISTER,
  AUTH_FAILURE,
  SET_LOADING,
  REGISTER_SUCCESS,
  SET_ERROR,
  SET_GROUP,
  SET_OPEN,
  LOGIN_CHANGE,
  DEFAULT_ACTION,
  CHANGE_ORGANISATION,
  CHANGE_NEWORGANISATION,
  FYRKREQUEST_CHANGE,
  CHANGE_FIRSTNAME,
  CHANGE_LASTNAME,
  SET_INFOPOP
} from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}
export function setInfoPop(value) {
  return {
    type: SET_INFOPOP,
    value,
  };
}
export function loginOnChange(login) {
  return {
    type: LOGIN_CHANGE,
    value: login,
  };
}
export function registerSuccess() {
  return {
    type: REGISTER_SUCCESS,
  };
}
export function setError(value) {
  return {
    type: SET_ERROR,
    value,
  };
}
export function setGroup(value) {
  return {
    type: SET_GROUP,
    value,
  };
}
export function setOpen(value) {
  return {
    type: SET_OPEN,
    value,
  };
}
export function changeOrganisation(value) {
  return {
    type: CHANGE_ORGANISATION,
    value,
  };
}
export function changeLastName(value) {
  return {
    type: CHANGE_LASTNAME,
    value,
  };
}
export function changeFirstName(value) {
  return {
    type: CHANGE_FIRSTNAME,
    value,
  };
}
export function changeNewOrganisation(value) {
  return {
    type: CHANGE_NEWORGANISATION,
    value,
  };
}
export function submitLoginForm() {
  return {
    type: SUBMITREGISTER,
  };
}
export function passwordOnChange(password) {
  return {
    type: PASSWORD_CHANGE,
    value: password,
  };
}
export function passwordConfirmOnChange(password) {
  return {
    type: PASSWORDCONFIRM_CHANGE,
    value: password,
  };
}
export function fyrkrequestchange(fyrkrequestvalue) {
  return {
    type: FYRKREQUEST_CHANGE,
    value: fyrkrequestvalue,
  };
}
export function authFailure(message) {
  return {
    type: AUTH_FAILURE,
    value: message,
  };
}
export function setLoading(value) {
  return {
    type: SET_LOADING,
    value,
  };
}
