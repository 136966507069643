/*
 *
 * AddEvenemang reducer
 *
 */
import _ from 'lodash';

import { fromJS } from 'immutable';
import moment from 'moment/min/moment-with-locales';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import RandomImage from '../../components/RandomImage';
import parseISO from 'date-fns/parseISO';
import {
  DEFAULT_ACTION,
  SET_CUSTOM_OPEN,
  TITLE_CHANGEEN,
  TITLE_CHANGESV,
  TITLE_CHANGEFI,
  SET_ERROR,
  CLEAR_FORM,
  DESCRIPTION_CHANGE_SV,
  DESCRIPTION_CHANGE_FI,
  DESCRIPTION_CHANGE_EN,
  SHORTDESCRIPTION_CHANGE_FI,
  SHORTDESCRIPTION_CHANGE_EN,
  SHORTDESCRIPTION_CHANGE_SV,
  SEARCH_FIELD,
  LINK_CHANGE,
  CHANGE_TABVALUETITLE,
  IMAGE_DROP,
  ADD_LINK,
  REMOVE_LINK,
  ADD_PRICE,
  REMOVE_PRICE,
  CHANGE_TABVALUE,
  CHANGE_TABVALUESHORT,
  SUBMIT_AddEvenemangFORM,
  CHANGE_RSVP,
  CHANGE_SENDING,
  ADD_PROFILE,
  REMOVE_PROFILE,
  CHANGE_PROFILE,
  CHANGE_PROFILENAME,
  CHANGE_LANGUAGE,
  CLEAR_SUGGESTIONS,
  SET_SUGGESTIONS,
  ADD_DATE,
  REMOVE_DATE,
  CHANGE_ORG,
  CHANGE_CATEGORY,
  CHANGE_REGION,
  CHANGE_LINK,
  CHANGE_LINKNAME,
  TOGGLE_FREE,
  ADD_GROUP,
  REMOVE_GROUP,
  CHANGE_PRICE,
  CHANGE_PRICEDESCRIPTION,
  ADD_META,
  REMOVE_META,
  PROFILES_LOADED,
  CHANGE_BUYLINK,
  CHANGE_EDITORSTATE_SV,
  CHANGE_EDITORSTATE_EN,
  CHANGE_EDITORSTATE_FI,
  EVENT_LOADED,
  LOAD_EVENT,
  SET_EDITINDEX,
  STARTDATE_CHANGE,
  STARTHOUR_CHANGE,
  ENDDATE_CHANGE,
  ENDHOUR_CHANGE,
  CHANGE_PLACE,
  CHANGE_ADDRESS,
  CHANGE_CITY,
  CHANGE_POSTAL,
  CHANGE_LOCATION,
  ADD_CATEGORY_EVENEMANG,
  REMOVE_CATEGORY_EVENEMANG,
  CLEAR_IMAGE,
  RESTORE_IMAGE,
} from './constants';

const notiscategories = [
  { id: 'kulturforum:240', name: 'Andliga' },
  { id: 'kulturforum:169', name: 'Bio' },
  { id: 'kulturforum:159', name: 'Dans' },
  { id: 'kulturforum:179', name: 'Föreläsningar' },
  { id: 'kulturforum:256', name: 'Idrott' },
  { id: 'kulturforum:271', name: 'Kurser' },
  { id: 'kulturforum:230', name: 'Musik' },
  { id: 'kulturforum:262', name: 'Teater' },
  { id: 'kulturforum:272', name: 'Utställningar' },
  { id: 'kulturforum:401', name: 'Övriga' },
  { id: 'kulturforum:digitala', name: 'Digitala evenemang' },
];
export const initialState = fromJS({
  notiscategoriesevenemang: notiscategories,
  titlefi: '',
  titlesv: '',
  titleen: '',
  descriptionfi: '',
  descriptionsv: '',
  descriptionen: '',
  shortdescriptionfi: '',
  shortdescriptionen: '',
  shortdescriptionsv: '',
  sending: false,
  link: '',
  rsvp: '',
  org: '',
  pictures: false,
  value: false,
  suggestions: false,
  locationvalue: false,
  selectedgroups: [],
  selectedmetas: [],
  endtimedate: null,
  starttimedate: null,
  searchfieldvalue: '',
  editindex: false,
  place: '',
  editorstatesv: EditorState.createEmpty(),
  editorstateen: EditorState.createEmpty(),
  editorstatefi: EditorState.createEmpty(),
  city: '',
  postal: '',
  address: '',
  isfree: false,
  price: '',
  prices: [
    { id: new Date().getTime(), price: '', pricedescription: '', buylink: '' },
  ],
  pricedescription: '',
  links: [],
  dates: [{ id: new Date().getTime(), start: new Date(), end: new Date() }],
  profiles: [],
  searchfield: [],
  custom_open: '',
  error: false,
  buylink: '',
  eventitem: false,
  totalcategories: [],
  language: 'utan',
  region: '',
  tabvalue: 2,
  tabvaluetitle: 2,
  tabvalueshort: 2,
  kultur: [],
  selectedmaincategories: [],
});

function AddEvenemangReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case ADD_CATEGORY_EVENEMANG:
      const templistevenemang = state.get('selectedmaincategories').toJS();
      templistevenemang.push({id : action.id,  value : action.value});
      return state.set(
        'selectedmaincategories',
        fromJS(templistevenemang)
          .toSet()
          .toList(),
      );
    case REMOVE_CATEGORY_EVENEMANG:
      let currentcategoriesevenemang = state
        .get('selectedmaincategories')
        .toJS();
      let currentcategories = state.get('value');

      if (currentcategories == false) {
        currentcategories = [];
      }

      if (action.value == 'all') {
        currentcategoriesevenemang = [];
      } else {

        let index = _.findIndex(currentcategoriesevenemang, { 'id': action.value});
        let indexother = _.findIndex(currentcategories, { 'id': action.value});

        if (index > -1) {
          currentcategoriesevenemang.splice(index, 1);
        }
        if (indexother > -1) {
          currentcategories.splice(indexother, 1);
        }
      }
      return state
        .set(
          'selectedmaincategories',
          fromJS(currentcategoriesevenemang)
            .toSet()
            .toList(),
        )
        .set('value', currentcategories);
    case PROFILES_LOADED:
      return state.set('kultur', action.value);
    case EVENT_LOADED:
      const {
        end_time,
        start_time,
        location: locationvalue,
        keywords,
        images,
        external_links,
        offers,
        custom_data,
        sub_events,
        provider,
      } = action.event;

      const { description } = action.event;

      const { short_description } = action.event;
      const { name } = action.event;

      const { sv: titlesv, en: titleen, fi: titlefi } = name;

      let {
        sv: short_description_sv,
        fi: short_description_fi,
        en: short_description_en,
      } = short_description;
      const {
        sv: descriptionsv,
        fi: descriptionfi,
        en: descriptionen,
      } = description;

      let custom_end_time = '';

      if (end_time) {
        custom_end_time = end_time;
      }

      const categories = action.eventkeywords;
      const groups = action.eventgroups;
      const locations = action.eventlocations;
      const linkrearray = [];
      external_links.map((list, index) => {
        let linkname = list.name;
        if (linkname.includes('social')) {
          linkname = linkname.replace('social_', '');
          const linkaddress = list.link;
          const id = new Date().getTime();
          linkrearray.push({ id, name: linkname, value: linkaddress });
        }
      });

      const metarearray = [];
      let language = null;
      if (custom_data && custom_data.hasOwnProperty('language')) {
        language = custom_data.language;
      } else {
        language = 'utan';
      }
      let custom_open = '';
      if (custom_data && custom_data.hasOwnProperty('custom_open')) {
        custom_open = custom_data.custom_open;
      } else {
        custom_open = '';
      }
      _.forEach(custom_data, function(v, k) {
        if (k.includes('accessibility_') && v) {
          k = k.replace('accessibility_', '');
          metarearray.push(k);
        }
      });
      const profilerearray = [];
      external_links.map((list, index) => {
        let profilename = list.name;
        let profileddress = list.link;
        const id = new Date().getTime();
        if (profilename.includes('profile')) {
          if (profileddress.includes('profile')) {
            profilename = profilename.replace('profile_', '');
            const profileddressnumber = decodeURIComponent(
              profileddress.replace('https://kulturforum.fi/profile/', ''),
            );
            profilerearray.push({
              id,
              name: profilename,
              link: profilename,
              profilelink: profileddressnumber,
            });
          } else {
            profilename = profilename.replace('profile_', '');
            profileddress = decodeURIComponent(
              profileddress.replace('http://remove.me/', ''),
            );
            profilerearray.push({
              id,
              name: profilename,
              link: profileddress,
            });
          }
        }
      });
      let neweditorstatesv = EditorState.createEmpty();
      let neweditorstateen = EditorState.createEmpty();
      let neweditorstatefi = EditorState.createEmpty();

      if (
        description &&
        typeof description !== 'undefined' &&
        description != null
      ) {
        if (
          typeof descriptionsv !== 'undefined' &&
          descriptionsv.trim() != ''
        ) {
          const blocksFromHTMLSv = convertFromHTML(descriptionsv);

          neweditorstatesv = ContentState.createFromBlockArray(
            blocksFromHTMLSv.contentBlocks,
            blocksFromHTMLSv.entityMap,
          );
          neweditorstatesv = EditorState.createWithContent(neweditorstatesv);
        }
        if (
          typeof descriptionen !== 'undefined' &&
          descriptionen.trim() != ''
        ) {
          const blocksFromHTMLEn = convertFromHTML(descriptionen);
          neweditorstateen = ContentState.createFromBlockArray(
            blocksFromHTMLEn.contentBlocks,
            blocksFromHTMLEn.entityMap,
          );
          neweditorstateen = EditorState.createWithContent(neweditorstateen);
        }
        if (
          typeof descriptionfi !== 'undefined' &&
          descriptionfi.trim() != ''
        ) {
          const blocksFromHTMLFi = convertFromHTML(descriptionfi);
          neweditorstatefi = ContentState.createFromBlockArray(
            blocksFromHTMLFi.contentBlocks,
            blocksFromHTMLFi.entityMap,
          );
          neweditorstatefi = EditorState.createWithContent(neweditorstatefi);
        }
      }

      if (typeof short_description_sv === 'undefined') {
        short_description_sv = '';
      }
      if (typeof short_description_en === 'undefined') {
        short_description_en = '';
      }
      if (typeof short_description_fi === 'undefined') {
        short_description_fi = '';
      }

      const tempcity = [];
      const tempgroups = [];
      const datesgroup = [];
      const tempcategories = [];
      const tempcategoriesmain = [];
      const totalcategories = [];
      keywords.map(value => totalcategories.push(value));
      totalcategories.map(list => {
        const check1 = _.find(notiscategories, { id: list.id });
        if (check1) {
          tempcategoriesmain.push({ id: list.id, name: list.name.fi });
        } else if (_.indexOf(categories, list.id) > -1) {
          tempcategories.push({ id: list.id, name: list.name.fi });
        }

        if (_.indexOf(locations, list.id) > -1) {
          tempcity.push({ id: list.id, name: list.name.fi });
        }
        if (_.indexOf(groups, list.id) > -1) {
          tempgroups.push(list.id);
        }
      });
      const valuearraylocationorig = [];
      const valuearraylocationnamesorig = [];
      tempcity.map(value => {
        valuearraylocationorig.push(value);
        valuearraylocationnamesorig.push(value.name);
      });

      if (locationvalue.address_locality) {
        locationvalue.address_locality = locationvalue.address_locality.sv;
      } else {
        locationvalue.address_locality = '';
      }
      let address_region = '';
      if (locationvalue.address_region) {
        address_region = locationvalue.address_region;
      } else {
        address_region = locationvalue.address_locality.sv;
      }


      const isfree = action.event.offers[0].is_free;
      const prices = [];
      let rsvp = '';
      // {"is_free":false,"price":{"fi":"10","sv":"10","en":"10"},"info_url":{"fi":"","sv":"","en":""},"description":{"fi":"VIP","sv":"VIP","en":"VIP"}
      // { id: id, price: '', pricedescription: '', buylink : '' }
      if (isfree) {
        if (
          offers[0] &&
          offers[0].hasOwnProperty('info_url') &&
          offers[0].info_url &&
          offers[0].info_url.hasOwnProperty('sv')
        )
          rsvp = offers[0].info_url.sv;
        // prices.push({ id: id, price: '', pricedescription: '', buylink : '' })
      } else {
        offers.map(function(offer) {
          if (offer.description) {
            offer.description = offer.description.sv;
          } else {
            offer.description = '';
          }
          if (offer.info_url) {
            offer.info_url = offer.info_url.sv;
          } else {
            offer.info_url = '';
          }
          if (offer.price) {
            offer.price = offer.price.sv;
          } else {
            offer.price = '';
          }
          const idprice = new Date().getTime();
          prices.push({
            id: idprice,
            price: offer.price,
            pricedescription: offer.description,
            buylink: offer.info_url,
          });
        });
      }
      if (offers[0].description) {
        offers[0].description = offers[0].description.sv;
      } else {
        offers[0].description = '';
      }
      if (offers[0].info_url) {
        offers[0].info_url = offers[0].info_url.sv;
      } else {
        offers[0].info_url = '';
      }
      if (locationvalue.street_address) {
        locationvalue.street_address = locationvalue.street_address.sv;
      } else {
        locationvalue.street_address = '';
      }
      locationvalue.name = locationvalue.name.sv;
      locationvalue.address_locality = locationvalue.address_locality.sv;

      let eventimage = RandomImage();

      if (images && images.length > 0) {
        eventimage = images[0].url;
        eventimage = eventimage.replace(/http:/g, 'https:');
        eventimage = eventimage.replace(/WxH/, '1069x605');
      }
      let org = '';
      if (provider && provider.hasOwnProperty('sv')) {
        org = provider.sv;
      }
      let subeventlist = '';
      if (sub_events.length > 0) {
        subeventlist = sub_events.map((list, index) => {
          if (list.end_time && list.end_time != '') {
            datesgroup.push({
              id: index,
              start: parseISO(list.start_time),
              end: parseISO(list.end_time),
            });
          } else {
            datesgroup.push({
              id: index,
              start: parseISO(list.start_time),
              end: '',
            });
          }
        });
      } else {
        const iddate = new Date().getTime();
        if (custom_end_time != '') {
          datesgroup.push({
            id: iddate,
            start: parseISO(start_time),
            end: parseISO(custom_end_time),
          });
        } else {
          datesgroup.push({ id: iddate, start: parseISO(start_time), end: '' });
        }
      }
      return (
        state
          .set('titlesv', titlesv)
          .set('titleen', titleen)
          .set('titlefi', titlefi)
          .set('language', language)
          .set('eventitem', action.event)
          .set('isfree', offers[0].is_free)
          .set('prices', fromJS(prices).toList())
          .set('pricedescription', offers[0].description)
          .set('buylink', offers[0].info_url)
          .set('totalcategories', totalcategories)
          .set('selectedmetas', fromJS(metarearray).toList())
          .set('descriptionsv', descriptionsv)
          .set('selectedmaincategories', fromJS(tempcategoriesmain).toList())
          .set('descriptionfi', descriptionfi)
          .set('descriptionen', descriptionen)
          .set('shortdescriptionen', short_description_en)
          .set('shortdescriptionfi', short_description_fi)
          .set('shortdescriptionsv', short_description_sv)
          .set('editorstatesv', neweditorstatesv)
          .set('editorstateen', neweditorstateen)
          .set('editorstatefi', neweditorstatefi)
          .set('org', org)

          .set('dates', fromJS(datesgroup).toList())
          .set('links', fromJS(linkrearray).toList())
          .set('profiles', fromJS(profilerearray).toList())
          .set('searchfieldvalue', '')
          .set('isfree', isfree)
          .set('rsvp', rsvp)
          .set('custom_open', custom_open)
          .set('suggestions', '')
          .set('pictures', eventimage)
          .set('value', tempcategories)
          .set('selectedgroups', fromJS(tempgroups).toList())
          .set('place', locationvalue.name)
          .set('locationvalue', valuearraylocationorig[0])
          .set('city', valuearraylocationnamesorig.join())
          // .set('city', locationvalue.address_locality)
          .set('postal', locationvalue.postal_code)
          .set('region', address_region)
          .set('address', locationvalue.street_address)
      );

    case CHANGE_EDITORSTATE_SV:
      return state.set('editorstatesv', action.value);
    case CHANGE_EDITORSTATE_EN:
      return state.set('editorstateen', action.value);
    case CHANGE_EDITORSTATE_FI:
      return state.set('editorstatefi', action.value);
    case CHANGE_TABVALUE:
      return state.set('tabvalue', action.value);
    case CHANGE_TABVALUETITLE:
      return state.set('tabvaluetitle', action.value);
    case CHANGE_TABVALUESHORT:
      return state.set('tabvalueshort', action.value);
    case CHANGE_PLACE:
      return state.set('place', action.value);
    case CHANGE_REGION:
      return state.set('region', action.value);
    case SET_CUSTOM_OPEN:
      return state.set('custom_open', action.value);
    case SUBMIT_AddEvenemangFORM:
      return state.set('sending', true);

    case SET_EDITINDEX:
      return state.set('editindex', action.value);
    case ADD_GROUP:
      const grouplist = state.get('selectedgroups').toJS();
      grouplist.push(action.value);
      return state.set('selectedgroups', fromJS(grouplist).toList());
    case REMOVE_GROUP:
      const currentgroups = state.get('selectedgroups').toJS();
      const groupindex = currentgroups.indexOf(action.value);
      if (groupindex > -1) {
        currentgroups.splice(groupindex, 1);
      }
      return state.set('selectedgroups', fromJS(currentgroups).toList());
    case CHANGE_PROFILE:
      let valueindex = 0;
      const profilelink = '';
      if (action.id == '') {
        valueindex = state.toJS().editindex;
      } else {
        valueindex = action.id;
      }

      const profilelist3 = state.get('profiles').toJS();
      profilelist3[valueindex].link = action.value;
      profilelist3[valueindex].profilelink = action.profilelink;
      return state.set('profiles', fromJS(profilelist3).toList());
    case CHANGE_PROFILENAME:
      const profilelist4 = state.get('profiles').toJS();
      profilelist4[action.id].name = action.value;
      return state.set('profiles', fromJS(profilelist4).toList());
    case ADD_PROFILE:
      const profilelist1 = state.get('profiles').toJS();
      profilelist1.push(action.value);
      return state.set('profiles', fromJS(profilelist1).toList());
    case REMOVE_PROFILE:
      const actionidprofile = action.value;

      const profilelist2 = state.get('profiles').toJS();
      const findidprofile = _.findIndex(profilelist2, function(o) {
        return o.id == actionidprofile;
      });

      profilelist2.splice(findidprofile, 1);
      return state.set('profiles', fromJS(profilelist2).toList());

    case SEARCH_FIELD:
      return state.set('searchfieldvalue', action.value);
    case CHANGE_LINK:
      const linklist3 = state.get('links').toJS();
      linklist3[action.id].value = action.value;
      return state.set('links', fromJS(linklist3).toList());
    case CHANGE_LINKNAME:
      const linklist4 = state.get('links').toJS();
      linklist4[action.id].name = action.value;
      return state.set('links', fromJS(linklist4).toList());
    case ADD_LINK:
      const linklist1 = state.get('links').toJS();
      linklist1.push(action.value);
      return state.set('links', fromJS(linklist1).toList());
    case REMOVE_LINK:
      const actionid = action.value;
      // get index of object with id

      const linklist2 = state.get('links').toJS();
      const findid = _.findIndex(linklist2, function(o) {
        return o.id == actionid;
      });
      linklist2.splice(findid, 1);

      return state.set(
        'links',
        fromJS(linklist2)
          .toSet()
          .toList(),
      );
    case ADD_DATE:
      const datelist1 = state.get('dates').toJS();
      datelist1.push(action.value);
      return state.set('dates', fromJS(datelist1).toList());
    case REMOVE_DATE:
      const actioniddate = action.value;
      const datelist2 = state.get('dates').toJS();
      const findiddate = _.findIndex(datelist2, function(o) {
        return o.id == actioniddate;
      });

      datelist2.splice(findiddate, 1);
      return state.set('dates', fromJS(datelist2).toList());
    case ADD_PRICE:
      const pricelist1 = state.get('prices').toJS();
      pricelist1.push(action.value);
      return state.set('prices', fromJS(pricelist1).toList());
    case REMOVE_PRICE:
      const actionidprice = action.value;

      const pricelist2 = state.get('prices').toJS();
      const findidprice = _.findIndex(pricelist2, function(o) {
        return o.id == actionidprice;
      });

      pricelist2.splice(findidprice, 1);
      return state.set('prices', fromJS(pricelist2).toList());
    case ADD_META:
      const metalist = state.get('selectedmetas').toJS();
      metalist.push(action.value);
      return state.set('selectedmetas', fromJS(metalist).toList());
    case REMOVE_META:
      const currentmetas = state.get('selectedmetas').toJS();
      const metaindex = currentmetas.indexOf(action.value);
      if (metaindex > -1) {
        currentmetas.splice(metaindex, 1);
      }
      return state.set('selectedmetas', fromJS(currentmetas).toList());
    case CHANGE_CITY:
      return state.set('city', action.value);
    case CHANGE_POSTAL:
      return state.set('postal', action.value);
    case CHANGE_BUYLINK:
      const pricelist3 = state.get('prices').toJS();
      pricelist3[action.id].buylink = action.value;
      return state.set('prices', fromJS(pricelist3).toList());
    case CHANGE_PRICE:
      const pricelist4 = state.get('prices').toJS();
      pricelist4[action.id].price = action.value;
      return state.set('prices', fromJS(pricelist4).toList());
    case CHANGE_PRICEDESCRIPTION:
      const pricelist5 = state.get('prices').toJS();
      pricelist5[action.id].pricedescription = action.value;
      return state.set('prices', fromJS(pricelist5).toList());
    case CHANGE_ADDRESS:
      return state.set('address', action.value);
    case CHANGE_LANGUAGE:
      return state.set('language', action.value);
    case CHANGE_RSVP:
      return state.set('rsvp', action.value);
    case CHANGE_SENDING:
      return state.set('sending', action.value);
    case CHANGE_ORG:
      return state.set('org', action.value);
    case TITLE_CHANGEFI:
      return state.set('titlefi', action.value);
    case TITLE_CHANGESV:
      return state.set('titlesv', action.value);
    case TITLE_CHANGEEN:
      return state.set('titleen', action.value);
    case DESCRIPTION_CHANGE_SV:
      return state.set('descriptionsv', action.value);
    case DESCRIPTION_CHANGE_FI:
      return state.set('descriptionfi', action.value);
    case DESCRIPTION_CHANGE_EN:
      return state.set('descriptionen', action.value);
    case SHORTDESCRIPTION_CHANGE_FI:
      return state.set('shortdescriptionfi', action.value);
    case SHORTDESCRIPTION_CHANGE_SV:
      return state.set('shortdescriptionsv', action.value);
    case SHORTDESCRIPTION_CHANGE_EN:
      return state.set('shortdescriptionen', action.value);
    case CHANGE_CATEGORY:
      return state.set('value', action.value);
    case CHANGE_LOCATION:
      return state
        .set('locationvalue', action.value)
        .set('city', action.value.name);

    case LINK_CHANGE:
      return state.set('link', action.value);
    case STARTDATE_CHANGE:
      const datelist3 = state.get('dates').toJS();
      datelist3[action.id].start = action.value;
      datelist3[action.id].end = action.value;
      return state.set('dates', fromJS(datelist3).toList());
    case ENDDATE_CHANGE:
      const datelist4 = state.get('dates').toJS();
      datelist4[action.id].end = action.value;
      return state.set('dates', fromJS(datelist4).toList());

    case IMAGE_DROP:
      return state.set('pictures', action.value);
    case CLEAR_IMAGE:
        return state.set("pictures", false);
    case RESTORE_IMAGE:
        return state.set("pictures", state.get("eventimage"));
    case CLEAR_SUGGESTIONS:
      return state.set('suggestions', false);
    case SET_SUGGESTIONS:
      state.set('suggestions', '');
      return state;

    case TOGGLE_FREE:
      if (state.get('isfree')) {
        return state.set('isfree', false);
      }
      return state.set('isfree', true);
    case SET_ERROR:
      return state.set('error', action.value);
    case CLEAR_FORM:
      return initialState;
    default:
      return state;
  }
}

export default AddEvenemangReducer;
