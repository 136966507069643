/**
 *
 * LoginPage
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { RESTART_ON_REMOUNT } from "./../../utils/constants";
import injectSaga from "./../../utils/injectSaga";
import injectReducer from "./../../utils/injectReducer";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/styles/withStyles";
import saga from "./saga";
import reducer from "./reducer";
import {
  loginOnChange,
  passwordOnChange,
  submitLoginForm,
  confirmToken,
} from "./actions";
import {
  makeSelectPassword,
  makeSelectLogin,
  makeSelectMessage,
  makeSelectLoading,
} from "./selectors";
import { hideLeftDrawer } from "../App/actions";
/* eslint-disable react/prefer-stateless-function */

const styles = (theme) => ({
  layout: {
    /*   width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },*/
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    background: "#F5F5F5",
    marginBottom: theme.spacing(2),
  },
  avatar: {
    //margin: theme.spacing.unit,
    //backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
  },
  spinner: {
    //marginLeft: '20px',
    //width: '20px',
    //height: '20px',
  },
  submit: {
    //marginTop: theme.spacing(3),
  },
  linkleft: {
    float: "left",
    cursor: "pointer",
    color: "#f57d6c",
  },
  linkright: {
    float: "right",
    cursor: "pointer",
    color: "#f57d6c",
  },

  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  griditem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: "center",
  },
  errorStyle: {
    fontSize: "12px",
  },
});
const SpinnerAdornment = withStyles(styles)((props) => (
  <CircularProgress
    className={props.classes.spinner}
    size={20}
    style={{ color: "#fff" }}
  />
));

const AdornedButton = (props) => {
  const { children, loading, ...rest } = props;
  return (
    <Button {...rest}>
      {children}
      {loading && <SpinnerAdornment {...rest} />}
    </Button>
  );
};
const errorProps = {
  error: true,
};
export class LoginPage extends React.PureComponent {
  componentDidMount() {
    const { token } = this.props.match.params;
    if (token) {
      this.props.onSubmitForm(token);
    }
    this.props.hideSearch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      loginvisible,
      classes,
      login,
      password,
      error,
      loading,
      passwordvalue,
      loginvalue,
    } = this.props;
    const { error_email, error_non_field_errors } = error;

    return (
      <div className={classes.root}>
        <Container maxWidth="xs">
          <Grid container spacing={6} direction="row" justifyContent="center">
            <form
              onKeyPress={(event) => {
                if (event.which === 13 && event.target.type != "textarea") {
                  event.preventDefault();
                }
              }}
              className={classes.form}
              onSubmit={this.props.onSubmit}
            >
              <Grid item xs={12} className={classes.griditem}>
                <Typography variant="h4" align="center">
                  Logga in
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.griditem}>
                <TextField
                  id="login"
                  label="E-post"
                  helperText={login}
                  margin="normal"
                  FormHelperTextProps={errorProps}
                  onChange={this.props.loginOnChange}
                  value={loginvalue.toLowerCase()}
                  fullWidth
                  required
                  autoFocus
                  variant="outlined"
                  inputProps={{
                    style: { textTransform: "lowercase" },
                  }}
                />
                <span className={classes.errorStyle}>{error_email}</span>
              </Grid>
              <Grid item xs={12} className={classes.griditem}>
                <TextField
                  id="password"
                  label="Lösenord"
                  variant="outlined"
                  helperText={password}
                  margin="normal"
                  FormHelperTextProps={errorProps}
                  onChange={this.props.passwordOnChange}
                  fullWidth
                  value={passwordvalue}
                  required
                  autoFocus
                  type="password"
                />
                <span className={classes.errorStyle}>
                  {error_non_field_errors}
                </span>
                <p>
                  <AdornedButton
                    type="submit"
                    loading={loading}
                    variant="contained"
                    color="primary"
                  >
                    LOGGA IN
                  </AdornedButton>
                </p>
                <p className={classes.linkleft}>
                  <a className={classes.linkleft} href="/register">
                    Registera
                  </a>
                </p>
                <p className={classes.linkright}>
                  <a className={classes.linkright} href="/resetpassword">
                    Glömt Lösenord?
                  </a>
                </p>
              </Grid>
            </form>
          </Grid>
        </Container>
      </div>
    );
  }
}

LoginPage.propTypes = {
  login: PropTypes.string,
  password: PropTypes.string,
  message: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  loginvalue: makeSelectLogin(),
  passwordvalue: makeSelectPassword(),
  error: makeSelectMessage(),
  loading: makeSelectLoading(),
});

function mapDispatchToProps(dispatch) {
  return {
    loginOnChange: (evt) => {
      dispatch(loginOnChange(evt.target.value));
    },
    hideSearch: (evt) => {
      dispatch(hideLeftDrawer());
    },
    passwordOnChange: (evt) => {
      dispatch(passwordOnChange(evt.target.value));
    },
    onSubmit: (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      dispatch(submitLoginForm());
    },
    onSubmitForm: (token) => {
      dispatch(confirmToken(token));
    },
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: "LoginPage", reducer });
const withSaga = injectSaga({
  key: "LoginPage",
  saga,
  mode: RESTART_ON_REMOUNT,
});

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withStyles(styles)
)(LoginPage);
