/**
 *
 * AddFyrk
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import injectSaga from "../../utils/injectSaga";
import injectReducer from "../../utils/injectReducer";
import _ from "lodash";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CardMedia from "@material-ui/core/CardMedia";
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import withStyles from "@material-ui/styles/withStyles";
/* eslint-disable react/prefer-stateless-function */
import Button from "@material-ui/core/Button";
import { withCookies } from "react-cookie";
import ImageUploader from "react-images-upload";
import Select from "react-select";
import Grid from "@material-ui/core/Grid";
import H2 from "../../components/H2";
import H3 from "../../components/H3";
import saga from "./saga";
import Avatar from "@material-ui/core/Avatar";
import classNames from "classnames";
import FyrkImage from '../../images/Fyrk_webb.png';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import reducer from "./reducer";
import {
  imageDrop,
  titleOnChange,
  linkOnChange,
  linkDescriptionOnChange,
  descriptionOnChange,
  submitAddFyrkForm,
  handleChange,
  changeLocation,
  changeGroup,
  changeFyrk,
  loadFyrkItem,
  setLoading,
  changeProcurator,
  changeContactName,
  changeContactEmail,
  changeContactPhone,
  changeContactAddress,
  changeNextPeriod,
  changePeriodType,
  changePeriodStatus,
  changeUpdaterEmail,
  clearImage,
  restoreImage
} from "./actions";
import { hideLeftDrawer } from "../App/actions";
import {
  makeSelectCategoriesAll,
  makeSelectPlats,
  makeSelectGroups,
  getHelp,
} from "../App/selectors";
import {
  makeSelectTitle,
  getProcurator,
  makeSelectLink,
  makeSelectLinkDescription,
  makeSelectDescription,
  makeSelectPictures,
  makeSelectValue,
  getSelectedGroups,
  getSelectedLocations,
  getError,
  getFyrk,
  getFyrkItem,
  getLoading,
  getContactName,
  getContactPhone,
  getContactEmail,
  getContactAddress,
  getNextPeriod,
  getPeriodType,
  getPeriodStatus,
  getUpdaterEmail,
} from "./selectors";
import { makeSelectCurrentUser } from "../../containers/App/selectors";

import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import sv from "date-fns/locale/sv";
import "moment/locale/sv";
import FormControl from "@material-ui/core/FormControl";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: "left",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: "80%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  textField: {
    width: "100%",
    border: "1px solid #d6d1f0",
    background: "#fff",
    "&:before": {
      border: "none",
    },
    paddingLeft: "5px",
  },
  textFieldRoot: {
    "&:before": {
      border: "none",
    },
  },
  errorBlock: {
    backgroundColor: "#ffa4a4",
    color: "#fff",
    marginTop: 0,
    padding: "0.4em",
  },
  clearButton: {
    float: "right",
    marginTop: "2em",
  },
  leftIcon: {
    float: "left",
  },
  media: {
    height: 0,
    paddingTop: "56.5%", // 16:9
  },
  row: {
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  imagepaper: {
    marginTop: theme.spacing(1),
    minHeight: "200px",
    backgroundColor: "rgba(242,122,104,0.11)",
    border: "1px solid #F27A68",
  },
  centerLabel: {
    textAlign: "center",
    paddingTop: "5em",
  },
  form: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  wysiwyg: {
    border: "1px solid #ccc",
    marginTop: "1em",
  },
  wlabel: {
    marginTop: "2em",
    marginBottom: "0",
    fontSize: "1.2rem",
  },
  submit: {
    float: "left",
    backgroundColor: "#44AB66",
    color: "#fff",
    fontWeight: "bold",
    borderRadius: "0",
    paddingLeft: "2em",
    paddingRight: "2em",
    marginTop: theme.spacing(3),
  },
  chooseFileButton: {
    backgroundColor: "#44AB66",
    color: "#fff",
    borderRadius: "0",
  },
  errorStyle: {
    color: "#f44336",
    textAlign: "center",
  },
  helpStyle: {
    color: "#9691BC",
    textAlign: "left",
    marginTop: "-20px",
  },
  bigAvatar: {
    width: 150,
    height: 150,
    marginTop: 40,
  },
  avatar: {
    margin: 10,
  },
});

export class AddFyrk extends React.PureComponent {
  UNSAFE_componentWillMount() {
    this.props.hideSearch();
    this.fyrkimage = FyrkImage;
    const singleid = this.props.match.params.id;
    this.singleid = singleid;
    this.edited = false;
    if (singleid && singleid > 0) {
      this.props.setLoading(true);
      this.props.loadFyrkItem(singleid);
    } else {
      this.props.setLoading(false);
    }
    this.description = React.createRef();
    this.procurator = React.createRef();
    this.title = React.createRef();
  //  this.fyrkimage = React.createRef();
    this.contactemail = React.createRef();
    this.contactname = React.createRef();
    this.nextperiod = React.createRef();
    this.scrolled = false;
  }
  openScroll(refe) {
    this.scrolled = false;
  }

  render() {
    const {
      titlevalue,
      linkvalue,
      linkdescriptionvalue,
      procurator,
      descriptionvalue,
      contactname,
      classes,
      pictures,
      loading,
      helptexts,
      contactphone,
      contactemail,
      contactaddress,
      nextperiod,
      periodtype,
      next_period_status,
      error,
      updateremail,
      currentuser,
    } = this.props;
    let { categories } = this.props;
    let updateremailvalue = "";

    if (updateremail === false && !this.edited) {
      updateremailvalue = currentuser.email;
    } else {
      updateremailvalue = updateremail;
      this.edited = true;
    }

    const {
      error_title,
      error_contactemail,
      error_contactname,
      error_procurator,
      error_description,
      error_urls,
      error_fyrkimage,
      error_nextperiod,
    } = error;

    if (!categories) {
      categories = [];
    }

    let { groups } = this.props;
    if (!groups) {
      groups = [];
    }
    let { locations } = this.props;
    if (!locations) {
      locations = [];
    }

    let { selectedgroups } = this.props;
    if (!selectedgroups) {
      selectedgroups = [];
    }
    let { selectedlocations } = this.props;
    if (!selectedlocations) {
      selectedlocations = [];
    }

    const { title, description, urls } = error;

    let link = "";
    if (urls && urls.length > 0) {
      link = urls[0].url;
    } else {
      link = "";
    }
    let buttonvalue = "Create";
    if (this.singleid) {
      buttonvalue = "Spara";
    } else {
      buttonvalue = "Skapa";
    }
    let { value } = this.props;
    if (!value) {
      value = null;
    }
    const pStyle = {
      background: "transparent",
      boxShadow: "none",
    };
    let fyrkimage = this.fyrkimage;

    if (pictures.length > 0 && pictures[0] != "") {
      fyrkimage = pictures[0];
    }

    let imagepadding = "0";
    if (fyrkimage != FyrkImage) {
      imagepadding = "8em";
    }

    const errorProps = {
      error: true,
    };

    let fyrk_image = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: "fyrk_link_image" });
      if (temptext) {
        fyrk_image = temptext.description;
      }
    }

    let fyrk_info = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: "fyrk_info" });
      if (temptext) {
        fyrk_info = temptext.description;
      }
    }

    let fyrk_title = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: "fyrk_title" });
      if (temptext) {
        fyrk_title = temptext.description;
      }
    }
    let fyrk_proc = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: "fyrk_proc" });
      if (temptext) {
        fyrk_proc = temptext.description;
      }
    }
    let fyrk_desc = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: "fyrk_desc" });
      if (temptext) {
        fyrk_desc = temptext.description;
      }
    }

    let fyrk_link = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: "fyrk_link" });
      if (temptext) {
        fyrk_link = temptext.description;
      }
    }
    let fyrk_datum = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: "fyrk_datum" });
      if (temptext) {
        fyrk_datum = temptext.description;
      }
    }
    let fyrk_cat = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: "fyrk_cat" });
      if (temptext) {
        fyrk_cat = temptext.description;
      }
    }
    let fyrk_region = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: "fyrk_region" });
      if (temptext) {
        fyrk_region = temptext.description;
      }
    }

    if (
      !this.scrolled &&
      error &&
      error.error_description &&
      error_description != ""
    ) {
      if (this.description.current) {
        this.description.current.scrollIntoView({ behavior: "smooth" });
        this.scrolled = true;
      }
    }

    if (
      !this.scrolled &&
      error &&
      error.error_urls &&
      error_urls != ""
    ) {
      if (this.description.current) {
        this.description.current.scrollIntoView({ behavior: "smooth" });
        this.scrolled = true;
      }
    }

    if (!this.scrolled && error && error.error_title && error_title != "") {
      if (this.title.current) {
        this.title.current.scrollIntoView({ behavior: "smooth" });
        this.scrolled = true;
      }
    }
    if (
      !this.scrolled &&
      error &&
      error.error_procurator &&
      error_procurator != ""
    ) {
      if (this.procurator.current) {
        this.procurator.current.scrollIntoView({ behavior: "smooth" });
        this.scrolled = true;
      }
    }
    if (
      !this.scrolled &&
      error &&
      error.error_fyrkimage &&
      error_fyrkimage != ""
    ) {
      if (this.fyrkimage.current) {
        this.fyrkimage.current.scrollIntoView({ behavior: "smooth" });
        this.scrolled = true;
      }
    }
    if (
      !this.scrolled &&
      error &&
      error.error_contactemail &&
      error_contactemail != ""
    ) {
      if (this.contactemail.current) {
        this.contactemail.current.scrollIntoView({ behavior: "smooth" });
        this.scrolled = true;
      }
    }
    if (
      !this.scrolled &&
      error &&
      error.error_contactname &&
      error_contactname != ""
    ) {
      if (this.contactname.current) {
        this.contactname.current.scrollIntoView({ behavior: "smooth" });
        this.scrolled = true;
      }
    }
    if (
      !this.scrolled &&
      error &&
      error.error_nextperiod &&
      error_nextperiod != ""
    ) {
      if (this.nextperiod.current) {
        this.nextperiod.current.scrollIntoView({ behavior: "smooth" });
        this.scrolled = true;
      }
    }
    return (
      <div>
        <CssBaseline />
        <form className={classes.form} onSubmit={this.props.onSubmitForm}>
          
          <Grid container spacing={10}>
            <Grid item xs={false} md={2} />
            <Grid item xs={12} md={8}>

              <div ref={fyrkimage} className={classes.row} style={{paddingLeft:imagepadding}}>
                <Avatar
                  variant="square"
                  alt="Fyrk logo"
                  src={fyrkimage}
                  style={{ textAlign: "center" }}
                  className={classNames(classes.avatar, classes.bigAvatar)}
                />
                {fyrkimage != FyrkImage && (
                  <div style={{width:'5em',paddingTop:'6em'}}>
                  <Button
                  onClick={evt => {
                    this.props.clearImage();
                  }}
                  variant="text"
                  size="small"
                  className={classes.deletebutton}
                >
                  <DeleteIcon className={classes.leftIcon} />
                </Button>
                
                <Button
                  onClick={evt => {
                    this.props.restoreImage();
                  }}
                  variant="text"
                  size="small"
                  className={classes.deletebutton}
                >
                  <HistoryIcon className={classes.leftIcon} />
                </Button>
                </div>
                  )}
                  
              </div>
              <ImageUploader
                singleImage
                withPreview={false}
                withIcon={false}
                label=""
                buttonText="Ladda upp bild"
                buttonStyles={{backgroundColor:"#44AB66", borderRadius: "0px"}}
                onChange={this.props.imageDrop}
                imgExtension={[".jpg", ".jpeg", ".png", ".gif"]}
                maxFileSize={2242880}
                fileContainerStyle={pStyle}
              />
              
              <p className={classes.helpStyle} style={{ textAlign: "center" }}>
                {fyrk_image}
              </p>
              <span className={classes.errorStyle}>{error_fyrkimage}</span>
            </Grid>
            <Grid item xs={false} md={2} />
          </Grid>
          <Grid container spacing={10}>
            <Grid item xs={false} md={3} />

            <Grid item xs={12} md={6}>
              <Grid item xs={12}>
                <H2>Basinformation</H2>
                <p className={classes.helpStyle}>{fyrk_info}</p>
                <label ref={this.title} className={classes.wlabel}>
                  Rubrik*
                </label>
                <TextField
                  onChange={this.props.titleOnChange}
                  helperText={title}
                  fullWidth
                  margin="normal"
                  value={titlevalue}
                  FormHelperTextProps={errorProps}
                  InputProps={{
                    className: classes.textField,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {error && error.error_title && error_title != "" && (
                  <p className={classes.errorBlock}>{error_title}</p>
                )}

                <p
                  ref={this.description}
                  className={classes.helpStyle}
                  style={{ marginTop: "0" }}
                >
                  {fyrk_title}{" "}
                </p>

                <label ref={this.procurator} className={classes.wlabel}>
                  Bidragskälla*
                </label>
                <TextField
                  onChange={this.props.changeProcurator}
                  helperText={title}
                  fullWidth
                  margin="normal"
                  value={procurator}
                  FormHelperTextProps={errorProps}
                  InputProps={{
                    className: classes.textField,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {error && error.error_procurator && error_procurator != "" && (
                  <p className={classes.errorBlock}>{error_procurator}</p>
                )}

                <p className={classes.helpStyle} style={{ marginTop: "0" }}>
                  {fyrk_proc}{" "}
                </p>

                <label ref={this.description} className={classes.wlabel}>
                  Beskrivning*
                </label>
                <TextField
                  onChange={this.props.descriptionOnChange}
                  multiline
                  fullWidth
                  minRows={4}
                  margin="normal"
                  value={descriptionvalue}
                  FormHelperTextProps={errorProps}
                  InputProps={{
                    className: classes.textField,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {error &&
                  error.error_description &&
                  error_description != "" && (
                    <p className={classes.errorBlock}>{error_description}</p>
                  )}

                <p className={classes.helpStyle} style={{ marginTop: "0" }}>
                  {fyrk_desc}
                </p>

                <Grid container spacing={10}>
                  <Grid item xs={false} md={6}>
                    <label className={classes.wlabel}>Länk</label>

                    <TextField
                      onChange={this.props.linkOnChange}
                      helperText={link}
                      placeholder="https://..."
                      fullWidth
                      margin="normal"
                      value={linkvalue}
                      FormHelperTextProps={errorProps}
                      InputProps={{
                        className: classes.textField,
                        disableUnderline: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {error && error.error_urls && error_urls != "" && (
                      <p className={classes.errorBlock}>{error_urls}</p>
                    )}
                  </Grid>
                  <Grid item xs={false} md={6}>
                    <label className={classes.wlabel}>Länknamn</label>
                    <TextField
                      onChange={this.props.linkDescriptionOnChange}
                      helperText={link}
                      placeholder="Till..."
                      fullWidth
                      margin="normal"
                      value={linkdescriptionvalue}
                      FormHelperTextProps={errorProps}
                      InputProps={{
                        className: classes.textField,
                        disableUnderline: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <p className={classes.helpStyle} style={{ marginTop: "0" }}>
                  {fyrk_link}{" "}
                </p>

                <H2 ref={this.contactname}>Kontaktinformation</H2>
                <label className={classes.wlabel}>Kontaktperson*</label>
                <TextField
                  fullWidth
                  margin="normal"
                  FormHelperTextProps={errorProps}
                  onChange={this.props.contactNameOnChange}
                  value={contactname}
                  InputProps={{
                    className: classes.textField,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {error &&
                  error.error_contactname &&
                  error_contactname != "" && (
                    <p className={classes.errorBlock}>{error_contactname}</p>
                  )}

                <label className={classes.wlabel}>Adress</label>
                <TextField
                  fullWidth
                  margin="normal"
                  onChange={this.props.changeContactAddress}
                  value={contactaddress}
                  FormHelperTextProps={errorProps}
                  multiline
                  minRows={3}
                  InputProps={{
                    className: classes.textField,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <label ref={this.contactemail} className={classes.wlabel}>
                  E-post*
                </label>
                <TextField
                  fullWidth
                  margin="normal"
                  value={contactemail}
                  onChange={this.props.changeContactEmail}
                  FormHelperTextProps={errorProps}
                  InputProps={{
                    className: classes.textField,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {error &&
                  error.error_contactemail &&
                  error_contactemail != "" && (
                    <p className={classes.errorBlock}>{error_contactemail}</p>
                  )}

                <label className={classes.wlabel}>Telefon</label>
                <TextField
                  fullWidth
                  margin="normal"
                  onChange={this.props.changeContactPhone}
                  value={contactphone}
                  FormHelperTextProps={errorProps}
                  InputProps={{
                    className: classes.textField,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormControl component="fieldset">
                  <H2>Periodtyp</H2>

                  <RadioGroup
                    aria-label="position"
                    name="position"
                    value={periodtype}
                    onChange={this.props.changePeriodType}
                    row
                  >
                    <FormControlLabel
                      value="EXACT"
                      control={<Radio color="primary" />}
                      label="Exakt datum"
                      labelPlacement="top"
                    />
                    <FormControlLabel
                      value="VARIABLE"
                      control={<Radio color="primary" />}
                      label="Verifierad datum"
                      labelPlacement="top"
                    />
                    <FormControlLabel
                      value="CONTINUOUS"
                      control={<Radio color="primary" />}
                      label="Fortlöpande ansökningstid"
                      labelPlacement="top"
                    />
                  </RadioGroup>
                </FormControl>
                {periodtype !== "CONTINUOUS" && (
                  <>
                    <H3 ref={this.nextperiod} className={classes.wlabel}>
                      Ansökningstid* 
                    </H3>
                    <br />
                    <Grid container>
                      <Grid item>
                        <DatePicker
                          locale="sv"
                          timeCaption="Tid"
                          placeholderText="Välj datum"
                          dateFormat="dd.MM.yyyy"
                          selected={nextperiod}
                          onChange={(evt) => {
                            this.props.changeNextPeriod(evt);
                          }}
                          customInput={
                            <TextField
                              InputProps={{
                                className: classes.textField,
                                disableUnderline: true,
                              }}
                            />
                          }
                        />
                        {error &&
                          error.error_nextperiod &&
                          error_nextperiod != "" && (
                            <p className={classes.errorBlock}>
                              {error_nextperiod}
                            </p>
                          )}
                      </Grid>
                      <Grid item style={{marginTop:'-2em'}}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="position"
                            name="position"
                            value={next_period_status}
                            onChange={this.props.changePeriodStatus}
                            row
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio color="primary" />}
                              label="Verfierad"
                              labelPlacement="top"
                            />
                            <FormControlLabel
                              value="0"
                              control={<Radio color="primary" />}
                              label="Preliminära"
                              labelPlacement="top"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <p className={classes.helpStyle} style={{ marginTop: "0" }}>
                      {fyrk_datum}{" "}
                    </p>
                  </>
                )}
                <H2>Kategorier</H2>
                <Select
                  onChange={this.props.handleChange}
                  name="form-field-name"
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  style={{
                    marginTop: "5px",
                  }}
                  isMulti={true}
                  value={value}
                  options={categories}
                />

                <p className={classes.helpStyle} style={{ marginTop: "0" }}>
                  {fyrk_cat}{" "}
                </p>

                <label className={classes.wlabel}>Updaterad av</label>
                <TextField
                  fullWidth
                  margin="normal"
                  onChange={this.props.changeUpdaterEmail}
                  value={updateremailvalue}
                  FormHelperTextProps={errorProps}
                  InputProps={{
                    className: classes.textField,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  disableElevation={true}
                  className={classes.submit}
                >
                  {" "}
                  Spara
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={false} md={3} />
          </Grid>
        </form>
      </div>
    );
  }
}

AddFyrk.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  description: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  titlevalue: makeSelectTitle(),
  procurator: getProcurator(),
  linkvalue: makeSelectLink(),
  linkdescriptionvalue: makeSelectLinkDescription(),
  descriptionvalue: makeSelectDescription(),
  pictures: makeSelectPictures(),
  categories: makeSelectCategoriesAll(),
  value: makeSelectValue(),
  selectedlocations: getSelectedLocations(),
  locations: makeSelectPlats(),
  groups: makeSelectGroups(),
  selectedgroups: getSelectedGroups(),
  error: getError(),
  fyrk: getFyrk(),
  contactname: getContactName(),
  contactemail: getContactEmail(),
  contactphone: getContactPhone(),
  contactaddress: getContactAddress(),
  loading: getLoading(),
  fyrkitem: getFyrkItem(),
  helptexts: getHelp(),
  nextperiod: getNextPeriod(),
  periodtype: getPeriodType(),
  next_period_status: getPeriodStatus(),
  updateremail: getUpdaterEmail(),
  currentuser: makeSelectCurrentUser(),
});

function mapDispatchToProps(dispatch) {
  return {
    titleOnChange: (evt) => {
      dispatch(titleOnChange(evt.target.value));
    },
    changeProcurator: (evt) => {
      dispatch(changeProcurator(evt.target.value));
    },
    changePeriodType: (evt) => {
      dispatch(changePeriodType(evt.target.value));
    },
    changePeriodStatus: (evt) => {
      dispatch(changePeriodStatus(evt.target.value));
    },
    changeContactName: (evt) => {
      dispatch(changeContactName(evt.target.value));
    },
    changeUpdaterEmail: (evt) => {
      dispatch(changeUpdaterEmail(evt.target.value));
    },
    changeContactEmail: (evt) => {
      dispatch(changeContactEmail(evt.target.value));
    },
    changeContactPhone: (evt) => {
      dispatch(changeContactPhone(evt.target.value));
    },
    changeContactAddress: (evt) => {
      dispatch(changeContactAddress(evt.target.value));
    },
    changeNextPeriod: (evt) => {
      dispatch(changeNextPeriod(evt));
    },
    fyrkOnChange: (evt) => {
      dispatch(changeFyrk());
    },
    hideSearch: (evt) => {
      dispatch(hideLeftDrawer());
    },
    linkOnChange: (evt) => {
      dispatch(linkOnChange(evt.target.value));
    },
    linkDescriptionOnChange: (evt) => {
      dispatch(linkDescriptionOnChange(evt.target.value));
    },
    contactNameOnChange: (evt) => {
      dispatch(changeContactName(evt.target.value));
    },
    descriptionOnChange: (evt) => {
      dispatch(descriptionOnChange(evt.target.value));
    },
    onSubmitForm: (evt) => {
      dispatch(setLoading(true));
      evt.preventDefault();
      evt.stopPropagation();
      dispatch(submitAddFyrkForm());
    },

    clearForm: (evt) => {
      dispatch(descriptionOnChange(""));
      dispatch(linkOnChange(""));
      dispatch(titleOnChange(""));
    },
    handleChange: (value) => {
      dispatch(handleChange(value));
    },
    setLoading: (value) => {
      dispatch(setLoading(value));
    },
    clearImage: () => {
      dispatch(clearImage());
    },
    restoreImage: () => {
      dispatch(restoreImage());
    },
    loadFyrkItem: (id) => {
      dispatch(loadFyrkItem(id));
    },
    imageDrop: (event, picture) => {
      dispatch(imageDrop(_.last(picture)));
    },
    locationChange: (value) => {
      dispatch(changeLocation(value));
    },
    groupChange: (value) => {
      dispatch(changeGroup(value));
    },
    handleChangeFyrk: (value) => {
      dispatch(changeFyrk());
    },
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: "AddFyrk", reducer });
const withSaga = injectSaga({ key: "AddFyrk", saga });

export default compose(
  withReducer,
  withSaga,
  withCookies,
  withConnect,
  withStyles(styles)
)(AddFyrk);
