/*
 * HomeReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */
import { fromJS } from 'immutable';
import {
  FAVORITES_LOADED,
  SET_LOADING,
  LOAD_FAVORITES,
  SET_FILTER,
  TO_LIST,
  TO_MASONRY
} from './constants';

// The initial state of the App
const initialState = fromJS({
  favorites: false,
  visiblefavorites : false,
  loader : false,
  error : false,
  filter : "",
  masonryview: false,
  listview: true,
});

function favoriteReducer(state = initialState, action) {
  switch (action.type) {
    case FAVORITES_LOADED:
      let defaultfilter= state.get("filter");

    if(defaultfilter === "") {
      return state.set('visiblefavorites', action.favorites).set('favorites', action.favorites);
    } else {
      const result2 = action.favorites.filter(item => item.type == defaultfilter);
      return state.set('visiblefavorites', result2).set('favorites', action.favorites);
    }
    case LOAD_FAVORITES:
      return state.set('loader', true);
    case SET_LOADING:
      return state.set('loader', action.value);
    case TO_LIST:
      return state.set('listview', true).set('masonryview', false);
    case TO_MASONRY:
      return state.set('masonryview', true).set('listview', false);
    case SET_FILTER:
        let filtervalue = action.value;
        if(action.value == "") {
          return state.set('filter', action.value).set('visiblefavorites', state.get("favorites"));

        } 
        let all_favorites = state.get("favorites");
        const result = all_favorites.filter(item => item.type == filtervalue);
        return state.set('filter', action.value).set('visiblefavorites', result);
 
    default:
      return state;
  }
}

export default favoriteReducer;
