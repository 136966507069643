import { call, put, select, takeLatest } from 'redux-saga/effects';
import { hasProfile } from '../../containers/App/actions';
import request from '../../utils/request';
import { push } from 'connected-react-router';
import _ from 'lodash';

import {
  makeSelectUserId,
  makeSelectCurrentUser,
  getUserGroupId,
  getUserFyrkStatus
} from '../../containers/App/selectors';

import { LOAD_PROFILE, SUBMIT_EDITPROFILEFORM } from './constants';

import {
  profileLoaded,
  loadProfile,
  saveEvents,
  setLoading,
  setError,
  loadProfileSingle,
  profilesLoaded,
  clearForm,
} from './actions';

import { LE_ROOT, API_ROOT } from '../../api-config';

import {
  getFyrk,
  makeSelectPicturesProfile,
  makeSelectPictures,
  makeSelectValue,
  makeSelectPosterCategories,
  getSelectedLocations,
  getSelectedGroups,
  getDescription,
  getGroupName,
  getUrls,
  getContact,
  getPhone,
  getTitle,
  getLoading,
  getFirstName,
  getLastName,
  makeSelectProfile,
  getSelectedProfiles,
  getHeaderImage,
  getProfileImage,
} from './selectors';
/**
 * Github repos request/response handler
 */
export function* getProfileSingle(value) {
  // yield put(profileLoaded(''));
  yield put(setLoading(true));

  yield put(clearForm());

  let userid = yield select(makeSelectUserId());
  if (!userid) {
    userid = localStorage.getItem('userid');
  }
  let usergroupid = yield select(getUserGroupId());
  if (!usergroupid) {
    usergroupid = localStorage.getItem('usergroupid');
  }
  let requestURL = `${API_ROOT}posters/${value.id}`;

  if (value.id == 0) {
    requestURL = `${API_ROOT}posters/?isfritid=false&user=${userid}`;
  }
  if(value.id == -1) {

  } else {

  try {
    const profile = yield call(request, requestURL);
    if (profile) {
      if (value.id == 0) {
        if (profile.count == 0) {
        } else {
          yield put(profileLoaded(profile.results[0]));
        }
      } else {
        yield put(profileLoaded(profile));
      }
    }
  } catch (err) {
    // yield put(nyheterLoadingError(err));
  }
  yield put(setLoading(false));
}
const requestProfiles = `${API_ROOT}posters/?isfritid=false&profile=true&limit=999`;
const kultur = yield call(request, requestProfiles);
const kulturtotal = kultur.results.map(item => {
  {
    item.visiblename = `${item.groupname} ${item.firstname} ${item.lastname}`;
  }
});
yield put(profilesLoaded(kultur.results));
  /* const requestEvents = `${LE_ROOT}event/?include=location,keywords&super_event=null&custom_data=origin_uid:${userid}`;

  try {
    const events = yield call(request, requestEvents);
    yield put(saveEvents(events.data));
  } catch (err) {
  } */
}
const fetchJSON = (url, options = {}) =>
  new Promise((resolve, reject) =>
    fetch(url, options)
      .then(response =>
        response.status !== 201 &&
        response.status !== 200 &&
        response.status !== 400
          ? reject(response)
          : response,
      )
      .then(response => {
        if (response.status === 400) {
          response.json().then(error => reject(error));
        } else {
          return resolve(response.json());
        }
        return '';
      })
      .catch(error => reject(error)),
  );
  const validateForm= (payload) => {
    let errorresult = false;
    let error = {};
    let firstnamepass = false;
    let lastnamepass = false;
    let grouppass = false;


    if(payload.groupname != "" ) {
      grouppass = true;
    }
    if(payload.firstname != "" ) {
      firstnamepass = true;
    }
    if(payload.lastname != "" ) {
      lastnamepass = true;
    }


    if(!(grouppass  || (firstnamepass && lastnamepass))) {
      if(!grouppass) {
        if((!firstnamepass && lastnamepass)) {
          errorresult = true;
          error.firstname = "Förnamn saknas";
        }
        if((firstnamepass && !lastnamepass)) {
          errorresult = true;

          error.lastname = "Efternamn saknas";

        }
    }
  }


    if(!grouppass  && !lastnamepass && !firstnamepass) {
        errorresult = true;
        error.groupname = "Förening / Organisation / Grupp saknas";
    } else {
      error.groupname="";
    }
    if(payload.postercategories.length == 0) {
      errorresult = true;
      error.categories = "Kategorier saknas";
    } else {
      error.categories = "";
    }

    if(payload.description == "<p></p>") {
      errorresult = true;
      error.description = "Beskrivning saknas";
    } else {
      error.description="";
    }

    if(errorresult) {
      throw error;
    }

  };

export function* editProfileRequest() {
  yield put(setLoading(true));

  const profile = yield select(makeSelectProfile());

  function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.toString().split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const headerimage = yield select(getHeaderImage());
  const profileimage = yield select(getProfileImage());
  const values = yield select(makeSelectValue());
  const postercategories = yield select(makeSelectPosterCategories());

  const locations = yield select(getSelectedLocations());
  const groups = yield select(getSelectedGroups());
  const profiles = yield select(getSelectedProfiles());
  let userid = yield select(makeSelectUserId());
  if (!userid) {
    userid = localStorage.getItem('userid');
  }
  let usergroupid = yield select(getUserGroupId());
  if (!usergroupid) {
    usergroupid = localStorage.getItem('usergroupid');
  }

  const description = yield select(getDescription());
  const title = yield select(getTitle());
  const firstname = yield select(getFirstName());
  const lastname = yield select(getLastName());
  const groupname = yield select(getGroupName());
  const contact = yield select(getContact());
  const phone = yield select(getPhone());
  const originalprofile = yield select(makeSelectProfile());
  const fyrk = yield select(getFyrk());
  const fyrkstatus = yield select(getUserFyrkStatus());

  const kultur = []
  const profileid = originalprofile.id || 0;
  
  let profileresponse = '';

  const profileuser = originalprofile.user;
  let service = 'kulturforum.fi'
  
  if (fyrkstatus == true && profileuser == userid) {
    service = 'fyrk.fi'
  }
  
  if (profileid != 0) {
    userid = profileuser;
  }
  
  const payload = {
    user: userid,
    firstname,
    lastname,
    description,
    kultur,
    // newsfrequency: freqvalue,
    title,
    groupname,
    contact,
    phone,
    fyrk,
    service,
    isfritid : false,
  };



  payload.usergroup = originalprofile.usergroup;

  let url = yield select(getUrls());
  if (url !== '') {
    if (!url.startsWith('https') && !url.startsWith('http')) {
      url = 'http://'+url
    }

    payload.urls = [{'url': url,
                  'text': url}]

  }

  if (headerimage == false) {
    const headerindex = _.findIndex(originalprofile.posterimages, {
      role: 'header',
    });
    if (headerindex != -1) {
      originalprofile.posterimages.splice(headerindex, 1);
    }
  }
  if (profileimage == false) {
    const profileindexindex = _.findIndex(originalprofile.posterimages, {
      role: 'header',
    });
    if (profileindexindex != -1) {
      originalprofile.posterimages.splice(profileindexindex, 1);
    }
  }
  payload.posterimages = [];

  if (values.length > 0) {
    const catObjData = values.map((data, idx) => ({ id: data.id }));
    payload.categories = catObjData;
  } else {
    payload.categories = [];
  }

  if (postercategories.length > 0) {
    const postercatObjData = postercategories.map((data, idx) => ({
      id: data.id,
    }));
    payload.postercategories = postercatObjData;
  } else {
    payload.postercategories = [];
  }
  if (locations.length > 0) {
    const locationObjData = locations.map((data, idx) => ({ id: data.id }));
    payload.regions = locationObjData;
  } else {
    payload.regions = [];
  }
  if (groups.length > 0) {
    const groupObjData = groups.map((data, idx) => ({ id: data.id }));
    payload.groups = groupObjData;
  } else {
    payload.groups = [];
  }

  if (profiles.length > 0) {
    const profileObjData = profiles.map((data, idx) => ({ id: data.id }));
    payload.related = profileObjData;
  } else {
    payload.related = [];
  }
  try {
    validateForm(payload);


    const token = localStorage.getItem('token');

    let path = `${API_ROOT}posters/${originalprofile.id}/`;
    let method = 'PUT';
    if (profileid == 0) {
      path = `${API_ROOT}posters/`;
      method = 'POST';
    }
    const profileres = yield call(fetchJSON, path, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(payload),
    });
    profileresponse = profileres;
    if (
      headerimage &&
      !headerimage.includes('https:') &&
      !headerimage.includes('http:')
    ) {
      const file = dataURLtoFile(headerimage, 'testFile.png');
      const imagepayload = { role: 'header', file };

      const formData = new FormData();

      for (const name in imagepayload) {
        formData.append(name, imagepayload[name]);
      }

      try {
        const resimage = yield call(
          fetchJSON,
          `${API_ROOT}posters/${profileres.id}/upload_image/`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              Authorization: `Token ${token}`,
            },
            body: formData,
          },
        );
      } catch (error) {
        error.headerimage = error.detail[0];
        throw error;
      }
    }
    if (
      profileimage &&
      !profileimage.includes('https:') &&
      !profileimage.includes('http:')
    ) {
      const file = dataURLtoFile(profileimage, 'testFile.png');
      const imagepayload = { role: 'profile', file };

      const formData = new FormData();

      for (const name in imagepayload) {
        formData.append(name, imagepayload[name]);
      }

      try {
        const resimage2 = yield call(
          fetchJSON,
          `${API_ROOT}posters/${profileres.id}/upload_image/`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              Authorization: `Token ${token}`,
            },
            body: formData,
          },
        );
      } catch (error) {
        error.profileimage = error.detail[0];
        throw error;
      }
    }
    // clear errors

    if (profileresponse.profile) {
      yield put(hasProfile(true));
    }
    yield put(loadProfileSingle(profileres.id));
   // yield put(push(`/profile/${profileres.id}`));
   yield put(push(`/groups`));

  } catch (error) {
    const errorarray = [];
    Object.keys(error).map((key, index) => {
      errorarray[`error_${key}`] = error[key];
    });
    if (typeof profileresponse.id !== 'undefined') {
      yield put(loadProfileSingle(profileresponse.id));
    }

    yield put(setError(errorarray));
    yield put(setLoading(false));

    // loadProfileSingle(singleid)
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export default function* githubData() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(SUBMIT_EDITPROFILEFORM, editProfileRequest);
  yield takeLatest(LOAD_PROFILE, getProfileSingle);
}
