/*
 *
 * UppgifterPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  PASSWORD_CHANGE,
  PASSWORDCONFIRM_CHANGE,
  CURRENTPASS_CHANGE,
  AUTH_FAILURE,
  REGISTER_SUCCESS,
  SET_LOADING,
  SET_ERROR,
  SET_OPEN,
  SET_OPENDIALOG,
  SET_GROUP,
  DEFAULT_ACTION,
  LOGIN_CHANGE,
  FYRKREQUESTCHANGE,
} from './constants';

export const initialState = fromJS({
  login: '',
  password: '',
  passwordconfirm: '',
  message: '',
  loading: false,
  token: '',
  error: false,
  registersuccess: false,
  open : false,
  opendialog : false
});

function UppgifterPageReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case LOGIN_CHANGE:
      return state.set('login', action.value);
    case PASSWORD_CHANGE:
      return state.set('password', action.value);
    case PASSWORDCONFIRM_CHANGE:
      return state.set('passwordconfirm', action.value);
    case AUTH_FAILURE:
      return state.set('message', action.value);
    case REGISTER_SUCCESS:
      return state.set('loading', false).set('registersuccess',  action.value);
    case SET_LOADING:
      return state.set('loading', action.value);
    case SET_OPEN:
      return state.set('open', action.value);
    case SET_OPENDIALOG:
      return state.set('opendialog', action.value);
    case SET_ERROR:
      return state.set('error', action.value);
    case FYRKREQUESTCHANGE:
      if (state.get('fyrklover')) {
        return state.set('fyrklover', false);
      }
      return state.set('fyrklover', true);
  
    default:
      return state;
  }
}



export default UppgifterPageReducer;
