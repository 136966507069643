/**
 *
 * AddNyhet
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import { Editor } from 'react-draft-wysiwyg';
import _ from 'lodash';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/styles/withStyles';
/* eslint-disable react/prefer-stateless-function */
import white from '@material-ui/core/colors/common';
import Button from '@material-ui/core/Button';
import { withCookies } from 'react-cookie';
import ImageUploader from 'react-images-upload';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import Select from 'react-select';
import RandomImage from '../../components/RandomImage';
import Grid from '@material-ui/core/Grid';
import H2 from '../../components/H2';
import saga from './saga';
import reducer from './reducer';
import {
  imageDrop,
  titleOnChange,
  linkOnChange,
  descriptionOnChange,
  submitAddNyhetForm,
  handleChange,
  changeLocation,
  changeGroup,
  changeFyrk,
  changeFyrkDate,
  changeEditorState,
  loadNewsItem,
  setLoading,
  clearImage,
  restoreImage,
} from './actions';
import { hideLeftDrawer } from '../App/actions';
import {
  makeSelectCategoriesAll,
  makeSelectPlats,
  makeSelectGroups,
  getHelp,
} from '../App/selectors';
import {
  makeSelectTitle,
  makeSelectLink,
  makeSelectDescription,
  makeSelectPictures,
  makeSelectValue,
  getSelectedGroups,
  getSelectedLocations,
  getError,
  getFyrk,
  getFyrkDate,
  getEditorState,
  getNewsItem,
  getLoading,
} from './selectors';

import 'moment/locale/sv';
const styles = theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'left',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  editor: {
    minHeight: '8em',
    padding: '1em',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight:theme.spacing(1),
    width: '100%',
  },
  clearButton: {
    float: 'right',
    marginTop: '2em',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  imagepaper: {
    marginTop: theme.spacing(1),
    minHeight: '200px',
    backgroundColor: 'rgba(242,122,104,0.11)',
    border: '1px solid #F27A68',
  },
  centerLabel: {
    textAlign: 'center',
    paddingTop: '5em',
  },
  form: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  wysiwyg: {
    border: '1px solid #ccc',
    marginTop: '1em',
  },
  wlabel: {
    marginTop: '2em',
  },
  submit: {
    float: 'right',
    marginTop: theme.spacing(3),
  },
  errorStyle: {
    color: '#f44336',
    textAlign: 'center',
  },
  helpStyle: {
    color: '#9691BC',
    textAlign: 'left',
    marginTop: '-20px',
  },
});

export class AddNyhet extends React.PureComponent {
  UNSAFE_componentWillMount() {
    this.props.hideSearch();
    this.newsimage = RandomImage();
    this.tempimage = this.newsimage;
    const singleid = this.props.match.params.id;
    this.singleid = singleid;
    if (singleid && singleid > 0) {
      this.props.setLoading(true);

      this.props.loadNewsItem(singleid);
    } else {
      this.props.setLoading(false);
    }
  }

  render() {
    const {
      titlevalue,
      linkvalue,
      classes,
      pictures,
      loading,
      error,
      editorstate,
      helptexts,
    } = this.props;
    let { categories } = this.props;

    if (!categories) {
      categories = [];
    }

    let { groups } = this.props;
    if (!groups) {
      groups = [];
    }
    let { locations } = this.props;
    if (!locations) {
      locations = [];
    }

    let { selectedgroups } = this.props;
    if (!selectedgroups) {
      selectedgroups = [];
    }
    let { selectedlocations } = this.props;
    if (!selectedlocations) {
      selectedlocations = [];
    }

    const { title, description, urls } = error;
  
    let link = '';
    if (urls && urls.length > 0) {
      link = urls[0].url;
    } else {
      link = '';
    }
    let buttonvalue = 'Create';
    if (this.singleid) {
      buttonvalue = 'Spara';
    } else {
      buttonvalue = 'Skapa';
    }
    let { value } = this.props;
    if (!value) {
      value = null;
    }
    const pStyle = {
      background: 'transparent',
      boxShadow: 'none',
    };
    let { newsimage } = this;
    if (pictures && pictures.length > 0) {
      newsimage = pictures[0];
    }
    const errorProps = {
      error: true,
    };

    let news_image = '';
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'news_image' });
      if (temptext) {
        news_image = temptext.description;
      }
    }

    let news_info = '';
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'news_info' });
      if (temptext) {
        news_info = temptext.description;
      }
    }

    let news_title = '';
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'news_title' });
      if (temptext) {
        news_title = temptext.description;
      }
    }

    let news_desc = '';
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'news_desc' });
      if (temptext) {
        news_desc = temptext.description;
      }
    }

    let news_link = '';
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'news_link' });
      if (temptext) {
        news_link = temptext.description;
      }
    }

    let news_cat = '';
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'news_cat' });
      if (temptext) {
        news_cat = temptext.description;
      }
    }
    let news_region = '';
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'news_region' });
      if (temptext) {
        news_region = temptext.description;
      }
    }
    let news_group = '';
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'news_group' });
      if (temptext) {
        news_group = temptext.description;
      }
    }
    return (
      <div>
        <CssBaseline />
        <form className={classes.form} onSubmit={this.props.onSubmitForm}>
          <Grid container spacing={10}>
            <Grid item xs={false} md={2} />
            <Grid item xs={12} md={8}>
              <CardMedia
                className={classes.media}
                image={newsimage}
                title="Nyhets bild"
              />
              <Grid container spacing={0}>
            <Grid item xs={false} md={4} />
            <Grid item xs={3}>          
            <ImageUploader
                singleImage
                withPreview={false}
                withIcon={false}
                label=""
                buttonText="Ladda upp bild"
                onChange={this.props.imageDrop}
                imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                maxFileSize={2242880}
                fileContainerStyle={pStyle}
              />
              </Grid>
              <Grid item xs={1}>
               {newsimage != this.tempimage && (
                  <div style={{width:'5em',paddingTop:'2em'}}>
                  <Button
                  onClick={evt => {
                    this.props.clearImage();
                  }}
                  variant="text"
                  size="small"
                  className={classes.deletebutton}
                >
                  <DeleteIcon className={classes.leftIcon} />
                </Button>
                
                <Button
                  onClick={evt => {
                    this.props.restoreImage();
                  }}
                  variant="text"
                  size="small"
                  className={classes.deletebutton}
                >
                  <HistoryIcon className={classes.leftIcon} />
                </Button>
                </div>
                  )}
              </Grid>
              </Grid>
              <p className={classes.helpStyle} style={{ textAlign: 'center' }}>
                {news_image}
              </p>
            </Grid>
            <Grid item xs={false} md={2} />
          </Grid>
          <Grid container spacing={10}>
            <Grid item xs={false} md={3} />

            <Grid item xs={12} md={6}>
              <Grid item xs={12}>
                <H2>Basinformation</H2>
                <p className={classes.helpStyle} dangerouslySetInnerHTML={{ __html:news_info}} />

                <TextField
                  id="title"
                  className={classes.textField}
                  onChange={this.props.titleOnChange}
                  label="Titel"
                  helperText={title}
                  margin="normal"
                  value={titlevalue}
                  FormHelperTextProps={errorProps}
                />
                <p className={classes.helpStyle} style={{ marginTop: '0' }}>
                  {news_title}{' '}
                </p>

                <p>
                  <label className={classes.wlabel}>Beskrivning</label>
                </p>
                <Editor
                  editorState={editorstate}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName={classes.wysiwyg}
                  editorClassName={classes.editor}
                  onEditorStateChange={this.props.onEditorStateChange}
                  stripPastedStyles
                  toolbar={{
                    options: [
                      'inline',
                      'blockType',
                      'fontSize',
                      'fontFamily',
                      'list',
                      'textAlign',
                      'link',
                    ],
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                  }}
                />
                <p className={classes.helpStyle} style={{ marginTop: '0' }}>
                  {news_desc}
                </p>
                <p className={classes.errorStyle}>{description}</p>

                <TextField
                  id="name"
                  onChange={this.props.linkOnChange}
                  className={classes.textField}
                  label="Länk"
                  margin="normal"
                  type="url"
                  placeholder="https://"
                  helperText={link}
                  value={linkvalue}
                  FormHelperTextProps={errorProps}
                />
                <p className={classes.helpStyle} style={{ marginTop: '0' }}>
                  {news_link}{' '}
                </p>
                <H2>Kategorier</H2>


                <Select
                  onChange={this.props.handleChange}
                  name="form-field-name"
                  getOptionLabel={({name}) => name}
                 getOptionValue={({id}) => id}
                  style={{
                    marginTop: '5px',
                  }}
                  isMulti={true}
                  value={value}
                  options={categories}
                />
                <p className={classes.helpStyle} style={{ marginTop: '0' }}>
                  {news_cat}{' '}
                </p>

                <H2>Plats</H2>
                <Select
                  onChange={this.props.locationChange}
                  name="form-field-name"
                  getOptionLabel={({name}) => name}
                 getOptionValue={({id}) => id}
                  style={{
                    marginTop: '5px',
                  }}
                  value={selectedlocations}
                  isMulti={true}
                  options={locations}
                />
                <p className={classes.helpStyle} style={{ marginTop: '0' }}>
                  {news_region}{' '}
                </p>

                <H2>För Vem</H2>
                <Select
                  onChange={this.props.groupChange}
                  name="form-field-name"
                  getOptionLabel={({name}) => name}
                 getOptionValue={({id}) => id}
                  style={{
                    marginTop: '5px',
                  }}
                  value={selectedgroups}
                  isMulti={true}
                  options={groups}
                />
                <p className={classes.helpStyle} style={{ marginTop: '0' }}>
                  {news_group}{' '}
                </p>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  className={classes.submit}
                >
                  {' '}
                  Spara
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={false} md={3} />
          </Grid>
        </form>
      </div>
    );
  }
}

AddNyhet.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  description: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  titlevalue: makeSelectTitle(),
  linkvalue: makeSelectLink(),
  descriptionvalue: makeSelectDescription(),
  pictures: makeSelectPictures(),
  categories: makeSelectCategoriesAll(),
  value: makeSelectValue(),
  selectedlocations: getSelectedLocations(),
  locations: makeSelectPlats(),
  groups: makeSelectGroups(),
  selectedgroups: getSelectedGroups(),
  error: getError(),
  loading: getLoading(),
  editorstate: getEditorState(),
  newsitem: getNewsItem(),
  helptexts: getHelp(),
});

function mapDispatchToProps(dispatch) {
  return {
    titleOnChange: evt => {
      dispatch(titleOnChange(evt.target.value));
    },
  
    hideSearch: evt => {
      dispatch(hideLeftDrawer());
    },
    linkOnChange: evt => {
      dispatch(linkOnChange(evt.target.value));
    },
    descriptionOnChange: evt => {
      dispatch(descriptionOnChange(evt.target.value));
    },
    onSubmitForm: evt => {
      dispatch(setLoading(true));
      evt.preventDefault();
      evt.stopPropagation();
      dispatch(submitAddNyhetForm());
    },
    clearForm: evt => {
      dispatch(descriptionOnChange(''));
      dispatch(linkOnChange(''));
      dispatch(titleOnChange(''));
    },
    handleChange: value => {
      dispatch(handleChange(value));
      // dispatch(loadNyheter());
      // dispatch(loadKultur());
    },
    setLoading: value => {
      dispatch(setLoading(value));
      // dispatch(loadNyheter());
      // dispatch(loadKultur());
    },
    loadNewsItem: id => {
      dispatch(loadNewsItem(id));
    },
    imageDrop: (event, picture) => {
      dispatch(imageDrop(_.last(picture)));
    },
    clearImage: () => {
      dispatch(clearImage());
    },
    restoreImage: () => {
      dispatch(restoreImage());
    },
    locationChange: value => {
      dispatch(changeLocation(value));
    },
    groupChange: value => {
      dispatch(changeGroup(value));
    },
    handleChangeFyrk: value => {
      dispatch(changeFyrk());
    },
    fyrkDateOnChange: value => {
      dispatch(changeFyrkDate(value.format('MM.DD.YY')));
    },
    onEditorStateChange: editorstate => {
      dispatch(changeEditorState(editorstate));
      if (editorstate) {
        dispatch(
          descriptionOnChange(
            draftToHtml(convertToRaw(editorstate.getCurrentContent())),
          ),
        );
      }
    },
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'AddNyhet', reducer });
const withSaga = injectSaga({ key: 'AddNyhet', saga });

export default compose(
  withReducer,
  withSaga,
  withCookies,
  withConnect,
  withStyles(styles),
)(AddNyhet);
