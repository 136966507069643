import React from 'react';
import ESF from '../../images/KF_logo_ESFbg.png';
import HAVKRAFT from '../../images/KF_logo_HAVKRAFTbg.png';
import LUCKAN from '../../images/KF_logo_LUCKANbg.png';
import KSF from '../../images/konstsamfundet.png';
import SKF from '../../images/KF_logo_SKFbg.png';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import cc from '../../images/licence.png';
import { withStyles } from '@material-ui/core/styles';
import messages from './messages';
import Section from './Section';
import Wrapper from './Wrapper';

const styles = theme => ({
  footer: {
    fontFamily: 'Barlow !important',
    fontWeight: '600 !important',
    color: '#000',
    textDecoration: 'none',
    backgroundColor: 'rgba(227,222,250,1)',
    "@media print": {
      display: "none !important",
    }
  },
  footerlogos: {
    width: '165px',
    paddingRight: '2.5em',
    marginTop: '1em'
  },
  KSF_logo: {
    width: '225px',
    paddingRight: '2.5em',
    marginTop: '1em'
  },
  link: {
    fontFamily: 'Barlow !important',
    fontWeight: '600 !important',
    color: '#000',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  nomarginSection: {
    margin: '0 auto',
    padding: theme.spacing(3),
    paddingBottom: '2em',
    maxWidth: '1600px',
    "@media print": {
      display: "none",
    }
  },
});
function Footer(props) {
  const { classes } = props;

  return (
    <Wrapper className={classes.footer}>
      <Section className={classes.nomarginSection}>
        <Grid container spacing={10}>
          <Grid item xs={12} md={4}>
            <p className={classes.footer}>© Luckans webbtjänster 2023</p>
            <p className={classes.footer}>
              Made with love by Luckan.{' '}
            </p>
          </Grid>
          <Grid item xs={12} md={4}>
            <p className={classes.footer}>
              <Link className={classes.link} to="/omoss">
                Om oss
              </Link>
            </p>
            <p className={classes.footer}>
              <Link className={classes.link} to="/policy">
                Integritetspolicy
              </Link>
            </p>
          </Grid>
          <Grid item xs={12} md={4}>
            <img src={cc} />
            <p className={classes.footer}>
              <a
                className={classes.link}
                target="_blank"
                href="https://creativecommons.org/licenses/by/4.0/legalcode.sv"
              >
                Evenemangsinformationen hör under Creative Commons Erkännande
                4.0 Internationell Licens
              </a>
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={10}>
        <Grid item xs={12} md={12}>
        <img src={SKF} className={classes.footerlogos}/>
        <img src={KSF} className={classes.KSF_logo}/>
        <img src={ESF} className={classes.footerlogos}/>
        <img src={HAVKRAFT} className={classes.footerlogos} />
        <img src={LUCKAN} className={classes.footerlogos}/>
        </Grid>
        </Grid>
      </Section>
    </Wrapper>
  );
}

export default withStyles(styles)(Footer);
