/**
 * Homepage selectors
 */

import { createSelector } from 'reselect';

const selectFyrk = state => state.get('fyrk');

const makeSelectListView = () =>
  createSelector(
    selectFyrk,
    FyrkState => FyrkState.get('listview'),
  );
const makeSelectMasonryView = () =>
  createSelector(
    selectFyrk,
    FyrkState => FyrkState.get('masonryview'),
  );

const getPage = () =>
  createSelector(
    selectFyrk,
    FyrkState => FyrkState.get('page'),
  );
const getPageSize = () =>
  createSelector(
    selectFyrk,
    FyrkState => FyrkState.get('pagesize'),
  );

const getMore = () =>
  createSelector(
    selectFyrk,
    FyrkState => FyrkState.get('more'),
  );

const getExpanded = () =>
    createSelector(
      selectFyrk,
      FyrkState => FyrkState.get('expanded'),
    );

const getSelectedPeriodType = () =>
    createSelector(
      selectFyrk,
      FyrkState => FyrkState.get('selectedperiodtype'),
    );

export {
  selectFyrk,
  getPage,
  getPageSize,
  makeSelectMasonryView,
  makeSelectListView,
  getMore,
  getExpanded,
  getSelectedPeriodType
};
