/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from "prop-types";

import { getHelp } from '../../containers/App/selectors';
import { withStyles } from '@material-ui/core/styles';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import _ from 'lodash';
import { any } from 'underscore';

const styles = {
  card: {
    marginTop: '1em',
  },
  tabRoot: {
    minWidth : '50px',
    textTransform: 'uppercase',
    fontWeight: '900',
    fontSize: '16px', // Some CSS
    fontFamily: ['Barlow'].join(','),
    color: '#333',
    '&:hover': {
      color: '#000',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#000',
      fontWeight: '900',
    },
    '&:focus': {
      color: '#000',
    },
  },
  tabSelected: {},

};


export class OmOssPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {tab: 0};
    this.handleChangeTab = this.handleChangeTab.bind(this);
  }


  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }
  handleChangeTab = function(evt,value) {
    this.tab  = value;
    this.setState(prevState => ( {tab: value}));
  }
  render() {
    const { helptexts ,classes} = this.props;

    let kulturhelp = '';
    if (helptexts) {
      const kulturtemptext = _.find(helptexts, { slug: 'omoss' });
      if (kulturtemptext) {
        kulturhelp = kulturtemptext.description;
      }
    }
    let fyrkhelp = '';
    if (helptexts) {
      const fyrktemptext = _.find(helptexts, { slug: 'fyrk_omoss' });
      if (fyrktemptext) {
        fyrkhelp = fyrktemptext.description;
      }
    }

    return (
      <div>
        <Helmet>
          <title>OM OSS</title>
          <meta name="Om oss" content="" />
        </Helmet>


        <Grid container spacing={10}>
          <Grid item xs={false} md={3} />
          <Grid item xs={12} md={8}>
          <Tabs
            value={ this.state.tab }
            onChange={
              this.handleChangeTab
            }
            indicatorColor="primary"
            textColor="primary"
            scrollable="true"
            scrollButtons="auto"
          >
            <Tab  label={<h3>Kulturforum</h3>} />
            <Tab label={<h3>Fyrk.fi</h3>} />

          </Tabs>
      

        {  this.state.tab == 0 && (
          <>
          <Grid item xs={12} md={8}>
            <div dangerouslySetInnerHTML={{ __html: kulturhelp }} />
          </Grid>
          </>
        )}
        {  this.state.tab == 1 && (
          <>
          <Grid item xs={12} md={8} >
          <div dangerouslySetInnerHTML={{ __html: fyrkhelp }} />
          </Grid>
          </>
        )}
          </Grid>
        </Grid>
      </div>
    );
  }
}



OmOssPage.propTypes = {
  helptexts: PropTypes.any,
  tab: PropTypes.any,
  handleChangeTab: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  helptexts: getHelp(),
});

const withConnect = connect(
  mapStateToProps,
);

export default compose(
  withConnect,
  withStyles(styles),
)(OmOssPage);
