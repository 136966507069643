/*
 *
 * AddEvenemang constants
 *
 */

export const DEFAULT_ACTION = 'app/AddEvenemang/DEFAULT_ACTION';
export const TITLE_CHANGEEN = 'app/AddEvenemang/TITLE_CHANGEEN';
export const TITLE_CHANGESV = 'app/AddEvenemang/TITLE_CHANGESV';
export const TITLE_CHANGEFI = 'app/AddEvenemang/TITLE_CHANGEFI';


export const DESCRIPTION_CHANGE_SV = 'app/AddEvenemang/DESCRIPTION_CHANGE_SV';
export const DESCRIPTION_CHANGE_FI = 'app/AddEvenemang/DESCRIPTION_CHANGE_FI';
export const DESCRIPTION_CHANGE_EN = 'app/AddEvenemang/DESCRIPTION_CHANGE_EN';
export const CHANGE_SENDING = 'app/AddEvenemang/CHANGE_SENDING';

export const LINK_CHANGE = 'app/AddEvenemang/LINK_CHANGE';
export const SUBMIT_AddEvenemangFORM =
  'app/AddEvenemang/SUBMIT_AddEvenemangFORM';
export const IMAGE_DROP = 'app/AddEvenemang/IMAGE_DROP';
export const CHANGE_CATEGORY = 'app/AddEvenemang/CHANGE_CATEGORY';
export const CHANGE_LOCATION = 'app/AddEvenemang/CHANGE_LOCATION';
export const TOGGLE_FREE = 'app/AddEvenemang/TOGGLE_FREE';
export const SET_EDITINDEX = 'app/AddEvenemang/SET_EDITINDEX';
export const LOAD_EVENT = 'app/AddEvenemang/LOAD_PROFILE';
export const EVENT_LOADED = 'app/AddEvenemang/EVENT_LOADED';

export const SET_ERROR = 'app/AddEvenemang/SET_ERROR';
export const SET_CUSTOM_OPEN= 'app/AddEvenemang/SET_CUSTOM_OPEN';

export const CLEAR_FORM = 'app/AddEvenemang/CLEAR_FORM';
export const STARTDATE_CHANGE = 'app/AddEvenemang/STARTDATE_CHANGE';
export const STARTHOUR_CHANGE = 'app/AddEvenemang/STARTHOUR_CHANGE';
export const ENDDATE_CHANGE = 'app/AddEvenemang/ENDDATE_CHANGE';
export const ENDHOUR_CHANGE = 'app/AddEvenemang/ENDHOUR_CHANGE';
export const CHANGE_PLACE = 'app/AddEvenemang/CHANGE_PLACE';
export const CHANGE_ADDRESS = 'app/AddEvenemang/CHANGE_ADDRESS';
export const CHANGE_CITY = 'app/AddEvenemang/CHANGE_CITY';
export const CHANGE_POSTAL = 'app/AddEvenemang/CHANGE_POSTAL';

export const SHORTDESCRIPTION_CHANGE_FI = 'app/AddEvenemang/SHORTDESCRIPTION_CHANGE_FI';
export const SHORTDESCRIPTION_CHANGE_EN = 'app/AddEvenemang/SHORTDESCRIPTION_CHANGE_EN';
export const SHORTDESCRIPTION_CHANGE_SV = 'app/AddEvenemang/SHORTDESCRIPTION_CHANGE_SV';

export const CHANGE_PRICE = 'app/AddEvenemang/CHANGE_PRICE';
export const CHANGE_PRICEDESCRIPTION = 'app/AddEvenemang/CHANGE_PRICEDESCRIPTION';

export const ADD_LINK = 'app/AddEvenemang/ADD_LINK';
export const REMOVE_LINK = 'app/AddEvenemang/REMOVE_LINK';
export const CHANGE_LINK = 'app/AddEvenemang/CHANGE_LINK';
export const CHANGE_LINKNAME = 'app/AddEvenemang/CHANGE_LINKNAME';
export const ADD_PROFILE = 'app/AddEvenemang/ADD_PROFILE';
export const REMOVE_PROFILE = 'app/AddEvenemang/REMOVE_PROFILE';
export const CHANGE_PROFILE = 'app/AddEvenemang/CHANGE_PROFILE';
export const CHANGE_PROFILENAME = 'app/AddEvenemang/CHANGE_PROFILENAME';
export const CLEAR_SUGGESTIONS = 'app/AddEvenemang/CLEAR_SUGGESTIONS';
export const SET_SUGGESTIONS = 'app/AddEvenemang/SET_SUGGESTIONS';
export const SEARCH_FIELD = 'app/AddEvenemang/SEARCH_FIELD';
export const CHANGE_EDITORSTATE_SV = 'app/AddEvenemang/CHANGE_EDITORSTATE_SV';
export const CHANGE_EDITORSTATE_EN = 'app/AddEvenemang/CHANGE_EDITORSTATE_EN';
export const CHANGE_EDITORSTATE_FI = 'app/AddEvenemang/CHANGE_EDITORSTATE_FI';

export const CHANGE_BUYLINK = 'app/AddEvenemang/CHANGE_BUYLINK';
export const CHANGE_TABVALUE = 'app/AddEvenemang/CHANGE_TABVALUE';
export const CHANGE_TABVALUESHORT = 'app/AddEvenemang/CHANGE_TABVALUESHORT';
export const CHANGE_TABVALUETITLE = 'app/AddEvenemang/CHANGE_TABVALUETITLE';
export const CHANGE_LANGUAGE= 'app/AddEvenemang/CHANGE_LANGUAGE';
export const CHANGE_RSVP= 'app/AddEvenemang/CHANGE_RSVP';
export const CHANGE_ORG= 'app/AddEvenemang/CHANGE_ORG';
export const CHANGE_REGION= 'app/AddEvenemang/CHANGE_REGION';


export const ADD_GROUP = 'app/AddEvenemang/ADD_GROUP';
export const REMOVE_GROUP = 'app/AddEvenemang/REMOVE_GROUP';
export const ADD_META = 'app/AddEvenemang/ADD_META';
export const REMOVE_META = 'app/AddEvenemang/REMOVE_META';
export const ADD_DATE = 'app/AddEvenemang/ADD_DATE';
export const REMOVE_DATE = 'app/AddEvenemang/REMOVE_DATE';
export const ADD_PRICE = 'app/AddEvenemang/ADD_PRICE';
export const REMOVE_PRICE = 'app/AddEvenemang/REMOVE_PRICE';
export const ADD_CATEGORY_EVENEMANG = 'app/AddEvenemang/ADD_CATEGORY_EVENEMANG';
export const REMOVE_CATEGORY_EVENEMANG = 'app/AddEvenemang/REMOVE_CATEGORY_EVENEMANG';
export const PROFILES_LOADED = 'app/AddEvenemang/PROFILES_LOADED';
export const CLEAR_IMAGE = 'app/AddEvenemang/CLEAR_IMAGE';
export const RESTORE_IMAGE = 'app/AddEvenemang/RESTORE_IMAGE';
