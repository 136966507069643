import { call, put, select, takeLatest } from 'redux-saga/effects';
import request from '../../utils/request';
import { makeSelectUserId } from '../../containers/App/selectors';
import { push } from 'connected-react-router';
import { LOAD_PROFILE, LOAD_EVENTS } from './constants';
import {
  profileLoaded,
  saveEvents,
  saveNews,
  saveFyrk,
  saveSimilar,
  saveEventsPast,
} from './actions';
import { API_ROOT, LE_ROOT } from '../../api-config';

/**
 * Github repos request/response handler
 */
export function* loadProfile(value) {
  yield put(profileLoaded(''));
  const profileId = value.id;
  let profileuser = 0;
  let userid = yield select(makeSelectUserId());
  if (!userid) {
    userid = localStorage.getItem('userid');
  }
  let requestURL = `${API_ROOT}posters/${value.id}`;

  if (value.id == 0) {
    requestURL = `${API_ROOT}posters/?profile=true&isfritid=false&user=${userid}`;
  }

  try {
    const profile = yield call(request, requestURL);

    if (value.id == 0) {
      if (profile.count == 0) {
        yield put(push('/profile/edit'));
      } else {
        profileuser = profile.results[0].user;
        yield put(profileLoaded(profile.results[0]));
      }
    } else {
      profileuser = profile.user;
      yield put(profileLoaded(profile));
    }

    let similarpar = '';
    let similarset = false;
    if (value.id == 0) {
      if (
        profile.results[0] &&
        profile.results[0].hasOwnProperty('postercategories')
      ) {
        similarpar = profile.results[0].postercategories
          .map(k => k.id)
          .join(',');
        similarset = true;
      }
    } else if (profile && profile.hasOwnProperty('postercategories')) {
      similarpar = profile.postercategories.map(k => k.id).join(',');
      similarset = true;
    }

    const requestSimilar = `${API_ROOT}posters/?profile=true&service=kulturforum.fi&isfritid=false&postercategories=${similarpar}`;
    const requestEvents = `${LE_ROOT}event/?data_source=kulturforum&include=location,keywords&start=today&page_size=9&sort=end_time&super_event=null&custom_data=origin_uid:${profileuser}`;
    const requestEventsPast = `${LE_ROOT}event/?data_source=kulturforum&include=location,keywords&end=today&page_size=9&sort=end_time&super_event=null&custom_data=origin_uid:${profileuser}`;
    const requestFyrk = `${API_ROOT}fyrk/?user=${profileuser}`;

    try {
      const events = yield call(request, requestEvents);
      yield put(saveEvents(events.data));
    } catch (err) {
      // yield put(nyheterLoadingError(err));
    }
    try {
      const eventspast = yield call(request, requestEventsPast);
      yield put(saveEventsPast(eventspast.data));
    } catch (err) {
      // yield put(nyheterLoadingError(err));
    }
    const requestNews = `${API_ROOT}news/?user=${profileuser}`;

    try {
      const news = yield call(request, requestNews);
      yield put(saveNews(news.results));
    } catch (err) {
      // yield put(nyheterLoadingError(err));
    }
    try {
      const fyrk = yield call(request, requestFyrk);
      yield put(saveFyrk(fyrk.results));
    } catch (err) {
      // yield put(nyheterLoadingError(err));
    }
    try {
      if (similarset) {
        const similar = yield call(request, requestSimilar);
        yield put(saveSimilar(similar.results));
      }
    } catch (err) {
      // yield put(nyheterLoadingError(err));
    }
  } catch (err) {
    console.log(err);
    // yield put(nyheterLoadingError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* githubData() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(LOAD_PROFILE, loadProfile);
}
