/**
 * RepoListItem
 *
 * Lists the name and the issue count of a repository
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';


import Avatar from '@material-ui/core/Avatar';
import RandomImage from '../../components/RandomImage';
import { deleteProfile } from '../App/actions';
import { likeEvent,dislikeEvent,loadKultur,addGroup,addCategory } from '../App/actions';

import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { compose } from 'redux';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@material-ui/core/IconButton';
import { API_ROOT } from '../../api-config';
import _ from 'lodash';
import Box from '@material-ui/core/Box';
import PopFavorite from '../../components/PopFavorite';

const styles = theme => ({
  card: {
    maxWidth: 345,
    minWidth: 345,
    height: `${100}%`,
  },
  likebutton: {
    color: '#F27A68',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  flexNone :{
     flex : 1,
     width: "18em",
     paddingRight: "2em"

  },
  categorylink: {
    cursor: 'pointer',
    color: '#f57d6c',
  },
  email: {
    marginLeft: '2em',
    marginTop: '2em',

  },
  emailfloat: {
    float:"right",
    paddingLeft : "2em",
    marginTop: "1em",
    width: "25%",
    fontSize: "0.5em !important"
  },
  namefloat: {
    float:"left",
    width: "50%",
    marginTop: "2em",
    marginLeft: "2em",
    fontSize: "0.5em !important"

  },
  rowitem: {
    [theme.breakpoints.down('xs')]: {
      display: `block`,
    },

    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {},
  },
  avataritem: {
    [theme.breakpoints.down('xs')]: {
      float: `left`,
      margin: '0 16px !important',
    },
    [theme.breakpoints.up('xs')]: {
      marginRight: '16px !important',
    },
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {},
  },
  '@media print': {
    avataritem: {
      display: 'none'
    },
    menulink: {
      display: 'none'
    },
    editbutton: {
      display: 'none'
    },
    deletebutton: {
      display: 'none'
    },
  },
});

export class KulturListItem extends React.PureComponent {
  UNSAFE_componentWillMount() {
    this.tempimage = RandomImage();
  }

  handleItemClick(id) {
    this.props.history.push(`/profile/${id}`);
  }

  handleItemClickEdit(evt, id) {
    evt.stopPropagation();
    this.props.history.push(`/profile/${id}/edit`);
  }

  render() {
    const item = this.props.item;
    const { posterimages, categories,regions,groups,contact, phone } = this.props.item;
    const { classes, userid, user,favoriter } = this.props;
    let {firstname, lastname, groupname } = this.props.item;
    if (firstname == null)  {
      firstname = '';
    }
    if (lastname == null)  {
      lastname = '';
    }
    if (groupname == null)  {
      lastname = '';
    }
    let headerimage = this.tempimage;
    let profileimage = this.tempimage;
    const headerimageapi = _.find(posterimages, { role: 'header' });
    const profileimageapi = _.find(posterimages, { role: 'profile' });
    if (headerimageapi) {
      headerimage = headerimageapi.thumbnail;
      headerimage = API_ROOT.replace(/\/$/, '') + headerimage;
      headerimage = headerimage.replace(/WxH/g, '1000x1000');
    }
    if (profileimageapi) {
      profileimage = profileimageapi.thumbnail;
      profileimage = API_ROOT.replace(/\/$/, '') + profileimage;
      profileimage = profileimage.replace(/WxH/g, '440x440');
    }
    let favoriteids = _.map(favoriter, 'urls');
    let favorite = false;



    let itempath = API_ROOT+"posters/"+item.id+"/"
    if(_.indexOf(favoriteids, itempath) != -1) {
      favorite = true;
    }
    let groupslist ='';
    if (groups && groups.length > 0) {
     groupslist = groups.map(list => {

        return <a key={list.id} id={list.id} className={classes.categorylink}    onClick={(evt) => {
          this.props.addGroup(evt,list.id, list.name)
        }}>#{list.name} </a>;

    });
  }
  let categorieslist = '';
  if (categories && categories.length > 0) {
     categorieslist = categories.map((list,index )=> {
      if (categories.length > 0 && index < 5) {
        return <a key={list.id} className={classes.categorylink}
        onClick={(evt) => {
          this.props.addCategory(evt,list.id, list.name)
        }}>#{list.name} </a>;
      }
    });
  }
  let regionlist = '';
  if (regions && regions.length > 0) {
     regionlist = regions.map(list => {

        return <span key={list.id}>{list.name} </span>;

    });
  }
    let editbutton = '';
    if (user) {
      if((item.user == userid || user.is_staff)) {
        editbutton = (
          <Button
            onClick={evt => {
          this.handleItemClickEdit(evt,item.id);
        }} variant="text" size="small" className={classes.editbutton}>
            <EditIcon className={classes.leftIcon} />
        </Button>);
      }
      }


      let deletebutton = '';
      if (user) {
        if((item.user == userid || user.is_staff)) {
          deletebutton = (
            <Button
              onClick={evt => {
            this.props.deleteNyhet(evt,item.id);
          }} variant="text" size="small" className={classes.deletebutton}>
            <DeleteIcon className={classes.leftIcon} />
          </Button>);
        }
      }
    let likebutton = '';
        if(favorite) {
          likebutton = (
            <IconButton  onClick={evt => {
              this.props.dislikeKultur(evt, item.id);
            }} className={classes.likebutton}><Favorite /></IconButton>
          );


        } else {
          likebutton = (
            <IconButton  onClick={evt => {
              this.props.likeKultur(evt, item.id);
            }} className={classes.likebutton}><PopFavorite /></IconButton>
          );

        }

    

    return (
      <ListItem className={classes.rowitem} onClick={() => { this.handleItemClick(item.id) }} key={item.id}  button divider={true}>
         <Box display="inline-flex" displayPrint="none">
         <ListItemAvatar>
          <Avatar  className={classes.avataritem} src={profileimage} />
          </ListItemAvatar>
        </Box>
        <Box display="none" displayPrint="inline-block"> <ListItemText key={item.id+"name"} className={classes.namefloat} primary={`${firstname} ${lastname} ${groupname}`} /> </Box>
        <Box display="inline-flex" displayPrint="none"> <ListItemText key={item.id+"name"} className={classes.flexNone} primary={`${firstname} ${lastname} ${groupname}`} /> </Box>
        <Box display="inline-flex" displayPrint="none"><ListItemText  key={item.id+"city"} className={classes.flexNone} primary={regionlist}  /> </Box>
        <Box display="inline-flex" displayPrint="none"><ListItemText  key={item.id+"keywords"} className={classes.flexNone} primary={categorieslist}  />   </Box>
        <Box className={classes.emailfloat} display="none" displayPrint="inline-block"> {contact}</Box>
        <Box className={classes.emailfloat} display="none" displayPrint="inline-block"> {phone}</Box>
    <Box display="inline-flex" displayPrint="none">
        <ListItemSecondaryAction >
        {likebutton}
        {deletebutton}
        {editbutton}
        </ListItemSecondaryAction>
        </Box>
      </ListItem>
    );
  }
}

KulturListItem.propTypes = {
  item: PropTypes.object,
};

export function mapDispatchToProps(dispatch) {
  return {
    deleteNyhet: (evt, id) => {
      dispatch(deleteProfile(id));
      evt.stopPropagation();
    },
    addCategory: (evt, id, value) => {
      evt.stopPropagation();
      dispatch(addCategory(id, value));
      dispatch(loadKultur());
    },
    addGroup: (evt, id, value) => {
      evt.stopPropagation();
      dispatch(addGroup(id));
      dispatch(loadKultur());
    },
    likeKultur: (evt, id) => {
      dispatch(likeEvent(id, 'PROFILE'));
      evt.stopPropagation();
    },
    dislikeKultur: (evt, id) => {
      dispatch(dislikeEvent(id, 'PROFILE'));
      evt.stopPropagation();
    },
  };
}
const withConnect = connect(
  null,
  mapDispatchToProps,
);

KulturListItem.propTypes = {
  item: PropTypes.object,
  classes: PropTypes.object,
};
export default compose(
  withConnect,
  withRouter,

  withStyles(styles),
)(KulturListItem);
