import { createSelector } from 'reselect';

const selectSingleEvenemangModal = state => state.get('evenemangsinglemodal');

const makeSelectEvenemang = () =>
  createSelector(selectSingleEvenemangModal, evenemangState =>
    evenemangState.get('evenemang'),
  );
  const makeSelectSubEvenemang = () =>
  createSelector(selectSingleEvenemangModal, evenemangState =>
    evenemangState.get('subevenemang'),
  );
  const getProfile = () =>
  createSelector(selectSingleEvenemangModal, evenemangState =>
    evenemangState.get('profile'),
  );
  const getOpen = () =>
  createSelector(selectSingleEvenemangModal, evenemangState =>
    evenemangState.get('open'),
  );
  const getTabValue = () =>
  createSelector(selectSingleEvenemangModal, evenemangState =>
    evenemangState.get('tabvalue'),
  );
  const getTabValueTitle = () =>
  createSelector(selectSingleEvenemangModal, evenemangState =>
    evenemangState.get('tabvaluetitle'),
  );
export { selectSingleEvenemangModal, makeSelectEvenemang,makeSelectSubEvenemang,getProfile,getOpen,getTabValue,getTabValueTitle };
