import { createSelector } from "reselect";
import { initialState } from "./reducer";

/**
 * Direct selector to the RegisterPage state domain
 */

const selectRegisterPageDomain = (state) =>
  state.get("RegisterPage", initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by RegisterPage
 */

const makeSelectRegisterPage = () =>
  createSelector(selectRegisterPageDomain, (loginState) => loginState.toJS());

const makeSelectLogin = () =>
  createSelector(selectRegisterPageDomain, (loginState) =>
    loginState.get("login")
  );
const getInfoPop = () =>
  createSelector(selectRegisterPageDomain, (loginState) =>
    loginState.get("infopop")
  );
const getError = () =>
  createSelector(selectRegisterPageDomain, (loginState) =>
    loginState.get("error")
  );
const getGroup = () =>
  createSelector(selectRegisterPageDomain, (loginState) =>
    loginState.get("newgroup")
  );
const getOrganisation = () =>
  createSelector(selectRegisterPageDomain, (loginState) =>
    loginState.get("organisation")
  );
const getFirstName = () =>
  createSelector(selectRegisterPageDomain, (loginState) =>
    loginState.get("firstname")
  );
const getLastName = () =>
  createSelector(selectRegisterPageDomain, (loginState) =>
    loginState.get("lastname")
  );
const getOpen = () =>
  createSelector(selectRegisterPageDomain, (loginState) =>
    loginState.get("open")
  );
const getNewOrganisation = () =>
  createSelector(selectRegisterPageDomain, (loginState) =>
    loginState.get("neworganisation")
  );
const getRegisterSuccess = () =>
  createSelector(selectRegisterPageDomain, (loginState) =>
    loginState.get("registersuccess")
  );
const makeSelectMessage = () =>
  createSelector(selectRegisterPageDomain, (loginState) =>
    loginState.get("message")
  );
const makeSelectLoading = () =>
  createSelector(selectRegisterPageDomain, (loginState) =>
    loginState.get("loading")
  );
const makeSelectPassword = () =>
  createSelector(selectRegisterPageDomain, (loginState) =>
    loginState.get("password")
  );
const makeSelectPasswordConfirm = () =>
  createSelector(selectRegisterPageDomain, (loginState) =>
    loginState.get("passwordconfirm")
  );
const getfyrkrequestvalue = () =>
  createSelector(selectRegisterPageDomain, (loginState) =>
    loginState.get("fyrkrequestvalue")
  );
  
export {
  getRegisterSuccess,
  getError,
  getGroup,
  getOrganisation,
  getNewOrganisation,
  makeSelectRegisterPage,
  makeSelectLogin,
  getInfoPop,
  makeSelectPassword,
  makeSelectMessage,
  makeSelectLoading,
  makeSelectPasswordConfirm,
  getFirstName,
  getLastName,
  getOpen,
  getfyrkrequestvalue,
};
