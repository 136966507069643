/*
 * HomeReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */
import { fromJS } from 'immutable';
import { API_ROOT } from '../../api-config';

import {
  CHANGE_EDITORSTATE,
  CHANGE_FYRK,
  SET_ERROR,
  CHANGE_DESCRIPTION,
  CHANGE_GROUPNAME,
  CHANGE_TITLE,
  CHANGE_FIRSTNAME,
  CHANGE_LASTNAME,
  CHANGE_CATEGORY,
  CHANGE_POSTERCATEGORY,
  CHANGE_LOCATION,
  CHANGE_GROUP,
  CHANGE_URLS,
  CHANGE_CONTACT,
  CHANGE_PHONE,
  CLEAR_IMAGE,
  CLEAR_PROFILE,
  SET_LOADING,
  ADD_KULTUR,
  REMOVE_KULTUR,
  CHANGE_FREQ,
  IMAGE_DROP,
  IMAGE_DROP_PROFILE,
  PROFILE_LOADED,
  EVENTS_LOADED,
  PROFILES_LOADED,
  CHANGE_PROFILES,
  CHANGE_FULLPROFILE,
  CLEAR_FORM,
} from './constants';
import _ from 'lodash';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';

// The initial state of the App
export const initialState = fromJS({
  profile: '',
  previousheaderimage: false,
  previousprofileimage: false,
  selectedlocations: false,
  selectedgroups: false,
  selectedprofiles: false,
  value: false,
  postercategories: false,
  firstname: '',
  loading : true,
  lastname: '',
  description: '',
  pictures: [],
  profilepictures: [],
  title: '',
  groupname: '',
  urls: '',
  contact: '',
  phone: '',
  editorstate: EditorState.createEmpty(),
  error: '',
  fyrk: false,
  events: false,
  kultur: [],
  //freq: 'NONE',
  fullprofile : false,
  profiles: false,
});

function profilesingleeditReducer(state = initialState, action) {
  switch (action.type) {

    case PROFILE_LOADED:
      const kulturoriginal = ['d', 's', 'l'];

     if(action.profile.related) {

        action.profile.related.map(item => {
          {
            item.visiblename = `${item.groupname} ${item.firstname} ${item.lastname}`;
          }
        });

      }
      const { posterimages } = action.profile;
      const headerimageapi = _.find(posterimages, { role: 'header' });
      const profileimageapi = _.find(posterimages, { role: 'profile' });

      let headerimage = false;
      if (headerimageapi) {
        headerimage = headerimageapi.thumbnail;
        headerimage = API_ROOT.replace(/\/$/, '') + headerimage;
        headerimage = headerimage.replace(/WxH/g, '1000x1000');
      }
      let profileimage = false;
      if (profileimageapi) {
        profileimage = profileimageapi.thumbnail;
        profileimage = API_ROOT.replace(/\/$/, '') + profileimage;
        profileimage = profileimage.replace(/WxH/g, '440x440');
      }
      let EditorNewstate = null;
      const blocksFromHTML = convertFromHTML(action.profile.description);
      if(blocksFromHTML.contentBlocks) {
        let neweditorstate = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
        EditorNewstate = EditorState.createWithContent(neweditorstate)
      }
      let link = "";
      if(action.profile.urls.length > 0) {
        link = action.profile.urls[0].url;
      }
      return state
        .set('value', action.profile.categories)
        .set('postercategories', action.profile.postercategories)
        .set('previousheaderimage', headerimage)
        .set('previousprofileimage', profileimage)
        .set('selectedlocations', action.profile.regions)
        .set('selectedgroups', action.profile.groups)
        .set('selectedprofiles', action.profile.related)
        .set('profile', action.profile)
        .set('lastname', action.profile.lastname)
        .set('firstname', action.profile.firstname)
        .set('title', action.profile.title)
        .set('groupname', action.profile.groupname)
        .set('urls', link)
        .set('contact', action.profile.contact)
        .set('phone', action.profile.phone)
        .set('description', action.profile.description)
        .set('fyrk', action.profile.fyrk)
        .set('editorstate', EditorNewstate);

    case EVENTS_LOADED:
      return state.set('events', action.events);
    case PROFILES_LOADED:
      return state.set('profiles', action.value);
    case CHANGE_CATEGORY:
      return state.set('value',  action.value);
    case CHANGE_POSTERCATEGORY:
      return state.set('postercategories', action.value);
    case CHANGE_LOCATION:
      return state.set('selectedlocations',  action.value);
    case CHANGE_EDITORSTATE:
      return state.set('editorstate', action.value);
    case ADD_KULTUR:
      const kulturcurrent = state.get('kultur');
      kulturcurrent.push(action.value);
      const testarray = [];
      kulturcurrent.map(value => testarray.push(value));
      return state.set('kultur', testarray);
    case REMOVE_KULTUR:
      const currentkultur = state.get('kultur');
      const kulturindex = currentkultur.indexOf(action.value);
      if (kulturindex > -1) {
        currentkultur.splice(kulturindex, 1);
      }
      const testarray2 = [];
      currentkultur.map(value => testarray2.push(value));
      return state.set('kultur', testarray2);
    case CHANGE_FREQ:
      return state.set('freq', action.value);
    case CLEAR_IMAGE:
      return state.set('previousheaderimage', false);
    case CLEAR_PROFILE:
        return state.set('previousprofileimage', false);  
    case IMAGE_DROP:
      return state.set('previousheaderimage', _.last(action.value));
    case CHANGE_DESCRIPTION:
      return state.set('description', action.value);
    case CHANGE_GROUPNAME:
      return state.set('groupname', action.value);
    case CHANGE_URLS:
      return state.set('urls', action.value);
    case CHANGE_CONTACT:
      return state.set('contact', action.value);
    case CHANGE_PHONE:
      return state.set('phone', action.value);
    case CHANGE_FYRK:
      if (state.get('fyrk')) {
        return state.set('fyrk', false);
      }
      return state.set('fyrk', true);
    case CHANGE_FULLPROFILE:
        if (state.get('fullprofile')) {
          return state.set('fullprofile', false);
        }
        return state.set('fullprofile', true);

    case CHANGE_TITLE:
      return state.set('title', action.value);
    case CHANGE_LASTNAME:
      return state.set('lastname', action.value);
    case CHANGE_FIRSTNAME:
      return state.set('firstname', action.value);
    case SET_ERROR:
      return state.set('error', action.value);
    case SET_LOADING:
      return state.set('loading', action.value);
    case IMAGE_DROP_PROFILE:
      return state.set('previousprofileimage', _.last(action.value));
    case CHANGE_GROUP:
      return state.set('selectedgroups', action.value);
    case CHANGE_PROFILES:
      return state.set('selectedprofiles', action.value);
    case CLEAR_FORM:
        return initialState;
    default:
      return state;
  }
}

export default profilesingleeditReducer;
