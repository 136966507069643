/**
 *
 * UppgifterPage
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import Divider from "@material-ui/core/Divider";
import injectSaga from "../../utils/injectSaga";
import injectReducer from "../../utils/injectReducer";
import Dashboard from "../../components/Dashboard";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radera from "../../images/radera.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { FormControlLabel, Checkbox } from "@mui/material";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/styles/withStyles";
import saga from "./saga";
import reducer from "./reducer";
import {
  hideLeftDrawer,
  removeUser,
  changeFirstName,
  changeLastName,
  changeUserName,
} from "../App/actions";

import {
  getGroups,
  makeSelectFirstName,
  makeSelectLastName,
  makeSelectUserName,
} from "../App/selectors";

import {
  loginOnChange,
  passwordOnChange,
  passwordConfirmOnChange,
  submitUppgifterForm,
  changeNewOrganisation,
  setOpen,
  setOpenDialog,
  fyrkrequestchange,
} from "./actions";

import {
  getRegisterSuccess,
  getPassword,
  makeSelectPasswordConfirm,
  makeSelectMessage,
  makeSelectLoading,
  getError,
  getOpen,
  getOpenDialog,
  getfyrkrequestvalue
} from "./selectors";


const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    background: "#F5F5F5",
    marginBottom: theme.spacing(2),
  },
  dialogcontent: {
    padding: theme.spacing(4),
    textAlign: "center",
  },
  form: {
    width: "100%",
  },
  selectitem: {
    zIndex: "100",
  },
  spinner: {
    marginLeft: "20px",
    width: "20px",
    height: "20px",
  },
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(10),
  },
  raderabutton: {
    color: "#FFA000",
    borderColor: "#FFA000",
    background: "#FFF5E5",
    border: "1px solid transparent",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginLeft: "1em",
  },
  griditem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: "center",
  },
});
const SpinnerAdornment = withStyles(styles)((props) => (
  <CircularProgress
    className={props.classes.spinner}
    size={20}
    style={{ color: "#fff" }}
  />
));

const AdornedButton = (props) => {
  const { children, loading, ...rest } = props;
  return (
    <Button {...rest}>
      {children}
      {loading && <SpinnerAdornment {...rest} />}
    </Button>
  );
};

export class UppgifterPage extends React.PureComponent {
  componentDidMount() {
    this.props.hideSearch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      firstname,
      lastname,
      logininputvalue,
      registersuccess,
      classes,
      loginvalue,
      passwordvalue,
      passwordconfirmvalue,
      message,
      loading,
      error,
      opendialog,
      open,
      fyrklover,
    } = this.props;
    const {
      username,
      email,
      password1,
      passwordconfirm,
      non_field_errors,
      new_password2,
    } = error;
    const errorProps = {
      error: true,
    };
    const emailerror = email || "";
    const usererror = username || "";
    const password2error = new_password2 || "";
    let sendmessage = "";

    return (
      <div className={classes.root}>
        <form
          onKeyPress={(event) => {
            if (event.which === 13 && event.target.type != "textarea") {
              event.preventDefault();
            }
          }}
          className={classes.form}
          onSubmit={this.props.onSubmit}
        >
          <Container maxWidth="md">
            <Grid container direction="row" justifyContent="center">
              <Dashboard tabvalue={0} />
            </Grid>
            <Grid container direction="row" justifyContent="flex-start">
              <Grid item xs={6} className={classes.griditem}>
                <TextField
                  id="firstname"
                  label="Förnamn"
                  variant="outlined"
                  //helperText={fr}
                  margin="normal"
                  FormHelperTextProps={errorProps}
                  onChange={this.props.changeFirstName}
                  value={firstname}
                  fullWidth
                  autoFocus
                  required
                  disabled={registersuccess}
                />
              </Grid>
              <Grid item xs={6} className={classes.griditem}>
                <TextField
                  id="lastname"
                  label="Efternamn"
                  variant="outlined"
                  //helperText={emailerror +" "+usererror}
                  margin="normal"
                  FormHelperTextProps={errorProps}
                  onChange={this.props.changeLastName}
                  value={lastname}
                  fullWidth
                  autoFocus
                  required
                  disabled={registersuccess}
                />
              </Grid>
              <Grid item xs={6} className={classes.griditem}>
                <TextField
                  id="username"
                  label="E-post"
                  variant="outlined"
                  helperText={emailerror}
                  margin="normal"
                  FormHelperTextProps={errorProps}
                  onChange={this.props.loginOnChange}
                  value={logininputvalue.toLowerCase()}
                  fullWidth
                  required
                  autoFocus
                  disabled={registersuccess}
                  inputProps={{
                    style: { textTransform: "lowercase" },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={4}
                className={classes.griditem}
                style={{ paddingTop: "2em" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      value={fyrklover}
                      onChange={this.props.fyrkrequestchange}
                    />
                  }
                  label="Jag är ombudsman/finansiär"
                  id="FYRK_request"
                />
              </Grid>
              <Grid item xs={12} className={classes.griditem}>
                <Divider />
              </Grid>
              <Grid item xs={12} className={classes.griditem}>
                <Typography style={{ textAlign: "center" }} variant="h4">
                  Byt lösenord
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.griditem}>
                <TextField
                  id="password"
                  label="Nya lösenord"
                  variant="outlined"
                  helperText={password1}
                  margin="normal"
                  FormHelperTextProps={errorProps}
                  onChange={this.props.passwordOnChange}
                  fullWidth
                  value={passwordvalue}
                  //   required
                  autoFocus
                  type="password"
                  disabled={registersuccess}
                />
              </Grid>
              <Grid item xs={6} className={classes.griditem}>
                <TextField
                  id="passwordconfirm"
                  variant="outlined"
                  label="Skriv nya lösenord igen"
                  helperText={new_password2}
                  margin="normal"
                  FormHelperTextProps={errorProps}
                  onChange={this.props.passwordConfirmOnChange}
                  value={passwordconfirmvalue}
                  fullWidth
                  //   required
                  autoFocus
                  type="password"
                  disabled={registersuccess}
                />
              </Grid>
              <Grid item xs={12} className={classes.griditem}>
                <Divider />
              </Grid>
            </Grid>

            <Grid container direction="row" justifyContent="center">
              <Grid item xs={6} className={classes.griditem}>
                <Button
                  onClick={(evt) => {
                    this.props.openDialog();
                  }}
                  className={classes.raderabutton}
                  variant="text"
                  size="small"
                >
                  {" "}
                  <img src={Radera} />
                  Radera
                </Button>
              </Grid>
              <Grid item xs={6} className={classes.griditem}>
                <AdornedButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={loading}
                  disabled={registersuccess}
                >
                  REDIGERA
                </AdornedButton>

                {message}
              </Grid>
            </Grid>
          </Container>
        </form>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText className={classes.dialogcontent}>
              Ändringar sparade
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.props.closeDialog} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={opendialog}
          onClose={this.props.closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Radera? "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Vill du verkligen ta bort användaren?
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.props.closeDialog} color="primary">
              NEJ!
            </Button>
            <Button
              onClick={(evt) => {
                this.props.removeUser();
              }}
              variant="text"
              size="small"
              className={classes.deletebutton}
            >
              JA!
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

UppgifterPage.propTypes = {
  login: PropTypes.string,
  password: PropTypes.string,
  message: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  logininputvalue: makeSelectUserName(),
  passwordvalue: getPassword(),
  passwordconfirmvalue: makeSelectPasswordConfirm(),
  error: getError(),
  message: makeSelectMessage(),
  loading: makeSelectLoading(),
  registersuccess: getRegisterSuccess(),
  usergroups: getGroups(),
  lastname: makeSelectLastName(),
  firstname: makeSelectFirstName(),
  open: getOpen(),
  loginvalue: makeSelectUserName(),
  opendialog: getOpenDialog(),
  fyrklover: getfyrkrequestvalue(),
});

function mapDispatchToProps(dispatch) {
  return {
    loginOnChange: (evt) => {
      dispatch(changeUserName(evt.target.value));
    },
    passwordOnChange: (evt) => {
      dispatch(passwordOnChange(evt.target.value));
    },
    openDialog: (evt) => {
      dispatch(setOpenDialog(true));
    },
    removeUser: (evt) => {
      dispatch(removeUser());
    },
    closeDialog: (evt) => {
      dispatch(setOpen(false));
      dispatch(setOpenDialog(false));
    },
    passwordConfirmOnChange: (evt) => {
      dispatch(passwordConfirmOnChange(evt.target.value));
    },
    hideSearch: (evt) => {
      dispatch(hideLeftDrawer());
    },
    changeFirstName: (evt) => {
      dispatch(changeFirstName(evt.target.value));
    },
    changeLastName: (evt) => {
      dispatch(changeLastName(evt.target.value));
    },
    changeNewOrganisation: (evt) => {
      dispatch(changeNewOrganisation(evt.target.value));
    },
    fyrkrequestchange: (evt) => {
      dispatch(fyrkrequestchange(evt.target.value));
    },
    onSubmit: (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      dispatch(submitUppgifterForm());
    },
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: "UppgifterPage", reducer });
const withSaga = injectSaga({ key: "UppgifterPage", saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withStyles(styles)
)(UppgifterPage);
