/*
 *
 * LoginPage actions
 *
 */

import { DEFAULT_ACTION } from './constants';
import { LOGIN_CHANGE } from './constants';
import { PASSWORD_CHANGE } from './constants';
import { SUBMITLOGIN } from './constants';
import { AUTH_FAILURE } from './constants';
import { SET_LOADING, CONFIRM_TOKEN } from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}
export function loginOnChange(login) {
  return {
    type: LOGIN_CHANGE,
    value: login,
  };
}
export function submitLoginForm() {
  return {
    type: SUBMITLOGIN,
  };
}
export function passwordOnChange(password) {
  return {
    type: PASSWORD_CHANGE,
    value: password,
  };
}
export function authFailure(message) {
  return {
    type: AUTH_FAILURE,
    value: message,
  };
}
/* export function authSuccess(token) {
  return {
    type: AUTH_SUCCESS,
    value: token,
  };
}*/
export function setLoading(value) {
  return {
    type: SET_LOADING,
    value,
  };
}
export function confirmToken(value) {
  return {
    type: CONFIRM_TOKEN,
    value,
  };
}
