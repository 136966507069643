/*
 *
 * UppgifterPage constants
 *
 */

export const DEFAULT_ACTION = 'app/UppgifterPage/DEFAULT_ACTION';
export const LOGIN_CHANGE = 'app/UppgifterPage/LOGIN_CHANGE';
export const PASSWORD_CHANGE = 'app/UppgifterPage/PASSWORD_CHANGE';
export const FYRKREQUESTCHANGE = 'app/UppgifterPage/FYRKREQUESTCHANGE';
export const PASSWORDCONFIRM_CHANGE = 'app/UppgifterPage/PASSWORDCONFIRM_CHANGE';
export const SUBMITREGISTER = 'app/UppgifterPage/SUBMITREGISTER';
export const AUTH_FAILURE = 'app/UppgifterPage/AUTH_FAILURE';
export const REGISTER_SUCCESS = 'app/UppgifterPage/REGISTER_SUCCESS';
export const SET_LOADING = 'app/UppgifterPage/SET_LOADING';
export const SET_ERROR = 'app/UppgifterPage/SET_ERROR';
export const SET_GROUP = 'app/UppgifterPage/SET_GROUP';
export const SET_OPEN = 'app/UppgifterPage/SET_OPEN';
export const SET_OPENDIALOG = 'app/UppgifterPage/SET_OPENDIALOG';


export const CHANGE_ORGANISATION = 'app/UppgifterPage/CHANGE_ORGANISATION';


export const CHANGE_NEWORGANISATION = 'app/UppgifterPage/CHANGE_NEWORGANISATION';
export const CHANGE_FIRSTNAME = 'app/UppgifterPage/CHANGE_FIRSTNAME';
export const CHANGE_LASTNAME = 'app/UppgifterPage/CHANGE_LASTNAME';


