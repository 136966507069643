/**
 * Gets the repositories of the user from Github
 */
import { takeLatest } from 'redux-saga/effects';
import { call, put, select } from 'redux-saga/effects';
import { API_ROOT } from '../../api-config';
import { SUBMITREGISTER } from './constants';
import { setLoading, setError, registerSuccess } from './actions';
import {
  makeSelectLogin,
  makeSelectPassword,
  makeSelectPasswordConfirm,
  selectUid,
  selectHash,
} from './selectors';

/**
 * Github repos request/response handler
 */
const fetchJSON = (url, options = {}) =>
  new Promise((resolve, reject) =>
    fetch(url, options)
      .then(
        response =>
          response.status !== 201 &&
          response.status !== 200 &&
          response.status !== 400
            ? reject(response)
            : response,
      )
      .then(response => {
        if (response.status === 400) {
          response.json().then(error => reject(error));
        } else {
          return resolve(response.json());
        }
        return '';
      })
      .catch(error => reject(error)),
  );
export function* registerRequest() {
  yield put(setLoading(true));
  const uid = yield select(selectUid());
  const hash = yield select(selectHash());
  const password = yield select(makeSelectPassword());
  const passwordconfirm = yield select(makeSelectPasswordConfirm());

  const payload = {
    uid: uid,
    token: hash,
    new_password1: password,
    new_password2: passwordconfirm,
  };

  try {
    yield call(fetchJSON, `${API_ROOT}rest-auth/password/reset/confirm/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    // success
    yield put(setError({}));
    yield put(registerSuccess());
  } catch (error) {
    yield put(setError(error));
    yield put(setLoading(false));
  }
}

export default function* registerWatcher() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(SUBMITREGISTER, registerRequest);
}
