/*
 * HomeReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */
import { fromJS } from 'immutable';

import { CHANGE_USERNAME } from './constants';
import {
  CHANGE_PAGE,
  CHANGE_MORE,
  CHANGE_PAGESIZE,
  TO_LIST,
  TO_MASONRY,
  SET_EXPANDED,
  SET_PERIODTYPE
} from './constants';

const initialState = fromJS({
  username: '',
  masonryview: false,
  listview: true,
  pagesize: 20,
  page: 1,
  more : false,
  expanded: false,
  selectedperiodtype : ""
});

function fyrkReducer(state = initialState, action) {

  switch (action.type) {
    case CHANGE_PAGE:
      return state.set('page', action.value);
    case CHANGE_PAGESIZE:
      return state.set('pagesize', action.value);
    case CHANGE_MORE:
      return state.set('more', action.value);
    case SET_EXPANDED:
        return state.set('expanded', action.value);
    case SET_PERIODTYPE:
        return state.set('selectedperiodtype', action.value);
    case CHANGE_USERNAME:
      return state.set('username', action.name.replace(/@/gi, ''));
    case TO_LIST:
      return state.set('listview', true).set('masonryview', false);
    case TO_MASONRY:
      return state.set('masonryview', true).set('listview', false);
    default:
      return state;
  }
}
export default fyrkReducer;
