/*
 * HomeReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */
import { fromJS } from 'immutable';

import { PROFILE_LOADED, EVENTS_LOADED, NEWS_LOADED,FYRK_LOADED, EVENTS_LOADED_PAST, SIMILAR_LOADED } from './constants';

// The initial state of the App
const initialState = fromJS({
  profile: '',
  events: false,
  eventspast : false,
  news:false,
  fyrk: false,
  similar:false
});

function profileSingleReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE_LOADED:
      return state.set('profile', action.profile);
    case EVENTS_LOADED:
      return state.set('events', action.events);
    case EVENTS_LOADED_PAST:
      return state.set('eventspast', action.events);
    case NEWS_LOADED:
      return state.set('news', action.news);
    case FYRK_LOADED:
      return state.set('fyrk', action.fyrk);
    case SIMILAR_LOADED:
        return state.set('similar', action.similar);
    default:
      return state;
  }
}

export default profileSingleReducer;
