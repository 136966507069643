/**
 * RepoListItem
 *
 * Lists the name and the issue count of a repository
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import moment from 'moment/min/moment-with-locales';
import 'moment/locale/sv';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import RandomImage from '../../components/RandomImage';
import { compose } from 'redux';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { connect } from 'react-redux';
import _ from 'lodash';
import { API_ROOT, LE_ROOT } from '../../api-config';

import {
  addCategoryEvenemang,
  loadEvenemang,
  handleChangeEvenemang,
  likeEvent,
  dislikeEvent,
  deleteEvent,
} from '../App/actions';
import PopFavorite from '../../components/PopFavorite';

const styles = theme => ({
  iconcolor: {
    marginRight: '0.5em',
    color: '#666',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  title: {
    textAlign: 'left',
    paddingLeft: '1em',
  },
  flexNone: {
    flex: '1',
  },
  categorylink: {
    cursor: 'pointer',
    color: '#f57d6c',
  },
  likebutton: {
    color: '#F27A68',
  },

  rowitem: {
    [theme.breakpoints.down('xs')]: {
      display: `block`,
    },

    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {},
  },
  avataritem: {
    [theme.breakpoints.down('xs')]: {
      float: `left`,
      margin: '0 16px !important',
    },
    [theme.breakpoints.up('xs')]: {
      marginRight: '16px !important',
    },
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {},
  },
  '@media print': {
    avataritem: {
      display: 'none'
    },
    menulink: {
      display: 'none'
    },
    editbutton: {
      display: 'none'
    },
    deletebutton: {
      display: 'none'
    },
  },
});

export class EvenemangListItem extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  UNSAFE_componentWillMount() {
    this.tempimage = RandomImage();
  }

  handleItemClick(id) {
    this.props.history.push(`/evenemang/${id}`);
  }

  handleItemClickEdit(evt, id) {
    evt.stopPropagation();
    this.props.history.push(`/evenemang/${id}/edit`);
  }

  render() {
    const { item } = this.props;

    const {
      name,
      description,
      short_description,
      start_time,
      end_time,
      location,
      id,
      keywords,
      images,
      custom_data,
      data_source,
    } = this.props.item;
    const { user, userid,favoriter } = this.props;


    let favorite = false;
    let favoriteids = _.map(favoriter, 'urls');
    




    let itempath = LE_ROOT+"event/"+item.id+"/"
    if(_.indexOf(favoriteids, itempath) != -1) {
      favorite = true;
    }



    let origin_uid = null;
    if (custom_data && custom_data.hasOwnProperty('origin_uid')) {
      origin_uid = custom_data.origin_uid;
    }
    let fi = ' ';
    let namefi = ' ';
    let short_descriptionfi = ' ';
    if (
      description &&
      description.hasOwnProperty('sv') &&
      description.fi != undefined
    ) {
      fi = description.sv;
      fi = fi.substr(0, 250);
    }
    if (name && name.hasOwnProperty('sv') && name.sv != undefined) {
      namefi = name.sv;
    } else if (name && name.hasOwnProperty('fi') && name.fi != undefined) {
      namefi = name.fi;
    } else if (name && name.hasOwnProperty('en') && name.en != undefined) {
      namefi = name.en;
    }

    if (
      short_description &&
      short_description.hasOwnProperty('sv') &&
      short_description.sv != undefined
    ) {
      fi = short_description.sv.substr(0, 250);
    } else if (
      short_description &&
      short_description.hasOwnProperty('fi') &&
      short_description.fi != undefined
    ) {
      fi = short_description.fi.substr(0, 250);
    } else if (
      short_description &&
      short_description.hasOwnProperty('en') &&
      short_description.en != undefined
    ) {
      fi = short_description.en.substr(0, 250);
    }
    let eventimage = this.tempimage;

    if (images.length > 0) {
      eventimage = images[0].url;
      eventimage = eventimage.replace(/http:/g, 'https:');
    }
    if (
      short_description &&
      short_description.hasOwnProperty('sv') &&
      short_description.sv != undefined
    ) {
      short_descriptionfi = short_descriptionfi.fi;
      short_descriptionfi = fi.substr(0, 250);
    }
    const { classes } = this.props;

    let parsedStart = '';
    let parsedEnd = '';

    if (start_time) {
      parsedStart = start_time;
    } else {
      parsedEnd = '';
    }
    if (end_time) {
      parsedEnd = end_time;
    } else {
      parsedEnd = '';
    }
    let address_sv = '';
    let locale_sv = '';
    let locationname_sv = '';

    if (item.hasOwnProperty('location') && location) {
      address_sv =
        location.street_address && 'sv' in location.street_address
          ? location.street_address.sv
          : '';
      locationname_sv =
        location.name && 'sv' in location.name ? location.name.sv : '';
      locale_sv =
        location.address_locality && 'sv' in location.address_locality
          ? location.address_locality.sv
          : '';
    } else {
      address_sv = '';
      locale_sv = '';
    }
    moment.locale('sv');

    const keywordlist = keywords.map((list, index) => {
      if (keywords.length > 0 && index < 5) {
        const listname = list.name && 'fi' in list.name ? list.name.fi : '';

        return (
          <a
            className={classes.categorylink}
            onClick={evt => {
              this.props.addCategory(evt, list.id, listname);
            }}
            key={list.id}
          >
            #{listname}{' '}
          </a>
        );
      }
    });
    let editbutton = '';
    if (user && origin_uid && data_source == 'kulturforum') {
      if (origin_uid == userid || user.is_staff) {
        editbutton = (
          <Button
            onClick={evt => {
              this.handleItemClickEdit(evt, item.id);
            }}
            variant="text"
            size="small"
            className={classes.editbutton}
          >
            <EditIcon className={classes.leftIcon} />
          </Button>
        );
      }
    }

    let deletebutton = '';
    if (user && origin_uid && data_source == 'kulturforum') {
      if (origin_uid == userid || user.is_staff) {
        deletebutton = (
          <Button
            onClick={evt => {
              this.props.deleteEvent(evt, item.id);
            }}
            variant="text"
            size="small"
            className={classes.deletebutton}
          >
            <DeleteIcon className={classes.leftIcon} />
          </Button>
        );
      }
    }
    let likebutton = '';
      if (favorite) {
        likebutton = (
          <IconButton
            onClick={evt => {
              this.props.dislikeEvent(evt, item.id);
            }}
            className={classes.likebutton}
          >
            <Favorite />
          </IconButton>
        );
      } else {
        likebutton = (
          <IconButton
            onClick={evt => {
              this.props.likeEvent(evt, item.id);
            }}
            className={classes.likebutton}
          >
            <PopFavorite />
          </IconButton>
        );
      }
    

    // If the repository is owned by a different person than we got the data for
    // it's a fork and we should show the name of the owner

    // Put together the content of the repository
    const ListItemTest = fi => (
      <div>
        <span dangerouslySetInnerHTML={{ __html: fi }} />
      </div>
    );
    let timevalue = '';
    const start = moment(parsedStart);
    const end = moment(parsedEnd);

    if (start.isValid()) {
      timevalue += start.format('dddd D, MMMM');
    }
    if (end.isValid()) {
      timevalue += ` - ${end.format('dddd D, MMMM')}`;
    }

    // Render the content into a list item
    return (
      <ListItem
        key={item.id}
        className={classes.rowitem}
        button
        divider
        onClick={() => {
          this.handleItemClick(id);
        }}
      >
        <Avatar
          className={classes.avataritem}
          alt="Event Image"
          src={eventimage}
        />
        <ListItemText className={classes.flexNone} primary={namefi} />
        <ListItemText className={classes.flexNone} primary={timevalue} />
        <ListItemText className={classes.flexNone} primary={locationname_sv} />
        <ListItemText className={classes.flexNone} primary={keywordlist} />
        {likebutton}
        {deletebutton}
        {editbutton}
      </ListItem>
    );
  }
}
export function mapDispatchToProps(dispatch) {
  return {
    deleteEvent: (evt, id) => {
      dispatch(deleteEvent(id));
      evt.stopPropagation();
    },
    likeEvent: (evt, id) => {
      dispatch(likeEvent(id, 'EVENT'));
      evt.stopPropagation();
    },
    dislikeEvent: (evt, id) => {
      dispatch(dislikeEvent(id, 'EVENT'));
      evt.stopPropagation();
    },
    addCategory: (evt, id, name) => {
      dispatch(addCategoryEvenemang(id, name));
      dispatch(loadEvenemang());
      evt.stopPropagation();
    },
  };
}
const withConnect = connect(
  null,
  mapDispatchToProps,
);
EvenemangListItem.propTypes = {
  item: PropTypes.object,
  classes: PropTypes.object,
};
export default compose(
  withConnect,
  withRouter,
  withStyles(styles),
)(EvenemangListItem);
