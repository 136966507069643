/**
 * Gets the repositories of the user from Github
 */
import { takeLatest } from 'redux-saga/effects';
import { call, put, select,delay } from 'redux-saga/effects';
import { API_ROOT } from '../../api-config';
import { SUBMITREGISTER } from './constants';
import { setLoading, setError, registerSuccess,setOpen } from './actions';
import {
  getGroup,
  getOrganisation,
  getNewOrganisation
} from './selectors';
import {
  makeSelectUserId,
} from '../../containers/App/selectors';

/**
 * Github repos request/response handler
 */
const fetchJSON = (url, options = {}) =>
  new Promise((resolve, reject) =>
    fetch(url, options)
      .then(
        response =>
          response.status !== 201 &&
          response.status !== 200 &&
          response.status !== 400
            ? reject(response)
            : response,
      )
      .then(response => {
        if (response.status === 400) {
          response.json().then(error => reject(error));
        } else {
          return resolve(response.json());
        }
        return '';
      })
      .catch(error => reject(error)),
  );
export function* registerRequest() {
  yield put(setLoading(true));


  const selectedorganisation = yield select(getOrganisation());
  const userid = yield select(makeSelectUserId());
  const token = localStorage.getItem('token');


  try {
    yield call(fetchJSON, `${API_ROOT}usergroup/apply/${selectedorganisation.id}/${userid}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Token '+token
      }
    });
    // success
    yield put(setError({}));
    yield put(setOpen(true));


  } catch (error) {
    yield put(setError(error));
    yield put(setLoading(false));

  }


}

export default function* registerWatcher() {
  yield takeLatest(SUBMITREGISTER, registerRequest);
}
