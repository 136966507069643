/*
 *
 * ResetPage actions
 *
 */

import {
  PASSWORD_CHANGE,
  PASSWORDCONFIRM_CHANGE,
  SUBMITREGISTER,
  AUTH_FAILURE,
  SET_LOADING,
  REGISTER_SUCCESS,
  SET_ERROR,
  LOGIN_CHANGE,
  DEFAULT_ACTION,
} from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}
export function loginOnChange(login) {
  return {
    type: LOGIN_CHANGE,
    value: login,
  };
}
export function registerSuccess() {
  return {
    type: REGISTER_SUCCESS,
  };
}
export function setError(value) {
  return {
    type: SET_ERROR,
    value,
  };
}
export function submitLoginForm() {
  return {
    type: SUBMITREGISTER,
  };
}
export function passwordOnChange(password) {
  return {
    type: PASSWORD_CHANGE,
    value: password,
  };
}
export function passwordConfirmOnChange(password) {
  return {
    type: PASSWORDCONFIRM_CHANGE,
    value: password,
  };
}
export function authFailure(message) {
  return {
    type: AUTH_FAILURE,
    value: message,
  };
}
export function setLoading(value) {
  return {
    type: SET_LOADING,
    value,
  };
}
