import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectKultur } from '../../containers/App/selectors';
import _ from 'lodash';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { addGroup, addCategory } from '../App/actions';
import 'moment/locale/sv';
import { loadKultur } from '../App/actions';

const styles = {
  card: {
    marginTop: '1em',
  },
  cardcontent: {
    marginLeft: '3em',
    marginRight: '3em',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  iconcolor: {
    marginRight: '0.5em',
    color: '#666',
  },
  title: {
    textAlign: 'left',
    paddingLeft: '1em',
  },
};

export class KulturSingle extends React.PureComponent {
  componentDidMount() {
    const singleid = this.props.match.params.id;
    const kulturcollection = this.props.kultur;
    const single = _.find(kulturcollection, o => o.id == singleid);
  }

 

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  handleBack() {
    this.props.history.push('/');
  }

  render() {
    const singleid = this.props.match.params.id;
    const kulturcollection = this.props.kultur;
    const single = _.find(kulturcollection, o => o.id == singleid);
    const item = single;

    const { title, description, categories, groups } = item;

    const { classes } = this.props;

    const groupslist = groups.map(list => {
      if (groups.length > 0) {
        return (
          <a
            key={list.id}
            id={list.id}
            className={classes.categorylink}
            onClick={evt => {
              this.props.addGroup(evt, list.id, list.name);
            }}
          >
            #{list.name}{' '}
          </a>
        );
      }
    });
    const categorieslist = categories.map(list => {
      if (categories.length > 0) {
        return (
          <a
            key={list.id}
            className={classes.categorylink}
            onClick={evt => {
              this.props.addCategory(evt, list.id, list.name);
            }}
          >
            #{list.name}{' '}
          </a>
        );
      }
    });
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography gutterBottom variant="headline" component="h2">
            {title}
          </Typography>
          <p>
            {categorieslist} {groupslist}
          </p>
          <p dangerouslySetInnerHTML={{ __html: description.substr(0, 250) }} />
        </CardContent>
        <CardActions />
      </Card>
    );
  }
}

KulturSingle.propTypes = {
  kultur: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
};

const mapStateToProps = createStructuredSelector({
  kultur: makeSelectKultur(),
});
export function mapDispatchToProps(dispatch) {
  return {
    addCategory: (evt, id, value) => {
      evt.stopPropagation();
      dispatch(addCategory(id, value));
      dispatch(loadKultur());
    },
    addGroup: (evt, id, value) => {
      evt.stopPropagation();
      dispatch(addGroup(id));
      dispatch(loadKultur());
    },
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withRouter,
  withStyles(styles),
)(KulturSingle);
