import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the ResetPage state domain
 */

const selectResetPageDomain = state =>
  state.get('ResetPage', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by ResetPage
 */

const makeSelectResetPage = () =>
  createSelector(selectResetPageDomain, loginState => loginState.toJS());

const makeSelectLogin = () =>
  createSelector(selectResetPageDomain, loginState =>
    loginState.get('login'),
  );
const getError = () =>
  createSelector(selectResetPageDomain, loginState =>
    loginState.get('error'),
  );
const getRegisterSuccess = () =>
  createSelector(selectResetPageDomain, loginState =>
    loginState.get('registersuccess'),
  );
const makeSelectMessage = () =>
  createSelector(selectResetPageDomain, loginState =>
    loginState.get('message'),
  );
const makeSelectLoading = () =>
  createSelector(selectResetPageDomain, loginState =>
    loginState.get('loading'),
  );
const makeSelectPassword = () =>
  createSelector(selectResetPageDomain, loginState =>
    loginState.get('password'),
  );
const makeSelectPasswordConfirm = () =>
  createSelector(selectResetPageDomain, loginState =>
    loginState.get('passwordconfirm'),
  );
export {
  getRegisterSuccess,
  getError,
  makeSelectResetPage,
  makeSelectLogin,
  makeSelectPassword,
  makeSelectMessage,
  makeSelectLoading,
  makeSelectPasswordConfirm,
};
