/**
 * Gets the repositories of the user from Github
 */
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { push } from 'connected-react-router';
import { API_ROOT } from '../../api-config';

import {
  SUBMITLOGIN,
  CONFIRM_TOKEN,
} from './constants';

import { authFailure, setLoading, confirmToken } from './actions';
import { setUser, hasProfile, setProfile } from '../App/actions';
import { makeSelectLogin, makeSelectPassword } from './selectors';
import { loadFavorites } from '../FavoritePage/actions';


/**
 * Github repos request/response handler
 */
const fetchJSON = (url, options = {}) =>
  new Promise((resolve, reject) =>
    fetch(url, options)
      .then(
        response =>
          response.status !== 201 &&
          response.status !== 200 &&
          response.status !== 400
            ? reject(response)
            : response,
      )
      .then(response => {
        if (response.status === 400) {
          response.json().then(error => reject(error));
        } else {
          return resolve(response.json());
        }
        return '';
      })
      .catch(error => reject(error)),
  );
export function* loginRequest() {
  yield put(setLoading(true));
  const login = yield select(makeSelectLogin());
  const password = yield select(makeSelectPassword());

  const payload = { username: login, password, email: login };
  let formBody = [];
  for (const property in payload) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(payload[property]);
    formBody.push(`${encodedKey}=${encodedValue}`);
  }
  formBody = formBody.join('&');

  try {
    const res = yield call(fetchJSON, `${API_ROOT}rest-auth/login/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formBody,
    });

    localStorage.setItem('token', res.key);
    localStorage.setItem('userid', res.user.id);
    localStorage.removeItem('cachefavorites');

    let usergroup = 0;
    if(res.user && res.user.groups.length > 0) {
      usergroup = res.user.groups[0].id;
      localStorage.setItem("usergroupid", usergroup);

    }  

    let fyrk = false;
    if(res.user.ext) {
      if(res.user.ext.fyrk) {
        fyrk = res.user.ext.fyrk;
      }
    }
    
    res.user.ext = {};
    res.user.ext.fyrk = fyrk; 

    yield put(loadFavorites())  

    yield put(setUser(res.user));

    const profile = res.user.poster;

    if (profile.length == 0) {
      yield put(hasProfile(false));
      yield put(setProfile(profile[0]));
    } else{
      yield put(hasProfile(true));
      yield put(setProfile(profile[0]));
    }

    yield put(setLoading(false));

    if(usergroup) {
      yield put(push('/groups'));
    } else {
      yield put(push('/group'));
    }


  } catch (error) {
       const errorarray = [];
    Object.keys(error).map((key, index) => {
    errorarray["error_"+key] = error[key]
      });
    yield put(authFailure(errorarray));

    localStorage.removeItem('token');
    yield put(setLoading(false));
  }
}
export function* tokenRequest() {
  //alert('confirm token');
}

export default function* loginWatcher() {
  yield takeLatest(SUBMITLOGIN, loginRequest);
  yield takeLatest(CONFIRM_TOKEN, tokenRequest);
}
