/*
 *
 * GroupPage constants
 *
 */

export const DEFAULT_ACTION = 'app/GroupPage/DEFAULT_ACTION';
export const LOGIN_CHANGE = 'app/GroupPage/LOGIN_CHANGE';
export const PASSWORD_CHANGE = 'app/GroupPage/PASSWORD_CHANGE';
export const PASSWORDCONFIRM_CHANGE = 'app/GroupPage/PASSWORDCONFIRM_CHANGE';
export const SUBMITREGISTER = 'app/GroupPage/SUBMITREGISTER';
export const AUTH_FAILURE = 'app/GroupPage/AUTH_FAILURE';
export const REGISTER_SUCCESS = 'app/GroupPage/REGISTER_SUCCESS';
export const SET_LOADING = 'app/GroupPage/SET_LOADING';
export const SET_ERROR = 'app/GroupPage/SET_ERROR';
export const SET_GROUP = 'app/GroupPage/SET_GROUP';
export const SET_OPEN = 'app/GroupPage/SET_OPEN';


export const CHANGE_ORGANISATION = 'app/GroupPage/CHANGE_ORGANISATION';
export const CHANGE_FIRSTNAME= 'app/GroupPage/CHANGE_FIRSTNAME';
export const CHANGE_LASTNAME = 'app/GroupPage/CHANGE_LASTNAME';

export const CHANGE_NEWORGANISATION = 'app/GroupPage/CHANGE_NEWORGANISATION';

