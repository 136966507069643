/**
 * RepoListItem
 *
 * Lists the name and the issue count of a repository
 */
import React from 'react';

import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import { connect } from 'react-redux';
import { compose } from 'redux';
import CardActions from '@material-ui/core/CardActions';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { likeEvent ,dislikeEvent,addCategory,loadNyheter,addGroup} from '../App/actions';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

//import { withRouter } from 'connected-react-router';
import RandomImage from '../../components/RandomImage';
import { deleteNyhet } from '../App/actions';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { API_ROOT } from '../../api-config';
import PopFavorite from '../../components/PopFavorite';
// import saga from './saga'

const styles = {
  card: {
    // height: `${100}%`,
    margin: '1em',
    textAlign: 'left',
    backgroundColor: 'transparent',
    height: '534px',
  },
  griditem: {
    width: '33%',
  },
  categorylink: {
    cursor: 'pointer',
    color: '#9691BC',
  },
  iconcolor: {
    marginRight: '0.5em',
    color: '#666',
  },
  actions: {
    display: 'flex',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  title: {
    textAlign: 'left',
    paddingLeft: '0',
    marginBottom: 0,
    marginTop: 0,
    overflowWrap: 'break-word',
  },
  cardcontent: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  likebutton: {
    position: 'absolute',
    color: '#F27A68',
    top: '4%',
    right: '5%',
  },
};

export class NyheterListItemCard extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  UNSAFE_componentWillMount() {
    this.tempimage = RandomImage();
  }

  handleItemClick(id) {
    this.props.history.push(`/nyhet/${id}`);
  }

  handleItemClickEdit(evt, id) {
    evt.stopPropagation();
    this.props.history.push(`/nyhet/${id}/edit`);
  }

  render() {
    const {item} = this.props;
    const {
      title,
      description,
      newsimages,
      categories,
      groups,
    } = this.props.item;
    const { userid, user, favoriter } = this.props;
    const favoriteids = _.map(favoriter, 'urls');
    let favorite = false;
    const itempath = `${API_ROOT}news/${item.id}/`
    if (_.indexOf(favoriteids, itempath) != -1) {
      favorite = true;
    }
    const { classes } = this.props;
    let newsimage = this.tempimage;
    const clear_desc = description
      .replace(/<[^>]*>?/gm, ' ')
      .replace(/\s+/g, ' ');

    if (newsimages.length > 0) {
      newsimage = API_ROOT.replace(/\/$/, '') + newsimages[0].thumbnail;
      newsimage = newsimage.replace(/WxH/g, '440x440');
    }

    const categorieslist = categories.map((list, index) => {
      if (categories.length > 0 && index < 3) {
        return (
          <a
            key={list.id}
            className={classes.categorylink}
            onClick={evt => {
              this.props.addCategory(evt, list.id, list.name);
            }}
          >
            #{list.name}{' '}
          </a>
        );
      }
    });
    let editbutton = '';

    if (item.user == userid || user.is_staff) {
      editbutton = (
        <Button
          onClick={evt => {
            this.handleItemClickEdit(evt, item.id);
          }}
          variant="text"
          size="small"
          className={classes.editbutton}
        >
          <EditIcon className={classes.leftIcon} />

        </Button>
      );
    }
    let deletebutton = '';
    if (item.user == userid || user.is_staff) {
      deletebutton = (
        <Button
          onClick={evt => {
            this.props.deleteNyhet(evt, item.id);
          }}
          variant="text"
          size="small"
          className={classes.deletebutton}
        >
          <DeleteIcon className={classes.leftIcon} />
          
        </Button>
      );
    }

    const groupslist = groups.map(list => {
      if (groups.length > 0) {
        return (
          <a
            id={list.id}
            key={list.id}
            className={classes.categorylink}
            onClick={evt => {
            this.props.addGroup(evt,list.id, list.name)
          }}>#{list.name} </a>);
      }
    });
    let likebutton = '';
      if(favorite) {
        likebutton = (
          <IconButton onClick={evt => {
            this.props.dislikeNyhet(evt, item.id);
          }}
            className={classes.likebutton}
          >
            <Favorite />
          </IconButton>
        );
      } else {
        likebutton = (
          <IconButton onClick={evt => {
            this.props.likeNyhet(evt, item.id);
          }}
            className={classes.likebutton}
          >
            <PopFavorite />
          </IconButton>
        );
      }
    

    let actionsection = '';
    if (user) {
      if (item.user == userid || user.is_staff) {
        actionsection = (
          <CardActions className={classes.actions} >
            {deletebutton}
            {editbutton}
          </CardActions>
        );
      }
    }

    return (
      <Card className={classes.card} elevation={0} key={item.id}>
        <CardMedia
          onClick={evt => {
            this.handleItemClick(item.id);
          }}
          className={classes.media}
          image={newsimage}
        >
          {' '}
          {likebutton}
        </CardMedia>
        <CardContent
        className="cardcontent" classes={{ root: classes.cardcontent}}

          onClick={() => {
            this.handleItemClick(item.id);
          }}
        >
          <h5
            className={classes.title}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <p
            className="cardtext"
            dangerouslySetInnerHTML={{ __html: clear_desc.substr(0, 200) }}
          />

          <p>
            {' '}
            {categorieslist} {groupslist}
          </p>
        </CardContent>
        {actionsection}
      </Card>
    );
  }
}
export function mapDispatchToProps(dispatch) {
  return {
    deleteNyhet: (evt, id) => {
      dispatch(deleteNyhet(id));
      evt.stopPropagation();
    },
    likeNyhet: (evt, id) => {
      dispatch(likeEvent(id, 'NEWS'));
      evt.stopPropagation();
    },
    dislikeNyhet: (evt, id) => {
      dispatch(dislikeEvent(id, 'NEWS'));
      evt.stopPropagation();
    },
    addCategory: (evt, id, value) => {
      evt.stopPropagation();
      dispatch(addCategory(id, value));
      dispatch(loadNyheter());
    },
    addGroup: (evt, id, value) => {
      evt.stopPropagation();
      dispatch(addGroup(id));
      dispatch(loadNyheter());
    },
  };
}
const withConnect = connect(
  null,
  mapDispatchToProps,
);

NyheterListItemCard.propTypes = {
  item: PropTypes.object,
  classes: PropTypes.object,
};
export default compose(
  withConnect,
  withRouter,
  withStyles(styles),
)(NyheterListItemCard);
