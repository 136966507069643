/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import injectReducer from "../../utils/injectReducer";
import injectSaga from "../../utils/injectSaga";
import { addCategory, loadKultur, addGroup } from "../App/actions";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import classNames from "classnames";
import Container from "@material-ui/core/Container";
import { makeSelectLocale } from "../LanguageProvider/selectors";
import { Link } from "react-router-dom";
import Add from "@mui/icons-material/Add";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  makeSelectProfile,
  getCurrentEvent,
  getSimilar,
  getExpanded,
  getOpenDialog,
  getCurrentUser,
  getCurrentUserGroup,
  getEvents,
  getTab,
  getAnchorEl,
  getMenuOpen,
  getEvent,
  getOpenEvent,
  getFyrk
} from "./selectors";
import ProfileSingleCard from "../../containers/ProfileSingleCard";
import { withStyles } from "@material-ui/core/styles";

import RandomImage from "../../components/RandomImage";
import EvenemangList from "../../components/EvenemangList";
import FyrkList from "../../components/FyrkList";
import Dashboard from "../../components/Dashboard";

import {
  makeSelectUserId,
  makeSelectCurrentUser,
  getUserGroupId,
  selectLocation,
} from "../../containers/App/selectors";
import "moment/locale/sv";
import reducer from "./reducer";
import saga, { loadGroupFyrk } from "./saga";
import {
  removeUserGroup,
  setUSerGroup,
  setProfile,
  deleteEvent,
} from "../App/actions";
import EditIcon from "@mui/icons-material/Edit";
import {
  loaduserProfiles,
  setExpanded,
  setCurrentUser,
  openDialog,
  closeDialog,
  loadEvents,
  setTab,
  toggleMenu,
  closeEvent,
} from "./actions";
import Radera from "../../images/radera.svg";
import Redigera from "../../images/redigera.svg";

import EvenemangSingleModal from "../../containers/EvenemangSingleModal";

const styles = {
  card: {
    marginTop: "1em",
  },
  cardcontent: {
    marginLeft: "3em",
    marginRight: "3em",
  },
  menubutton: {
    color: "#3F51B5",
    borderColor: "#3F51B5",
    border: "1px solid transparent",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginLeft: "1em",
    backgroundColor: "#E8EAF6",
  },
  raderalink: {
    color: "#FFA000",
  },
  raderabutton: {
    color: "#FFA000",
    borderColor: "#FFA000",
    background: "#FFF5E5",
    border: "1px solid transparent",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginLeft: "1em",
  },
  media: {
    height: 0,
    paddingTop: "56.5%", // 16:9
  },
  iconcolor: {
    marginRight: "0.5em",
    color: "#666",
  },
  bigAvatar: {
    width: 150,
    height: 150,
    marginTop: 40,
  },
  avatar: {
    margin: 10,
  },
  tablecontainer: {
    width: "100%",
  },
  heading: {
    fontWeight: "600",
  },
  categorylink: {
    cursor: "pointer",
    color: "#000",
    textDecoration: "none",
    fontSize: "16px",
    lineHeight: "24px",
  },
  row: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "60px",
    lineHeight: "90px",
    letterSpacing: "-0.5px",
    margin: "0px",
  },
  place: {
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    /* identical to box height, or 150% */
    letterSpacing: "0.5px",
    /* Primary / 400 */
    color: "#5C6BC0",
    mixBlendMode: "normal",
  },
  accordiondetails: {
    display: "block",
  },
  aligncenter: {
    textAlign: "center",
  },
  grouptitle: {
    textAlign: "center",
    marginTop: "1em",
    marginBottom: "1em",
  },
  description: {
    borderTop: "2px #E5E5E5",
    borderBottom: "2px #E5E5E5",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0.15px",
  },
  subtitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "30px",
    margin: "0px !important",
  },
};

export class userProfiles extends React.PureComponent {
  UNSAFE_componentWillMount() {
    this.props.onSubmitForm(0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
    //this.props.loadEvents();
  }

  handleBack() {
    this.props.toggleMenu(null);

    this.props.history.push("/");
  }

  handleItemClickEdit(evt, id) {
    this.props.toggleMenu(null);

    evt.stopPropagation();
    this.props.history.push(`/profile/${id}/edit`);
  }

  handleItemClickEditEvent(evt, id) {
    this.props.toggleMenu(null);

    evt.stopPropagation();
    this.props.history.push(`/hobby/${id}/edit`);
  }

  render() {
    const {
      classes,
      usergroups,
      similar,
      userid,
      user,
      locale,
      tab,
      expanded,
      opendialog,
      currentuser,
      currentusergroup,
      anchorel,
      menuopen,
      selectedevent,
      openevent,
      selectedeventitem,
      routerlocation,
      usergroupid,
    } = this.props;

    let loading = true;
    let events = this.props.events;
    let fyrk = this.props.fyrk;

    if (events != false || fyrk != false) {
      loading = false;
    }

    if (events == false) {
      events = [];
    }

    const evenemangListProps = {
      loading: loading,
      error: false,
      evenemang: events,
      edit: false,
      listview: true,
      masonryview: false,
    };

    if (fyrk == false) {
      fyrk = [];
    }

    const fyrkListProps = {
      loading: loading,
      error: false,
      fyrk: fyrk,
      listview: true,
      masonryview: false,
      userid,
      user,
    };

    /*   const evenemangListProps = {
      loading,
      error,
      evenemang,
      listview,
      masonryview

    };*/

    if (usergroups != false) {
      return (
        <Container maxWidth="md">
          <Grid
            container
            direction="row"
            justifyContent="center"
            style={{ paddingBottom: "24px" }}
          >
            <Dashboard tabvalue={1} />
          </Grid>
          <Grid container direction="row" justifyContent="center" spacing={10}>
            <Grid item xs={12} md={12} className={classes.aligncenter}>
              <Button
                className={classes.menubutton}
                variant="text"
                size="small"
                component={Link}
                to="/group"
              >
                {" "}
                <Add />
                NY ORGANISATION
              </Button>
            </Grid>
          </Grid>
          {usergroups.map((usergroup, i) => {
            let profilecard = null;
            let profileselect = false;
            if (usergroup.poster[0]) {
              profileselect = usergroup.poster[0];
            }
            if (usergroup.poster[0]) {
              profilecard = (
                <div key={"div" + usergroup.id + i}>
                  <Typography className={classes.grouptitle} variant="h4">
                    Organisationskort{" "}
                    <Button
                      onClick={(evt) => {
                        this.handleItemClickEdit(evt, usergroup.poster[0].id);
                      }}
                      size="small"
                      className={classes.menubutton}
                    >
                      <img src={Redigera} />
                      Redigera
                    </Button>
                  </Typography>
                  <ProfileSingleCard
                    locale={locale}
                    eventlist={false}
                    key={"ProfileSingleCard" + i}
                    profile={usergroup.poster[0]}
                  />
                </div>
              );
            }
            let users = usergroup.users;
            return (
              <Grid key={"grid" + usergroup.id + i} container spacing={10}>
                <Grid item xs={12} md={12}>
                  <Accordion
                    expanded={expanded === usergroup.id}
                    key={usergroup.id}
                    onChange={(evt) => {
                      this.props.handleChangeAccordion(evt, usergroup);
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <Typography>Organisation</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography className={classes.heading}>
                            {usergroup.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordiondetails}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        style={{ paddingBottom: "24px" }}
                      >
                        <Tabs
                          value={tab}
                          onChange={this.props.handleChangeTab}
                          indicatorColor="primary"
                          textColor="primary"
                          scrollable="true"
                          scrollButtons="auto"
                        >
                          <Tab label="Organisationskort" />
                          <Tab label="Användare" />
                          <Tab label="Evenemang" />
                          <Tab label="Fyrkposter" />
                        </Tabs>
                      </Grid>
                      {tab === 1 && (
                        <TableContainer className={classes.tableContainer}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Namn</TableCell>
                                <TableCell align="right">E-post</TableCell>
                                <TableCell align="right"></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {users.map((user, i) => {
                                return (
                                  <TableRow key={user.username}>
                                    <TableCell component="th" scope="row">
                                      {user.first_name} {user.last_name}
                                    </TableCell>
                                    <TableCell align="right">
                                      {user.username}
                                    </TableCell>
                                    <TableCell align="right">
                                      {user.id == userid && (
                                        <Button
                                          className={classes.menubutton}
                                          variant="text"
                                          size="small"
                                          component={Link}
                                          to="/uppgifter"
                                        >
                                          <img src={Redigera} />
                                          Redigera
                                        </Button>
                                      )}
                                      <Button
                                        className={classes.raderabutton}
                                        variant="text"
                                        size="small"
                                        onClick={(evt) => {
                                          this.props.setCurrentUser(
                                            evt,
                                            user.id,
                                            usergroup.id
                                          );
                                        }}
                                      >
                                        {" "}
                                        <img src={Radera} />
                                        Radera
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                      {tab === 0 && <div>{profilecard}</div>}
                      {tab === 2 && (
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          spacing={10}
                        >
                          <Grid
                            item
                            xs={12}
                            md={12}
                            className={classes.aligncenter}
                          >
                            <Button
                              className={classes.menubutton}
                              variant="text"
                              size="small"
                              component={Link}
                              to={`/evenemang/${usergroup.id}/add`}
                            >
                              {" "}
                              <Add />
                              NYTT EVENEMANG
                            </Button>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <EvenemangList
                              usergroup={usergroup.id}
                              {...evenemangListProps}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {tab === 3 && (
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          spacing={10}
                        >
                          <Grid
                            item
                            xs={12}
                            md={12}
                            className={classes.aligncenter}
                          >
                            <Button
                              className={classes.menubutton}
                              variant="text"
                              size="small"
                              component={Link}
                              to={`/fyrkpost/${usergroup.id}/add`}
                            >
                              {" "}
                              <Add />
                              NY FYRKPOST
                            </Button>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <FyrkList
                              usergroup={usergroup.id}
                              {...fyrkListProps}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            );
          })}
          {anchorel && (
            <Menu
              id="hobby-menu"
              open={menuopen}
              anchorEl={anchorel}
              className={classes.actionmenu}
              onClose={(evt) => {
                this.props.toggleMenu(null);
              }}
            >
              <MenuItem
                onClick={(evt) => {
                  this.handleItemClickEditEvent(evt, selectedevent);
                }}
              >
                <EditIcon /> Redigera
              </MenuItem>
              <MenuItem
                onClick={(evt) => {
                  this.props.deleteEvent(evt, selectedevent, usergroupid);
                }}
                // component={Link}
                className={classes.raderalink}
              >
                <RemoveCircleIcon /> Radera
              </MenuItem>
            </Menu>
          )}
          <EvenemangSingleModal
            user={user}
            userid={userid}
            profile={false}
            evenemang={selectedeventitem}
            locale={locale}
            openevent={openevent}
            onClose={this.props.closeEvent}
          />
          <Dialog
            open={opendialog}
            onClose={this.props.closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Radera? "}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Vill du verkligen ta bort användaren?
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={this.props.closeDialog} color="primary">
                NEJ!
              </Button>
              <Button
                onClick={(evt) => {
                  this.props.removeUserGroup(
                    evt,
                    currentusergroup,
                    currentuser
                  );
                }}
                variant="text"
                size="small"
                className={classes.deletebutton}
              >
                JA!
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      );
    }

    return (
      <Grid container direction="row" justifyContent="center" spacing={10}>
        <Grid item xs={12} md={12} className={classes.aligncenter}>
          <Button
            className={classes.menubutton}
            variant="text"
            size="small"
            component={Link}
            to="/group"
          >
            {" "}
            <Add />
            NY ORGANISATION
          </Button>
        </Grid>
      </Grid>
    );
  }
}

userProfiles.propTypes = {
  evenemang: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  fyrk: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
};

const mapStateToProps = createStructuredSelector({
  usergroups: makeSelectProfile(),
  similar: getSimilar(),
  userid: makeSelectUserId(),
  user: makeSelectCurrentUser(),
  locale: makeSelectLocale(),
  expanded: getExpanded(),
  opendialog: getOpenDialog(),
  currentuser: getCurrentUser(),
  currentusergroup: getCurrentUserGroup(),
  usergroupid: getUserGroupId(),
  events: getEvents(),
  fyrk: getFyrk(),
  tab: getTab(),
  anchorel: getAnchorEl(),
  menuopen: getMenuOpen(),
  selectedevent: getCurrentEvent(),
  selectedeventitem: getEvent(),
  openevent: getOpenEvent(),
  routerlocation: selectLocation(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onSubmitForm: (singleid) => {
      dispatch(loaduserProfiles(singleid));
      // dispatch(loadEvents(singleid));
    },
    deleteEvent: (evt, id, usergroupid) => {
      dispatch(toggleMenu(null));
      dispatch(deleteEvent(id));
      dispatch(loadEvents(usergroupid));
      dispatch(loadGroupFyrk(usergroupid));
      evt.stopPropagation();
    },
    deleteProfile: (evt, id) => {
      evt.stopPropagation();
    },
    handleChangeAccordion: (evt, usergroup) => {
      dispatch(setUSerGroup(usergroup.id));
      dispatch(setExpanded(usergroup.id));
      dispatch(loadEvents(usergroup.id));
      dispatch(loadGroupFyrk(usergroup.id));
      if (usergroup.poster[0]) {
        dispatch(setProfile(usergroup.poster[0]));
      }
    },
    handleChangeTab: (evt, value) => {
      dispatch(setTab(value));
    },
    removeUserGroup: (evt, id, userid) => {
      dispatch(removeUserGroup(id, userid));
      dispatch(loaduserProfiles(0));
      dispatch(closeDialog());
      evt.stopPropagation();
    },
    openDialog: () => {
      dispatch(openDialog());
    },
    closeDialog: () => {
      dispatch(closeDialog());
    },
    loadEvents: () => {
      dispatch(loadEvents());
    },
    toggleMenu: (evt, id) => {
      dispatch(toggleMenu(id));
    },

    setCurrentUser: (evt, id, usergroup) => {
      dispatch(setCurrentUser(id, usergroup));
      dispatch(openDialog());
      evt.stopPropagation();
    },
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: "userProfiles", reducer });
const withSaga = injectSaga({ key: "userProfiles", saga });

export default compose(
  withReducer,
  withSaga,
  withRouter,
  withConnect,
  withStyles(styles)
)(userProfiles);
