/**
 * Gets the repositories of the user from Github
 */

import { takeLatest, takeEvery } from 'redux-saga/effects';
import { take, call, put, select, fork, cancel } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import _ from 'lodash';

import { SUBMIT_AddEvenemangFORM, LOAD_EVENT } from './constants';
// import { authFailure,setLoading } from './actions';
// import { setSessionId} from './../App/actions';
import {
  makeSelectUserId,
  getProfile,
  makeSelectCurrentUser,
  getRegions,
} from '../../containers/App/selectors';

import { push , browserHistory } from 'connected-react-router';
import { API_ROOT } from '../../api-config';
import { LE_ROOT } from '../../api-config';
import { clearForm, eventLoaded,profilesLoaded } from './actions';
import { setError,changeSending } from './actions';
import {
  makeSelectGroupsSelected,
  getEvent,
  getEditorStateSv,
  getEditorStateEn,
  getEditorStateFi,
  getProfiles,
  getLinks,
  getDates,
  getRSVP,
  getPrice,
  getPriceDescription,
  isFree,
  getPrices,
  makeSelectDescriptionSv,
  makeSelectShortDescriptionSv,
  makeSelectDescriptionFi,
  makeSelectShortDescriptionFi,
  makeSelectDescriptionEn,
  makeSelectShortDescriptionEn,
  makeSelectTitleFi,
  makeSelectTitleSv,
  makeSelectTitleEn,
  makeSelectLink,
  makeSelectPictures,
  makeSelectValue,
  makeSelectLocationValue,
  getBuyLink,
  getSelectedMainCategories,
  getLanguage,
  getCustomOpen,
  getOrg,
  getRegion
} from './selectors';
import {
  selectPlace,
  makeSelectMetasSelected,
  selectAddress,
  selectPostal,
  selectCity,
  selectStartTimeDate,
  selectStartTimeHour,
  selectEndTimeDate,
  selectEndTimeHour,
} from './selectors';

import {
  makeSelectLocationsEvenemang
} from '../App/selectors';




import request from '../../utils/request';
import moment from 'moment';
export function* getEventSingle(value) {
  yield put(clearForm());

  const requestEvenemangCategories =`${LE_ROOT}keyword_set/`;
  const evenemangCategories = yield call(request, requestEvenemangCategories);

  const eventcategories = evenemangCategories.data;
  const eventgroupdata = evenemangCategories.data;
  const eventlocationdata = evenemangCategories.data;

  const evenemangsection = _.find(eventcategories, {
    id: 'kulturforum:api.postercategory',
  });
  const groupssection = _.find(eventgroupdata, {
    id: 'kulturforum:api.postergroup',
  });
  const locationsection = _.find(eventlocationdata, {
    id: 'kulturforum:region',
  });

  const eventkeywords = evenemangsection.keywords;
  const eventgroups = groupssection.keywords;
  let eventlocations = locationsection.keywords;


  let tempeventkeywords = eventkeywords.map(item => decodeURI(item["@id"]).replace(/http:/g, 'https:').replace(LE_ROOT+"keyword/", "").replace(/\/$/, ""));
  let tempeventeventgroups = eventgroups.map(item => decodeURI(item["@id"]).replace(/http:/g, 'https:').replace(LE_ROOT+"keyword/", "").replace(/\/$/, ""));
  let tempeventeventlocations= eventlocations.map(item => decodeURI(item["@id"]).replace(/http:/g, 'https:').replace(LE_ROOT+"keyword/", "").replace(/\/$/, ""));

  if (value.id) {
    const {id} = value;
    const requestURL =
      LE_ROOT + 'event/' + `${id}` + '/?include=location,keywords,sub_events';
    try {
      const event = yield call(request, requestURL);
      if(event.id) {
        yield put(eventLoaded(event,tempeventkeywords,tempeventeventgroups,tempeventeventlocations));
      } else {
      }
    } catch (err) {
    }
  }
  const requestProfiles = `${API_ROOT}posters?profile=true&isfritid=false&service=kulturforum.fi&limit=999`;
  const kultur = yield call(request, requestProfiles);
  yield put(profilesLoaded(kultur.results));
}


const fetchJSON = (url, options = {}) =>
  new Promise((resolve, reject) =>
    fetch(url, options)
      .then(response =>
        response.status !== 201 &&
        response.status !== 204 &&
        response.status !== 200 &&
        response.status !== 400
          ? reject(response)
          : response,
      )
      .then(response => {
        if (response.status === 400) {
          response.json().then(error => reject(error));
        } else if (response.status === 204) {
          return resolve({});
        } else {
          return resolve(response.json());
        }
        return '';
      })
      .catch(error => reject(error)),
  );
const validateForm= (payload,locationpayload,categoriescount) => {
  let errorresult = false;
  let error = {};
  if(payload.name.sv == "") {
    errorresult = true;
    error.name = 'Titel saknas';
  } else {
    error.name = '';
  }
  if(!payload.description.sv  && payload.description.sv == "") {
    errorresult = true;
    error.description = 'Beskrivning saknas';
  } else {
    error.description = '';
  }
  // error.start_time = "Starttid saknas";
  if(!locationpayload.name.sv  && locationpayload.name.sv == "") {
    errorresult = true;
    error.lokalen = 'Lokalen saknas';
  } else {
    error.lokalen = '';
  }
  if(!locationpayload.street_address.sv  && locationpayload.street_address.sv == "") {
    errorresult = true;
    error.address = 'Address saknas';
  } else {
    error.address = '';
  }

  if(!locationpayload.address_locality.sv  && locationpayload.address_locality.sv == "") {
    errorresult = true;
    error.region = 'Region saknas';
  } else {
    error.region = '';
  }
  if(categoriescount == 0) {
    error.keywords = 'Kategorier saknas';
    errorresult = true;
  } else {
    error.keywords = '';
  }
  error.external_links = ""
  if(payload.external_links && payload.external_links.length > 0 ) {
    _.forEach(payload.external_links, function(value, key) {
      if (
        !(value.link.includes('https://') || value.link.includes('http://'))
      ) {
        error.external_links = 'http: required';
        errorresult = true;
      }
    });
  }
  if(errorresult) {
    throw error;
  }
};

export function* AddEvenemangRequest(testvalue) {
  const locationvalue = yield select(makeSelectLocationValue());

  const namefi = yield select(makeSelectTitleFi());
  const namesv = yield select(makeSelectTitleSv());
  const nameen = yield select(makeSelectTitleEn());
  const regions = yield select(getRegions());

  const descriptionsv = yield select(makeSelectDescriptionSv());
  const descriptionen = yield select(makeSelectDescriptionEn());
  const descriptionfi = yield select(makeSelectDescriptionFi());

  const shortdescriptionsv = yield select(makeSelectShortDescriptionSv());
  const shortdescriptionen = yield select(makeSelectShortDescriptionEn());
  const shortdescriptionfi = yield select(makeSelectShortDescriptionFi());
  let usergroupid = localStorage.getItem('usergroupid');

  const editorstatesv = yield select(getEditorStateSv());
  const editorstateen = yield select(getEditorStateEn());
  const editorstatefi = yield select(getEditorStateFi());
  const profile = yield select(getProfile());
  const provider = yield select(getOrg());

  const user = yield select(makeSelectCurrentUser());

  // let shortdescriptionsvplain = editorstatesv.getCurrentContent().getPlainText('');
  // let shortdescriptionenplain = editorstateen.getCurrentContent().getPlainText('');
  // let shortdescriptionfiplain = editorstatefi.getCurrentContent().getPlainText('');

  /* shortdescriptionsvplain = _.truncate(shortdescriptionsvplain, {
      length: 160,
      separator: ' ',
    });

    shortdescriptionenplain = _.truncate(shortdescriptionenplain, {
      length: 160,
      separator: ' ',
    });

    shortdescriptionfiplain = _.truncate(shortdescriptionfiplain, {
      length: 160,
      separator: ' ',
    }); */

  const prevevent = yield select(getEvent());
  const link = yield select(makeSelectLink());
  const images = yield select(makeSelectPictures());
  const userid = yield select(makeSelectUserId());
  const values = yield select(makeSelectValue());
  const language = yield select(getLanguage());
  const price = yield select(getPrice());
  const pricedescription = yield select(getPriceDescription());
  const links = yield select(getLinks());
  const pricesvalues = yield select(getPrices());
  const rsvp = yield select(getRSVP());
  const datesvalues = yield select(getDates());
  const custom_open = yield select(getCustomOpen());

  const dates = datesvalues.toJS();
  const subids = [];
  const prices = pricesvalues.toJS();
  let repeatingevent = false;
  if (dates.length > 1) {
    repeatingevent = true;
  }
  const starttimedate = dates[0].start;
  const endtimedate = dates[0].end;
  const linkarray = links.toJS();
  const profiles = yield select(getProfiles());
  const profilearray = profiles.toJS();

  const startdate = moment(starttimedate);
  const startdatestring = startdate.toISOString();

  let region = yield select(getRegion());

  const enddate = moment(endtimedate);
  const enddatestring = enddate.toISOString();
  const isfree = yield select(isFree());
  const buylink = yield select(getBuyLink());
  const groups = yield select(makeSelectGroupsSelected());
  const grouparray = groups.toJS();
  const place = yield select(selectPlace());
  const address = yield select(selectAddress());
  const postal = yield select(selectPostal());
  const city = yield select(selectCity());
  const metas = yield select(makeSelectMetasSelected());
  let maincategories = yield select(getSelectedMainCategories());
  let categoriescount = 0;
  let locationpayload = null;
  const payload = {
    data_source: 'kulturforum',
    publisher: 'kulturforum:',
    name: {
      sv: namesv,
      fi: namefi,
      en: nameen,
    },
  };
  payload.provider = {};
  if (provider != '') {
    payload.provider.sv = provider;
  } else if (profile.groupname != '' && !user.is_staff) {
    payload.provider.sv = profile.groupname;
  } else {
    payload.provider.sv = '';
  }

  let mappedregion = false;

  // check if region can be mapped from array and use that if found
  if (regions != false) {
    mappedregion = _.find(regions, { name: city });
  }
  if (mappedregion && mappedregion.region != '') {
    region = mappedregion.region;
  } else {
    region = city;
  }

  // conver editor to plaintext to filter out cases with empty paragraph tags
  const descriptionsvplain = editorstatesv.getCurrentContent().getPlainText('');
  const descriptionenplain = editorstateen.getCurrentContent().getPlainText('');
  const descriptionfiplain = editorstatefi.getCurrentContent().getPlainText('');

  payload.description = {
    "sv" : '',
    "fi" : '',
    "en" :  '',
  };
  payload.short_description = {
    sv: ' ',
    fi: ' ',
    en: ' ',
  };

  if (descriptionsvplain != '') {
    payload.description.sv = descriptionsv;
  }
  if (shortdescriptionsv != '') {
    payload.short_description.sv = shortdescriptionsv;
  }
  if (descriptionenplain != '') {
    payload.description.en = descriptionen;
  }
  if (shortdescriptionen != '') {
    payload.short_description.en = shortdescriptionen;
  }
  if (descriptionfiplain != '') {
    payload.description.fi = descriptionfi;
  }
  if (shortdescriptionfi != '') {
    payload.short_description.fi = shortdescriptionfi;
  }

  // let start = '';
  // let end = '';
  // let faux_end = '';

  if (repeatingevent) {
    payload.super_event_type = 'recurring';
    // needs tp fetch the first and last dates of the whole array
    let datesstart = _.map(dates, function(date) {
      return moment(date.start);
    });
    let datesend = _.map(dates, function(date) {
      return moment(date.end);
    });
     let start = _.min(datesstart);
     let end = _.max(datesend);
     let faux_end = _.max(datesstart);

    payload.start_time = start.toISOString();
    payload.end_time = end.toISOString();
    payload.sub_events = [];
  } else {
    payload.super_event_type = null;
    let start = moment(starttimedate);
    let end = moment(endtimedate);
    let faux_end = moment(endtimedate);
    payload.start_time = start.toISOString();
    if (enddatestring != '') {
      payload.end_time = end.toISOString();
    } else {
     payload.end_time = faux_end.toISOString(); 
  }
  }
  
  payload.keywords =[]
  /*
  if (values.length > 0) {
    const catObjData = values.map((data, idx) => ({ "@id": LE_ROOT+`keyword/${data}/`}));
    payload.keywords = catObjData;
  } else {
    payload.keywords =[];
  } */
  if (values.length) {
    categoriescount += values.length;
  }

  if (values.length > 0) {
    values.map(function(data, idx) {
      if (data != 'undefined') {
        payload.keywords.push({ '@id': LE_ROOT + `keyword/${data.id}/` });
      }
    });
  }

  if (maincategories.length) {
    categoriescount += maincategories.length;
  }

  if (maincategories.length > 0) {
    maincategories.map(function(data, idx) {
      if (data != 'undefined') {
        payload.keywords.push({ '@id': LE_ROOT + `keyword/${data.id}/` });
      }
    });
  }
  if (locationvalue) {
    payload.keywords.push({ "@id": `${LE_ROOT}keyword/${locationvalue.id}/`})

  }

  if (grouparray.length > 0) {
    grouparray.map((data, idx) =>
      payload.keywords.push({ '@id': LE_ROOT + `keyword/${data}/` }),
    );
  }
  const test = {};
  payload.custom_data = {};
  let origin_uid = userid;
  metas.map(value => (payload.custom_data[`accessibility_${value}`] = 'true'));

  if (
    prevevent.custom_data &&
    prevevent.custom_data.hasOwnProperty('origin_uid')
  ) {
    origin_uid = prevevent.custom_data.origin_uid;
  }

  if (
    prevevent.custom_data &&
    prevevent.custom_data.hasOwnProperty('profile_id')
  ) {
  payload.custom_data.profile_id = profile.id;
  }

  if (
    prevevent.custom_data &&
    prevevent.custom_data.hasOwnProperty('usergroup_id')
  ) {
    usergroupid = prevevent.custom_data.usergroup_id;
  }


  payload.custom_data.origin_uid = origin_uid;
  payload.custom_data.usergroup_id = usergroupid;
  payload.custom_data.profile_id = profile.id;
  payload.custom_data.language = language;

  if (custom_open != '') {
    payload.custom_data.custom_open = custom_open;
  }

  const linkObjData = [];
  if (linkarray.length > 0) {
    payload.external_links = {};
    linkarray.map(function(value) {
      if (value.name != '' && value.value != '') {
        linkObjData.push({
          "name": `social_${value.name}`,
          "link": value.value,
          "language" :"sv",
        } )
      }
    });
    payload.external_links = linkObjData;
  } else {
    payload.external_links = [];
  }
  // payload.external_links = [ ...payload.external_links, ...linkObjData ]

  if (profilearray.length > 0) {
    const profileObjData = profilearray.map(function(value) {
      if(value.profilelink == "" || value.profilelink == undefined) {
        return {
          "name": `profile_${value.name}`,
          "link": encodeURI(`http://remove.me/${value.link}`),
          "language" :"sv"
        }
      }
        return {
          "name": `profile_${value.name}`,
          "link": encodeURI(`https://kulturforum.fi/profile/${value.profilelink}`),
          "language" :"sv"
        }

    });
    payload.external_links = [...payload.external_links, ...profileObjData];
  }
  const pricearray = [];
  let priceObjData = [];
  if (prices.length > 0) {
    priceObjData = prices.map(function(value) {
      return {
        "is_free": false,
        "price": {
          "fi": value.price,
          "en":value.price,
          "sv": value.price,
        },
        "description": {
          "fi": value.pricedescription,
          "en": value.pricedescription,
          "sv": value.pricedescription,
        },
        "info_url": {
          "fi": encodeURI(value.buylink),
          "en": encodeURI(value.buylink),
          "sv": encodeURI(value.buylink),
        },
      }
    });
  } else {
  }

  //  if (locationvalue) {
  //    payload.location = {"@id": LE_ROOT+"place/"+locationvalue.id+"/"};
  //  } else {

  locationpayload = {
    data_source: 'kulturforum',
    origin_id: Date.now(),
    name: {
      fi: place,
      en: place,
      sv: place,
    },
    publisher: 'kulturforum:',
    street_address: {
      fi: address,
      sv: address,
      en: address,
    },
    address_locality: {
      fi: city,
      sv: city,
      en: city,
    },
    address_region: region,
    postal_code: postal,
  };
  //  }

  if (isfree) {
    payload.offers = [
      {
        is_free: true,
        price: {
          fi: '0',
          en: '0',
          sv: '0',
        },
        description: null,
        info_url: {
          fi: encodeURI(rsvp),
          en: encodeURI(rsvp),
          sv: encodeURI(rsvp),
        },
      },
    ];
  } else {
    payload.offers = priceObjData;
  }

  let image = false;
  const token = localStorage.getItem('token');
  if (images != false && images.length > 200) {
    image = images;

    function dataURLtoFile(dataurl) {
      let arr = dataurl.toString().split(','); var mime = arr[0].match(/:(.*?);/)[1];

        var bstr = atob(arr[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
      let filename = arr[0].split(';')[1].replace('name=', '');
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }

    const file = dataURLtoFile(image);
    const imagepayload = file;

    var formData = new FormData();
    formData.append('file', imagepayload);
  }

  let finalresponse = {};
  let resimage = null;
  let newimage = false;
  let thumbnail = '';

  try {
    validateForm(payload, locationpayload, categoriescount);

    if (image) {
      try {
        resimage = yield call(fetchJSON, API_ROOT + `eventimage/`, {
          method: 'POST',
          headers: {
              Accept: 'application/json',
            Authorization: `Token ${token}`,
            },
            body: formData,
        });
      } catch (error) {
        error.headerimage = error.detail[0];
        throw error;
      }

      thumbnail = API_ROOT.replace(/\/$/, '') + resimage.thumbnail;

      const eventimage = yield call(fetchJSON, LE_ROOT + 'image/', {
        credentials: 'omit',
        method: 'POST',
        headers: {
          Accept: 'application/json',
            'Content-Type' : 'application/json',
            'apikey': '60429c29-8acb-4585-91db-3dff0ee1b159',
        },
          body: JSON.stringify({"url" : thumbnail}),
        });

      const imageid = eventimage['@id'];
      payload.images = [{ '@id': imageid }];
      newimage = true;
    }
    try {
      const res1 = yield call(fetchJSON, LE_ROOT + 'place/', {
        credentials: 'omit',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          apikey: '60429c29-8acb-4585-91db-3dff0ee1b159',
        },
        body: JSON.stringify(locationpayload),
      });
      payload.location = {
        '@id': `${LE_ROOT}place/${  res1.id  }/`,
      };
    } catch (error) {
      throw error;
    }
    try {
      const eventid = prevevent.id || 0;
      let path = `${LE_ROOT}event/${eventid}/`;
      let method = 'PUT';

      if (!newimage && prevevent.images && prevevent.images.length > 0) {
        const previmageid = prevevent.images[0]['@id'];
        payload.images = [{ '@id': previmageid }];
      }
      if (eventid == 0) {
        path = `${LE_ROOT}event/`;
        method = 'POST';
      }
      finalresponse = yield call(fetchJSON, path, {
        credentials: 'omit',
        method,
        headers: {
        Accept: 'application/json',
          'Content-Type': 'application/json',
          apikey: '60429c29-8acb-4585-91db-3dff0ee1b159',
        },
        body: JSON.stringify(payload),
      });

      // loop through subevents
      if (repeatingevent) {
        for (let value in dates) {
          payload.start_time = dates[value].start;
          payload.end_time = dates[value].end;

          payload.super_event_type = null;
          payload.super_event = {
            '@id': `${LE_ROOT}event/${  finalresponse.id  }/`,
          };
          try {
            path = `${LE_ROOT}event/`;
            method = "POST";

            const subresponse = yield call(fetchJSON, path, {
              credentials: 'omit',
              method,
              headers: {
              Accept: 'application/json',
                'Content-Type': 'application/json',
                apikey: '60429c29-8acb-4585-91db-3dff0ee1b159',
              },
              body: JSON.stringify(payload),
            });
            //delete previous subevents
          } catch (error) {
            throw error;
          }
        }
      }
      if (prevevent.sub_events && prevevent.sub_events.length > 0) {
        const oldevents = prevevent.sub_events;
        for (let item in oldevents) {
          try {
            let result = yield call(
              fetchJSON,oldevents[item]["@id"].replace(/http:/g, 'https:'),
              {
                credentials: 'omit',
                method: 'DELETE',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  apikey: '60429c29-8acb-4585-91db-3dff0ee1b159',
                },
              },
            );
          } catch (error) {
            throw error;
          }
        }
      }
    } catch (error) {
      throw error;
    }

    yield put(clearForm());
    yield put(changeSending(false));

    yield put(push(`/evenemang/${finalresponse.id}`));
  } catch (error) {
    const errorarray = [];
    Object.keys(error).map((key, index) => {
      errorarray[`error_${key}`] = error[key]
    });
    yield put(setError(errorarray));
    yield put(changeSending(false));
  }
}

export default function* loginWatcher() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(LOAD_EVENT, getEventSingle);

  yield takeLatest(SUBMIT_AddEvenemangFORM, AddEvenemangRequest);
}
