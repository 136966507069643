import Img1 from '../../images/hero/image01.jpg';
import Img2 from '../../images/hero/image02.jpg';
import Img3 from '../../images/hero/image03.jpg';
import Img4 from '../../images/hero/image04.jpg';
import Img5 from '../../images/hero/image05.jpg';
import Img6 from '../../images/hero/image06.jpg';
import Img7 from '../../images/hero/image07.jpg';
import Img8 from '../../images/hero/image08.jpg';
import Img9 from '../../images/hero/image09.jpg';
import Img10 from '../../images/hero/image10.jpg';
import Img11 from '../../images/hero/image11.jpg';
import Img12 from '../../images/hero/image12.jpg';
import Img13 from '../../images/hero/image13.jpg';
import Img14 from '../../images/hero/image14.jpg';
import Img15 from '../../images/hero/image15.jpg';


function randomize(myArray) {
  return myArray[Math.floor(Math.random() * myArray.length)];
}
function RandomImageHome() {
  const arr = [
    Img1,
    Img2,
    Img3,
    Img4,
    Img5,
    Img6,
    Img7,
    Img8,
    Img9,
    Img10,
    Img11,
    Img12,
    Img13,
    Img14,
    Img15,

  ];
  return randomize(arr);
}

export default RandomImageHome;
