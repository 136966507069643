import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import List from '../../components/List';
import ListItem from '../../components/ListItem';
import LoadingIndicator from '../../components/LoadingIndicator';
import NyheterListItemCard from '../../containers/NyheterListItemCard';
import EvenemangListItemCard from '../../containers/EvenemangListItemCard';
import FyrkFavItem from '../../containers/FyrkFavItem';
import ProfileSingleCard from '../../containers/ProfileSingleCard';
import FyrkSingleCard from '../../containers/FyrkSingleCard';

import FyrkListItemCard from '../../containers/FyrkListItemCard';
import EvenemangSingleModal from '../../containers/EvenemangSingleModal';

const masonryOptions = {
  transitionDuration: 0,
};

const imagesLoadedOptions = { background: '.my-bg-image-el' };

const styles = theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'left',
  },
  control: {
    padding: theme.spacing(2),
  },
  griditem: {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },

    [theme.breakpoints.up('md')]: {
      width: '33%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '25%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '20%',
    },
    [theme.breakpoints.up('xxl')]: {
      width: '16.5%',
    },
  
  
  },
});
function FavoriteListPrint(props) {
  const { favoriter, favorites, user, userid, loader, error, filter,
    masonryview,
    listview } = props;
  const { classes } = props;
  if (loader) {
    return <List component={LoadingIndicator} />;
  }
  if (error !== false) {
    const ErrorComponent = () => (
      <ListItem item="Something went wrong, please try again!" />
    );
    return <List component={ErrorComponent} />;
  }

   return( <Grid
    container
    justifyContent="flex-start"
    className={classes.root}
    spacing={6}
  >
    {favorites.map((card, i) => {
      if (card.type == "EVENT" && card.id != undefined){
        return <Grid item xs={12} >
        <EvenemangListItemCard favoriter={favoriter} user={user} userid={userid} item={card} key={i} />
        </Grid>
      }
     if (card.type == "FYRK" && card.id != undefined){
        return <Grid item xs={12} >
        <FyrkSingleCard fyrk={card} favoriter={favoriter} user={user} userid={userid} item={card} key={i} />
        </Grid>
      }
        if (card.type == "PROFILE" && card.id != undefined) {
        return <Grid item xs={12} >
        <ProfileSingleCard favoriter={favoriter} user={user} userid={userid}           
        profile={card}
        item={card} key={`${card.uid}listitem`}  />
        </Grid>
      }
      if (card.type == "NEWS" && card.id != undefined){ 
        return <Grid item xs={12} >
        <NyheterListItemCard favoriter={favoriter} user={user} userid={userid} item={card} key={i} />
        </Grid>
      }
    }
    )}
     </Grid>
   );
  


}
FavoriteListPrint.propTypes = {
  loading: PropTypes.bool,
  Nyheter: PropTypes.any,
};

export default withStyles(styles)(FavoriteListPrint);
