/*
 * Home Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
  LOAD_PROFILE,
  PROFILE_LOADED,
  LOAD_EVENTS,
  EVENTS_LOADED,
  EVENTS_LOADED_PAST,
  NEWS_LOADED,
  FYRK_LOADED,
  SIMILAR_LOADED
} from './constants';

export function profileLoaded(profile) {
  return {
    type: PROFILE_LOADED,
    profile,
  };
}
export function saveEvents(events) {
  return {
    type: EVENTS_LOADED,
    events,
  };
}
export function saveEventsPast(events) {
  return {
    type: EVENTS_LOADED_PAST,
    events,
  };
}
export function saveNews(news) {
  return {
    type: NEWS_LOADED,
    news,
  };
}
export function saveFyrk(fyrk) {
  return {
    type: FYRK_LOADED,
    fyrk,
  };
}
export function saveSimilar(similar) {
  return {
    type: SIMILAR_LOADED,
    similar,
  };
}
export function loadEvents() {
  return {
    type: LOAD_EVENTS,
  };
}
export function loadProfileSingle(id) {
  return {
    type: LOAD_PROFILE,
    id,
  };
}
