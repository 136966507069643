/*
 * Home Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import { LOAD_FYRK, FYRK_LOADED, DELETE_FYRK } from './constants';

export function fyrkLoaded(fyrk) {
  return {
    type: FYRK_LOADED,
    fyrk,
  };
}
export function loadFyrkSingle(id) {
  return {
    type: LOAD_FYRK,
    id,
  };
}
export function deleteFyrk(id) {
  return {
    type: DELETE_FYRK,
    id,
  };
}
