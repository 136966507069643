import styled from 'styled-components';

const H2 = styled.h2`
  font-size: 1.938rem;
  line-height: 135.6%;
  font-weight: 700;
  margin-bottom: 1em;
`;

export default H2;
