/*
 * HomeReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */
import { fromJS } from 'immutable';

import { 
  PROFILE_LOADED, 
  EVENTS_LOADED,
  FYRK_LOADED,
  SOMMAR_LOADED, 
  NEWS_LOADED, 
  SIMILAR_LOADED,
  SET_MENUOPEN,SET_EVENT,
  SET_ANCHOREL,
  OPEN_DIALOG,
  CLOSE_DIALOG,
  SET_CURRENT_USER,
  CLOSE_EVENT,
  SET_EXPANDED,
  SET_TAB 
} from './constants';

// The initial state of the App
const initialState = fromJS({
  profile: false,
  events: false,
  fyrk: false,
  sommarevents: false,
  news:false,
  tab : 0,
  similar:false,
  expanded : 0,
  currentuser :false,
  currentusergroup: false,
  opendialog: false,
  anchorel: null,
  menuopen : false,
  selectedevent : 0,
  eventitem : false,
  openevent: false
});

function userProfilesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MENUOPEN:
      if(state.get("menuopen")) {
        return state.set('menuopen', false);
      }
      return state.set('menuopen', true).set('selectedevent',action.value);
    case SET_ANCHOREL:
      return state.set('anchorel', action.value);
    case SET_EVENT:
        return state.set('eventitem', action.value).set('openevent', true);
    case CLOSE_EVENT:
        return state.set('eventitem', false).set('openevent', false);
    case PROFILE_LOADED:
      return state.set('profile', action.profile);
    case EVENTS_LOADED:
      return state.set('events', action.event);
    case FYRK_LOADED:
      return state.set('fyrk', action.fyrk);
    case SOMMAR_LOADED:
      return state.set('sommarevents', action.event);
    case SET_EXPANDED:

      let currentexpanded =  state.get('expanded');
      if(currentexpanded == action.value)    {
        return state.set('expanded', 0);
      } 
      return state.set('expanded', action.value);
    case SET_TAB:
      return state.set('tab', action.value);
    case OPEN_DIALOG:
      return state.set('opendialog',true);
    case CLOSE_DIALOG:
      return state.set('opendialog',false).set('currentuser', false).set('currentusergroup', false);
    case NEWS_LOADED:
      return state.set('news', action.news);
    case SIMILAR_LOADED:
        return state.set('similar', action.similar);
    case SET_CURRENT_USER:
      return state.set('currentuser', action.user).set('currentusergroup', action.usergroup);
    
    default:
      return state;
  }
}

export default userProfilesReducer;
