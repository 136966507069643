import { createSelector } from "reselect";
import { initialState } from "./reducer";

/**
 * Direct selector to the AddFyrk state domain
 */

const selectAddFyrkDomain = (state) => state.get("AddFyrk", initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by AddFyrk
 */

const makeSelectAddFyrk = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) => fyrkState.toJS());

const getFyrkItem = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) => fyrkState.get("fyrkitem"));

const makeSelectTitle = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) => fyrkState.get("title"));

const getProcurator = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) =>
    fyrkState.get("procurator")
  );

const getContactEmail = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) =>
    fyrkState.get("contactemail")
  );

const getUpdaterEmail = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) =>
    fyrkState.get("updateremail")
  );

const getNextPeriod = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) =>
    fyrkState.get("nextperiod")
  );

const getPeriodType = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) =>
    fyrkState.get("periodtype")
  );
const getPeriodStatus = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) =>
    fyrkState.get("next_period_status")
  );

const getContactPhone = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) =>
    fyrkState.get("contactphone")
  );

const getContactAddress = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) =>
    fyrkState.get("contactaddress")
  );

const getContactName = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) =>
    fyrkState.get("contactname")
  );

const makeSelectDescription = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) =>
    fyrkState.get("description")
  );
const makeSelectValue = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) => fyrkState.get("value"));

const makeSelectLink = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) => fyrkState.get("link"));

const makeSelectLinkDescription = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) =>
    fyrkState.get("linkdescription")
  );

const makeSelectPictures = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) => fyrkState.get("pictures"));
const getSelectedLocations = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) =>
    fyrkState.get("selectedlocations")
  );
const getError = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) => fyrkState.get("error"));
const getSelectedGroups = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) =>
    fyrkState.get("selectedgroups")
  );
const getFyrk = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) => fyrkState.get("fyrk"));

const getLoading = () =>
  createSelector(selectAddFyrkDomain, (fyrkState) => fyrkState.get("loading"));

export {
  getFyrkItem,
  getContactName,
  getContactAddress,
  getContactPhone,
  getContactEmail,
  getUpdaterEmail,
  getNextPeriod,
  getPeriodType,
  getPeriodStatus,
  getFyrk,
  makeSelectTitle,
  getProcurator,
  makeSelectDescription,
  makeSelectLink,
  makeSelectLinkDescription,
  getLoading,
  makeSelectPictures,
  makeSelectValue,
  getSelectedLocations,
  getSelectedGroups,
  getError,
};
