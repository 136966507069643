/**
 * RepoListItem
 *
 * Lists the name and the issue count of a repository
 */
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { addCategory,dislikeEvent,likeEvent,loadFyrk} from '../App/actions';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment/min/moment-with-locales';

import 'moment/locale/sv';
//import { withRouter } from 'connected-react-router';
import FyrkImage from '../../components/FyrkImage';
import { deleteFyrk, likeFyrk, dislikeFyrk } from './actions';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { API_ROOT } from '../../api-config';
import PopFavorite from '../../components/PopFavorite';
// import saga from './saga'

const styles = {
  card: {
    height: `534px`,
    margin: '1em',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: "16px 16px 16px 16px",
  },
  griditem: {
    width: '33%',
  },
  categorylink: {
    cursor: 'pointer',
    color: '#9691BC',
  },
  iconcolor: {
    marginRight: '0.5em',
    color: '#666',
  },

  media: {
    height: 0,
    paddingTop: '56.5%', // 16:9
  },
  title: {
    textAlign: 'left',
    paddingLeft: '0',
    marginBottom: 0,
    marginTop: 0,
    overflowWrap: 'break-word',
    fontWeight: '600 !important',
  },
  cardcontent: {
    padding: '1.5em !important',
    backgroundColor: "#FFF"
  },
  procurator: {
    fontWeight: '400',
    background: "#E2F3E7",
    minHeight: "217px"

  },
  likebutton: {
    color: '#44AB66',
  },
  likedbutton: {
    color: '#44AB66',
  },
  actions :{
    marginTop : 'auto',
    display: 'inline',
    marginBottom: '16px',
    paddingLeft: '1.5em',
  },
  rightAlignItem: {
    marginLeft: 'auto',
    float: 'right',
    display: 'inline-block',
  },
  avatar: {
    boxShadow: "1px 3px 4px rgba(0, 0, 0, 0.2)",
    width: "72px",
    height: "72px"
}
};

export class FyrkListItemCard extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  UNSAFE_componentWillMount() {
    this.tempimage = FyrkImage();
  }

  handleItemClick(id) {
    this.props.history.push(`/fyrkpost/${id}`);
  }

  handleItemClickEdit(evt, id) {
    evt.stopPropagation();
    this.props.history.push(`/fyrkpost/${id}/edit`);
  }

  render() {
    const {item} = this.props;
    const {
      title,
      procurator,
      description,
      categories,
      next_period,
      next_period_status,
      prev_period,
      periodtype,
      fyrkimages,
    } = this.props.item;
    const { userid, user, favoriter } = this.props;
    const favoriteids = _.map(favoriter, 'urls');
    let favorite = false;
    const itempath = `${API_ROOT}fyrk/${item.id}/`
    if (_.indexOf(favoriteids, itempath) != -1) {
      favorite = true;
    }
    const { classes } = this.props;
    const clear_desc = description
      .replace(/<[^>]*>?/gm, ' ')
      .replace(/\s+/g, ' ').substring(0,250);

    let fyrkimage = this.tempimage;
    if (fyrkimages && fyrkimages.length > 0) {
      fyrkimage = API_ROOT.replace(/\/$/, '') + fyrkimages[0].thumbnail;
      fyrkimage = fyrkimage.replace(/WxH/g, '440x440');
    }
    var datestring = "";
    var periodstatus = "";
    var periodstring = "";
    if(next_period && next_period_status == 1 ) {
      datestring = 'Ansökningstid '+moment(next_period).format('D.M.YYYY');
      periodstatus = " (slutgiltig)"
    }
    if(next_period && next_period_status == 0 ) {
      datestring = 'Ansökningstid '+moment(next_period).format('MMM YYYY');
      periodstatus = " (preliminär)"

    }

    if( next_period == null && prev_period != null) {
      datestring = moment(prev_period).format(' MMM YYYY');
      // periodstatus = " (föregående)"
    }

    if(periodtype == "CONTINUOUS") {
      periodstring = (<><b>Fortlöpande ansökningstid</b></>)
    } else {
      periodstring = datestring+periodstatus
    }

    const categorieslist = categories.map((list, index) => {
      if (categories.length > 0 && index < 9) {
        return (
          <a
            key={list.id}
            className={classes.categorylink}
            onClick={evt => {
              this.props.addCategory(evt, list.id, list.name);
            }}
          >
            &nbsp;#{list.name}{' '}
          </a>
        );
      }
    });

    let editbutton = '';
    let usergroupid = localStorage.getItem('usergroupid');
    if (user) {
      if (item.user == userid || user.is_staff || usergroupid == item.usergroup) {
      editbutton = (
        <Button
          onClick={evt => {
            this.handleItemClickEdit(evt, item.id);
          }}
          variant="text"
          size="small"
          className={classes.rightAlignItem}
        >
          <EditIcon className={classes.leftIcon} />

        </Button>
      );
    }
  }
    let deletebutton = '';
    if (user) {
    if (item.user == userid || user.is_staff || usergroupid == item.usergroup) {
      deletebutton = (
        <Button
          onClick={evt => {
            this.props.deleteFyrk(evt, item.id);
          }}
          variant="text"
          size="small"
          className={classes.rightAlignItem}
        >
          <DeleteIcon className={classes.leftIcon} />
        </Button>
      );
    }
  }

    let likebutton = '';
      if(favorite) {
        likebutton = (
          <IconButton
          onClick={evt => {
            this.props.dislikeFyrk(evt, item.id);
          }}
            className={classes.likebutton}
          >
            <Favorite />
          </IconButton>
        );
      } else {
        likebutton = (
          <IconButton
          onClick={evt => {
            this.props.likeFyrk(evt, item.id);
          }}
            className={classes.likedbutton}
          >
            <PopFavorite />
          </IconButton>
        );
      }
    

    let actionsection = '';
    if (user) {
      if (item.user == userid || user.is_staff) {
        actionsection = (<div>
            {deletebutton}
            {editbutton}
            </div>
        );
      }
    }
    return (
      <Card className={classes.card}  key={item.id} square={true} >
        <CardHeader className={classes.procurator} style={{paddingBottom: '0'}}
        avatar={
          <Avatar aria-label="Logo" className={classes.avatar} src={fyrkimage}>
          </Avatar>
        }
        onClick={() => {
          this.handleItemClick(item.id);
        }}
        action={likebutton}
        title={procurator}
      />
        <CardContent
        className="cardcontent"
          onClick={() => {
            this.handleItemClick(item.id);
          }}
        >
          <div className={classes.title} style={{fontWeight:'bold'}}>{title}</div>
          <br/>
          <div>{clear_desc}</div>

        </CardContent>
        <CardActions className={classes.actions} disableSpacing>
        <div className={classes.title}>{periodstring}</div>
        {categorieslist}
        {actionsection}
        </CardActions>
      </Card>
    );
  }
}
export function mapDispatchToProps(dispatch) {
  return {
    deleteFyrk: (evt, id) => {
      dispatch(deleteFyrk(id));
      evt.stopPropagation();
    },
    likeFyrk: (evt, id) => {
      dispatch(likeEvent(id, 'FYRK'));
      evt.stopPropagation();
    },
    dislikeFyrk: (evt, id) => {
      dispatch(dislikeEvent(id, 'FYRK'));
      evt.stopPropagation();
    },
    addCategory: (evt, id, value) => {
      evt.stopPropagation();
      dispatch(addCategory(id, value));
      dispatch(loadFyrk());
    }
  };
}
const withConnect = connect(
  null,
  mapDispatchToProps,
);

FyrkListItemCard.propTypes = {
  item: PropTypes.object,
  classes: PropTypes.object,
};
export default compose(
  withConnect,
  withRouter,
  withStyles(styles),
)(FyrkListItemCard);
