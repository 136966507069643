/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const LOAD_EVENEMANG = 'boilerplate/App/LOAD_EVENEMANG';
export const LOAD_EVENEMANG_SUCCESS = 'boilerplate/App/LOAD_EVENEMANG_SUCCESS';
export const LOAD_EVENEMANG_ERROR = 'boilerplate/App/LOAD_EVENEMANG_ERROR';
export const LOAD_NYHETER = 'boilerplate/App/LOAD_NYHETER';
export const LOAD_NYHETER_SUCCESS = 'boilerplate/App/LOAD_NYHETER_SUCCESS';
export const LOAD_NYHETER_ERROR = 'boilerplate/App/LOAD_NYHETER_ERROR';
export const LOAD_FYRK = 'boilerplate/App/LOAD_FYRK';
export const LOAD_FYRK_SUCCESS = 'boilerplate/App/LOAD_FYRK_SUCCESS';
export const LOAD_FYRK_ERROR = 'boilerplate/App/LOAD_FYRK_ERROR';
export const LOAD_KULTUR = 'boilerplate/App/LOAD_KULTUR';
export const LOAD_CATEGORIES = 'boilerplate/App/LOAD_CATEGORIES';
export const LOAD_TAGS = 'boilerplate/App/LOAD_TAGS';
export const LOAD_FAVORITER = 'boilerplate/App/LOAD_FAVORITER';

export const LOAD_CATEGORIES_SUCCESS =
  'boilerplate/App/LOAD_CATEGORIES_SUCCESS';
  export const LOAD_REGIONS_SUCCESS =
  'boilerplate/App/LOAD_REGIONS_SUCCESS';
export const LOAD_KULTUR_SUCCESS = 'boilerplate/App/LOAD_KULTUR_SUCCESS';
export const LOAD_KULTUR_ERROR = 'boilerplate/App/LOAD_KULTUR_ERROR';
export const DEFAULT_LOCALE = 'en';
export const TOGGLE_RIGHTMENU = 'App/TOGGLE_RIGHTMENU';
export const TOGGLE_RIGHTDRAWER = 'App/TOGGLE_RIGHTDRAWER';

export const TOGGLE_LEFTMENU = 'App/TOGGLE_LEFTMENU';
export const HIDE_LEFTMENU = 'App/HIDE_LEFTMENU';
export const CHANGE_CATEGORY = 'App/CHANGE_CATEGORY';
export const SET_PERIODTYPE = 'App/SET_PERIODTYPE';
export const CHANGE_PLATS = 'App/CHANGE_PLATS';

export const ADD_CATEGORY = 'App/ADD_CATEGORY';
export const REMOVE_CATEGORY = 'App/REMOVE_CATEGORY';
export const ADD_CATEGORY_EVENEMANG = 'App/ADD_CATEGORY_EVENEMANG';
export const REMOVE_CATEGORY_EVENEMANG = 'App/REMOVE_CATEGORY_EVENEMANG';
export const ADD_GROUP = 'App/ADD_GROUP';
export const REMOVE_GROUP = 'App/REMOVE_GROUP';
export const ADD_KULTUR = 'App/ADD_KULTUR';
export const REMOVE_KULTUR = 'App/REMOVE_KULTUR';
export const SET_SCROLL = 'App/SET_SCROLL';

export const SEARCH = 'App/SEARCH';
export const SEARCHEVENEMANG = 'App/SEARCHEVENEMANG';
export const LOAD_GROUPS = 'App/LOAD_GROUPS';
export const LOAD_PLATS = 'App/LOAD_PLATS';
export const LOAD_CATEGORIES_MAIN = 'App/LOAD_CATEGORIES_MAIN';
export const LOAD_CATEGORIES_MAINEVENEMANG =
  'App/LOAD_CATEGORIES_MAINEVENEMANG';

export const LOAD_CATEGORIES_EVENEMANG = 'App/LOAD_CATEGORIES_EVENEMANG';
export const LOAD_LOCATIONS_EVENEMANG = 'App/LOAD_LOCATIONS_EVENEMANG';
export const HASPROFILE = 'App/HASPROFILE';
export const OPEN_DIALOG = 'app/OPEN_DIALOG';
export const CLOSE_DIALOG = 'app/CLOSE_DIALOG';
export const Q_OPEN_DIALOG = 'app/Q_OPEN_DIALOG';
export const Q_CLOSE_DIALOG = 'app/Q_CLOSE_DIALOG';
export const CHANGE_CATEGORY_EVENEMANG = 'App/CHANGE_CATEGORY_EVENEMANG';
export const CHANGE_LOCATION_EVENEMANG = 'App/CHANGE_LOCATION_EVENEMANG';
export const SET_CURRENT_EVENT = 'App/SET_CURRENT_EVENT';

export const FILTER_DATE = 'App/FILTER_DATE';
export const FILTER_DATESTART = 'App/FILTER_DATESTART';
export const FILTER_DATEEND = 'App/FILTER_DATEEND';
export const FILTER_DATE_EVENT = 'App/FILTER_DATE_EVENT';
export const FILTER_DATESTART_EVENT = 'App/FILTER_DATESTART_EVENT';
export const FILTER_SET_REFERENCETIME = 'App/FILTER_SET_REFERENCETIME';
export const FILTER_SET_REFERENCETIMEEVENT = 'App/FILTER_SET_REFERENCETIMEEVENT';
export const FILTER_DATEEND_EVENT = 'App/FILTER_DATEEND_EVENT';
export const SET_SUGGESTIONS = 'App/SET_SUGGESTIONS';
export const CLEAR_SUGGESTIONS = 'App/CLEAR_SUGGESTIONS';
export const SET_SUGGESTIONS_EVENEMANG = 'App/SET_SUGGESTIONS_EVENEMANG';
export const CLEAR_SUGGESTIONS_EVENEMANG = 'App/CLEAR_SUGGESTIONS_EVENEMANG';
export const SEARCH_FIELD = 'App/SEARCH_FIELD';
export const SEARCH_FIELD_EVENEMANG = 'App/SEARCH_FIELD_EVENEMANG';

export const SET_USER = 'App/SET_USER';
export const SET_PROFILE = 'App/SET_PROFILE';
export const SET_HELP = 'App/SET_HELP';
export const HIDE_DRAWERS = 'App/HIDE_DRAWERS';



export const LOGOUT = 'App/LOGOUT';
export const LOAD_GROUPS_EVENEMANG = 'App/LOAD_GROUPS_EVENEMANG';
export const LOAD_GRUPS = 'App/LOAD_GRUPS';

export const ADD_GROUP_EVENEMANG = 'App/ADD_GROUP_EVENEMANG';
export const REMOVE_GROUP_EVENEMANG = 'App/REMOVE_GROUP_EVENEMANG';
export const CHANGE_TIMEINDEX = 'App/CHANGE_TIMEINDEX';
export const CHANGE_TIMEINDEX_EVENT = 'App/CHANGE_TIMEINDEX_EVENT';
export const DELETE_NYHET = 'App/DELETE_NYHET';
export const DELETE_FYRK = 'App/DELETE_FYRK';

export const DELETE_PROFILE = 'App/DELETE_PROFILE';
export const DELETE_EVENT = 'App/DELETE_EVENT';
export const LIKE_EVENT = 'App/LIKE_EVENT';
export const DISLIKE_EVENT = 'App/DISLIKE_EVENT';
export const LOAD_LIKES = 'App/LOAD_LIKES';

export const REMOVE_USERGROUP= 'App/REMOVE_USERGROUP';
export const SET_USERGROUP= 'App/SET_USERGROUP';
export const DELETE_PROFILEGROUP = 'App/DELETE_PROFILEGROUP';
export const REMOVE_USER= 'App/REMOVE_USER';
export const CHANGE_FIRSTNAME= 'App/CHANGE_FIRSTNAME';
export const CHANGE_LASTNAME = 'App/CHANGE_LASTNAME';
export const CHANGE_USERNAME= 'App/CHANGE_USERNAME';

