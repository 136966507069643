/**
 * Homepage selectors
 */

import { createSelector } from 'reselect';

const selectSingleProfile = state => state.get('profilesingle');

const makeSelectProfile = () =>
  createSelector(selectSingleProfile, profileState =>
    profileState.get('profile'),
  );
const getOwnEvents = () =>
  createSelector(selectSingleProfile, profileState =>
    profileState.get('events'),
  );
  const getOwnEventsPast = () =>
  createSelector(selectSingleProfile, profileState =>
    profileState.get('eventspast'),
  );
  const getOwnNews= () =>
  createSelector(selectSingleProfile, profileState =>
    profileState.get('news'),
  );
  const getOwnFyrk= () =>
  createSelector(selectSingleProfile, profileState =>
    profileState.get('fyrk'),
  );
  const getSimilar= () =>
  createSelector(selectSingleProfile, profileState =>
    profileState.get('similar'),
  );
export { getOwnFyrk,getOwnNews, getOwnEvents, selectSingleProfile, makeSelectProfile, getOwnEventsPast, getSimilar };
