/**
 *
 * RegisterPage
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import injectSaga from "./../../utils/injectSaga";
import injectReducer from "./../../utils/injectReducer";
import List from "../../components/List";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InfoIcon from "@mui/icons-material/Info";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/styles/withStyles";
import saga from "./saga";
import reducer from "./reducer";
import { hideLeftDrawer } from "../App/actions";
//import { getGroups, getOpenDialog,getHelp } from '../App/selectors';
import { getOpenDialog, getHelp } from "../App/selectors";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Select from "react-select";

import {
  loginOnChange,
  passwordOnChange,
  passwordConfirmOnChange,
  fyrkrequestchange,
  submitLoginForm,
  setGroup,
  changeOrganisation,
  changeNewOrganisation,
  changeFirstName,
  changeLastName,
  setOpen,
  setInfoPop,
} from "./actions";
import {
  getRegisterSuccess,
  makeSelectPassword,
  makeSelectPasswordConfirm,
  makeSelectLogin,
  makeSelectMessage,
  makeSelectLoading,
  getError,
  getGroup,
  getOrganisation,
  getNewOrganisation,
  getLastName,
  getFirstName,
  getOpen,
  getInfoPop,
  getfyrkrequestvalue,

} from "./selectors";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    background: "#F5F5F5",
    marginBottom: theme.spacing(2),
  },
  dialogcontent: {
    padding: theme.spacing(10),
    textAlign: "center",
  },
  form: {
    width: "100%",
  },
  selectitem: {
    zIndex: "100",
  },
  spinner: {
    //marginLeft: '20px',
    //width: '20px',
    //height: '20px',
  },
  infobutton: {
    marginLeft: "1em",
    color: "#3949AB",
    cursor: "pointer",
  },
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  griditem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: "center",
  },
});
const SpinnerAdornment = withStyles(styles)((props) => (
  <CircularProgress
    className={props.classes.spinner}
    size={20}
    style={{ color: "#fff" }}
  />
));

const AdornedButton = (props) => {
  const { children, loading, ...rest } = props;
  return (
    <Button {...rest}>
      {children}
      {loading && <SpinnerAdornment {...rest} />}
    </Button>
  );
};

export class RegisterPage extends React.PureComponent {
  componentDidMount() {
    this.props.hideSearch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      firstname,
      lastname,
      loginvisible,
      registersuccess,
      classes,
      loginvalue,
      passwordvalue,
      passwordconfirmvalue,
      message,
      loading,
      error,
      infopop,
      helptexts,
      newgroup,
      organisation,
      neworganisation,
      usergroups,
      open,
      fyrkrequestvalue,

    } = this.props;

    const { username, email, password1, passwordconfirm, non_field_errors } =
      error;
    const errorProps = {
      error: true,
    };
    const emailerror = email || "";
    const usererror = username || "";

    let sendmessage = "";

    if (registersuccess) {
      sendmessage = "Verifierande e-post skickad.";
    }
    let fritid_reg_info = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: "fritid_reg_info" });
      if (temptext) {
        fritid_reg_info = temptext.description;
      }
    }
    return (
      <div className={classes.root}>
        <form
          onKeyPress={(event) => {
            if (event.which === 13 && event.target.type != "textarea") {
              event.preventDefault();
            }
          }}
          className={classes.form}
          onSubmit={this.props.onSubmit}
        >
          <Container maxWidth="xs">
            <Grid container direction="row" justifyContent="center">
              <Grid
                item
                xs={12}
                className={classes.griditem}
              >
                <Typography variant="h4" align="center">
                  Registrera dig
                  <InfoIcon
                    onClick={this.props.openInfoPop}
                    className={classes.infobutton}
                  />
                </Typography>
              </Grid>

              <Grid item xs={6} className={classes.griditem}>
                <TextField
                  id="firstname"
                  label="Förnamn"
                  variant="outlined"
                  //helperText={fr}
                  margin="normal"
                  FormHelperTextProps={errorProps}
                  onChange={this.props.changeFirstName}
                  value={firstname}
                  fullWidth
                  autoFocus
                  required
                  disabled={registersuccess}
                  inputProps={{}}
                />
              </Grid>
              <Grid item xs={6} className={classes.griditem}>
                <TextField
                  id="lastname"
                  label="Efternamn"
                  variant="outlined"
                  //helperText={emailerror +" "+usererror}
                  margin="normal"
                  FormHelperTextProps={errorProps}
                  onChange={this.props.changeLastName}
                  value={lastname}
                  fullWidth
                  autoFocus
                  required
                  disabled={registersuccess}
                  inputProps={{}}
                />
              </Grid>
              <Grid item xs={12} className={classes.griditem}>
                <TextField
                  id="username"
                  label="E-post"
                  variant="outlined"
                  helperText={emailerror + " " + usererror}
                  margin="normal"
                  FormHelperTextProps={errorProps}
                  onChange={this.props.loginOnChange}
                  value={loginvalue.toLowerCase()}
                  fullWidth
                  required
                  autoFocus
                  disabled={registersuccess}
                  inputProps={{
                    style: { textTransform: "lowercase" },
                  }}
                />
              </Grid>
              <Grid item xs={12} className={classes.griditem}>
                <Divider />
              </Grid>
              <Grid item xs={12} className={classes.griditem}>
                <TextField
                  id="password"
                  label="Lösenord"
                  variant="outlined"
                  helperText={password1}
                  margin="normal"
                  FormHelperTextProps={errorProps}
                  onChange={this.props.passwordOnChange}
                  fullWidth
                  value={passwordvalue}
                  required
                  autoFocus
                  type="password"
                  disabled={registersuccess}
                />
              </Grid>
              <Grid item xs={12} className={classes.griditem}>
                <TextField
                  id="passwordconfirm"
                  variant="outlined"
                  label="Bekräfta lösenord"
                  helperText={passwordconfirm}
                  margin="normal"
                  FormHelperTextProps={errorProps}
                  onChange={this.props.passwordConfirmOnChange}
                  value={passwordconfirmvalue}
                  fullWidth
                  required
                  autoFocus
                  type="password"
                  disabled={registersuccess}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value={fyrkrequestvalue}
                      onChange={this.props.fyrkrequestchange}
                    />
                  }
                  label="Jag är ombudsman/finansiär"
                  id="FYRK_request"
                />
                <div>{non_field_errors}
                    {sendmessage}
                </div>

              </Grid>
              <Grid item xs={12} className={classes.griditem}>
                <AdornedButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={loading}
                  disabled={registersuccess}
                >
                  Registrera dig
                </AdornedButton>

                {message}
              </Grid>
            </Grid>
          </Container>
        </form>
        <Dialog
          open={infopop}
          onClose={this.props.closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {fritid_reg_info}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.props.closeInfoPop} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText className={classes.dialogcontent}>
              Vi skickade en bekräftelselänk till din e-post. När du har
              bekräftat din e-postadress, styrs du till Luckans webbtjänsters
              sida, klicka på service och du förs till dess förstasida och kan
              logga in.
              <br />
              <i>
                NB. Om du använder gmail så hamnar länken lätt i Kampanjer, så
                titta där
              </i>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.props.closeDialog} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

RegisterPage.propTypes = {
  login: PropTypes.string,
  password: PropTypes.string,
  message: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  loginvalue: makeSelectLogin(),
  passwordvalue: makeSelectPassword(),
  passwordconfirmvalue: makeSelectPasswordConfirm(),
  error: getError(),
  message: makeSelectMessage(),
  loading: makeSelectLoading(),
  registersuccess: getRegisterSuccess(),
  //usergroups: getGroups(),
  newgroup: getGroup(),
  organisation: getOrganisation(),
  neworganisation: getNewOrganisation(),
  lastname: getLastName(),
  firstname: getFirstName(),
  open: getOpen(),
  helptexts: getHelp(),
  infopop: getInfoPop(),
  fyrkrequestvalue: getfyrkrequestvalue(),

});

function mapDispatchToProps(dispatch) {
  return {
    loginOnChange: (evt) => {
      dispatch(loginOnChange(evt.target.value));
    },
    passwordOnChange: (evt) => {
      dispatch(passwordOnChange(evt.target.value));
    },
    passwordConfirmOnChange: (evt) => {
      dispatch(passwordConfirmOnChange(evt.target.value));
    },
    closeDialog: () => {
      dispatch(setOpen(false));
    },
    openDialog: () => {
      dispatch(setOpen(true));
    },
    openInfoPop: () => {
      dispatch(setInfoPop(true));
    },
    closeInfoPop: () => {
      dispatch(setInfoPop(false));
    },
    hideSearch: (evt) => {
      dispatch(hideLeftDrawer());
    },
    setGroup: (value) => {
      dispatch(setGroup(!value));
    },
    changeOrganisation: (value) => {
      dispatch(changeOrganisation(value));
    },
    changeFirstName: (evt) => {
      dispatch(changeFirstName(evt.target.value));
    },
    changeLastName: (evt) => {
      dispatch(changeLastName(evt.target.value));
    },
    changeNewOrganisation: (evt) => {
      dispatch(changeNewOrganisation(evt.target.value));
    },
    fyrkrequestchange: (evt) => {
      dispatch(fyrkrequestchange(evt.target.checked));
    },
    onSubmit: (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      dispatch(submitLoginForm());
    },
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: "RegisterPage", reducer });
const withSaga = injectSaga({ key: "RegisterPage", saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withStyles(styles)
)(RegisterPage);
