import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the GroupPage state domain
 */

const selectGroupPageDomain = state =>
  state.get('GroupPage', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by GroupPage
 */

const makeSelectGroupPage = () =>
  createSelector(selectGroupPageDomain, loginState => loginState.toJS());

const makeSelectLogin = () =>
  createSelector(selectGroupPageDomain, loginState =>
    loginState.get('login'),
  );
const getError = () =>
  createSelector(selectGroupPageDomain, loginState =>
    loginState.get('error'),
  );
  const getGroup = () =>
  createSelector(selectGroupPageDomain, loginState =>
    loginState.get('newgroup'),
  );
  const getOrganisation= () =>
  createSelector(selectGroupPageDomain, loginState =>
    loginState.get('organisation'),
  );
  const getFirstName= () =>
  createSelector(selectGroupPageDomain, loginState =>
    loginState.get('firstname'),
  );
  const getLastName =() =>
  createSelector(selectGroupPageDomain, loginState =>
    loginState.get('lastname'),
  );
  const getOpen=() =>
  createSelector(selectGroupPageDomain, loginState =>
    loginState.get('open'),
  );
  const getNewOrganisation= () =>
  createSelector(selectGroupPageDomain, loginState =>
    loginState.get('neworganisation'),
  );
const getRegisterSuccess = () =>
  createSelector(selectGroupPageDomain, loginState =>
    loginState.get('registersuccess'),
  );
const makeSelectMessage = () =>
  createSelector(selectGroupPageDomain, loginState =>
    loginState.get('message'),
  );
const makeSelectLoading = () =>
  createSelector(selectGroupPageDomain, loginState =>
    loginState.get('loading'),
  );
const makeSelectPassword = () =>
  createSelector(selectGroupPageDomain, loginState =>
    loginState.get('password'),
  );
const makeSelectPasswordConfirm = () =>
  createSelector(selectGroupPageDomain, loginState =>
    loginState.get('passwordconfirm'),
  );
export {
  getRegisterSuccess,
  getError,
  getGroup,
  getOrganisation,
  getNewOrganisation,
  makeSelectGroupPage,
  makeSelectLogin,
  makeSelectPassword,
  makeSelectMessage,
  makeSelectLoading,
  makeSelectPasswordConfirm,
  getFirstName,
  getLastName,
  getOpen,
};
