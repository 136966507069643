
import {
  CHANGE_USERNAME,
  TO_LIST,
  TO_MASONRY,
  CHANGE_PAGESIZE,
  CHANGE_PAGE,
  CHANGE_MORE
} from './constants';


export function changePage(value) {
  return {
    type: CHANGE_PAGE,
    value,
  };
}
export function changePageSize(value) {
  return {
    type: CHANGE_PAGESIZE,
    value,
  };
}
export function changeMore(value) {
  return {
    type: CHANGE_MORE,
    value,
  };
}
export function changeUsername(name) {
  return {
    type: CHANGE_USERNAME,
    name,
  };
}
export function toListView(name) {
  return {
    type: TO_LIST,
    name,
  };
}

export function toMasonryView(name) {
  return {
    type: TO_MASONRY,
    name,
  };
}
