


import { createSelector } from 'reselect';

const selectFavorite = state => state.get('favoriter');

  const getFavorites = () =>
  createSelector(selectFavorite, favoriteState => favoriteState.get('favorites'));


  const getVisibleFavorites = () =>
  createSelector(selectFavorite, favoriteState => favoriteState.get('visiblefavorites'));

  const getFilter = () =>
  createSelector(selectFavorite, favoriteState => favoriteState.get('filter'));
  const getLoading = () =>
  createSelector(selectFavorite, favoriteState => favoriteState.get('loader'));
  const getError= () =>
  createSelector(selectFavorite, favoriteState => favoriteState.get('error'));
  

  const makeSelectListView = () =>
  createSelector(selectFavorite, favoriteState =>
    favoriteState.get('listview'),
  );
const makeSelectMasonryView = () =>
  createSelector(selectFavorite, favoriteState =>
    favoriteState.get('masonryview'),
  );

export {
  makeSelectMasonryView,
  makeSelectListView,
  selectFavorite,
  getFavorites,
  getVisibleFavorites,
  getLoading,
  getError,
  getFilter
}

