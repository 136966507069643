/*
 * Home Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
  
  FAVORITES_LOADED,
  LOAD_FAVORITES,
  SET_LOADING,
  SET_FILTER,
  TO_LIST,
  TO_MASONRY,
} from './constants';


export function loadFavorites() {
  return {
    type: LOAD_FAVORITES,
  };
}
export function setLoading(value) {
  return {
    type: SET_LOADING,
     value
  };
}
export function toListView(name) {
  return {
    type: TO_LIST,
    name,
  };
}

export function toMasonryView(name) {
  return {
    type: TO_MASONRY,
    name,
  };
}
export function setFilter(value) {
  return {
    type: SET_FILTER,
     value
  };
}
export function favoritesLoaded(favorites) {
  return {
    type: FAVORITES_LOADED,
    favorites,
  };
}
