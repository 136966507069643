/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const SET_EVENTS = 'boilerplate/Home/SET_EVENTS';
export const SET_NEWS = 'boilerplate/Home/SET_NEWS';
export const SET_PROFILES = 'boilerplate/Home/SET_PROFILES';
export const LOAD_HOMEDATA = 'boilerplate/Home/LOAD_HOMEDATA';
export const TO_LIST_EVENTS = 'boilerplate/Kultur/TO_LIST_EVENTS';
export const TO_MASONRY_EVENTS = 'boilerplate/Kultur/TO_MASONRY_EVENTS';

export const TO_LIST_NEWS = 'boilerplate/Kultur/TO_LIST_NEWS ';
export const TO_MASONRY_NEWS  = 'boilerplate/Kultur/TO_MASONRY_NEWS ';

export const TO_LIST_PROFILES = 'boilerplate/Kultur/TO_LIST_PROFILES';
export const TO_MASONRY_PROFILES = 'boilerplate/Kultur/TO_MASONRY_PROFILES';

