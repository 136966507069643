/*
 *
 * AddNyhet constants
 *
 */

export const DEFAULT_ACTION = 'app/AddNyhet/DEFAULT_ACTION';
export const TITLE_CHANGE = 'app/AddNyhet/TITLE_CHANGE';
export const DESCRIPTION_CHANGE = 'app/AddNyhet/DESCRIPTION_CHANGE';
export const LINK_CHANGE = 'app/AddNyhet/LINK_CHANGE';
export const SUBMIT_ADDNYHETFORM = 'app/AddNyhet/SUBMIT_ADDNYHETFORM';
export const IMAGE_DROP = 'app/AddNyhet/IMAGE_DROP';
export const CHANGE_CATEGORY = 'app/AddNyhet/CHANGE_CATEGORY';
export const CHANGE_LOCATION = 'app/AddNyhet/CHANGE_LOCATION';
export const CHANGE_GROUP = 'app/AddNyhet/CHANGE_GROUP';
export const SET_ERROR = 'app/AddNyhet/SET_ERROR';
export const SET_LOADING = 'app/AddNyhet/SET_LOADING';

export const CLEAR_FORM = 'app/AddNyhet/CLEAR_FORM';
export const CHANGE_FYRK = 'app/AddNyhet/CHANGE_FYRK';
export const CHANGE_FYRKDATE = 'app/AddNyhet/CHANGE_FYRKDATE';
export const CHANGE_EDITORSTATE = 'app/AddNyhet/CHANGE_EDITORSTATE';
export const LOAD_NEWSITEM = 'app/AddNyhet/LOAD_NEWSITEM';
export const NYHET_LOADED = 'app/AddNyhet/NYHET_LOADED';
export const CLEAR_IMAGE = 'app/AddNyhet/CLEAR_IMAGE';
export const RESTORE_IMAGE = 'app/AddNyhet/RESTORE_IMAGE';
