import { call, put, select, takeLatest } from 'redux-saga/effects';
import request from '../../utils/request';
import { push } from 'connected-react-router';
import { API_ROOT } from '../../api-config';
import { nyhetLoaded } from './actions';
import { LOAD_NYHET, DELETE_NYHET } from './constants';

/**
 * Github repos request/response handler
 */
const token = localStorage.getItem('token');

const fetchJSON = (url, options = {}) =>
  new Promise((resolve, reject) =>
    fetch(url, options)
      .then(response =>
        response.status !== 201 &&
        response.status !== 200 &&
        response.status !== 400
          ? reject(response)
          : response,
      )
      .then(response => {
        if (response.status == 400) {
          response.json().then(error => reject(error));
        } else {
          return response.json();
        }
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => reject(error)),
  );
export function* removeNyhetRequest(value) {
  const token = localStorage.getItem('token');

  const nyhetId = value.id;
  const requestURL = `${API_ROOT}news/${nyhetId}`;
  try {
    let res = yield call(fetchJSON, `${API_ROOT}news/${nyhetId}/`, {
      credentials: 'omit',
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    });
  } catch (error) {
    throw error;
  }
}
export function* loadNyhet(value) {
  const nyhetId = value.id;
  const requestURL = `${API_ROOT}news/${nyhetId}`;

  try {
    const nyhet = yield call(request, requestURL);
    yield put(nyhetLoaded(nyhet));
  } catch (err) {
    yield put(push('/'));
    // yield put(nyheterLoadingError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* githubData() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(DELETE_NYHET, removeNyhetRequest);

  yield takeLatest(LOAD_NYHET, loadNyhet);
}
