/**
 * Gets the repositories of the user from Github
 */
import { takeLatest } from "redux-saga/effects";
import { call, put, select } from "redux-saga/effects";
import { API_ROOT } from "../../api-config";
import { SUBMITREGISTER } from "./constants";
import {
  setLoading,
  setError,
  registerSuccess,
  setOpen,
  passwordConfirmOnChange,
  passwordOnChange,
  fyrkrequestchange,
} from "./actions";
import {
  makeSelectLogin,
  getPassword,
  makeSelectPasswordConfirm,
  getfyrkrequestvalue,
} from "./selectors";

import {
  makeSelectFirstName,
  makeSelectLastName,
  makeSelectUserId,
  makeSelectUserName,
  getUserGroupId,
} from "../App/selectors";


/**
 * Github repos request/response handler
 */
const fetchJSON = (url, options = {}) =>
  new Promise((resolve, reject) =>
    fetch(url, options)
      .then((response) =>
        response.status !== 201 &&
        response.status !== 200 &&
        response.status !== 400
          ? reject(response)
          : response
      )
      .then((response) => {
        if (response.status === 400) {
          response.json().then((error) => reject(error));
        } else {
          return resolve(response.json());
        }
        return "";
      })
      .catch((error) => reject(error))
  );

export function* registerRequest() {
  let userid = yield select(makeSelectUserId());
  let usergroup_id = yield select(getUserGroupId());

  if (!userid) {
    userid = localStorage.getItem("userid");
  }

  if (!usergroup_id) {
    usergroup_id = localStorage.getItem("usergroupid");
  }

  yield put(setLoading(true));
  yield put(registerSuccess(true));

  const username = yield select(makeSelectUserName());

  const login = yield select(makeSelectUserName());
  const password = yield select(getPassword());
  const passwordconfirm = yield select(makeSelectPasswordConfirm());
  const firstname = yield select(makeSelectFirstName());
  const lastname = yield select(makeSelectLastName());
  const fyrklover = yield select(getfyrkrequestvalue());

  const payload = {
    first_name: firstname,
    last_name: lastname,
    username: login,
    email: login,
  };
  
  const token = localStorage.getItem("token");
  let error = {};
  
  if (fyrklover) {
    try {
      yield call(
        fetchJSON,
        `${API_ROOT}fyrklover/` + usergroup_id + `/` + userid,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Token " + token,
          },
        }
      );
    } catch (error) {}
  }
  try {
    yield call(fetchJSON, `${API_ROOT}users/` + userid + "/", {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
      body: JSON.stringify(payload),
    });
    if (password != "" || passwordconfirm != "") {
      const passwordpayload = {
        new_password1: password,
        new_password2: passwordconfirm,
      };
      try {
        yield call(fetchJSON, `${API_ROOT}rest-auth/password/change/`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Token " + token,
          },
          body: JSON.stringify(passwordpayload),
        });
      } catch (error) {
        throw error;
      }
    }
    yield put(setOpen(true));
    yield put(setError({}));
    yield put(passwordConfirmOnChange(""));
    yield put(passwordOnChange(""));
    yield put(fyrkrequestchange(false));
  } catch (error) {
    yield put(setError(error));
    yield put(setLoading(false));
    yield put(registerSuccess(false));
  }
  yield put(setLoading(false));
  yield put(registerSuccess(false));
}

export default function* registerWatcher() {
  yield takeLatest(SUBMITREGISTER, registerRequest);
}
