/*
 *
 * RegisterPage constants
 *
 */

export const DEFAULT_ACTION = 'app/RegisterPage/DEFAULT_ACTION';
export const LOGIN_CHANGE = 'app/RegisterPage/LOGIN_CHANGE';
export const PASSWORD_CHANGE = 'app/RegisterPage/PASSWORD_CHANGE';
export const PASSWORDCONFIRM_CHANGE = 'app/RegisterPage/PASSWORDCONFIRM_CHANGE';
export const FYRKREQUEST_CHANGE = 'app/RegisterPage/FYRKREQUEST_CHANGE';
export const SUBMITREGISTER = 'app/RegisterPage/SUBMITREGISTER';
export const AUTH_FAILURE = 'app/RegisterPage/AUTH_FAILURE';
export const REGISTER_SUCCESS = 'app/RegisterPage/REGISTER_SUCCESS';
export const SET_LOADING = 'app/RegisterPage/SET_LOADING';
export const SET_ERROR = 'app/RegisterPage/SET_ERROR';
export const SET_GROUP = 'app/RegisterPage/SET_GROUP';
export const SET_OPEN = 'app/RegisterPage/SET_OPEN';
export const SET_INFOPOP = 'app/RegisterPage/SET_INFOPOP';
export const CHANGE_ORGANISATION = 'app/RegisterPage/CHANGE_ORGANISATION';
export const CHANGE_FIRSTNAME= 'app/RegisterPage/CHANGE_FIRSTNAME';
export const CHANGE_LASTNAME = 'app/RegisterPage/CHANGE_LASTNAME';
export const CHANGE_NEWORGANISATION = 'app/RegisterPage/CHANGE_NEWORGANISATION';

