/*
 * Home Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
  CHANGE_USERNAME,
  TO_LIST,
  TO_MASONRY,
  CHANGE_PAGESIZE,
  CHANGE_PAGE,
  CHANGE_MORE,
} from './constants';

export function changePage(value) {
  return {
    type: CHANGE_PAGE,
    value,
  };
}
export function changeMore(value) {
  return {
    type: CHANGE_MORE,
    value,
  };
}

export function changePageSize(value) {
  return {
    type: CHANGE_PAGESIZE,
    value,
  };
}

export function changeUsername(name) {
  return {
    type: CHANGE_USERNAME,
    name,
  };
}
export function toListView(name) {
  return {
    type: TO_LIST,
    name,
  };
}

export function toMasonryView(name) {
  return {
    type: TO_MASONRY,
    name,
  };
}
