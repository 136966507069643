/**
 * Gets the repositories of the user from Github
 */
import { API_ROOT } from '../../api-config';
import { fromJS } from 'immutable';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { LOAD_KULTUR } from '../../containers/App/constants';
import { kulturLoaded, kulturLoadingError } from '../../containers/App/actions';
import { handleChange } from '../../containers/App/actions';
import { getPage, getPageSize, getResultSize } from './selectors';
import {changeMore,changePage}  from './actions';
import {
  makeSelectStartTime,
  makeSelectEndTime,
  getTimeIndex,
  makeSelectSearch,
  makeSelectPlatsSelected,
  makeSelectKultur,
  makeSelectGroupsSelected,
  makeToggleLeftDrawer,
  makeSelectCategoriesMainSelected,
  makeToggleRightDrawer,
  makeSelectCategories,
  makeSelectCategoriesMain,
  makeSelectValue,
  getKulturSkolan
} from '../../containers/App/selectors';

import request from '../../utils/request';

/**
 * Github repos request/response handler
 */
export function* getKultur(action) {
  if(action.value) {

  } else {

    yield put(changePage(1));
  }
  // Select username from store
  const queryparams = [];
  const currentpage = yield select(getPage());
  const pagesize = yield select(getPageSize());
  const nextpage = currentpage + 1;
  let categories = yield select(makeSelectValue());
  const currentkultur= yield select(makeSelectKultur());
 const kulturskolan = yield select(getKulturSkolan());
 const kulturiskolan = kulturskolan.toJS();
  if (!categories) {
    categories = [];
  }
  const starttime = yield select(makeSelectStartTime());
  const endtime = yield select(makeSelectEndTime());
  const filterindex = yield select(getTimeIndex());
  const maincategories = (yield select(
    makeSelectCategoriesMainSelected(),
  )).toJS();
  const searchparam = yield select(makeSelectSearch());
  const selectedgroups = (yield select(makeSelectGroupsSelected())).toJS();

  const totalcategories = [...categories, ...maincategories];

  let selectedplats = yield select(makeSelectPlatsSelected());
  if (!selectedplats) {
    selectedplats = '';
  }


  if (totalcategories.length > 0) {
    queryparams.categories = totalcategories.join();
  }
  if (selectedgroups.length > 0) {
    queryparams.groups = selectedgroups.join();
  }
  if (selectedplats.length != '') {
    queryparams.regions = selectedplats.id;
  }
  if (kulturiskolan.length > 0) {
    queryparams.kultur__icontains = kulturiskolan.join();
  }

  if (searchparam && searchparam != '') {
    queryparams.textsearch__icontains = searchparam;
  }
  if (starttime != '' && endtime != '') {
    queryparams.created__gt = starttime;
    queryparams.created__lt = endtime;
  }
  queryparams.offset = (currentpage-1)*pagesize;
  queryparams.service = "kulturforum.fi";
  queryparams.profile = "true";
  queryparams.isfritid = "false";
  const queryString = Object.keys(queryparams)
    .map(key => `${key}=${queryparams[key]}`)
    .join('&');
    queryparams.offset = (currentpage-1)*pagesize;

  // const categories = yield select(makeSelectValue());
  let requestURL = `${API_ROOT}posters/?limit=`+pagesize+`&`;
  requestURL += queryString;
  try {
    // Call our request helper (see '../../utils/request')
    const kultur = yield call(request, requestURL);

    if (currentpage > 1) {
      if(kultur.results.length > 0) {
        const totalkultur = [...currentkultur, ...kultur.results];
        yield put(kulturLoaded(totalkultur));
      }
    } else {
      yield put(kulturLoaded(kultur.results));

    }
    if(kultur.next != null) {
      yield put(changeMore(true));
    } else {
      yield put(changeMore(false));
    }
  } catch (err) {
    yield put(kulturLoadingError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* githubData() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(LOAD_KULTUR, getKultur);
}
