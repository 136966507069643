import { fromJS } from 'immutable';

import { CHANGE_USERNAME } from './constants';
import {
  CHANGE_PAGE,
  CHANGE_MORE,
  CHANGE_PAGESIZE,
  TO_LIST,
  TO_MASONRY,
} from './constants';

const initialState = fromJS({
  username: '',
  masonryview: true,
  listview: false,
  pagesize: 20,
  page: 1,
  more : false
});

function kulturReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PAGE:
      return state.set('page', action.value);
    case CHANGE_PAGESIZE:
      return state.set('pagesize', action.value);
    case CHANGE_MORE:
      return state.set('more', action.value);
    case CHANGE_USERNAME:
      return state.set('username', action.name.replace(/@/gi, ''));
    case TO_LIST:
      return state.set('listview', true).set('masonryview', false);
    case TO_MASONRY:
      return state.set('masonryview', true).set('listview', false);
    default:
      return state;
  }
}
export default kulturReducer;
