import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the LoginPage state domain
 */

const selectLoginPageDomain = state => state.get('LoginPage', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by LoginPage
 */

const makeSelectLoginPage = () =>
  createSelector(selectLoginPageDomain, loginState => loginState.toJS());

const makeSelectLoginVisible = () =>
  createSelector(selectLoginPageDomain, loginState =>
    loginState.get('loginvisible'),
  );
const makeSelectLogin = () =>
  createSelector(selectLoginPageDomain, loginState => loginState.get('login'));
const makeSelectMessage = () =>
  createSelector(selectLoginPageDomain, loginState =>
    loginState.get('message'),
  );
const makeSelectLoading = () =>
  createSelector(selectLoginPageDomain, loginState =>
    loginState.get('loading'),
  );
const makeSelectPassword = () =>
  createSelector(selectLoginPageDomain, loginState =>
    loginState.get('password'),
  );
export {
  makeSelectLoginPage,
  makeSelectLogin,
  makeSelectPassword,
  makeSelectMessage,
  makeSelectLoading,
};
