/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import RandomImage from '../../components/RandomImage';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeSelectUserId } from '../App/selectors';
import H2 from '../../components/H2';
import { loadNyhetSingle,deleteNyhet } from './actions';
import { makeSelectNyhet } from './selectors';
import reducer from './reducer';
import { API_ROOT } from '../../api-config';
import { addCategory, loadNyheter, addGroup } from '../App/actions';
import saga from './saga';
import CardMedia from '@material-ui/core/CardMedia';
import moment from 'moment/min/moment-with-locales';
import 'moment/locale/sv';
import EditIcon from '@mui/icons-material/Edit';

const styles = {
  card: {
    marginTop: '1em',
  },
  cardcontent: {
    marginLeft: '3em',
    marginRight: '3em',
  },
  categorylink: {
    cursor: 'pointer',
    color: '#f57d6c',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  iconcolor: {
    marginRight: '0.5em',
    color: '#666',
  },
  title: {
    textAlign: 'left',
    paddingLeft: '1em',
  },
  bigAvatar: {
    width: 150,
    height: 150,
    marginTop: 40,
  },
  avatar: {
    margin: 10,
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
};

export class NyheterSingle extends React.PureComponent {
  componentDidMount() {
    const singleid = this.props.match.params.id;
    this.props.onSubmitForm(singleid);
    this.tempimage = RandomImage();
  }



  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  handleBack() {
    this.props.history.push('/');
  }

  handleItemClickEdit(evt, id) {
    evt.stopPropagation();
    this.props.history.push(`/nyhet/${id}/edit`);
  }

  render() {
    const { classes, nyhet, userid } = this.props;
    const { title, newsimages, categories, groups, added, updated, urls } = nyhet;
    let newsimage = this.tempimage;

    if (newsimages && newsimages.length > 0) {
      newsimage = API_ROOT.replace(/\/$/, '') + newsimages[0].thumbnail;
      newsimage = newsimage.replace(/WxH/g, '1920x1080');
    }
    let description = '';
    if (nyhet.description) {
      description = nyhet.description;
    }
    let editbutton = '';

    if (nyhet.user == userid) {
      editbutton = (
        <Button
          onClick={evt => {
            this.handleItemClickEdit(evt, nyhet.id);
          }}
          variant="text"
          size="small"
          className={classes.editbutton}
        >
          <EditIcon className={classes.leftIcon} />
          REDIGERA
        </Button>
      );
    }
    let deletebutton = '';
    if (nyhet.user == userid) {
      deletebutton = (
        <Button
          onClick={evt => {
            this.props.deleteNyhet(evt, nyhet.id);
          }}
          variant="text"
          size="small"
          className={classes.deletebutton}
        >
          <DeleteIcon className={classes.leftIcon} />
          TA BORT
        </Button>
      );
    }
    let categorieslist = '';
    let groupslist = '';
    if (categories) {
      categorieslist = categories.map(list => {
        if (categories.length > 0) {
          return (
            <a
            key={list.id}
              className={classes.categorylink}
              onClick={evt => {
                this.props.addCategory(evt, list.id, list.name);
              }}
            >
              #{list.name}{' '}
            </a>
          );
        }
      });
    }
    if (groups) {
      groupslist = groups.map(list => {
        if (groups.length > 0) {
          return (
            <a
              id={list.id}
              key={list.id}
              className={classes.categorylink}
              onClick={evt => {
                this.props.addGroup(evt, list.id, list.name);
              }}
            >
              #{list.name}{' '}
            </a>
          );
        }
      });
    }

    let www = '';
    if (urls) {
      www = urls.map((list,index) => {
        if (urls.length > 0) {
          let link = 'Länk';
          if (list.text != 'string' && list.text != '') {
                  link = list.text
              }
        return (
            <a
            className={classes.categorylink}
              key={index}
              id={list.id}
              href={list.url}
            >
            {link}
            </a>
          );
        }
      });

    }

    return (
      <div>
        <Grid container spacing={10}>
          <Grid item xs={false} md={2} />
          <Grid item xs={12} md={8}>
          {newsimage &&
            <CardMedia className={classes.media} image={newsimage} />
          }
            {deletebutton}
            {editbutton}
          </Grid>
          <Grid item xs={false} md={2} />
        </Grid>

        <Grid container spacing={10}>
          <Grid item xs={false} md={3} />
          <Grid item xs={12} md={6}>
            <H2>{title}</H2>
            <p>
              {categorieslist} {groupslist}{' '}
            </p>
            <p>Updaterad: {moment(updated).format('dddd D, MMMM')}</p>
            <p dangerouslySetInnerHTML={{ __html: description }} />
            <p>{www}</p>
          </Grid>

          <Grid item xs={false} md={3} />
        </Grid>
      </div>
    );
  }
}

NyheterSingle.propTypes = {
  evenemang: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
};

const mapStateToProps = createStructuredSelector({
  nyhet: makeSelectNyhet(),
  userid: makeSelectUserId(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onSubmitForm: singleid => {
      dispatch(loadNyhetSingle(singleid));
    },
    deleteNyhet: (evt, id) => {
      dispatch(deleteNyhet(id));
      evt.stopPropagation();
    },
    addCategory: (evt, id, value) => {
      evt.stopPropagation();
      dispatch(addCategory(id, value));
      dispatch(loadNyheter());
      dispatch(push(`/nyheter`));
    },
    addGroup: (evt, id, value) => {
      evt.stopPropagation();
      dispatch(addGroup(id));
      dispatch(loadNyheter());
      dispatch(push(`/nyheter`));
    },
  };
}
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);
const withReducer = injectReducer({ key: 'nyhetsingle', reducer });
const withSaga = injectSaga({ key: 'nyhetsingle', saga });

export default compose(
  withReducer,
  withSaga,
  withRouter,
  withConnect,
  withStyles(styles),
)(NyheterSingle);
