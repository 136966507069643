/*
 *
 * AddFyrk constants
 *
 */

export const DEFAULT_ACTION = 'app/AddFyrk/DEFAULT_ACTION';
export const TITLE_CHANGE = 'app/AddFyrk/TITLE_CHANGE';
export const PROCURATOR_CHANGE = 'app/AddFyrk/PROCURATOR_CHANGE';

export const DESCRIPTION_CHANGE = 'app/AddFyrk/DESCRIPTION_CHANGE';
export const LINK_CHANGE = 'app/AddFyrk/LINK_CHANGE';
export const LINKDESCRIPTION_CHANGE = 'app/AddFyrk/LINKDESCRIPTION_CHANGE';

export const SUBMIT_ADDFYRKFORM = 'app/AddFyrk/SUBMIT_ADDFYRKFORM';
export const IMAGE_DROP = 'app/AddFyrk/IMAGE_DROP';
export const CHANGE_CATEGORY = 'app/AddFyrk/CHANGE_CATEGORY';
export const CHANGE_LOCATION = 'app/AddFyrk/CHANGE_LOCATION';
export const CHANGE_GROUP = 'app/AddFyrk/CHANGE_GROUP';
export const SET_ERROR = 'app/AddFyrk/SET_ERROR';
export const SET_LOADING = 'app/AddFyrk/SET_LOADING';

export const CLEAR_FORM = 'app/AddFyrk/CLEAR_FORM';
export const CHANGE_FYRK = 'app/AddFyrk/CHANGE_FYRK';
export const CHANGE_FYRKDATE = 'app/AddFyrk/CHANGE_FYRKDATE';
export const CHANGE_EDITORSTATE = 'app/AddFyrk/CHANGE_EDITORSTATE';
export const LOAD_FYRKITEM = 'app/AddFyrk/LOAD_FYRKITEM';
export const FYRK_LOADED = 'app/AddFyrk/FYRK_LOADED';
export const CONTACTNAME_CHANGE = 'app/AddFyrk/CONTACTNAME_CHANGE';

export const CONTACTEMAIL_CHANGE = 'app/AddFyrk/CONTACTEMAIL_CHANGE';
export const CONTACTPHONE_CHANGE = 'app/AddFyrk/CONTACTPHONE_CHANGE';
export const CONTACTADDRESS_CHANGE = 'app/AddFyrk/CONTACTADDRESS_CHANGE';
export const NEXTPERIOD_CHANGE = 'app/AddFyrk/NEXTPERIOD_CHANGE';
export const PERIODTYPE_CHANGE = 'app/AddFyrk/PERIODTYPE_CHANGE';
export const PERIODSTATUS_CHANGE = 'app/AddFyrk/PERIODSTATUS_CHANGE';
export const CHANGE_UPDATEREMAIL = 'app/AddFyrk/CHANGE_UPDATEREMAIL';
export const CLEAR_IMAGE = 'app/AddFyrk/CLEAR_IMAGE';
export const RESTORE_IMAGE = 'app/AddFyrk/RESTORE_IMAGE';

