/*
 *
 * AddEvenemang actions
 *
 */
import _ from 'lodash';

import {
  DEFAULT_ACTION,
  TITLE_CHANGESV,
  TITLE_CHANGEFI,
  TITLE_CHANGEEN,
  DESCRIPTION_CHANGE_SV,
  DESCRIPTION_CHANGE_FI,
  DESCRIPTION_CHANGE_EN,
  LINK_CHANGE,
  SET_EDITINDEX,
  SET_CUSTOM_OPEN,
  SEARCH_FIELD,
  SUBMIT_AddEvenemangFORM,
  SET_ERROR,
  CLEAR_FORM,
  CHANGE_RSVP,
  CHANGE_REGION,
  CHANGE_SENDING,
  CHANGE_TABVALUE,
  CHANGE_TABVALUESHORT,
  CHANGE_TABVALUETITLE,
  ADD_PROFILE,
  REMOVE_PROFILE,
  CHANGE_PROFILE,
  CHANGE_LANGUAGE,
  CHANGE_PROFILENAME,
  SET_SUGGESTIONS,
  CLEAR_SUGGESTIONS,
  CHANGE_EDITORSTATE_SV,
  CHANGE_EDITORSTATE_EN,
  CHANGE_EDITORSTATE_FI,
  IMAGE_DROP,
  ADD_GROUP,
  REMOVE_GROUP,
  ADD_META,
  REMOVE_META,
  ADD_DATE,
  REMOVE_DATE,
  ADD_PRICE,
  REMOVE_PRICE,
  ADD_LINK,
  REMOVE_LINK,
  CHANGE_LINK,
  CHANGE_LINKNAME,
  CHANGE_BUYLINK,
  CHANGE_CATEGORY,
  CHANGE_LOCATION,
  STARTDATE_CHANGE,
  STARTHOUR_CHANGE,
  ENDDATE_CHANGE,
  ENDHOUR_CHANGE,
  TOGGLE_FREE,
  SHORTDESCRIPTION_CHANGE_FI,
  SHORTDESCRIPTION_CHANGE_SV,
  SHORTDESCRIPTION_CHANGE_EN,
  CHANGE_ORG,
  EVENT_LOADED,
  CHANGE_PLACE,
  CHANGE_ADDRESS,
  CHANGE_CITY,
  CHANGE_POSTAL,
  CHANGE_PRICE,
  CHANGE_PRICEDESCRIPTION,
  LOAD_EVENT,
  ADD_CATEGORY_EVENEMANG,
  REMOVE_CATEGORY_EVENEMANG,
  PROFILES_LOADED,
  CLEAR_IMAGE,
  RESTORE_IMAGE,
} from './constants';


export function clearImage() {
  return {
    type: CLEAR_IMAGE,
  };
}
export function restoreImage() {
  return {
    type: RESTORE_IMAGE,
  };
}
export function addCategoryEvenemang(id, name) {
  return {
    type: ADD_CATEGORY_EVENEMANG,
    id,
    name,
  };
}
export function removeCategoryEvenemang(value) {
  return {
    type: REMOVE_CATEGORY_EVENEMANG,
    value,
  };
}
export function loadEventSingle(id) {
  return {
    type: LOAD_EVENT,
    id,
  };
}
export function eventLoaded(event, eventkeywords, eventgroups, eventlocations) {
  return {
    type: EVENT_LOADED,
    event,
    eventkeywords,
    eventgroups,
    eventlocations,
  };
}
export function profilesLoaded(value) {
  return {
    type: PROFILES_LOADED,
    value,
  };
}
export function changeEditorStateSv(value) {
  return {
    type: CHANGE_EDITORSTATE_SV,
    value,
  };
}
export function setCustomOpen(value) {
  return {
    type: SET_CUSTOM_OPEN,
    value,
  };
}
export function changeEditorStateEn(value) {
  return {
    type: CHANGE_EDITORSTATE_EN,
    value,
  };
}
export function changeEditorStateFi(value) {
  return {
    type: CHANGE_EDITORSTATE_FI,
    value,
  };
}
export function changePlace(value) {
  return {
    type: CHANGE_PLACE,
    value,
  };
}
export function changeLanguage(value) {
  return {
    type: CHANGE_LANGUAGE,
    value,
  };
}
export function changeSending(value) {
  return {
    type: CHANGE_SENDING,
    value,
  };
}
export function clearForm() {
  return {
    type: CLEAR_FORM,
  };
}
export function setError(value) {
  return {
    type: SET_ERROR,
    value,
  };
}
export function changePriceDescription(id, value) {
  return {
    type: CHANGE_PRICEDESCRIPTION,
    id,
    value,
  };
}
export function changePrice(id, value) {
  return {
    type: CHANGE_PRICE,
    id,
    value,
  };
}
export function changeCity(value) {
  return {
    type: CHANGE_CITY,
    value,
  };
}
export function changeTabValue(value) {
  return {
    type: CHANGE_TABVALUE,
    value,
  };
}
export function changeTabValueTitle(value) {
  return {
    type: CHANGE_TABVALUETITLE,
    value,
  };
}
export function changeTabValueShort(value) {
  return {
    type: CHANGE_TABVALUESHORT,
    value,
  };
}
export function changeProfile(id, value, profilelink) {
  return {
    type: CHANGE_PROFILE,
    id,
    value,
    profilelink,
  };
}
export function changeProfileName(id, value) {
  return {
    type: CHANGE_PROFILENAME,
    id,
    value,
  };
}
export function changeLink(id, value) {
  return {
    type: CHANGE_LINK,
    id,
    value,
  };
}
export function changeLinkName(id, value) {
  return {
    type: CHANGE_LINKNAME,
    id,
    value,
  };
}
export function toggleFree() {
  return {
    type: TOGGLE_FREE,
  };
}
export function changeAddress(value) {
  return {
    type: CHANGE_ADDRESS,
    value,
  };
}
export function changeRSVP(value) {
  return {
    type: CHANGE_RSVP,
    value,
  };
}
export function changeOrg(value) {
  return {
    type: CHANGE_ORG,
    value,
  };
}
export function changeBuyLink(id, value) {
  return {
    type: CHANGE_BUYLINK,
    id,
    value,
  };
}
export function changePostal(value) {
  return {
    type: CHANGE_POSTAL,
    value,
  };
}
export function changeRegion(value) {
  return {
    type: CHANGE_REGION,
    value,
  };
}
export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}
export function titleOnChangeFi(title) {
  return {
    type: TITLE_CHANGEFI,
    value: title,
  };
}
export function titleOnChangeSv(title) {
  return {
    type: TITLE_CHANGESV,
    value: title,
  };
}
export function titleOnChangeEn(title) {
  return {
    type: TITLE_CHANGEEN,
    value: title,
  };
}
export function handleChange(value) {
  return {
    type: CHANGE_CATEGORY,
    value,
  };
}
export function handleChangeLocation(value) {
  return {
    type: CHANGE_LOCATION,
    value,
  };
}
export function linkOnChange(link) {
  return {
    type: LINK_CHANGE,
    value: link,
  };
}

export function changeStartDate(id, value) {
  return {
    type: STARTDATE_CHANGE,
    id,
    value,
  };
}

export function changeEndDate(id, value) {
  return {
    type: ENDDATE_CHANGE,
    id,
    value,
  };
}

export function imageDrop(image) {
  return {
    type: IMAGE_DROP,
    value: image,
  };
}
export function submitAddEvenemangForm(data) {
  return {
    type: SUBMIT_AddEvenemangFORM,
    value: data,
  };
}
export function descriptionOnChangeSv(description) {
  return {
    type: DESCRIPTION_CHANGE_SV,
    value: description,
  };
}
export function descriptionOnChangeFi(description) {
  return {
    type: DESCRIPTION_CHANGE_FI,
    value: description,
  };
}
export function descriptionOnChangeEn(description) {
  return {
    type: DESCRIPTION_CHANGE_EN,
    value: description,
  };
}
export function shortdescriptionOnChangeFi(shortdescription) {
  return {
    type: SHORTDESCRIPTION_CHANGE_FI,
    value: shortdescription,
  };
}
export function shortdescriptionOnChangeSv(shortdescription) {
  return {
    type: SHORTDESCRIPTION_CHANGE_SV,
    value: shortdescription,
  };
}
export function shortdescriptionOnChangeEn(shortdescription) {
  return {
    type: SHORTDESCRIPTION_CHANGE_EN,
    value: shortdescription,
  };
}

export function removeProfile(value) {
  return {
    type: REMOVE_PROFILE,
    value,
  };
}

export function addProfile(value) {
  return {
    type: ADD_PROFILE,
    value,
  };
}
export function setEditIndex(value) {
  return {
    type: SET_EDITINDEX,
    value,
  };
}
export function removeLink(value) {
  return {
    type: REMOVE_LINK,
    value,
  };
}

export function addLink(value) {
  return {
    type: ADD_LINK,
    value,
  };
}
export function removePrice(value) {
  return {
    type: REMOVE_PRICE,
    value,
  };
}

export function addPrice(value) {
  return {
    type: ADD_PRICE,
    value,
  };
}
export function removeDate(value) {
  return {
    type: REMOVE_DATE,
    value,
  };
}

export function addDate(value) {
  return {
    type: ADD_DATE,
    value,
  };
}

export function setSuggestions(value) {
  return {
    type: SET_SUGGESTIONS,
    value,
  };
}
export function clearSuggestions(value) {
  return {
    type: SET_SUGGESTIONS,
    value,
  };
}
export function handleSearchFieldValue(value) {
  return {
    type: SEARCH_FIELD,
    value,
  };
}

export function addMeta(value) {
  return {
    type: ADD_META,
    value,
  };
}
export function removeMeta(value) {
  return {
    type: REMOVE_META,
    value,
  };
}
export function removeGroup(value) {
  return {
    type: REMOVE_GROUP,
    value,
  };
}
export function addGroup(value) {
  return {
    type: ADD_GROUP,
    value,
  };
}
