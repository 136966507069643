/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const LOAD_FYRK = 'boilerplate/FYRKListItem/LOAD_FYRK';
export const FYRK_LOADED = 'boilerplate/FYRKListItem/FYRK_LOADED';
export const DELETE_FYRK = 'boilerplate/FYRKListItem/DELETE_FYRK';
export const LIKE_FYRK = 'boilerplate/FYRKListItem/LIKE_FYRK';
export const DISLIKE_FYRK = 'boilerplate/FYRKListItem/DISLIKE_FYRK';