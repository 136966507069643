/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */
import { push } from "connected-react-router";
import { withRouter } from "react-router-dom";

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import injectReducer from "../../utils/injectReducer";
import injectSaga from "../../utils/injectSaga";
import FyrkImage from "../../components/FyrkImage";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  makeSelectUserId,
  makeSelectCurrentUser,
  getFavoriter,
} from "../App/selectors";
import H2 from "../../components/H2";
import { loadFyrkSingle, deleteFyrk } from "./actions";
import { makeSelectFyrk } from "./selectors";
import reducer from "./reducer";
import { API_ROOT } from "../../api-config";
import { likeEvent, dislikeEvent, addCategory, addGroup } from "../App/actions";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import PopFavorite from "../../components/PopFavorite";
import FyrkSingleCard from "../FyrkSingleCard";
import saga from "./saga";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import moment from "moment/min/moment-with-locales";
import "moment/locale/sv";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const styles = (theme) => ({
  alignright: {
    float: "right",
    marginRight: "1em",
    textAlign: "right",
  },
  root: {
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      flexFlow: "column",
    },
    [theme.breakpoints.up("sm")]: {
      display: "block",
      flexFlow: "nowrap",
    },

    [theme.breakpoints.up("md")]: {
      display: "flex",
      paddingBottom: "5em",
    },
    backgroundColor: "transparent",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },

  content: {
    // flex: '1 0 auto',
    order: 3,
  },

  card: {
    marginTop: "1em",
  },
  actions: {
    alignItems: "flex-start",
    flexDirection: "column",
    order: 3,
  },
  cardcontent: {
    marginLeft: "3em",
    marginRight: "3em",
  },
  description: {
    whiteSpace: "pre-wrap",
    marginTop: "2em",
    marginBottom: "1em",
  },
  categorylink: {
    cursor: "pointer",
    color: "#000000",
  },

  collabutton: {
    color: "#fff",
    fontWeight: "bold",
  },
  media: {
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      margin: "0 0em auto 0",
      padding: "1em",
      order: 1,
    },
    [theme.breakpoints.up("md")]: {
      width: "16em",
      height: "14em",
      margin: "1em 4em 2em 0",
      padding: "0",
      order: 1,
    },
  },

  iconcolor: {
    marginRight: "0.5em",
    color: "#666",
  },
  title: {
    textAlign: "left",
    paddingLeft: "1em",
  },
  bigAvatar: {
    width: 150,
    height: 150,
    marginTop: 40,
  },
  avatar: {
    width: "10em",
    height: "10em",
    margin: "0em 6em 1em 0",
  },
  row: {
    display: "flex",
    justifyContent: "center",
  },
  likebutton: {
    color: "#44AB66",
    backgroundColor: "##E2F3E7",
  },
  backarrow: {
    color: "#000000",
  },
});

export class FyrkerSingle extends React.PureComponent {
  componentDidMount() {
    const singleid = this.props.match.params.id;
    this.props.onSubmitForm(singleid);
    this.tempimage = FyrkImage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  handleBack() {
    this.props.history.push("/");
  }

  handleItemClickEdit(evt, id) {
    evt.stopPropagation();
    this.props.history.push(`/fyrkpost/${id}/edit`);
  }

  render() {
    const { item, classes, fyrk, userid, user, favoriter } = this.props;

    const favoriteids = _.map(favoriter, "urls");
    const itempath = `${API_ROOT}fyrk/${fyrk.id}/`;
    let favorite = false;
    if (_.indexOf(favoriteids, itempath) != -1) {
      favorite = true;
    }



    return (
      <div>
       <FyrkSingleCard fyrk={fyrk}  user={user} item={item} classes={classes} favoriter={favoriter} userid={userid}/>
      </div>
    );
  }
}

FyrkerSingle.propTypes = {
  item: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  fyrk: makeSelectFyrk(),
  userid: makeSelectUserId(),
  user: makeSelectCurrentUser(),
  favoriter: getFavoriter(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onSubmitForm: (singleid) => {
      dispatch(loadFyrkSingle(singleid));
    },
    deleteFyrk: (evt, id) => {
      dispatch(deleteFyrk(id));
      evt.stopPropagation();
    },
    addCategory: (evt, id, value) => {
      evt.stopPropagation();
      dispatch(addCategory(id, value));
      //dispatch(loadFyrker());
      dispatch(push(`/fyrk`));
    },
    addGroup: (evt, id, value) => {
      evt.stopPropagation();
      dispatch(addGroup(id));
      //dispatch(loadFyrker());
      dispatch(push(`/fyrk`));
    },
    likeFyrk: (evt, id) => {
      dispatch(likeEvent(id, "FYRK"));
      evt.stopPropagation();
    },
    dislikeFyrk: (evt, id) => {
      dispatch(dislikeEvent(id, "FYRK"));
      evt.stopPropagation();
    },
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: "fyrksingle", reducer });
const withSaga = injectSaga({ key: "fyrksingle", saga });

export default compose(
  withReducer,
  withSaga,
  withRouter,
  withConnect,
  withStyles(styles)
)(FyrkerSingle);
