/**
 * Gets the repositories of the user from Github
 */

import { takeLatest, takeEvery } from 'redux-saga/effects';
import { take, call, put, select, fork, cancel } from 'redux-saga/effects';
import { makeSelectUserId } from '../../containers/App/selectors';
import { push, browserHistory } from 'connected-react-router';
import moment from 'moment';

import request from '../../utils/request';
import { API_ROOT } from '../../api-config';
import { setError, setLoading, clearForm, nyhetLoaded } from './actions';

import { SUBMIT_ADDNYHETFORM, LOAD_NEWSITEM } from './constants';
import {
  makeSelectDescription,
  makeSelectTitle,
  makeSelectLink,
  makeSelectPictures,
  makeSelectValue,
  getSelectedGroups,
  getSelectedLocations,
  getFyrk,
  getFyrkDate,
  getNewsItem,
} from './selectors';

const fetchJSON = (url, options = {}) =>
  new Promise((resolve, reject) =>
    fetch(url, options)
      .then(response =>
        response.status !== 201 &&
        response.status !== 200 &&
        response.status !== 400
          ? reject(response)
          : response,
      )
      .then(response => {
        if (response.status == 400) {
          response.json().then(error => reject(error));
        } else {
          return response.json();
        }
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => reject(error)),
  );

export function* loadNewsItemRequest(action) {
  yield put(clearForm());
  const requestURL = `${API_ROOT}news/${action.value}`;

  try {
    const nyhet = yield call(request, requestURL);
    yield put(nyhetLoaded(nyhet));

    yield put(setLoading(false));
  } catch (err) {
    // yield put(nyheterLoadingError(err));
  }
}
export function* addNyhetRequest() {
  const title = yield select(makeSelectTitle());
  const description = yield select(makeSelectDescription());
  const link = yield select(makeSelectLink());
  const images = yield select(makeSelectPictures());
  const userid = yield select(makeSelectUserId());
  const values = yield select(makeSelectValue());
  const locations = yield select(getSelectedLocations());
  const groups = yield select(getSelectedGroups());
  const fyrk = yield select(getFyrk());
  const fyrkdate = yield select(getFyrkDate());
  const tempdate = moment(fyrkdate);
  const enddatestring = tempdate.toISOString();
  const newsitem = yield select(getNewsItem());
  let tempimage = false;
  let image = false;

  if (images.length > 0) {
    tempimage = images[0];
    image = tempimage;
  }

  function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.toString().split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const payload = {
    user: userid,
    title,
    description,
    opens: '2018-10-16T22:02:04.115000+03:00',
    fyrk,
    expires: enddatestring,
  };

  if (link != '') {
    payload.urls = [
      {
        url: link,
        text: 'string',
      },
    ];
  }
  const newsid = newsitem.id || 0;
  if (values.length > 0) {
    const catObjData = values.map((data, idx) => ({ id: data.id }));
    payload.categories = catObjData;
  }
  if (locations.length > 0) {
    const locationObjData = locations.map((data, idx) => ({ id: data.id }));
    payload.regions = locationObjData;
  }
  if (groups.length > 0) {
    const groupObjData = groups.map((data, idx) => ({ id: data.id }));
    payload.groups = groupObjData;
  }
  let path = `${API_ROOT}news/${newsitem.id}/`;
  let method = 'PUT';
  if (newsid == 0) {
    path = `${API_ROOT}news/`;
    method = 'POST';
  }

  const token = localStorage.getItem('token');
  try {
    const res = yield call(fetchJSON, path, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(payload),
    });

    if (image && !image.includes('http')) {
      const file = dataURLtoFile(image, 'testFile.png');
      const imagepayload = { role: 'news', file };

      const formData = new FormData();

      for (const name in imagepayload) {
        formData.append(name, imagepayload[name]);
      }

      try {
        const resimage = yield call(
          fetchJSON,
          `${API_ROOT}news/${res.id}/upload_image/`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              Authorization: `Token ${token}`,
            },
            body: formData,
          },
        );
      } catch (error) {}
    }
    yield put(clearForm());
    yield put(push(`/nyhet/${res.id}`));
  } catch (error) {
    yield put(setError(error));
  }
  yield put(setLoading(false));
}

export default function* loginWatcher() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(LOAD_NEWSITEM, loadNewsItemRequest);

  yield takeLatest(SUBMIT_ADDNYHETFORM, addNyhetRequest);
}
