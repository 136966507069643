/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */


import {
  LOGOUT,
  SET_SCROLL,
  LOAD_EVENEMANG,
  LOAD_REGIONS_SUCCESS,
  LOAD_LIKES,
  LOAD_EVENEMANG_SUCCESS,
  LOAD_EVENEMANG_ERROR,
  LOAD_NYHETER,
  LOAD_NYHETER_SUCCESS,
  LOAD_NYHETER_ERROR,
  LOAD_FYRK,
  LOAD_FYRK_SUCCESS,
  LOAD_FYRK_ERROR,
  LOAD_KULTUR,
  LOAD_GROUPS_EVENEMANG,
  LOAD_KULTUR_SUCCESS,
  HIDE_DRAWERS,
  LOAD_KULTUR_ERROR,
  LOAD_CATEGORIES,
  CHANGE_PLATS,
  SET_PERIODTYPE,
  LOAD_CATEGORIES_MAINEVENEMANG,
  CLEAR_SUGGESTIONS,
  FILTER_DATE,
  LOAD_FAVORITER,
  FILTER_DATEEND,
  FILTER_DATESTART,
  FILTER_DATE_EVENT,
  FILTER_DATEEND_EVENT,
  FILTER_DATESTART_EVENT,
  FILTER_SET_REFERENCETIME,
  FILTER_SET_REFERENCETIMEEVENT,
  LOAD_CATEGORIES_EVENEMANG,
  LOAD_LOCATIONS_EVENEMANG,
  LOAD_CATEGORIES_MAIN,
  LOAD_CATEGORIES_SUCCESS,
  LOAD_GROUPS,
  LOAD_GRUPS,
  LOAD_PLATS,
  LOAD_TAGS,
  TOGGLE_LEFTMENU,
  TOGGLE_RIGHTMENU,
  TOGGLE_RIGHTDRAWER,
  CHANGE_CATEGORY,
  CHANGE_CATEGORY_EVENEMANG,
  CHANGE_LOCATION_EVENEMANG,
  ADD_CATEGORY,
  REMOVE_CATEGORY,
  ADD_CATEGORY_EVENEMANG,
  REMOVE_CATEGORY_EVENEMANG,
  ADD_GROUP,
  REMOVE_GROUP,
  ADD_KULTUR,
  REMOVE_KULTUR,
  ADD_GROUP_EVENEMANG,
  REMOVE_GROUP_EVENEMANG,
  SEARCHEVENEMANG,
  SEARCH,
  SEARCH_FIELD,
  SEARCH_FIELD_EVENEMANG,
  SET_SUGGESTIONS,
  SET_SUGGESTIONS_EVENEMANG,
  SET_USER,
  SET_HELP,
  SET_PROFILE,
  HIDE_LEFTMENU,
  CHANGE_TIMEINDEX,
  CHANGE_TIMEINDEX_EVENT,
  DELETE_NYHET,
  DELETE_FYRK,
  LIKE_EVENT,
  DISLIKE_EVENT,
  DELETE_PROFILE,
  DELETE_EVENT,
  HASPROFILE,
  OPEN_DIALOG,CLOSE_DIALOG,
  Q_OPEN_DIALOG,Q_CLOSE_DIALOG,
  SET_CURRENT_EVENT,
  REMOVE_USERGROUP,
  SET_USERGROUP,
  DELETE_PROFILEGROUP,
  REMOVE_USER,
  CHANGE_FIRSTNAME,
  CHANGE_LASTNAME,
  CHANGE_USERNAME
} from './constants';

/**
 * Load the repositories, this action starts the request saga
 *
 * @return {object} An action object with a type of LOAD_EVENEMANG
 */
export function deleteNyhet(id) {
  return {
    type: DELETE_NYHET,
    id,
  };
}
export function deleteFyrk(id) {
  return {
    type: DELETE_FYRK,
    id,
  };
}
export function deleteEvent(id) {
  return {
    type: DELETE_EVENT,
    id,
  };
}
export function openDialog() {
  return {
    type: OPEN_DIALOG,
  };
}
export function closeDialog() {
  return {
    type: CLOSE_DIALOG,
  };
}
export function QopenDialog() {
  return {
    type: Q_OPEN_DIALOG,
  };
}
export function QcloseDialog() {
  return {
    type: Q_CLOSE_DIALOG,
  };
}
export function likeEvent(id, itemtype) {
  return {
    type: LIKE_EVENT,
    id,
    itemtype,
  };
}
export function loadLikes() {
  return {
    type: LOAD_LIKES,
  };
}
export function dislikeEvent(id, itemtype) {
  return {
    type: DISLIKE_EVENT,
    id,
    itemtype,
  };
}
export function hasProfile(value) {
  return {
    type: HASPROFILE,
    value,
  };
}
export function setCurrentEvent(value) {
  return {
    type: SET_CURRENT_EVENT,
    value,
  };
}
export function deleteProfile(id) {
  return {
    type: DELETE_PROFILE,
    id,
  };
}
export function toggleLeftDrawer() {
  return {
    type: TOGGLE_LEFTMENU,
  };
}
export function hideLeftDrawer() {
  return {
    type: HIDE_LEFTMENU,
  };
}
export function toggleRightDrawer(anchorEl) {
  return {
    type: TOGGLE_RIGHTMENU,
    anchorEl,
  };
}
export function toggleRightMenu() {
  return {
    type: TOGGLE_RIGHTDRAWER,
  };
}

export function evenemangLoaded(evenemang, username) {
  return {
    type: LOAD_EVENEMANG_SUCCESS,
    evenemang,
    username,
  };
}
export function loadEvenemang(value) {
  return {
    type: LOAD_EVENEMANG,
    value
  };
}

export function loadCategoriesEvenemang(value) {
  return {
    type: LOAD_CATEGORIES_EVENEMANG,
    value,
  };
}
export function changeTimeIndex(value) {
  return {
    type: CHANGE_TIMEINDEX,
    value,
  };
}
export function changeTimeIndexEvent(value) {
  return {
    type: CHANGE_TIMEINDEX_EVENT,
    value,
  };
}
export function loadGroupsEvenemang(value) {
  return {
    type: LOAD_GROUPS_EVENEMANG,
    value,
  };
}

export function changeLastName(value) {
  return {
    type: CHANGE_LASTNAME,
    value,
  };
}
export function changeFirstName(value) {
  return {
    type: CHANGE_FIRSTNAME,
    value,
  };
}
export function loadLocationsEvenemang(value) {
  return {
    type: LOAD_LOCATIONS_EVENEMANG,
    value,
  };
}
export function loadCategoriesMain(value) {
  return {
    type: LOAD_CATEGORIES_MAIN,
    value,
  };
}
export function loadCategoriesMainEvenemang(value) {
  return {
    type: LOAD_CATEGORIES_MAINEVENEMANG,
    value,
  };
}
export function loadCategories(value) {
  return {
    type: LOAD_CATEGORIES,
    value,
  };
}
export function tagsLoaded(value) {
  return {
    type: LOAD_TAGS,
    value,
  };
}
export function logOut() {
  return {
    type: LOGOUT,
  };
}

export function setUser(value) {
  return {
    type: SET_USER,
    value,
  };
}
export function setHelp(value) {
  return {
    type: SET_HELP,
    value,
  };
}
export function hideDrawers(value) {
  return {
    type: HIDE_DRAWERS,
    value,
  };
}
export function setProfile(value) {
  return {
    type: SET_PROFILE,
    value,
  };
}
export function setSuggestions(value) {
  return {
    type: SET_SUGGESTIONS,
    value,
  };
}
export function clearSuggestions(value) {
  return {
    type: SET_SUGGESTIONS,
    value,
  };
}

export function setSuggestionsEvenemang(value) {
  return {
    type: SET_SUGGESTIONS_EVENEMANG,
    value,
  };
}
export function clearSuggestionsEvenemang(value) {
  return {
    type: SET_SUGGESTIONS_EVENEMANG,
    value,
  };
}
export function loadGroups(value) {
  return {
    type: LOAD_GROUPS,
    value,
  };
}
export function loadGrups(value) {
  return {
    type: LOAD_GRUPS,
    value,
  };
}
export function loadPlats(value) {
  return {
    type: LOAD_PLATS,
    value,
  };
}
export function loadFavoriter(value) {
  return {
    type: LOAD_FAVORITER,
    value,
  };
}
export function evenemangLoadingError(error) {
  return {
    type: LOAD_EVENEMANG_ERROR,
    error,
  };
}
export function loadNyheter(value) {
  return {
    type: LOAD_NYHETER,
    value,
  };
}
export function nyheterLoaded(nyheter) {
  return {
    type: LOAD_NYHETER_SUCCESS,
    nyheter,
  };
}
export function nyheterLoadingError(error) {
  return {
    type: LOAD_NYHETER_ERROR,
    error,
  };
}
export function loadFyrk(value) {
  return {
    type: LOAD_FYRK,
    value,
  };
}
export function fyrkLoaded(fyrk) {
  return {
    type: LOAD_FYRK_SUCCESS,
    fyrk,
  };
}
export function fyrkLoadingError(error) {
  return {
    type: LOAD_FYRK_ERROR,
    error,
  };
}
export function changeUserName(value) {
  return {
    type: CHANGE_USERNAME,
    value,
  };
}

export function regionsLoaded(regions) {
  return {
    type: LOAD_REGIONS_SUCCESS,
    regions,
  };
}
export function categoriesLoaded(categories) {
  return {
    type: LOAD_CATEGORIES_SUCCESS,
    categories,
  };
}

export function handleChange(value) {
  return {
    type: CHANGE_CATEGORY,
    value,
  };
}
export function filterByDate(startdate, enddate) {
  return {
    type: FILTER_DATE,
    startdate,
    enddate,
  };
}
export function filterSetReferenceTime(referencetime) {
  return {
    type: FILTER_SET_REFERENCETIME,
    referencetime,
  };
}
export function filterSetReferenceTimeEvent(referencetimeevent) {
  return {
    type: FILTER_SET_REFERENCETIMEEVENT,
    referencetimeevent,
  };
}
export function filterByDateStart(startdate) {
  return {
    type: FILTER_DATESTART,
    startdate,
  };
}
export function filterByDateEnd(enddate) {
  return {
    type: FILTER_DATEEND,
    enddate,
  };
}
export function filterByDateEvent(startdate, enddate) {
  return {
    type: FILTER_DATE_EVENT,
    startdate,
    enddate,
  };
}
export function filterByDateStartEvent(startdate) {
  return {
    type: FILTER_DATESTART_EVENT,
    startdate,
  };
}
export function filterByDateEndEvent(enddate) {
  return {
    type: FILTER_DATEEND_EVENT,
    enddate,
  };
}
export function handleChangePlats(value) {
  return {
    type: CHANGE_PLATS,
    value,
  };
}
export function setPeriodType(value) {
  return {
    type: SET_PERIODTYPE,
    value,
  };
}
export function handleChangeEvenemang(value) {
  return {
    type: CHANGE_CATEGORY_EVENEMANG,
    value,
  };
}
export function handleChangeEvenemangLocation(value) {
  return {
    type: CHANGE_LOCATION_EVENEMANG,
    value,
  };
}
export function handleSearch(value) {
  return {
    type: SEARCH,
    value,
  };
}
export function handleSearchFieldValue(value) {
  return {
    type: SEARCH_FIELD,
    value,
  };
}
export function handleSearchFieldValueEvenemang(value) {
  return {
    type: SEARCH_FIELD_EVENEMANG,
    value,
  };
}
export function handleSearchEvenemang(value) {
  return {
    type: SEARCHEVENEMANG,
    value,
  };
}
export function addGroup(value) {
  return {
    type: ADD_GROUP,
    value,
  };
}
export function removeGroup(value) {
  return {
    type: REMOVE_GROUP,
    value,
  };
}
export function addKultur(value) {
  return {
    type: ADD_KULTUR,
    value,
  };
}
export function removeKultur(value) {
  return {
    type: REMOVE_KULTUR,
    value,
  };
}
export function addGroupEvenemang(value) {
  return {
    type: ADD_GROUP_EVENEMANG,
    value,
  };
}
export function removeGroupEvenemang(value) {
  return {
    type: REMOVE_GROUP_EVENEMANG,
    value,
  };
}
export function addCategory(id, name) {
  return {
    type: ADD_CATEGORY,
    id,
    name,
  };
}
export function removeCategory(value) {
  return {
    type: REMOVE_CATEGORY,
    value,
  };
}
export function setScroll(value) {
  return {
    type: SET_SCROLL,
    value,
  };
}
export function addCategoryEvenemang(id, name) {
  return {
    type: ADD_CATEGORY_EVENEMANG,
    id,
    name,
  };
}
export function removeCategoryEvenemang(value) {
  return {
    type: REMOVE_CATEGORY_EVENEMANG,
    value,
  };
}

export function kulturLoaded(kultur) {
  return {
    type: LOAD_KULTUR_SUCCESS,
    kultur,
  };
}
export function loadKultur(value) {
  return {
    type: LOAD_KULTUR,
    value
  };
}

export function kulturLoadingError(error) {
  return {
    type: LOAD_KULTUR_ERROR,
    error,
  };
}
export function setUSerGroup(value) {
  return {
    type: SET_USERGROUP,
    value,
  };
}
export function removeUserGroup(value,userid) {
  return {
    type: REMOVE_USERGROUP,
    value,
    userid
  };
}
export function deleteProfileGroup(profileid, usergroupid) {
  return {
    type: DELETE_PROFILEGROUP,
    profileid,usergroupid
  };
}
export function removeUser() {
  return {
    type: REMOVE_USER
  };
}