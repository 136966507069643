import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(1),
    backgroundColor: 'transparent',
    fontSize: '1em',
  },
  chip1: {
    margin: theme.spacing(1),
    backgroundColor: 'transparent',
    fontSize: '1em',
    fontWeight: 'bold',
  },
  alignright: {
    float: 'right',
    marginRight: '1em',
    textAlign: 'right',
  },
  alignleft: {
    marginLeft: '1em',
    textAlign: 'left',
    float: 'left',
    marginTop: '-8px',
  },
  nomarginSection: {
    margin: 0,
    paddingBottom: '2em',
  },
});

function BreadCrumb(props) {
  const { breadcrumb, classes, locationname } = props;
  const location = props.location.pathname;
  if (location == '/evenemang') {
    let categories = <span />;
    if (breadcrumb) {
      categories = breadcrumb.map(list => {
        const temp = list.toJS();
        if (breadcrumb.length > 0) {
          return (
            <Chip
              label={`#${temp.name}`}
              key={temp.id}
              onDelete={() => props.removeCategory(temp.id)}
              className={classes.chip}
            />
          );
        }
      });
    }
    let locationhashtag = '';
    if (locationname) {
      locationhashtag = (
        <Chip
          label={`#${locationname.name}`}
          key={locationname.id}
          onDelete={() => props.removeLocation()}
          className={classes.chip}
        />
      );
    }

    let val = '';
    if (locationhashtag !== '' || breadcrumb.length > 0) {
      val = <Chip className={classes.chip1} label={'Valda kategorier: '} />
      
    }

    return (
      <span>
        {val}
        {locationhashtag} {categories}
      </span>
    );
  }
  if (location == '/nyheter' || location == '/profiler' || location == '/fyrk') {
    const categories = breadcrumb.toJS().map(list => {
      if (breadcrumb.toJS().length > 0) {
        return (
          <Chip
            label={`#${list.name}`}
            onDelete={() => props.removeCategory(list.id)}
            key={list.id}
            className={classes.chip}
          />
        );
      }

    });
    let clearAll="";
    let val = '';
    if (breadcrumb.toJS().length > 0) {
      clearAll= (  <Chip
      label="Rensa val"
      onDelete={() => props.removeAllCategories()}
      className={classes.chip}
    />    );
    val = <Chip className={classes.chip1} label={'Valda kategorier: '} />

    }
    let locationhashtag = '';
    if (locationname) {
      locationhashtag = (
        <Chip
          label={`#${locationname.name}`}
          onDelete={() => props.removeLocation(locationname.id)}
          key={locationname.id}
          className={classes.chip}
        />
      );
    }

    return (
      <span>
        {val} {locationhashtag} {categories}{clearAll}
        
      </span>
    );
  }

  return '';
}

BreadCrumb.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  repos: PropTypes.any,
};

export default withStyles(styles)(BreadCrumb);
