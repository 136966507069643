export const LOAD_EVENEMANG = 'boilerplate/EvenemangsingleModal/LOAD_EVENEMANG ';
export const EVENEMANG_LOADED = 'boilerplate/EvenemangsingleModal/EVENEMANG_LOADED';
export const SUBEVENEMANG_LOADED = 'boilerplate/EvenemangsingleModal/SUBEVENEMANG_LOADED';

export const DELETE_EVENEMANG = 'boilerplate/EvenemangsingleModal/DELETE_EVENEMANG';
export const SET_PROFILE = 'boilerplate/EvenemangsingleModal/SET_PROFILE';
export const OPEN_DIALOG = 'boilerplate/EvenemangsingleModal/OPEN_DIALOG';

export const CLOSE_DIALOG = 'boilerplate/EvenemangsingleModal/CLOSE_DIALOG';
export const SET_TABVALUE = 'boilerplate/EvenemangsingleModal/SET_TABVALUE';
export const SET_TABVALUE_TITLE = 'boilerplate/EvenemangsingleModal/SET_TABVALUE_TITLE';

