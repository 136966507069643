import { call, put, select, takeLatest } from 'redux-saga/effects';
import { LOAD_EVENEMANG } from '../../containers/App/constants';
import { evenemangLoaded, evenemangLoadingError } from '../../containers/App/actions';

import {
  makeSelectEvenemang,
  getReferencetimeevent,
  getTimeIndexEvent,
  makeSelectGroupsSelectedEvenemang,
  makeSelectCategoriesEvenemangSelected,
  getEventLocationName,
  makeSelectSearchEvenemang,
  makeSelectCategoriesMainSelectedEvenemang,
} from '../../containers/App/selectors';
import request from '../../utils/request';
import moment from 'moment/min/moment-with-locales';
import { getPage, getPageSize } from './selectors';
import { changeMore, changePage } from './actions';
import {LE_ROOT } from '../../api-config';


export function* getEvenemang(action) {
  if (action.value) {
  } else {
    yield put(changePage(1));
  }
  // Select username from store
  const maincategoriesevenemang = (yield select(
    makeSelectCategoriesMainSelectedEvenemang(),
  )).toJS();
  const queryparams = [];
  const currentpage = yield select(getPage());
  const nextpage = currentpage + 1;
  const limit = yield select(getPageSize());
  let categories = yield select(makeSelectCategoriesEvenemangSelected());
  const groups = yield select(makeSelectGroupsSelectedEvenemang());
  let groupsarray = groups.toJS();
  let starttimeevent = '';
  let endtimeevent = '';
  let referencetime = yield select(getReferencetimeevent());

  if (referencetime == '') {
    referencetime = moment();
  }

  const currentevents = yield select(makeSelectEvenemang());
  if (!categories) {
    categories = [];
  }
  if (!groupsarray) {
    groupsarray = [];
  }
  let totalcategories = groupsarray.concat(categories);
  const locations = yield select(getEventLocationName());
  if (locations) {
    if (locations.type == 'region') {
      queryparams.address_region = locations.id;
    } else {
      if (locations.name.toLowerCase() == 'vasa' || locations.name.toLowerCase() == 'vaasa') {
        queryparams.address_locality_sv = 'Vaasa,Vasa';
      } else {
        queryparams.address_locality_sv = locations.name;
      }
    }
  }
  totalcategories = [...maincategoriesevenemang, ...totalcategories];
  const filterindex = yield select(getTimeIndexEvent());

  if (filterindex) {
    if (filterindex == 1) {
      starttimeevent = referencetime
        .utc()
        .startOf('day')
        .toISOString();
      endtimeevent = referencetime
        .utc()
        .endOf('day')
        .toISOString();
    } else if (filterindex == 2) {
      starttimeevent = moment(referencetime.utc())
        .isoWeekday(1)
        .startOf('day')
        .toISOString();
      endtimeevent = moment(referencetime.utc())
        .isoWeekday(7)
        .endOf('day')
        .toISOString();
    } else if (filterindex == 3) {
      starttimeevent = moment(referencetime.utc())
        .startOf('month')
        .toISOString();
      endtimeevent = moment(referencetime.utc())
        .endOf('month')
        .toISOString();
    } else {
      starttimeevent = '';
      endtimeevent = '';
    }
  } else {
    starttimeevent = '';
    endtimeevent = '';
  }

  // let maincategories = (yield select(makeSelectCategoriesMainSelected())).toJS();
  const searchparam = yield select(makeSelectSearchEvenemang());
  // let selectedgroups = (yield select(makeSelectGroupsSelected())).toJS();

  // const totalcategories = [...categories, ...maincategories];

  if (starttimeevent != '' && endtimeevent != '') {
    queryparams.start = starttimeevent;
    queryparams.end = endtimeevent;
  } else {
    // show only future events

    queryparams.start = moment
      .utc()
      .startOf('day')
      .toISOString();
  }

  if (totalcategories.length > 0) {
    queryparams.keyword = totalcategories.join();
  }
  /* if(locations != "" ) {
    queryparams.location = locations;
  } */
  /* if(selectedgroups.length > 0 ) {
    queryparams.groups = selectedgroups.join();
  }
 */

  if (currentpage > 1) {
    queryparams.page = currentpage;
  }
  queryparams.sort = 'end_time';
  // const categories = yield select(makeSelectValue());
  let requestURL = `${LE_ROOT}event/?include=location,keywords&super_event=null&`;
  queryparams.data_source =
    'kulturforum,osterbotten,luckan,place2go,place2go_hango,place2go_inga,place2go_raseborg,strax';
  if (searchparam && searchparam != '') {
    requestURL = `${LE_ROOT}event/?`;
    queryparams.text = searchparam;
    queryparams.include = 'location,keywords';
    queryparams.super_event = 'null';
    queryparams.data_source =
      'kulturforum,osterbotten,luckan,place2go,place2go_hango,place2go_inga,place2go_raseborg,strax';
  }

  const queryString = Object.keys(queryparams)
    .map(key => `${key}=${queryparams[key]}`)
    .join('&');

  requestURL += queryString;

  try {
    // Call our request helper (see '../../utils/request')
    const evenemang = yield call(request, requestURL);
    if (currentpage > 1) {
      const totalevents = [...currentevents, ...evenemang.data];
      yield put(evenemangLoaded(totalevents));
      if (evenemang.meta.next != null) {
        yield put(changeMore(true));
      } else {
        yield put(changeMore(false));
      }
    } else {
      yield put(evenemangLoaded(evenemang.data));
      if (evenemang.meta.next != null) {
        yield put(changeMore(true));
      } else {
        yield put(changeMore(false));
      }
    }
  } catch (err) {
    yield put(evenemangLoadingError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* githubData() {
  yield takeLatest(LOAD_EVENEMANG, getEvenemang);
}
