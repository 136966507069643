/*
 * Home Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
  SET_EVENTS,
  SET_NEWS,
  SET_PROFILES,
  LOAD_HOMEDATA,
  TO_LIST_EVENTS,
  TO_MASONRY_EVENTS,
  TO_LIST_NEWS,
  TO_MASONRY_NEWS,
  TO_LIST_PROFILES,
  TO_MASONRY_PROFILES
} from './constants';

export function setHomeEvents(value) {
  return {
    type: SET_EVENTS,
    value,
  };
}

export function setHomeNews(value) {
  return {
    type: SET_NEWS,
    value,
  };
}
export function setHomeProfiles(value) {
  return {
    type: SET_PROFILES,
    value,
  };
}
export function loadData(value) {
  return {
    type: LOAD_HOMEDATA,
    value,
  };
}
export function toListViewEvents(name) {
  return {
    type: TO_LIST_EVENTS,
    name,
  };
}

export function toMasonryViewEvents(name) {
  return {
    type: TO_MASONRY_EVENTS,
    name,
  };
}
export function toListViewNews(name) {
  return {
    type: TO_LIST_NEWS,
    name,
  };
}

export function toMasonryViewNews(name) {
  return {
    type: TO_MASONRY_NEWS,
    name,
  };
}
export function toListViewProfiles(name) {
  return {
    type: TO_LIST_PROFILES,
    name,
  };
}

export function toMasonryViewProfiles(name) {
  return {
    type: TO_MASONRY_PROFILES,
    name,
  };
}