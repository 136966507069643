/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
// import '@babel/polyfill';
import "core-js/stable";
import "regenerator-runtime/runtime";

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';

import FontFaceObserver from 'fontfaceobserver';
import history from './utils/history';
import 'sanitize.css/sanitize.css';
// import LanguageProvider from './containers/LanguageProvider';
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

// Import root app
import App from './containers/App';

// Import Language Provider
// import LanguageProvider from '../../containers/LanguageProvider';

// Load the favicon and the .htaccess file
//import '!file-loader?name=[name].[ext]!./images/favicon.ico';
//import 'file-loader?name=[name].[ext]!./.htaccess'; // eslint-disable-line import/extensions
import { BrowserRouter } from 'react-router-dom';

import configureStore from './configureStore';

// Import i18n messages
// import { translationMessages } from './i18n';

// Import CSS reset and Global Styles

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Barlow', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

// Create redux store with history
// const initialState = {};
const store = configureStore(history);
const MOUNT_NODE = document.getElementById('app');
const instance = createInstance({
  urlBase: "https://luckan.matomo.cloud/",
  siteId: 15,
  heartBeat: {
    active: true,
    seconds: 10,
  },
});
const render = messages => {
  ReactDOM.render(
    <MatomoProvider value={instance}>
      <Provider store={store}>
        <BrowserRouter>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </BrowserRouter>
      </Provider>
    </MatomoProvider>,
    MOUNT_NODE
  );
};
render();
/*
 if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', './containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}
//Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/sv.js'),
        import('intl/locale-data/jsonp/en.js'),
      ]),
    ) // eslint-disable-line prettier/prettier
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}
*/