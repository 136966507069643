/**
 * Homepage selectors
 */

import { createSelector } from 'reselect';

const selectHome = state => state.get('home');

const getHomeEvents = () =>
  createSelector(selectHome, homeState => homeState.get('homeevents'));
  const getHomeNews = () =>
  createSelector(selectHome, homeState => homeState.get('homenews'));
  const getHomeProfiles = () =>
  createSelector(selectHome, homeState => homeState.get('homeprofiles'));
const makeSelectListViewEvents = () =>
  createSelector(selectHome, homeState => homeState.get('listviewevents'));
const makeSelectMasonryViewEvents = () =>
  createSelector(selectHome, homeState => homeState.get('masonryviewevents'));
const makeSelectListViewNews = () =>
  createSelector(selectHome, homeState => homeState.get('listviewnews'));
const makeSelectMasonryViewNews = () =>
  createSelector(selectHome, homeState => homeState.get('masonryviewnews'));
  const makeSelectListViewProfiles = () =>
  createSelector(selectHome, homeState => homeState.get('listviewprofiles'));
const makeSelectMasonryViewProfiles = () =>
  createSelector(selectHome, homeState => homeState.get('masonryviewprofiles'));
export {
  selectHome,
  getHomeEvents,
  getHomeProfiles,
  getHomeNews,
  makeSelectMasonryViewEvents,
  makeSelectListViewEvents,
  makeSelectMasonryViewNews,
  makeSelectListViewNews,
  makeSelectMasonryViewProfiles,
  makeSelectListViewProfiles,
};
