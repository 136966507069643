/*
 *
 * LoginPage reducer
 *
 */

import { fromJS } from 'immutable';
import { DEFAULT_ACTION } from './constants';
import { LOGIN_CHANGE } from './constants';
import { PASSWORD_CHANGE } from './constants';
import { AUTH_FAILURE } from './constants';

import { SET_LOADING } from './constants';

export const initialState = fromJS({
  login: '',
  password: '',
  message: '',
  loading: false,
  token: '',
});

function LoginPageReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case LOGIN_CHANGE:
      return state.set('login', action.value);
    case PASSWORD_CHANGE:
      return state.set('password', action.value);
    case AUTH_FAILURE:
      return state.set('message', action.value);
    case SET_LOADING:
      return state.set('loading', action.value);
    default:
      return state;
  }
}

export default LoginPageReducer;
