/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import _ from 'lodash';
import Cookies from 'universal-cookie';

const cookie = new Cookies();

const selectGlobal = state => state.get('global');

const selectRouter = state => state.get('router');

const makeSelectCurrentUser = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('currentUser'),
  );

const selectLocation = () =>
  createSelector(
    selectRouter,
    routerState => routerState.location
  );

const makeSelectLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('loading'),
  );

const makeSelectAnchorEl = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('anchorEl'),
  );
const makeSelectError = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('error'),
  );
const makeSelectSessionId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'sessionid']),
  );

const isLoggedIn = () =>
  createSelector(
    selectGlobal,
    globalState => {
      if (
        globalState.getIn(['userData', 'sessionid']) &&
        globalState.getIn(['userData', 'userid'])
      ) {
        return true;
      }
    },
  );
const getHasProfile = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'hasprofile']),
  );
const getProfile = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('profile'),
    );
const getKulturSkolan = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('kultur'),
  );




const makeSelectUserId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'userid']),
  );
const makeSelectFirstName = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'first_name']),
  );
const makeSelectLastName = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'last_name']),
  );
const makeSelectEvenemang = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'evenemang']),
  );
const getFavoriter = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'favoriter']),
  );

const makeSelectNyheter = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'nyheter']),
  );
  const getFyrk = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'fyrk']),
  );
const makeSelectKultur = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'kultur']),
  );
const makeSelectSuggestions = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('suggestions'),
  );
const makeSelectSuggestionsEvenemang = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('suggestionsevenemang'),
  );

const makeSelectGroups = () =>
  createSelector(
    selectGlobal,
    globalState => {
      if (globalState.getIn(['userData', 'groups']) != false) {
        return globalState.getIn(['userData', 'groups']);
      }
      return false;
    },
  );
const makeSelectGroupsSelected = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('selectedgroups'),
  );
const makeSelectSearch = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('search'),
  );
const makeSelectSearchFieldValue = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('searchfieldvalue'),
  );
const makeSelectSearchFieldValueEvenemang = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('searchfieldvalueevenemang'),
  );
const makeSelectSearchEvenemang = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('searchevenemang'),
  );
const makeSelectCategoriesMain = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'maincategories']),
  );

const makeSelectCategoriesMainEvenemang = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'maincategoriesevenemang']),
  );

const makeSelectCategories = () =>
  createSelector(selectGlobal, globalState =>{
      if (globalState.getIn(['userData', 'categories']) != false) {
      return globalState.getIn(['userData', 'categories'])
      }
      return false;
    },
  );
const getRegions = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'regions']),
  );
  const getGrups = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('grups'),
  );
const makeSelectTags = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'tags']),
  );
const makeSelectCategoriesAll = () =>
  createSelector(
    selectGlobal,
    globalState => {
      if (
        globalState.getIn(['userData', 'maincategories']) != false &&
        globalState.getIn(['userData', 'categories']) != false &&
        globalState.getIn(['userData', 'tags']) != false
      ) {
        return globalState
          .getIn(['userData', 'maincategories'])
          .concat(globalState.getIn(['userData', 'categories']))
          .concat(globalState.getIn(['userData', 'tags']));
      }
      return false;
    },
  );
const makeSelectCategoriesEvenemang = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'categoriesevenemang']),
  );
const makeSelectGroupsEvenemang = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'groupsevenemang']),
  );
const makeSelectLocationsEvenemang = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'locationsevenemang']),
  );
const makeSelectLocationsEvenemangFiltered = () =>
  createSelector(
    selectGlobal,
    globalState => {
    const all  = globalState.getIn(['userData', 'locationsevenemang'])
      return _.filter(all, function(o) {
        return o.type == 'location';
      });
    },
  );
const makeSelectCategoriesEvenemangSelected = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('selectedcategoriesevenemang'),
  );
const makeSelectGroupsSelectedEvenemang = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('selectedgroupsevenemang'),
  );

const makeSelectCategoriesEvenemangSelectedNames = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('selectedcategoriesevenemangnames'),
  );

const makeSelectLocationsEvenemangSelected = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('selectedlocationsevenemang'),
  );
const getHelp = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('help'),
  );
const getScroll= () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('scroll'),
  );
const getEventLocationName = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('selectedlocationsevenemangname'),
  );
const getLocationName = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('selectedlocationname'),
  );
const makeSelectCategoriesMainSelected = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('selectedmaincategories'),
  );
const makeSelectCategoriesMainSelectedNames = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('selectedmaincategoriesnames'),
  );
  const makeSelectUserName= () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'username']),
  );
  const getUserFyrkStatus= () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'fyrkstatus']),
  );
const makeSelectCategoriesMainSelectedEvenemang = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('selectedmaincategoriesevenemang'),
  );
const makeSelectCategoriesMainSelectedNamesEvenemang = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('selectedmaincategoriesnamesevenemang'),
  );

const makeSelectValue = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('value'),
  );
const makeSelectLocation = () =>
  createSelector(
    selectRouter,
    routeState => routeState.get('location').toJS(),
  );

const makeToggleLeftDrawer = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('leftmenu'),
  );

const isFullWidth = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('fullwidth'),
  );
  const getGroups = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('groups'),
  );

const makeToggleRightDrawer = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('rightmenu'),
  );
const makeToggleRightMenu = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('rightdrawer'),
  );

const makeSelectPlats = () =>
  createSelector(
    selectGlobal,
    globalState => {
      if (globalState.getIn(['userData', 'plats']) != false) {
        return globalState.getIn(['userData', 'plats']);
      }
      return false;
    },
  );
const makeSelectPlatsSelected = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('selectedplats'),
  );
  const getSelectedPeriodType = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('selectedperiodtype'),
  );
const makeSelectStartTime = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('starttime'),
  );
const makeSelectEndTime = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('endtime'),
    );
const getReferencetime = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('referencetime'),
  );
const getReferencetimeevent = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('referencetimeevent'),
  );
const makeSelectStartTimeEvent = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('starttimeevent'),
  );
const makeSelectEndTimeEvent = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('endtimeevent'),
  );
const getTimeIndex = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('timeindex'),
    );
const getCurrentEvent = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('currentevent'),
  );
const getTimeIndexEvent = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('timeindexevent'),
  );
const getOpenDialog= () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('opendialog'),
  );
  const getUserGroupId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.getIn(['userData', 'usergroupid']),
  );
  const getQOpenDialog= () =>
    createSelector(
      selectGlobal,
      globalState => {
        // if(cookie.get('Q') == 1) {
        //   return false
        // } else {
        //   return globalState.get('qopendialog')
        // }
      }
    );
export {
  getTimeIndex,
  getOpenDialog,
  getQOpenDialog,
  getCurrentEvent,
  getTimeIndexEvent,
  getHasProfile,
  getReferencetime,
  makeSelectCategoriesEvenemangSelectedNames,
  makeSelectCategoriesMainSelectedNames,
  makeSelectCategoriesMainSelectedNamesEvenemang,
  makeSelectCategoriesMainSelectedEvenemang,
  makeSelectGroupsSelectedEvenemang,
  makeSelectLocationsEvenemangFiltered,
  getLocationName,
  getEventLocationName,
  selectGlobal,
  getFavoriter,
  getReferencetimeevent,
  makeSelectEndTime,
  makeSelectEndTimeEvent,
  makeSelectGroupsEvenemang,
  makeSelectStartTime,
  makeSelectStartTimeEvent,
  makeSelectCurrentUser,
  makeSelectLoading,
  makeSelectError,
  makeSelectNyheter,
  getFyrk,
  getGrups,
  getUserGroupId,
  makeSelectKultur,
  makeSelectCategories,
  makeSelectCategoriesMain,
  makeSelectEvenemang,
  makeSelectLocation,
  makeToggleRightDrawer,
  makeToggleLeftDrawer,
  makeSelectValue,
  makeSelectSearch,
  makeToggleRightMenu,
  makeSelectSearchFieldValue,
  makeSelectSearchEvenemang,
  makeSelectGroups,
  makeSelectCategoriesMainEvenemang,
  makeSelectSearchFieldValueEvenemang,
  makeSelectGroupsSelected,
  makeSelectCategoriesMainSelected,
  makeSelectCategoriesEvenemangSelected,
  makeSelectLocationsEvenemangSelected,
  makeSelectCategoriesEvenemang,
  makeSelectLocationsEvenemang,
  makeSelectPlats,
  makeSelectSuggestions,
  makeSelectSuggestionsEvenemang,
  makeSelectPlatsSelected,
  getProfile,
  getScroll,
  getSelectedPeriodType,
  makeSelectSessionId,
  makeSelectUserId,
  makeSelectAnchorEl,
  makeSelectFirstName,
  makeSelectLastName,
  makeSelectCategoriesAll,
  makeSelectTags,
  isLoggedIn,
  getRegions,
  isFullWidth,
  getKulturSkolan,
  getHelp,
  selectLocation,
  getGroups,
  makeSelectUserName,
  getUserFyrkStatus
};
