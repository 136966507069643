/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getHelp } from '../../containers/App/selectors';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const styles = {
  card: {
    marginTop: '1em',
  },
};
export class PolicyPage extends React.PureComponent {
  UNSAFE_componentWillReceiveProps(nextProps) {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { classes, helptexts } = this.props;
    let kulturhelp = '';
    if (helptexts) {
      const kulturtemptext = _.find(helptexts, { slug: 'policy' });
      if (kulturtemptext) {
        kulturhelp = kulturtemptext.description;
      }
    }
    return (
      <div>
        <Helmet>
          <title>Integritetspolicy</title>
          <meta name="Integritetspolicy" content="Integritetspolicy" />
        </Helmet>
        <Grid container spacing={10}>
          <Grid item xs={false} md={3} />
          <Grid item xs={12} md={6}>
            <div dangerouslySetInnerHTML={{ __html: kulturhelp }} />
          </Grid>
        </Grid>
      </div>
    );
  }
}


const mapStateToProps = createStructuredSelector({
  helptexts: getHelp(),
});

const withConnect = connect(
  mapStateToProps
);

export default compose(
  withConnect,
  withStyles(styles),
)(PolicyPage);
