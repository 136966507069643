/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */
export const SET_MENUOPEN = 'boilerplate/userProfiles/SET_MENUOPEN ';
export const SET_ANCHOREL = 'boilerplate/userProfiles/SET_ANCHOREL';
export const LOAD_PROFILE = 'boilerplate/userProfiles/LOAD_PROFILE';
export const PROFILE_LOADED = 'boilerplate/userProfiles/PROFILE_LOADED';

export const LOAD_EVENTS = 'boilerplate/userProfiles/LOAD_EVENTS';
export const EVENTS_LOADED = 'boilerplate/userProfiles/EVENTS_LOADED';

export const LOAD_FYRK = 'boilerplate/userProfiles/LOAD_FYRK';
export const FYRK_LOADED = 'boilerplate/userProfiles/FYRK_LOADED';

export const LOAD_GROUP_FYRK = 'boilerplate/userProfiles/LOAD_GROUP_FYRK';
export const SOMMAR_LOADED = 'boilerplate/userProfiles/SOMMAR_LOADED';

export const NEWS_LOADED = 'boilerplate/userProfiles/NEWS_LOADED';
export const SIMILAR_LOADED = 'boilerplate/userProfiles/SIMILAR_LOADED';

export const LOAD_GOUPEVENTS = 'boilerplate/userProfiles/LOAD_GOUPEVENTS';


export const SET_EXPANDED = 'boilerplate/userProfiles/SET_EXPANDED';
export const SET_TAB= 'boilerplate/userProfiles/SET_TAB';
export const SET_EVENT= 'boilerplate/userProfiles/SET_EVENT';

export const OPEN_DIALOG = 'boilerplate/userProfiles/OPEN_DIALOG';
export const CLOSE_DIALOG = 'boilerplate/userProfiles/CLOSE_DIALOG';
export const CLOSE_EVENT = 'boilerplate/userProfiles/CLOSE_EVENT';

export const SET_CURRENT_USER = 'boilerplate/SET_CURRENT_USER';
