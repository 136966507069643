/*
 *
 * AddNyhet actions
 *
 */

import { DEFAULT_ACTION } from './constants';
import { TITLE_CHANGE, CHANGE_FYRKDATE } from './constants';
import { DESCRIPTION_CHANGE } from './constants';
import { LINK_CHANGE } from './constants';
import { SUBMIT_ADDNYHETFORM } from './constants';
import { IMAGE_DROP, LOAD_NEWSITEM, NYHET_LOADED } from './constants';
import {
  CHANGE_EDITORSTATE,
  CHANGE_FYRK,
  CHANGE_CATEGORY,
  CHANGE_LOCATION,
  CHANGE_GROUP,
  SET_ERROR,
  SET_LOADING,
  CLEAR_FORM,
  CLEAR_IMAGE,
  RESTORE_IMAGE,
} from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}
export function restoreImage() {
  return {
    type: RESTORE_IMAGE,
  };
}
export function clearImage() {
  return {
    type: CLEAR_IMAGE,
  };
}
export function titleOnChange(title) {
  return {
    type: TITLE_CHANGE,
    value: title,
  };
}
export function clearForm() {
  return {
    type: CLEAR_FORM,
  };
}
export function nyhetLoaded(nyhet) {
  return {
    type: NYHET_LOADED,
    nyhet,
  };
}

export function setError(value) {
  return {
    type: SET_ERROR,
    value,
  };
}
export function setLoading(value) {
  return {
    type: SET_LOADING,
    value,
  };
}
export function handleChange(value) {
  return {
    type: CHANGE_CATEGORY,
    value,
  };
}
export function linkOnChange(link) {
  return {
    type: LINK_CHANGE,
    value: link,
  };
}
export function imageDrop(image) {
  return {
    type: IMAGE_DROP,
    value: image,
  };
}
export function loadNewsItem(id) {
  return {
    type: LOAD_NEWSITEM,
    value: id,
  };
}
export function submitAddNyhetForm() {
  return {
    type: SUBMIT_ADDNYHETFORM,
  };
}

export function changeLocation(value) {
  return {
    type: CHANGE_LOCATION,
    value,
  };
}

export function changeGroup(value) {
  return {
    type: CHANGE_GROUP,
    value,
  };
}
export function changeFyrk() {
  return {
    type: CHANGE_FYRK,
  };
}
export function changeFyrkDate(value) {
  return {
    type: CHANGE_FYRKDATE,
    value,
  };
}
export function changeEditorState(value) {
  return {
    type: CHANGE_EDITORSTATE,
    value,
  };
}

export function descriptionOnChange(description) {
  return {
    type: DESCRIPTION_CHANGE,
    value: description,
  };
}
