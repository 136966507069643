/*
 *
 * ChangePasswordPage constants
 *
 */

export const DEFAULT_ACTION = 'app/ChangePasswordPage/DEFAULT_ACTION';
export const LOGIN_CHANGE = 'app/ChangePasswordPage/LOGIN_CHANGE';
export const PASSWORD_CHANGE = 'app/ChangePasswordPage/PASSWORD_CHANGE';
export const PASSWORDCONFIRM_CHANGE = 'app/ChangePasswordPage/PASSWORDCONFIRM_CHANGE';
export const SUBMITREGISTER = 'app/ChangePasswordPage/SUBMITREGISTER';
export const AUTH_FAILURE = 'app/ChangePasswordPage/AUTH_FAILURE';
export const REGISTER_SUCCESS = 'app/ChangePasswordPage/REGISTER_SUCCESS';
export const SET_LOADING = 'app/ChangePasswordPage/SET_LOADING';
export const SET_ERROR = 'app/ChangePasswordPage/SET_ERROR';
export const SET_HASH =  'app/ChangePasswordPage/SET_HASH';
export const SET_UID =  'app/ChangePasswordPage/SET_UID';
