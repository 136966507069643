/**
 * Gets the repositories of the user from Github
 */
import { takeLatest } from 'redux-saga/effects';
import { call, put, select } from 'redux-saga/effects';
import { API_ROOT } from '../../api-config';
import { SUBMITREGISTER } from './constants';
import { setLoading, setError, registerSuccess,setOpen } from './actions';
import {
  makeSelectLogin,
  makeSelectPassword,
  makeSelectPasswordConfirm,
  getGroup,
  getOrganisation,
  getNewOrganisation,
  getfyrkrequestvalue,
  getLastName, 
  getFirstName
} from './selectors';

/**
 * Github repos request/response handler
 */
const fetchJSON = (url, options = {}) =>
  new Promise((resolve, reject) =>
    fetch(url, options)
      .then(
        response =>
          response.status !== 201 &&
          response.status !== 200 &&
          response.status !== 400
            ? reject(response)
            : response,
      )
      .then(response => {
        if (response.status === 400) {
          response.json().then(error => reject(error));
        } else {
          return resolve(response.json());
        }
        return '';
      })
      .catch(error => reject(error)),
  );
export function* registerRequest() {
  yield put(setLoading(true));


  const creatingnew = yield select(getGroup());
  const selectedorganisation = yield select(getOrganisation());
  const neworganisation = yield select(getNewOrganisation());

  const login = yield select(makeSelectLogin());
  const password = yield select(makeSelectPassword());
  const passwordconfirm = yield select(makeSelectPasswordConfirm());
  const firstname = yield select(getFirstName());
  const lastname = yield select(getLastName());
  const fyrkrequestvalue = yield select(getfyrkrequestvalue());


  const payload = {
    firstname : firstname,
    lastname : lastname ,
    first_name : firstname,
    last_name : lastname ,
    username: login,
    email: login,
    password1: password,
    password2: passwordconfirm,
    service: "kulturforum.fi",
    fyrkrequest: fyrkrequestvalue
  };
console.log("payload",payload);

  if(fyrkrequestvalue) {
    payload.service = 'fyrk.fi';
  }

  if(creatingnew) {
    if(neworganisation != "") {
      payload.new = neworganisation;
    }
  } else {
    if(selectedorganisation) {
      payload.groups = selectedorganisation.id;
    }

  }

  try {
    yield call(fetchJSON, `${API_ROOT}rest-auth/registration/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //  'Authorization': 'Token '+token
      },
      body: JSON.stringify(payload),
    });
    // success
    yield put(setError({}));
    yield put(registerSuccess());
    yield put(setOpen(true));

  } catch (error) {
    yield put(setError(error));
    yield put(setLoading(false));

  }

}

export default function* registerWatcher() {
  yield takeLatest(SUBMITREGISTER, registerRequest);
}
