import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the ChangePasswordPage state domain
 */

const selectChangePasswordPageDomain = state =>
  state.get('ChangePasswordPage', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by ChangePasswordPage
 */

const makeSelectChangePasswordPage = () =>
  createSelector(selectChangePasswordPageDomain, loginState => loginState.toJS());

const makeSelectLogin = () =>
  createSelector(selectChangePasswordPageDomain, loginState =>
    loginState.get('login'),
  );
  const selectHash = () =>
  createSelector(selectChangePasswordPageDomain, loginState =>
    loginState.get('hash'),
  );
  const selectUid = () =>
  createSelector(selectChangePasswordPageDomain, loginState =>
    loginState.get('uid'),
  );
const getError = () =>
  createSelector(selectChangePasswordPageDomain, loginState =>
    loginState.get('error'),
  );
const getRegisterSuccess = () =>
  createSelector(selectChangePasswordPageDomain, loginState =>
    loginState.get('registersuccess'),
  );
const makeSelectMessage = () =>
  createSelector(selectChangePasswordPageDomain, loginState =>
    loginState.get('message'),
  );
const makeSelectLoading = () =>
  createSelector(selectChangePasswordPageDomain, loginState =>
    loginState.get('loading'),
  );
const makeSelectPassword = () =>
  createSelector(selectChangePasswordPageDomain, loginState =>
    loginState.get('password'),
  );
const makeSelectPasswordConfirm = () =>
  createSelector(selectChangePasswordPageDomain, loginState =>
    loginState.get('passwordconfirm'),
  );
export {
  getRegisterSuccess,
  getError,
  makeSelectChangePasswordPage,
  makeSelectLogin,
  makeSelectPassword,
  makeSelectMessage,
  selectHash,
  selectUid,
  makeSelectLoading,
  makeSelectPasswordConfirm,
};
