/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';

import moment from 'moment/min/moment-with-locales';
import {
  LOAD_CATEGORIES_SUCCESS,
  LOAD_GRUPS,
  OPEN_DIALOG,
  CLOSE_DIALOG,
  Q_OPEN_DIALOG,
  Q_CLOSE_DIALOG,
  SET_CURRENT_EVENT,
  LOAD_CATEGORIES,
  LOAD_CATEGORIES_MAIN,
  LOAD_CATEGORIES_EVENEMANG,
  LOAD_LOCATIONS_EVENEMANG,
  LOAD_REGIONS_SUCCESS,
  LOAD_EVENEMANG_SUCCESS,
  LOAD_EVENEMANG,
  LOAD_EVENEMANG_ERROR,
  LOAD_NYHETER_SUCCESS,
  LOAD_NYHETER,
  LOAD_NYHETER_ERROR,
  LOAD_FYRK_SUCCESS,
  LOAD_FYRK,
  LOAD_FYRK_ERROR,
  LOAD_FAVORITER,
  LOAD_KULTUR_SUCCESS,
  LOAD_KULTUR,
  LOAD_CATEGORIES_MAINEVENEMANG,
  CLEAR_SUGGESTIONS,
  CLEAR_SUGGESTIONS_EVENEMANG,
  FILTER_DATE,
  FILTER_DATESTART,
  FILTER_DATEEND,
  FILTER_DATE_EVENT,
  FILTER_DATESTART_EVENT,
  FILTER_DATEEND_EVENT,
  FILTER_SET_REFERENCETIME,
  FILTER_SET_REFERENCETIMEEVENT,
  LOAD_KULTUR_ERROR,
  LOAD_GROUPS,
  LOAD_PLATS,
  LOAD_TAGS,
  TOGGLE_LEFTMENU,
  HIDE_LEFTMENU,
  TOGGLE_RIGHTMENU,
  TOGGLE_RIGHTDRAWER,
  HIDE_DRAWERS,
  CHANGE_CATEGORY,
  CHANGE_PLATS,
  CHANGE_CATEGORY_EVENEMANG,
  CHANGE_LOCATION_EVENEMANG,
  ADD_CATEGORY,
  REMOVE_CATEGORY,
  ADD_CATEGORY_EVENEMANG,
  REMOVE_CATEGORY_EVENEMANG,
  ADD_GROUP,
  REMOVE_GROUP,
  ADD_GROUP_EVENEMANG,
  REMOVE_GROUP_EVENEMANG,
  SEARCH,
  SEARCH_FIELD,
  ADD_KULTUR,
  REMOVE_KULTUR,
  SEARCH_FIELD_EVENEMANG,
  SEARCHEVENEMANG,
  SET_SUGGESTIONS,
  SET_SUGGESTIONS_EVENEMANG,
  SET_USER,
  SET_HELP,
  SET_PROFILE,
  SET_SCROLL,
  SET_PERIODTYPE,
  LOAD_GROUPS_EVENEMANG,
  CHANGE_TIMEINDEX,
  CHANGE_TIMEINDEX_EVENT,
  HASPROFILE,
  REMOVE_USERGROUP,
  SET_USERGROUP,
  DELETE_PROFILEGROUP,
  REMOVE_USER,
  CHANGE_LASTNAME,
  CHANGE_FIRSTNAME,
  CHANGE_USERNAME
} from './constants';

// The initial state of the App
const initialState = fromJS({
  loading: false,
  error: false,
  leftmenu: false,
  fullwidth: false,
  rightmenu: false,
  rightdrawer: false,
  currentUser: false,
  profile: false,
  scroll: false,
  selectedOption: '',
  anchorEl: null,
  groups:  [],
  value: false,
  currentevent: false,
  kultur: [],
  opendialog: false,
  qopendialog: true,
  selectedcategoriesevenemang: false,
  selectedcategoriesevenemangnames: [],
  selectedlocationsevenemang: false,
  selectedlocationsevenemangname: false,
  selectedlocationname: false,
  search: '',
  searchfieldvalue: '',
  searchfieldvalueevenemang: '',
  searchevenemang: '',
  selectedmaincategories: [],
  selectedmaincategoriesnames: [],
  selectedmaincategoriesevenemang: [],
  selectedmaincategoriesnamesevenemang: [],
  selectedgroups: [],
  selectedgroupsevenemang: [],
  selectedplats: false,
  selectedperiodtype: false,
  referencetime: '',
  referencetimeevent: '',
  starttime: '',
  suggestions: false,
  suggestionsevenemang: false,
  timeindex: 0,
  timeindexevent: 0,
  endtime: '',
  endtimeevent: '',
  help: false,
  starttimeevent: '',
  userData: {
    first_name: '',
    last_name: '',
    username : '',
    hasprofile: false,
    userid: false,
    sessionid: false,
    evenemang: false,
    nyheter: false,
    fyrk: false,
    fyrkstatus: false,
    kultur: false,
    favoriter: false,
    categories: false,
    regions: false,
    categoriesevenemang: false,
    groupsevenemang: false,
    locationsevenemang: false,
    usergroupid: false,
    groups: false,
    plats: false,
    maincategories: [],
    maincategoriesevenemang: [],
    tags: [],
  },
});


function appReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CATEGORY_EVENEMANG:
      const templistevenemang = state
        .get('selectedmaincategoriesevenemang')
        .toJS();
      const namelistevenemang = state
        .get('selectedmaincategoriesnamesevenemang')
        .toJS();
      templistevenemang.push(action.id);
      namelistevenemang.push({ name: action.name, id: action.id });
      return state
        .set(
          'selectedmaincategoriesevenemang',
          fromJS(templistevenemang)
            .toSet()
            .toList(),
        )
        .set(
          'selectedmaincategoriesnamesevenemang',
          fromJS(namelistevenemang)
            .toSet()
            .toList(),
        );

    case CHANGE_CATEGORY_EVENEMANG:
      const objectarrayev = action.value;
      if (action.value) {
        const valuearrayev = [];
        const valuearrayevnames = [];
        const valuesev = objectarrayev.map(value =>
          valuearrayev.push(value.id),
        );
        const valuesevnames = objectarrayev.map(value =>
          valuearrayevnames.push({ name: value.name, id: value.id }),
        );

        return state.set('selectedcategoriesevenemang', valuearrayev).set(
          'selectedcategoriesevenemangnames',
          fromJS(valuearrayevnames)
            .toSet()
            .toList(),
        );
      }
      const valuearrayevtestset = [];
      const namearrayevtestset = [];
      const testset = state.get('selectedcategoriesevenemang');
      const testsetnames = state.get('selectedcategoriesevenemang');

      const testsetvaluesev = testset.map(value => {
        valuearrayevtestset.push(value.id);
        namearrayevtestset.push(value.name);
      });

      return state
        .set('selectedcategoriesevenemang', valuearrayevtestset)
        .set('selectedcategoriesevenemangnames', namearrayevtestset);

    case REMOVE_CATEGORY_EVENEMANG:
      let currentcategoriesevenemang = state
        .get('selectedmaincategoriesevenemang')
        .toJS();

      let currentcategoriesnamesevenemang = state
        .get('selectedmaincategoriesnamesevenemang')
        .toJS();

      let selectedeventcategories = state.get('selectedcategoriesevenemang');
      if (!selectedeventcategories) {
        selectedeventcategories = [];
      }

      if (action.value == 'all') {
        currentcategoriesevenemang = [];
        currentcategoriesnamesevenemang = [];
      } else {
        const index = currentcategoriesevenemang.indexOf(action.value);
        if (index > -1) {
          currentcategoriesevenemang.splice(index, 1);
          currentcategoriesnamesevenemang.splice(index, 1);
        }
        const index2 = selectedeventcategories.indexOf(action.value);
        if (index2 > -1) {
          selectedeventcategories.splice(index, 1);
        }
      }
      return state
        .set('selectedcategoriesevenemang', selectedeventcategories)
        .set(
          'selectedmaincategoriesevenemang',
          fromJS(currentcategoriesevenemang)
            .toSet()
            .toList(),
        )
        .set(
          'selectedmaincategoriesnamesevenemang',
          fromJS(currentcategoriesnamesevenemang)
            .toSet()
            .toList(),
        );
    case ADD_CATEGORY:
      const templist = state.get('selectedmaincategories').toJS();
      const namelist = state.get('selectedmaincategoriesnames').toJS();
      templist.push(action.id);
      namelist.push({ name: action.name, id: action.id });
      return state
        .set(
          'selectedmaincategories',
          fromJS(templist)
            .toSet()
            .toList(),
        )
        .set(
          'selectedmaincategoriesnames',
          fromJS(namelist)
            .toSet()
            .toList(),
        );
    case REMOVE_CATEGORY:
      let currentcategories = state.get('selectedmaincategories').toJS();
      let currentcategoriesnames = state
        .get('selectedmaincategoriesnames')
        .toJS();
      if (action.value == 'all') {
        currentcategories = [];
        currentcategoriesnames = [];
      } else {
        const index = currentcategories.indexOf(action.value);
        if (index > -1) {
          currentcategories.splice(index, 1);
          currentcategoriesnames.splice(index, 1);
        }
      }
      return state
        .set(
          'selectedmaincategories',
          fromJS(currentcategories)
            .toSet()
            .toList(),
        )
        .set(
          'selectedmaincategoriesnames',
          fromJS(currentcategoriesnames)
            .toSet()
            .toList(),
        );
    case ADD_KULTUR:
      const kulturlist = state.get('kultur').toJS();
      kulturlist.push(action.value);
      return state.set(
        'kultur',
        fromJS(kulturlist)
          .toSet()
          .toList(),
      );
    case REMOVE_KULTUR:
      const currentkultur = state.get('kultur').toJS();
      const kulturindex = currentkultur.indexOf(action.value);
      if (kulturindex > -1) {
        currentkultur.splice(groupindex, 1);
      }
      return state.set(
        'kultur',
        fromJS(currentkultur)
          .toSet()
          .toList(),
      );

    case ADD_GROUP:
      const grouplist = state.get('selectedgroups').toJS();
      grouplist.push(action.value);
      return state.set(
        'selectedgroups',
        fromJS(grouplist)
          .toSet()
          .toList(),
      );
    case REMOVE_GROUP:
      const currentgroups = state.get('selectedgroups').toJS();
      const groupindex = currentgroups.indexOf(action.value);
      if (groupindex > -1) {
        currentgroups.splice(groupindex, 1);
      }
      return state.set(
        'selectedgroups',
        fromJS(currentgroups)
          .toSet()
          .toList(),
      );

    case ADD_GROUP_EVENEMANG:
      const grouplistevenemang = state.get('selectedgroupsevenemang').toJS();
      grouplistevenemang.push(action.value);
      return state.set(
        'selectedgroupsevenemang',
        fromJS(grouplistevenemang)
          .toSet()
          .toList(),
      );
    case REMOVE_GROUP_EVENEMANG:
      const currentcategoriesevenemang2 = state
        .get('selectedmaincategoriesevenemang')
        .toJS();
      const currentcategoriesnamesevenemang2 = state
        .get('selectedmaincategoriesnamesevenemang')
        .toJS();

      const index2 = currentcategoriesevenemang2.indexOf(action.value);
      if (index2 > -1) {
        currentcategoriesevenemang2.splice(index2, 1);
        currentcategoriesnamesevenemang2.splice(index2, 1);
      }

      const currentgroupsevenemang = state
        .get('selectedgroupsevenemang')
        .toJS();
      const groupindexevenemang = currentgroupsevenemang.indexOf(action.value);
      if (groupindexevenemang > -1) {
        currentgroupsevenemang.splice(groupindexevenemang, 1);
      }
      return state
        .set(
          'selectedgroupsevenemang',
          fromJS(currentgroupsevenemang)
            .toSet()
            .toList(),
        )
        .set(
          'selectedmaincategoriesevenemang',
          fromJS(currentcategoriesevenemang2)
            .toSet()
            .toList(),
        )
        .set(
          'selectedmaincategoriesnamesevenemang',
          fromJS(currentcategoriesnamesevenemang2)
            .toSet()
            .toList(),
        );
    case CHANGE_CATEGORY:
      const objectarray = action.value;
      let valuearray = state.get('value');
      valuearray = [];
      const values = objectarray.map(value => valuearray.push(value.id));
      return state.set('value', valuearray);
    case LOAD_GROUPS:
      return state.set('groups', action.value);
    case FILTER_DATE:
      return state
        .set('endtime', action.enddate)
        .set('starttime', action.startdate);
    case FILTER_DATESTART:
      return state.set('starttime', action.startdate);
    case FILTER_DATEEND:
      return state.set('endtime', action.enddate);
    case OPEN_DIALOG:
      return state.set('opendialog', true);
    case CLOSE_DIALOG:
      return state.set('opendialog', false);
    case Q_OPEN_DIALOG:
        return state.set('qopendialog', true);
    case Q_CLOSE_DIALOG:
        return state.set('qopendialog', false);
    case CHANGE_LASTNAME:
      return state.setIn(['userData', 'last_name'], action.value);
    case CHANGE_USERNAME:
      return state.setIn(['userData', 'username'], action.value);
    case CHANGE_FIRSTNAME:
        return state.setIn(['userData', 'first_name'], action.value);
    case FILTER_DATE_EVENT:
        return state
        .set('endtimeevent', action.enddate)
        .set('starttimeevent', action.startdate);
    case FILTER_DATESTART_EVENT:
      return state.set('starttimeevent', action.startdate);
    case FILTER_SET_REFERENCETIME:
      return state.set('referencetime', action.referencetime);
    case FILTER_SET_REFERENCETIMEEVENT:
      const prevdate = moment(state.get('referencetimeevent'));
      const newdate = moment(action.referencetimeevent);

      if (prevdate != '' && prevdate.isSame(newdate, 'day')) {
        return state.set('timeindexevent', 0).set('referencetimeevent', '');
      }

      return state
        .set('referencetimeevent', action.referencetimeevent)
        .set('timeindexevent', 1);
    case FILTER_DATEEND_EVENT:
      return state.set('endtimeevent', action.enddate);
    case CHANGE_PLATS:
      if (action.value) {
        return state
          .set('selectedplats', action.value)
          .set('selectedlocationname', {
            name: action.value.name,
            id: action.value.id,
          });
      }
      return state
        .set('selectedplats', false)
        .set('selectedlocationname', false);
    case SET_PERIODTYPE:
      if (action.value) {
        return state
          .set('selectedperiodtype', action.value)
      }
      return state
        .set('selectedperiodtype', false)
    case CHANGE_LOCATION_EVENEMANG:
      if (action.value) {
        return state
          .set('selectedlocationsevenemang', action.value)
          .set('selectedlocationsevenemangname', {
            name: action.value.name,
            id: action.value.id,
            type: action.value.type,
          });
      }
      return state
        .set('selectedlocationsevenemang', false)
        .set('selectedlocationsevenemangname', false);

    case LOAD_CATEGORIES:
      return state.setIn(['userData', 'categories'], []);
    case CLEAR_SUGGESTIONS:
      return state.set('suggestions', false);
    case SET_SUGGESTIONS:
      const searchparam = action.value;
      if (searchparam != '') {
        const searchablecats = state.getIn(['userData', 'categories']);
        const inputValue = searchparam.value.trim().toLowerCase();

        const inputLength = inputValue.length;
        let count = 0;
        const result =
          inputLength === 0
            ? []
            : searchablecats.filter(suggestion => {
                const keep =
                  count < 5 &&
                  suggestion.name.toLowerCase().slice(0, inputLength) ===
                    inputValue;

                if (keep) {
                  count += 1;
                }

              return keep;
            });
        return state.set('suggestions', result);
      }
      state.set('suggestions', '');
      return state;
    case CLEAR_SUGGESTIONS_EVENEMANG:
      return state.set('suggestionsevenemang', false);
    case SET_SUGGESTIONS_EVENEMANG:
      const searchparamev = action.value;
      if (searchparamev != '') {
        const searchablecatsev = state.getIn([
          'userData',
          'categoriesevenemang',
        ]);
        const inputValueEv = searchparamev.value.trim().toLowerCase();

        const inputLengthEv = inputValueEv.length;
        let count = 0;
        const resultev =
          inputLengthEv === 0
            ? []
            : searchablecatsev.filter(suggestion => {
                const keep =
                  count < 5 &&
                  suggestion.name.toLowerCase().slice(0, inputLengthEv) ===
                    inputValueEv;

                if (keep) {
                  count += 1;
                }

                return keep;
            });
        return state.set('suggestionsevenemang', resultev);
      }
      state.set('suggestionsevenemang', '');
      return state;

    case LOAD_GRUPS:
      return state.setIn(['userData', 'groups'], action.value);
    case LOAD_FAVORITER:
      return state.setIn(['userData', 'favoriter'], action.value);
    case LOAD_PLATS:
      return state.setIn(['userData', 'plats'], action.value);
    case LOAD_CATEGORIES_EVENEMANG:
      return state.setIn(['userData', 'categoriesevenemang'], action.value);
    case LOAD_GROUPS_EVENEMANG:
      return state.setIn(['userData', 'groupsevenemang'], action.value);
    case LOAD_LOCATIONS_EVENEMANG:
      return state.setIn(['userData', 'locationsevenemang'], action.value);
    case LOAD_TAGS:
      return state.setIn(['userData', 'tags'], action.value);
    case HASPROFILE:
      return state.setIn(['userData', 'hasprofile'], action.value);
    case LOAD_CATEGORIES_MAIN:
      return state.setIn(['userData', 'maincategories'], action.value);
    case LOAD_CATEGORIES_MAINEVENEMANG:
      return state.setIn(['userData', 'maincategoriesevenemang'], action.value);
    case LOAD_CATEGORIES_SUCCESS:
      return state
        .setIn(['userData', 'categories'], action.categories)
        .set('loading', false);
    case LOAD_REGIONS_SUCCESS:
      return state.setIn(['userData', 'regions'], action.regions);
    case SEARCH:
      return state.set('search', action.value);
    case SET_SCROLL:
      return state.set('scroll', action.value);
    case SET_USER:
      return state
        .setIn(['userData', 'token'], action.value.token)
        .setIn(['userData', 'userid'], action.value.id)
        .setIn(['userData', 'first_name'], action.value.first_name)
        .setIn(['userData', 'username'], action.value.username)
        .setIn(['userData', 'last_name'], action.value.last_name)
        .setIn(['userData', 'usergroupid'], action.value.usergroupid)
        .setIn(['userData', 'fyrkstatus'], action.value.ext.fyrk)
        .set('currentUser', action.value);
    case SET_USERGROUP:
        localStorage.setItem('usergroupid', action.value);
        return state.setIn(['userData', 'usergroupid'], action.value)
    case SET_PROFILE:
      return state.set('profile', action.value);
    case SET_HELP:
      return state.set('help', action.value);
    case SET_CURRENT_EVENT:
      return state.set('currentevent', action.value);
    case SEARCH_FIELD:
      return state.set('searchfieldvalue', action.value);
    case SEARCH_FIELD_EVENEMANG:
      return state.set('searchfieldvalueevenemang', action.value);
    case SEARCHEVENEMANG:
      return state.set('searchevenemang', action.value);
    case LOAD_KULTUR:
      return state.set('loading', true).set('error', false);
    case LOAD_KULTUR_SUCCESS:
      return state
        .setIn(['userData', 'kultur'], action.kultur)
        .set('loading', false);
    case LOAD_EVENEMANG:
      return state.set('loading', true).set('error', false);
    case LOAD_EVENEMANG_SUCCESS:
       return state
         .setIn(['userData', 'evenemang'], action.evenemang)
         .set('loading', false);
    case TOGGLE_LEFTMENU:
      if (state.get('leftmenu')) {
        return state.set('leftmenu', false).set('fullwidth', true);
      }
      return state.set('leftmenu', true).set('fullwidth', false);
    case HIDE_DRAWERS:
      return state.set('leftmenu', false).set('rightdrawer', false);
    case HIDE_LEFTMENU:
      return state.set('leftmenu', false).set('fullwidth', true);
    case TOGGLE_RIGHTMENU:
      if (state.get('rightmenu')) {
        return state.set('rightmenu', false).set('anchorEl', null);
      }
      return state.set('rightmenu', true).set('anchorEl', action.anchorEl);
    case TOGGLE_RIGHTDRAWER:
      if (state.get('rightdrawer')) {
        return state.set('rightdrawer', false);
      }
      return state.set('rightdrawer', true);
    case LOAD_EVENEMANG_ERROR:
      return state.set('error', action.error).set('loading', false);
    case CHANGE_TIMEINDEX:
      if (state.get('timeindex') == action.value) {
        return state
          .set('timeindex', '')
          .set('endtime', '')
          .set('starttime', '');
      }
      return state.set('timeindex', action.value);
    case CHANGE_TIMEINDEX_EVENT:
      if (state.get('timeindexevent') == action.value) {
        return state
          .set('timeindexevent', '')
          .set('endtimeevent', '')
          .set('starttimeevent', '');
      }
      return state.set('timeindexevent', action.value);
    case LOAD_NYHETER_SUCCESS:
      return state
        .setIn(['userData', 'nyheter'], action.nyheter)
        .set('loading', false);
    case LOAD_NYHETER:
        return state.set('loading', true).set('error', false);
    case LOAD_NYHETER_ERROR:
      return state.set('error', action.error).set('loading', false);
    case LOAD_FYRK:
        return state.set('loading', true).set('error', false);
    case LOAD_FYRK_SUCCESS:
        return state
          .setIn(['userData', 'fyrk'], action.fyrk)
          .set('loading', false);    
    case LOAD_KULTUR_ERROR:
      return state.set('error', action.error).set('loading', false);
    default:
      return state;
  }
}

export default appReducer;
