let backendHost, linkedEventsHost;

const hostname = window && window.location && window.location.hostname;
if (hostname === 'kulturforum.fi' || hostname === 'fyrk.fi') {
  backendHost = 'https://api.luckan.fi/';
  linkedEventsHost = 'https://luckan.linkedevents.fi/v1/';
} else {
  backendHost = 'https://api-test21.luckan.fi/';
  //backendHost = 'http://localhost:8000/';
  //linkedEventsHost = 'https://luckan-test.metatavu.io/v1/';
  linkedEventsHost = 'https://luckan.linkedevents.fi/v1/';

}

export const API_ROOT = `${backendHost}`;
export const LE_ROOT = `${linkedEventsHost}`;
