import * as React from 'react';
import Popover from '@mui/material/Popover';
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Typography from '@mui/material/Typography';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectCurrentUser } from '../../containers/App/selectors';

export default function PopFavorite() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const user = useSelector(makeSelectCurrentUser());
    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
      };

    const open = Boolean(anchorEl);



  return (
    <div>
    <FavoriteBorder         
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
    />
      {!user && 
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
      <Typography sx={{ p: 1 }}>
    Notera att gillade poster endast är tillgängliga <br/>
    under en webbsession, efter det nollställs sidan.  
    </Typography>    
    </Popover>
  }
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectCurrentUser(),

});

const withConnect = connect(
  mapStateToProps,
);