/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Button from '@material-ui/core/Button';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import withWidth from '@material-ui/core/withWidth';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import KulturList from '../../components/KulturList';
import NyheterList from '../../components/NyheterList';
import EvenemangList from '../../components/EvenemangList';
import Grid from '@material-ui/core/Grid';
import { push } from 'connected-react-router';
import Favorite from '@mui/icons-material/Favorite';
import Hidden from '@material-ui/core/Hidden';

import {
  handleSearchFieldValueEvenemang,
  handleSearchEvenemang,
  loadEvenemang,
} from '../../containers/App/actions';
import RandomImageHome from '../../components/RandomImageHome';
import { useMatomo } from "@datapunt/matomo-tracker-react";

import {
  makeSelectCurrentUser,
  makeSelectUserId,
  getFavoriter,
  getHelp,
  makeSelectSearchFieldValueEvenemang,
} from '../../containers/App/selectors';
import ViewList from '@mui/icons-material/ViewList';
import ViewModule from '@mui/icons-material/ViewModule';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import saga from './saga';
import reducer from './reducer';
import {
  getHomeEvents,
  getHomeProfiles,
  getHomeNews,
  makeSelectListViewEvents,
  makeSelectMasonryViewEvents,
  makeSelectListViewNews,
  makeSelectMasonryViewNews,
  makeSelectListViewProfiles,
  makeSelectMasonryViewProfiles,
} from './selectors';
import {
  loadData,
  toListViewEvents,
  toMasonryViewEvents,
  toListViewNews,
  toMasonryViewNews,
  toListViewProfiles,
  toMasonryViewProfiles,
} from './actions';
import Section from './Section';
import Img1 from '../../images/placeholders/carousel1.jpg';
import Img2 from '../../images/placeholders/carousel2.jpg';
import Img3 from '../../images/placeholders/carousel3.jpg';
import KulturAktor from '../../images/kulturaktor.svg';
import Private from '../../images/private.svg';
import News from '../../images/news.svg';
import Magazines from '../../images/magazines.svg';

const styles = theme => ({
  alignright: {
    float: 'right',
    marginRight: '1em',
    textAlign: 'right',
  },
  alignleft: {
    marginLeftt: '1em',
    textAlign: 'left',
    float: 'left',
    padding: '1em',
  },
  aligncenter: {
    textAlign: 'center',
  },
  textField: {
    width: '100%',
    border: '1px solid #d6d1f0',
    background: '#fff',
    '&:before': {
      border: 'none',
    },
    paddingLeft: '5px',
  },
  textFieldSearch: {
    width: '100%',
    fontSize: '20px',
    border: '1px solid #d6d1f0',
    background: '#fff',
    '&:before': {
      border: 'none',
    },
    padding: '6px',
  },

  carouseltitlesectionwrapper: {
    width: '100%',
    backgroundColor: 'transparent',
    textAlign: 'left',
    [theme.breakpoints.up('xs')]: {
      bottom: '5%',
      position: 'static',
    },
    [theme.breakpoints.up('sm')]: {
      bottom: '15%',
      position: 'absolute',
    },
    [theme.breakpoints.up('md')]: {
      bottom: '7%',
      position: 'absolute',
    },
    [theme.breakpoints.up('lg')]: {
      bottom: '20%',
      position: 'absolute',
    },
  },
  carouseltitlesection: {
    backgroundColor: 'transparent',
    color: '#000',
    textAlign: 'left',
    lineHeight: '20px',
    margin: '0 auto',
    maxWidth: '1600px',
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
    [theme.breakpoints.up('md')]: {
      width: '56.25%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
  },
  nomarginSection: {
    margin: '0 auto !important',
    padding: theme.spacing(3),
    paddingBottom: '2em',
    maxWidth: '1600px',
  },
  noPaddingsection: {
    paddingBottom: 0,
  },
  nomarginSectionFull: {
    margin: '0 auto !important',
    padding: theme.spacing(3),
    paddingBottom: '2em',
    backgroundColor: 'transparent',
  },
  bluecontainer: {
    backgroundColor: '#eeedf4',
  },
  nomarginSectionCarousel: {
    margin: '0 auto !important',
    padding: 0,
    backgroundColor: '#eeedf4',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0 !important',
    },

    [theme.breakpoints.up('md')]: {
      marginTop: '-80px  !important',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '-80px  !important',
    },
  },
  fullwidth: {
    width: '100%',
    marginBottom: 0,
  },
  fullwidthsearch: {
    width: '90%',
    display: 'inline-block',
    fontSize: '20px',
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
  },
  searchbutton: {
    display: 'inline-block',
    backgroundColor: '#000 !important',
    background: '#000',
    color: '#fff',
    marginLeft: '1em',
    borderRadius: 0,
    marginTop: '16px',
    padding: '4px 8px',
  },
  control: {
    padding: theme.spacing(2),
  },
  sectiontitle: {
    marginLeft: '0.4em',
    marginTop: 0,
    marginBottom: 0,
    float: 'left',
    [theme.breakpoints.down('xs')]: {
      float: 'none',
    },
  },
  carouselitem: {
    [theme.breakpoints.up('lg')]: {},
  },
  half: {
    clear: 'both',

    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0.4em',
      width: '100%',
    },

    [theme.breakpoints.up('md')]: {
      width: '50%',
      paddingLeft: '0.4em',
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
  },
  promoText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8em',
      marginTop: 0,
    },

    [theme.breakpoints.up('md')]: {
      fontSize: '1.2em',
      marginTop: 0,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.7em',
      marginTop: 0,
    },
  },
  alignleft: {
    textAlign: 'right',
  },
  promoicon: {
    fontSize: '7em',
    marginTop: '10px',
  },
  nomarginbottom: {
    marginBottom: 0,
  },
  carouseltitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em',
      margin: 0,
      lineHeight: '1.5em',
    },

    [theme.breakpoints.up('md')]: {
      fontSize: '1.8em',
      lineHeight: '1.2em',
      margin: 0,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2.3em',
      lineHeight: '1.8em',
    },
  },
  carouselsubtitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6em',
    },

    [theme.breakpoints.up('md')]: {
      fontSize: '1.2em',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.5em',
    },
  },
  griditem: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },

    [theme.breakpoints.up('md')]: {
      width: '33%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '25%',
    },
  },
  promoSection: {
    backgroundColor: '#FFB09A',
  },
});

export class HomePage extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  /**
   * when initial state username is not null, submit the form to load evenemang
   */

  UNSAFE_componentWillMount() {
    this.tempimageheader = RandomImageHome();
    this.props.onSubmitForm();
    this.columns = 4;
    this.match = false;
    this.checkWidth = () => {
      const match = window.matchMedia(`(min-width: 2500px)`);
      if (match.matches) {
        this.match = true;
      } else {
        this.match = false;
      }
    };
    this.checkWidth();
    window.addEventListener('resize', this.checkWidth);
  }

  render() {
    const {
      profiles,
      news,
      classes,
      events,
      userid,
      user,
      favoriter,
      masonryviewevents,
      listviewevents,
      masonryviewnews,
      listviewnews,
      searchfieldvalueevenemang,
      masonryviewprofiles,
      listviewprofiles,
      width,
      helptexts,
    } = this.props;
    let { columns } = this;
    if (width == 'xl') {
      columns = 5;
    }
    if (this.match) {
      columns = 6;
    }
    const home_info = '';

    return (
      <article>
        <Helmet>
          <title>Kulturforum - Svenskfinland</title>
          <meta
            name="Kulturforum - Svenskfinland"
            content="Kulturforum - Svenskfinland"
          />
        </Helmet>
        <div>
          <Section className={classes.nomarginSectionCarousel}>
            <Carousel
              infiniteLoop
              interval={6000}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
            >
              <div className={classes.carouselitem}>
                <Hidden xsDown>
                  {' '}
                  <img src={this.tempimageheader} />{' '}
                </Hidden>
                <div className={classes.carouseltitlesectionwrapper}>
                  <div className={classes.carouseltitlesection}>
                    <Hidden xsDown>
                      <h1 className={classes.carouseltitle}>
                        Evenemang och kontakter i Svenskfinland
                      </h1>
                      <p>
                        <strong>
                          Gör din egen profil och sprid dina evenemang och
                          nyheter med Kulturforum.fi.{' '}
                        </strong>
                        Vi ger synlighet och skapar kontakter på svenska i
                        Finland.
                      </p>
                    </Hidden>
                    <TextField
                      id="search"
                      onChange={this.props.handleChangeAutoCompleteEvenemang}
                      // label="Titel"
                      className={classes.fullwidthsearch}
                      value={searchfieldvalueevenemang}
                      placeholder="Sök evenemang"
                      onKeyPress={this.props.searchKeyPressed}
                      margin="normal"
                      InputProps={{
                        className: classes.textFieldSearch,
                        disableUnderline: true,
                      }}
                      // value={titlevalue}
                    />
                    <Button
                      className={classes.searchbutton}
                      onClick={this.props.searchButtonPressed.bind(
                        this,
                        searchfieldvalueevenemang,
                      )}
                      color="primary"
                    >
                      SÖK
                    </Button>
                  </div>
                </div>
              </div>
            </Carousel>
          </Section>

          <div className={classes.bluecontainer}>
            <Section className={`${classes.nomarginSection}`}>
              <h2 className={classes.sectiontitle}>Evenemang denna vecka</h2>
              <div className={classes.alignright}>
                <IconButton
                  onClick={() => {
                    this.props.onChangeViewMasonryEvents();
                  }}
                >
                  {' '}
                  <ViewModule />{' '}
                </IconButton>
                <IconButton
                  onClick={() => {
                    this.props.onChangeViewListEvents();
                  }}
                >
                  {' '}
                  <ViewList />{' '}
                </IconButton>
              </div>
            </Section>

            <Section
              className={`${classes.nomarginSection} ${
                classes.noPaddingsection
              }`}
            >
              <EvenemangList
                limit={columns}
                home={false}
                loading={false}
                error={false}
                evenemang={events}
                masonryview={masonryviewevents}
                listview={listviewevents}
                user={user}
                userid={userid}
                favoriter={favoriter}
              />
            </Section>
          </div>
          <div style={{ backgroundColor: '#F2F2F2' }}>
            <Section className={classes.nomarginSection}>
              <Grid container spacing={10}>
                <Grid className={classes.aligncenter} item xs={12} md={3}>
                  <img src={KulturAktor} alt="KulturAktor" />
                  <p>
                    <strong>Kulturaktörer & informatörer</strong>
                    <br />
                    Registrera dig och få synlighet för din verksamhet.
                    Fyll i på ett ställe och visas på flera.
                  </p>
                </Grid>
                <Grid className={classes.aligncenter} item xs={12} md={3}>
                  <img src={Private} alt="Private" />
                  <p>
                    <strong>Allmänna användare</strong>
                    <br />
                    Surfa runt bland evenemang och profiler. Hitta lämpliga kulturutövare och se händelser på svenska i landet.
                  </p>
                </Grid>
                <Grid className={classes.aligncenter} item xs={12} md={3}>
                  <img src={News} alt="News" />
                  <p>
                    <strong>Info om stipendier och bidrag?</strong>
                    <br />
                    Söker du medel för dina aktiviteter? På databasen <a href="https://fyrk.fi">Fyrk.fi </a>
                    listar vi aktuella finansieringsformer.
                  </p>
                </Grid>
                <Grid className={classes.aligncenter} item xs={12} md={3}>
                  <img src={Magazines} alt="Magazines" />
                  <p>
                    <strong>
                      Vill du informera om ditt evenemang i media?
                    </strong>
                    <br />
                    För bättre synlighet av dina evenemang levererar Kulturforum.fi
                    information till mediahus.
                  </p>
                </Grid>
              </Grid>
            </Section>
          </div>
          <div className={classes.bluecontainer}>
            <Section className={classes.nomarginSection}>
              <h2 className={classes.sectiontitle}>
                Nyheter från Svenskfinland
              </h2>
              <p className={classes.half}>
                Här lägger du upp aktualiteter, information om projekt och
                händelser. Tipsa om lediga jobb och praktikplatser. Alla,
                registrerade eller inte kan prenumerera på våra nyhetsbrev
                utgående från intresse, aktör eller region.
              </p>

              <div className={classes.alignright}>
                <IconButton
                  onClick={() => {
                    this.props.onChangeViewMasonryNews();
                  }}
                >
                  {' '}
                  <ViewModule />{' '}
                </IconButton>
                <IconButton
                  onClick={() => {
                    this.props.onChangeViewListNews();
                  }}
                >
                  {' '}
                  <ViewList />{' '}
                </IconButton>
              </div>
            </Section>

            <Section className={classes.nomarginSection}>
              <NyheterList
                limit={columns}
                home={false}
                loading={false}
                error={false}
                nyheter={news}
                masonryview={masonryviewnews}
                listview={listviewnews}
                user={user}
                userid={userid}
                favoriter={favoriter}
              />
            </Section>
          </div>
          <div style={{ backgroundColor: '#FFB09A' }}>
            <Section
              className={`${classes.nomarginSection} ${classes.promoSection}`}
            >
              <Grid container spacing={10}>
                <Grid item xs={1} md={2} />
                <Grid item xs={4} md={2}>
                  <p className={classes.alignright}>
                    <Favorite className={classes.promoicon} />
                  </p>
                </Grid>
                <Grid item xs={6} md={6}>
                  <h2 className={classes.nomarginbottom}>
                    Hjärtat på Kulturforum
                  </h2>
                  <p>
                    Samla och koppla samman profiler, händelser och nyheter som
                    intresserar dig med vår gillafunktion.
                  </p>
                </Grid>
                <Grid item xs={1} md={2} />
              </Grid>
            </Section>
          </div>
          <div className={classes.bluecontainer}>
            <Section className={classes.nomarginSection}>
              <h2 className={classes.sectiontitle}>Aktuella profiler</h2>
              <div className={classes.alignright}>
                <IconButton
                  onClick={() => {
                    this.props.onChangeViewMasonryProfiles();
                  }}
                >
                  {' '}
                  <ViewModule />{' '}
                </IconButton>
                <IconButton
                  onClick={() => {
                    this.props.onChangeViewListProfiles();
                  }}
                >
                  {' '}
                  <ViewList />{' '}
                </IconButton>
              </div>
            </Section>

            <Section className={classes.nomarginSection}>
              <KulturList
                limit={columns}
                home={false}
                loading={false}
                error={false}
                kultur={profiles}
                masonryview={masonryviewprofiles}
                listview={listviewprofiles}
                user={user}
                userid={userid}
                favoriter={favoriter}
              />
            </Section>
          </div>
        </div>
      </article>
    );
  }
}

HomePage.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  evenemang: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  onSubmitForm: PropTypes.func,
  username: PropTypes.string,
  onChangeUsername: PropTypes.func,
};

export function mapDispatchToProps(dispatch) {
  return {
    onSubmitForm: evt => {
      dispatch(loadData());
    },
    searchKeyPressed: (event, value) => {
      if (event.key === 'Enter') {
        dispatch(handleSearchEvenemang(event.target.value));
        dispatch(loadEvenemang());
        dispatch(push(`/evenemang`));
      }
    },
    searchButtonPressed: (evt, value) => {
      dispatch(handleSearchEvenemang(value));
      dispatch(loadEvenemang());
      dispatch(push(`/evenemang`));
    },
    onChangeViewListEvents: evt => dispatch(toListViewEvents()),
    onChangeViewMasonryEvents: evt => dispatch(toMasonryViewEvents()),
    onChangeViewListNews: evt => dispatch(toListViewNews()),
    onChangeViewMasonryNews: evt => dispatch(toMasonryViewNews()),
    onChangeViewListProfiles: evt => dispatch(toListViewProfiles()),
    onChangeViewMasonryProfiles: evt => dispatch(toMasonryViewProfiles()),
    handleChangeAutoCompleteEvenemang: (event, value) => {
      dispatch(handleSearchFieldValueEvenemang(event.target.value));
    },
  };
}

const mapStateToProps = createStructuredSelector({
  profiles: getHomeProfiles(),
  news: getHomeNews(),
  events: getHomeEvents(),
  listviewevents: makeSelectListViewEvents(),
  masonryviewevents: makeSelectMasonryViewEvents(),
  listviewnews: makeSelectListViewNews(),
  masonryviewnews: makeSelectMasonryViewNews(),
  listviewprofiles: makeSelectListViewProfiles(),
  masonryviewprofiles: makeSelectMasonryViewProfiles(),
  userid: makeSelectUserId(),
  user: makeSelectCurrentUser(),
  favoriter: getFavoriter(),
  helptexts: getHelp(),
  searchfieldvalueevenemang: makeSelectSearchFieldValueEvenemang(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'home', reducer });
const withSaga = injectSaga({ key: 'home', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withWidth(),
  withStyles(styles),
)(HomePage);
