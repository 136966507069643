/*
 *
 * GroupPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  PASSWORD_CHANGE,
  PASSWORDCONFIRM_CHANGE,
  AUTH_FAILURE,
  REGISTER_SUCCESS,
  SET_LOADING,
  SET_ERROR,
  SET_OPEN,
  SET_GROUP,
  DEFAULT_ACTION,
  LOGIN_CHANGE,
  CHANGE_ORGANISATION,
  CHANGE_NEWORGANISATION,
  CHANGE_LASTNAME,
  CHANGE_FIRSTNAME
} from './constants';

export const initialState = fromJS({
  login: '',
  password: '',
  passwordconfirm: '',
  message: '',
  loading: false,
  token: '',
  error: false,
  registersuccess: false,
  newgroup : false,
  neworganisation : "",
  organisation: null,
  lastname : "",
  firstname : "",
  open : false
});

function GroupPageReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case LOGIN_CHANGE:
      return state.set('login', action.value);
    case PASSWORD_CHANGE:
      return state.set('password', action.value);
    case PASSWORDCONFIRM_CHANGE:
      return state.set('passwordconfirm', action.value);
    case AUTH_FAILURE:
      return state.set('message', action.value);
    case REGISTER_SUCCESS:
      return state.set('loading', false).set('registersuccess', true);
    case SET_LOADING:
      return state.set('loading', action.value);
    case SET_OPEN:
      return state.set('open', action.value);
    case SET_ERROR:
      return state.set('error', action.value);
    case SET_GROUP:
      return state.set('newgroup', action.value);
    case CHANGE_ORGANISATION:
      return state.set('organisation', action.value);
    case CHANGE_LASTNAME:
      return state.set('lastname', action.value);
    case CHANGE_FIRSTNAME:
        return state.set('firstname', action.value);
    case CHANGE_NEWORGANISATION:
      return state.set('neworganisation', action.value);
    default:
      return state;
  }
}

export default GroupPageReducer;
