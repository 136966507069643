/*
 * HomeReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */
import { fromJS } from 'immutable';

import { EVENEMANG_LOADED,SET_PROFILE,SUBEVENEMANG_LOADED,OPEN_DIALOG ,CLOSE_DIALOG,SET_TABVALUE,SET_TABVALUE_TITLE} from './constants';

// The initial state of the App
const initialState = fromJS({
  evenemang: false,
  subevenemang : false,
  profile:false,
  open : false,
  tabvalue : 0,
  tabvaluetitle : 0,
});

function EvenemangsingleReducer(state = initialState, action) {
  switch (action.type) {
    case EVENEMANG_LOADED:
      return state.set('evenemang', action.evenemang);
    case SUBEVENEMANG_LOADED:
        return state.set('subevenemang', action.subevenemang);
    case SET_PROFILE:
      return state.set('profile', action.profile);
    case SET_TABVALUE:
      return state.set('tabvalue', action.value);
    case SET_TABVALUE_TITLE:
      return state.set('tabvaluetitle', action.value);
    case OPEN_DIALOG:
        return state.set('open', true);
    case CLOSE_DIALOG:
        return state.set('open', false);
    default:
      return state;
  }
}

export default EvenemangsingleReducer;
