/*
 *
 * LoginPage constants
 *
 */

export const DEFAULT_ACTION = 'app/LoginPage/DEFAULT_ACTION';
export const LOGIN_CHANGE = 'app/LoginPage/LOGIN_CHANGE';
export const PASSWORD_CHANGE = 'app/LoginPage/PASSWORD_CHANGE';
export const SUBMITLOGIN = 'app/LoginPage/SUBMITLOGIN';
export const AUTH_FAILURE = 'app/LoginPage/AUTH_FAILURE';
export const SET_LOADING = 'app/LoginPage/SET_LOADING';
export const CONFIRM_TOKEN = 'app/LoginPage/CONFIRM_TOKEN';
